import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import { 
        getPublishedTasks, 
        loadMorePublishedTasks,
        watchPublishedTasks, 
        filterPublishedTasks, 
        updateTask, 
        setEscalation, 
        createRating,
        getTaskDetails
} from '../../../../actions/taskAction'
import TaskList from './List';
import TaskItem from './Item';
import PauseTaskModal from '../../Modals/PauseTaskModal';
import StopTaskModal from '../../Modals/StopTaskModal';
import EscalateTaskModal from '../../Modals/EscalateTaskModal';
import TaskDetailsModal from '../../Modals/TaskDetailsModal';
import SpinnerModal from '../../../Spinner/SpinnerModal'
import CouponCodeModal from '../../Modals/CouponCode'
import MessageModal from '../../../Modals/Message'

import {
    setUserPresence
} from '../../../../actions/authAction';

import $ from 'jquery'

import {
    translate
} from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            filterType : "all",
            filterStatus : "all",
            isEmpty : false,
            selectedTask : {},
            isPauseTaskModalActive : false,
            pauseModalCaller : "main",
            isStopTaskModalActive : false,
            isEscalateTaskModalActive : false,
            isTaskDetailsModalActive : false,
            isProcessing : false,
            activeTab : "task-files",
            isAuthenticated : false,
            couponCode : "",
            isMessageModalActive : false,
            itemCt : 0
        }
    }

    async componentDidMount(){

        const { auth, userProfile } = this.props;

        if(auth.uid){
           
            this.props.setUserPresence({
                uid : auth.uid
            })
            this.loadTasks(true);
            this.unsubscribeWatchPublishedTasks = this.props.watchPublishedTasks();

            let task = this.props.history.location.state=== undefined ? null : this.props.history.location.state !== null ? this.props.history.location.state.task : null;
           
            if(task!==null){
                let displayTask = await this.props.getTaskDetails(task.uid);
                this.openTaskDetailsModal(displayTask);
                
            }
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }
    
    componentDidUpdate(prevProps){

        if(prevProps.typeFilter!==this.props.typeFilter || prevProps.statusFilter!==this.props.statusFilter){
            this.loadTasks();
        }

        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
        
    }

    componentWillMMount(){
        this.unsubscribeWatchPublishedTasks && this.unsubscribeWatchPublishedTasks();
    }

    loadTasks = async (firstLoad = false) => {
        await this.props.getPublishedTasks(firstLoad);
    }
    
    loadMoreTasks = () => {
        this.props.loadMorePublishedTasks();
    }

    filterByStatus = (status) => {

        const { filterStatus } = this.state;

        if(status===filterStatus){
            this.setState({
                filterStatus : "all"
            }, async () => {
                const { filterType, filterStatus } = this.state;
                await this.props.filterPublishedTasks(filterStatus, filterType);
            })
        }else{
            
            this.setState({
                filterStatus : status
            }, async () => {
                const { filterType, filterStatus } = this.state;
                this.props.filterPublishedTasks(filterStatus, filterType);
            })
        }

    }

    filterByType = (type) => {
        this.setState({
            filterType : type
        }, async () => {
            const { filterType, filterStatus } = this.state;
            this.props.filterPublishedTasks(filterStatus, filterType);
        })
    }

    openPauseTaskModal = (task, caller = "main") => {
        this.setState({
            isPauseTaskModalActive : true,
            selectedTask : {...task},
            pauseModalCaller : caller
        })
    }

    closePauseTaskModal = () => {
        $('#pauseTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                isPauseTaskModalActive : false,
                selectedTask : this.state.isTaskDetailsModalActive ? this.state.selectedTask : {}
            })
        }, 1500)
    }

    pauseTask = async (pauseData) => {
        const { selectedTask } = this.state;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            pauseReason : pauseData.pauseReason,
            dateToContinue : pauseData.dateToContinue,
            status : "paused"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.updateTask(data);

            this.loadTasks();

            const { pauseModalCaller } = this.state;
            $('#pauseTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
            if(pauseModalCaller==="main"){
                this.setState({
                    isProcessing : false,
                    selectedTask : null
                })
            }else{
                this.setState({
                    isProcessing : false,
                    isTaskDetailsModalActive : false,
                    selectedTask : null
                })
            }

            setTimeout( () => {
                if(pauseModalCaller==="main"){
                    this.setState({
                        isPauseTaskModalActive : false,
                    })
                }else{
                    this.setState({
                        isPauseTaskModalActive : false,
                    })
                }
            }, 1500)
        })  
    }

    giveFeedback = (task) => {
        if(task.status!=="completed"){
            this.setState({
                isMessageModalActive : true
            })
        }else{
            this.openTaskDetailsModal(task, "billing-details");
        }
    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        })
    }

    openTaskDetailsModal = (task, activeTab = "task-files") => {
        this.setState({
            isTaskDetailsModalActive : true,
            selectedTask : {...task},
            activeTab : activeTab
        })
    }

    closeTaskDetailsModal = () => {
        const { currentLanguage } = this.props;

        $('#taskDetails .modal-dialog').attr('class',`modal-dialog modal-dialog-centered ${currentLanguage==="ar"? 'slideOutLeft' : 'slideOutRight'} animated`)
        setTimeout( () =>{
            this.setState({
                isTaskDetailsModalActive : false,
                selectedTask : {}
            })
        }, 1500)
    }


    openCouponCodeModal = () => {
        this.setState({
            isCouponCodeModalActive : true
        })
    }

    closeCouponCodeModal = () => {
        $('#couponCodeModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

        setTimeout( () => {
            this.setState({
                isCouponCodeModalActive : false
            })
        }, 1500)
    }

    addCouponCode = (couponCode) => {
        this.setState({
            couponCode : couponCode,
            isCouponCodeModalActive : false
        })
    }

    removeCouponCode = () => {
        this.setState({
            couponCode : ""
        })
    }

    stopTask = async (stopData) => {
        const { selectedTask } = this.state;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            stopReason : stopData.stopReason,
            status : "stopped"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.updateTask(data);

            this.loadTasks();
            $('#stopTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

            setTimeout( () =>{
                this.setState({
                    isStopTaskModalActive : false,
                    isProcessing : false
                })
            }, 1500)

        })
    }

    openEscalateTaskModal = (task) => {
        this.setState({
            isEscalateTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeEscalateTaskModal = () => {
        $('#escalateTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

        setTimeout( () => {
            this.setState({
                isEscalateTaskModalActive : false,
                selectedTask : this.state.isTaskDetailsModalActive ? this.state.selectedTask : {}
            })
        }, 1500)
    }

    escalateTask = async (escalateData) => {
        const { selectedTask } = this.state;

        let data = {
            taskUid : selectedTask.uid,
            escalationLetter : escalateData.escalationLetter,
            escalationReason : escalateData.escalationReason,
            operator : ""
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.setEscalation(data);

            this.loadTasks()
            $('#escalateTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

            setTimeout( () => {
                this.setState({
                    isEscalateTaskModalActive : false,
                    isProcessing : false
                })
            }, 1500)
            
        })
    }

    openStopTaskModal = (task, caller = "main") => {
        this.setState({
            isStopTaskModalActive : true,
            selectedTask : {...task},
            pauseModalCaller : caller
        })
    }

    closeStopTaskModal = () => {
        const { pauseModalCaller } = this.state;
        $('#stopTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

        setTimeout( () => {
            this.setState({
                isStopTaskModalActive : false,
                selectedTask : this.state.isTaskDetailsModalActive ? this.state.selectedTask : null
            })
        }, 1500)
    }

    toggleProcessing = (signal) => {
        this.setState({
            isProcessing : signal
        })    
    }

    resetItemDelay = () => {
        this.setState({
            itemCt : 0
        })
    }

    render() {

        const { tasks, isLoadingMorePublishedTasks, isGettingTasks, isPublishedTasksEmpty, statusFilter, typeFilter } = this.props;
        const { auth, userProfile, isLoggingOut } = this.props;
        const { activeConversation } = this.props;
        const { isMobileView } = this.props;
        const { currentLanguage, t } = this.props;

        const {
            isAuthenticated, 
            activeTab, 
            isPauseTaskModalActive, 
            isStopTaskModalActive, 
            isEscalateTaskModalActive, 
            isTaskDetailsModalActive, 
            isCouponCodeModalActive,
            isProcessing, selectedTask,
            couponCode,
            isMessageModalActive,
            itemCt
        } = this.state;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="customer"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder page">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        {
                            (isPublishedTasksEmpty && tasks.length===0) ? 
                            isMobileView ?
                            <div style={{ marginTop : "0" }} className="landing-empty-task">
                                <div className="empty-heading">
                                    <p style={{ fontSize : "25px", lineHeight : "40px" }} className="empty-task-heading-text">{t('published_empty_header')}</p>
                                    <img style={{ width : "100%", heigth : "80%" }} src={require('../../../../images/create-task-empty-mob.png')}/>
                                    <span style={{ fontWeight : "bold", marginBottom : "15px", marginTop : "15px" }} className="empty-task-heading-sub">{t('published_empty_sub_header')}</span>

                                    <Link style={{ marginTop : "0", width : "100%" }} className="btn-link empty-task-heading-button" to="/task/create">{t('common_create_task')}</Link>
                                </div>
                            </div>:
                            <div className="landing-empty-task">
                                <div className="empty-heading">
                                    <p className="empty-task-heading-text">{t('published_empty_header')}</p>
                                    <span className="empty-task-heading-sub">{t('published_empty_sub_header')}</span>
                                    <Link className="btn-link empty-task-heading-button" to="/task/create">{t('common_create_task')}</Link>
                                </div>
                            </div>
                            :
                            <div className="div-holder">
                                {
                                    (isMobileView) ?
                                    <div className="module-header-div">
                                        <div className="align-left" style={{ flex : "1" }}>
                                        <p className="module-header">{t('published_header')}</p>
                                        </div>
                                        <div className="align-right" style={{ flex : "1", paddingTop : "10px" }}>
                                            <div className="align-left" style={{ display : "flex", flexWrap : "wrap" }}>
                                                <div style={{ flex : "1" }}>
                                                    <span style={{ display : "block", width: "100px", fontSize : "12px", color : "#414042"}}>{t('published_filter_by_stat')}</span>
                                                    <ul className="status-filters">
                                                        <li onClick={ () => this.filterByStatus('inProgress')}><span style={ statusFilter==="inProgress" ? {  background: "#FF8E26", color : "white" } : {   background: "#F5F4F6", color : "#7D7C7F" } } className="status-filter">{t('common_stat_in_progress')}</span></li>
                                                        <li onClick={ () => this.filterByStatus('completed')}><span style={ statusFilter==="completed" ? {  background: "#66BB6A", color : "white" } : {   background: "#F5F4F6", color : "#7D7C7F" }  } className="status-filter">{t('common_stat_completed')}</span></li>
                                                    </ul>
                                                </div>
                                                <div className="align-left" style={{ flex : "1" }}>
                                                    <span style={{ fontSize : "12px", color : "#414042", width : "200px"}}>{t('published_filter_by_type')}</span>
                                                    <ul className="type-filters">
                                                        <li onClick={ () => this.filterByType('all')}><span style={ typeFilter==="all" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">{t('common_all')}</span></li>
                                                        <li onClick={ () => this.filterByType('paid')}><span style={ typeFilter==="paid" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">{t('common_pay_stat_paid')}</span></li>
                                                        <li onClick={ () => this.filterByType('unpaid')}><span style={ typeFilter==="unpaid" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">{t('common_pay_stat_unpaid')}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="module-header-div">
                                        <div className="align-left" style={{ flex : "1" }}>
                                            <p className="module-header">{t('published_header')}</p>
                                        </div>
                                        <div className="align-right" style={{ flex : "1", paddingTop : "10px" }}>
                                            <div style={{ display : "flex" }}>
                                                <div style={{ flex : "1" }}>
                                                    <ul className="status-filters">
                                                        <li style={{ paddingTop: "10px" }}><span style={{ display : "block", width: "100px", fontSize : "12px", color : "#414042"}}>{t('published_filter_by_stat')}</span></li>
                                                        <li onClick={ () => this.filterByStatus('inProgress')}><span style={ statusFilter==="inProgress" ? {  background: "#FF8E26", color : "white" } : {   background: "#F5F4F6", color : "#7D7C7F" } } className="status-filter">{t('common_stat_in_progress')}</span></li>
                                                        <li onClick={ () => this.filterByStatus('completed')}><span style={ statusFilter==="completed" ? {  background: "#66BB6A", color : "white" } : {   background: "#F5F4F6", color : "#7D7C7F" }  } className="status-filter">{t('common_stat_completed')}</span></li>
                                                    </ul>
                                                </div>
                                                <div style={{ flex : "1" }}>
                                                    <ul className="type-filters">
                                                        <li style={{ paddingTop: "10px", width : "100px" }}><span style={{ fontSize : "12px", color : "#414042", width : "200px"}}>{t('published_filter_by_type')}</span></li>
                                                        <li onClick={ () => this.filterByType('all')}><span style={ typeFilter==="all" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">{t('common_all')}</span></li>
                                                        <li onClick={ () => this.filterByType('paid')}><span style={ typeFilter==="paid" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">{t('common_pay_stat_paid')}</span></li>
                                                        <li onClick={ () => this.filterByType('unpaid')}><span style={ typeFilter==="unpaid" ? { background : '#7AB7B6', color : "white"} : {} } className="type-filter">{t('common_pay_stat_unpaid')}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="div-holder">
                                {
                                    isGettingTasks ? 
                                    <div className="d-flex justify-content-center">
                                        <div style={{ width: "5rem", height : "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    tasks.length!==0 ?  
                                    <TaskList
                                        itemCt={itemCt}
                                        t={t}
                                        currentLanguage={currentLanguage}
                                        isMobileView={isMobileView}
                                        giveFeedback={this.giveFeedback}
                                        isLoadingMorePublishedTasks={isLoadingMorePublishedTasks}
                                        loadMoreTasks={this.loadMoreTasks}
                                        loadTasks={this.loadTasks} 
                                        tasks={tasks}
                                        openPauseTaskModal={this.openPauseTaskModal}
                                        openStopTaskModal={this.openStopTaskModal}
                                        openEscalateTaskModal={this.openEscalateTaskModal}
                                        openTaskDetailsModal={this.openTaskDetailsModal}
                                        /> : 
                                    <div className="filter-no-task">
                                        <img style={{ width : isMobileView  ? "50%" : "25%" }} src={require('../../../../images/no-task.png')}/>
                                        <p className="filter-no-task-text">{t('published_empty_list_header')}</p>
                                        <Link to="/task/create"><button className="filter-no-task-button">{t('navbar_create_task')}</button></Link>
                                    </div>
                                }
                            </div>
                            {
                                isPauseTaskModalActive && <PauseTaskModal pauseTask={this.pauseTask} closePauseTaskModal={this.closePauseTaskModal} isOpened={isPauseTaskModalActive}/>
                            }
                            {
                                isStopTaskModalActive && <StopTaskModal stopTask={this.stopTask} closeStopTaskModal={this.closeStopTaskModal} isOpened={isStopTaskModalActive}/>
                            }
                            {
                                isEscalateTaskModalActive && <EscalateTaskModal userProfile={userProfile} escalateTask={this.escalateTask} closeEscalateTaskModal={this.closeEscalateTaskModal} isOpened={isEscalateTaskModalActive}/>
                            }
                            {
                                isTaskDetailsModalActive && <TaskDetailsModal
                                currentLanguage={currentLanguage}
                                openPauseTaskModal={this.openPauseTaskModal}
                                openStopTaskModal={this.openStopTaskModal}
                                openEscalateTaskModal={this.openEscalateTaskModal}     
                                toggleProcessing={this.toggleProcessing}                                
                                couponCode={couponCode}
                                removeCouponCode={this.removeCouponCode} openCouponCodeModal={this.openCouponCodeModal} activeTab={activeTab} closeTaskDetailsModal={this.closeTaskDetailsModal} isOpened={isTaskDetailsModalActive} task={selectedTask} />
                            }
                            {
                                isCouponCodeModalActive &&
                                <CouponCodeModal 
                                    addCouponCode={this.addCouponCode}
                                    closeCouponCodeModal={this.closeCouponCodeModal}
                                    isOpened ={ isCouponCodeModalActive }
                                />
                            }
                            {
                                isProcessing && 
                                <SpinnerModal
                                    isOpened={isProcessing}
                                />
                            }
                            {
                                isLoggingOut && 
                                <SpinnerModal
                                    isOpened={isLoggingOut}
                                />
                            }
                            {
                                isMessageModalActive && 
                                <MessageModal 
                                    t={t}
                                    isOpened={isMessageModalActive}
                                    header={""}
                                    subHeader={t('published_task_feedback_unavailable')}
                                    closeMessageModal={this.closeMessageModal}
                                />
                            }
                            </div>
                        }
                    </div>
                }
            </div>
        )


        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        isLoggingOut: state.auth.isLoggingOut,
        userProfile : state.firebase.profile,
        tasks : state.task.publishedTasks,
        isPublishedTasksEmpty : state.task.isPublishedTasksEmpty,
        isLoadingMorePublishedTasks : state.task.isLoadingMorePublishedTasks,
        isGettingTasks : state.task.isGettingTasks,
        statusFilter : state.task.statusFilter,
        typeFilter : state.task.typeFilter,
        activeConversation : state.conversation.activeConversation,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect( mapStateToProps, { 
        setUserPresence, 
        watchPublishedTasks, 
        getPublishedTasks, 
        loadMorePublishedTasks, 
        filterPublishedTasks, 
        updateTask, 
        setEscalation, 
        createRating,
        getTaskDetails
    })
)(Index)

