import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import _ from 'lodash/'
import axios from 'axios'
import braintree from 'braintree-web';

import {
    updateCurrentUser,
    uploadUserImage,
    checkForDuplicateEmail,
    updateUserEmail,
    updateUserPassword,
    signOutUser,
    reAuthenticateUser,
    updateUserSettings,
    setUserPresence,
    getSignInMethods
} from '../../actions/authAction';

import {
    addModal,
    popModal
} from '../../actions/appAction';

import {
    Message
} from '../Modals/Message'
import {
    Reauthenticate
} from './Modals/Reauthenticate'
import {
    NewPassword
} from './Modals/NewPassword'
import {
    SpinnerModal
} from '../Spinner/SpinnerModal'

import { translate } from "react-switch-lang"

const countryList = require('country-list');
const countryCodes = require('country-codes-list')
const myCountryCodesObject = countryCodes.customList('countryNameEn','+{countryCallingCode}')

const acceptedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg"
]

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isAuthenticated : false,
            displayPhoto : "",
            phoneCode : "",
            profileData : {},
            changedProfileData : {},
            changeCount : 0,
            isChanged : false,
            errorCount : 0,
            isFullnameValid : true,
            isFullnameChanged : false,
            isLastnameValid : true,
            isLastnameChanged : false,
            isFirstnameValid : true,
            isFirstnameChanged : false,
            isMessageModalActive : false,
            messageModalHeader : "",
            messageModalSubHeader : "",
            isReauthenticateModalActive : false,
            isPasswordChangeModalActive : false,
            isEmailValid : true,
            isEmailChanged : false,
            isCountryChanged : false,
            isCountryValid : true,
            isPhoneNumberChanged : false,
            isPhoneNumberValid : true,
            isProcessing : false,
            
            authEmail : "",
            authPassword : "",
            isLoggingIn : false,
            errorMessage : "",
            reAuthFor : "",
            newAuthPassword : "",
            passwordError : false,
            passwordConfirmError : false,

            paymentMethodDetails : null,
            translatorBankAccountDetails : null,
            accountName : "",
            accountNumber : "",
            bankName : "",

            clientInstance : null,
            hostedFieldsInstance : null,
            isEditingBillingMethod : false,
            isEditingPaypalAccount : false,
            paypalMethodDetails : null,

            isErrorMessageModalActive : false,

            isEmailEditable : true,
            isPasswordHidden : false
        }

        this.checkForEmailDuplication = _.debounce(this.checkForEmailDuplication,500);
    }

    componentWillUnmount(){
        $('.spinner-modal').remove();
    }

    async componentDidMount(){
        
        const { auth, userProfile } = this.props;

        if(auth.uid){
            this.props.setUserPresence({
                uid : auth.uid
            });
        }

        if(userProfile.hasOwnProperty('fullName')){

            let splittedName = userProfile.fullName.split(",");

            let profileData = {
                fullName : userProfile.fullName,
                firstName : splittedName[1].trim(),
                lastName : splittedName[0],
                country : userProfile.country,
                emailAddress : userProfile.emailAddress,
                phoneNumber : userProfile.phoneNumber.replace(myCountryCodesObject[userProfile.country],'')
            }

            this.setState({
                isAuthenticated : true,
                phoneCode : myCountryCodesObject[userProfile.country],
                profileData : profileData,
                changedProfileData : profileData
            })

            this.checkBilling();
            // this.checkPaypal();

            // let result = await this.props.getSignInMethods()

            if(userProfile.loginType==="facebook" || userProfile.loginType==="google"){
                this.setState({
                    isEmailEditable : false,
                    isPasswordHidden : true
                })
            }
            
        }

        window.addEventListener("resize", this.onWindowResize.bind(this));

    }

    async componentDidUpdate(prevProps){

        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true,
                phoneCode : myCountryCodesObject[this.props.userProfile.country],
            })

            this.checkBilling();
            // this.checkPaypal();
            if(this.props.userProfile.loginType==="facebook" || this.props.userProfile.loginType==="google"){
                this.setState({
                    isEmailEditable : false,
                    isPasswordHidden : true
                })
            }
        }

    }

    closeMessageModal = () => {
        $('#messageModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            if($('.modal-backdrop')[0]!==undefined){
                $('.modal-backdrop')[0].remove();
            }
            if($('.spinner-modal')[0]!==undefined){
                $('.spinner-modal')[0].remove();
            }
        }, 800)
        setTimeout( () => {
            this.setState({
                isProcessing : false
            }, () => {
                this.props.signOutUser();
                
            })
        }, 1500)
        $('.spinner-modal').remove();
    }

    closeErrorMessageModal = () => {
        $('#messageModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                isErrorMessageModalActive : false
            })
        }, 1500)
    }

    /**
     * PASSWORD FUNCTIONS
     */
    onPasswordClick = () => {
        this.setState({
            isPasswordChangeModalActive : true,
            reAuthFor : "password"
        })
    }

    onPasswordChange = async (e) => {

        const { t } = this.props;

        let confirmPass = $('#authPasswordConfirm').val();
        let newPass = e.target.value;

        let regExp = /^[a-zA-Z0-9\u0660-\u0669]+$/i;
        $('#newAuthPassword').removeClass('error-input');
        $('#newAuthPassword').next('span').text('');

        if(newPass.length===0){
            return
        }

        if(!regExp.test(newPass)){
            $('#newAuthPassword').addClass('error-input');
            $('#newAuthPassword').next('span').text(t('signup_message_password_format'));
            this.setState({
                passwordError : true
            })
        }else{
            if(newPass.length<6){
                $('#newAuthPassword').addClass('error-input');
                $('#newAuthPassword').next('span').text(t('signup_message_password_too_short'));
                this.setState({
                    newAuthPassword : newPass,
                    passwordError : true
                })
            }else{
                $('#newAuthPassword').removeClass('error-input')
                $('#newAuthPassword').next('span').text('');
                this.setState({
                    newAuthPassword : newPass,
                    passwordError : false
                }, () => {
                    if(confirmPass!==newPass){
                        this.setState({
                            passwordConfirmError : true
                        })
                    }
                })
            }  
        }
    }

    onPasswordConfirmChange = async (e) => {

        $('#authPasswordConfirm').removeClass('error-input');
        $('#authPasswordConfirm').next('span').text('');

        const { newAuthPassword } = this.state;
        const { t } = this.props;

        if(e.target.value!==newAuthPassword){
            $('#authPasswordConfirm').addClass('error-input');
            $('#authPasswordConfirm').next('span').text(t('profile_password_dont_match'));
            this.setState({
                passwordConfirmError : true
            })
        }else{
            $('#authPasswordConfirm').removeClass('error-input');
            $('#authPasswordConfirm').next('span').text();
            this.setState({
                passwordConfirmError : false
            })
        }
    }
    
    onSavePasswordChange = async () => {

        const { passwordError, passwordConfirmError } = this.state;

        if( !passwordError && !passwordConfirmError ){
            this.setState({
                isProcessing : true
            }, async () => {
                const { newAuthPassword } = this.state;
                const { t } = this.props;
                
                let result = await this.props.updateUserPassword({
                    password : newAuthPassword
                })
    
                if(result.code===201){
                    $('#reAuthModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
                    setTimeout( () => {
                        this.setState({
                            isReauthenticateModalActive : true,
                            reAuthFor : "password",
                            isProcessing : false
                        })
                    }, 1500)
                }else{
                    $('#passwordChangeModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
                    setTimeout ( () => {
                        this.setState({
                            isProcessing : false,
                            isMessageModalActive : true,
                            messageModalHeader : t('profile_password_updated'),
                            isPasswordChangeModalActive : false,
                        })
                    }, 1500)
                }
            })
        }

    }

    closeNewPasswordModal = () => {
        $('#passwordChangeModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                isPasswordChangeModalActive : false
            })
        }, 1500)
    }

    /**
     * EMAIL FUNCTIONS
     */
    onEmailChange = async (e) => {

        const { t } = this.props;
        const { changedProfileData,  } = this.state;
        const { userProfile } = this.props;
      
        let cData = changedProfileData;
        cData[e.target.name] = e.target.value;

        let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
        let emailVal = e.target.value;

        if(!regExp.test(emailVal)){
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').parent('div').next('.block align-left error-input-message').text(t('login_message_incorrect_email'));
            this.setState({
                isEmailValid : false
            })
        }else{
            if(userProfile.emailAddress!==emailVal){
                await this.checkForEmailDuplication(emailVal);  
            }else{
                $('#emailAddress').removeClass('error-input');
                $('#emailAddress').parent('div').next('.block align-left error-input-message').text('');
                this.setState({
                    isEmailValid : true,
                    isEmailChanged : false
                })
            }
        }

    }

    checkForEmailDuplication = async (email) => {
        const { t } = this.props;
        let x = await this.props.checkForDuplicateEmail(email);
        if(x.code==="success"){
            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').parent('div').next('.block align-left error-input-message').text('');
            this.setState({
                isEmailValid : true,
                isEmailChanged : true
            })
        }else{
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').next('.block align-left error-input-message').text(t('signup_message_used_email'));
            this.setState({
                isEmailValid : false,
                isEmailChanged : true
            })
        }
    }

    saveEmail = async () => {

        const { t } = this.props;
        this.setState({
            isProcessing : true
        }, async () => {
            const { changedProfileData } = this.state;

            let result = await this.props.updateUserEmail({
                email : changedProfileData.emailAddress
            })
            
            if(result.code===201){
                $('#reAuthModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
                setTimeout( () => {
                    this.setState({
                        isReauthenticateModalActive : true,
                        reAuthFor : "email",
                        isProcessing : false
                    })
                }, 1500)
            }else{
    
                await this.props.updateCurrentUser({
                    emailAddress : changedProfileData.emailAddress
                })

                this.setState({
                    isProcessing : false,
                })
                $('#messageModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
                
                setTimeout( () => {
                    this.setState({
                        isProcessing : false,
                        isMessageModalActive : true,
                        messageModalHeader : t('profile_modal_updated_header'),
                        messageModalSubHeader : t('profile_modal_updated_sub_header'),
                    })
                }, 1500)
            }   
        })
       
    }

    /**
     * FULL NAME FUNCTIONS
     */
    onFullNameChange = async (e) => {

        const { changedProfileData } = this.state;
        const { userProfile } = this.props;

        if(e.target.name==="fullName"){
            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+$/;

            if(regExp.test(e.target.value)){
                $('#fullName').addClass('error-input');
                $('#fullName').parent('div').next('.block align-left error-input-message').text( `Name should be on the following format, Last Name, First Name` );
                this.setState({
                    isFullnameValid : false
                })
            }else{
                $('#fullName').removeClass('error-input');
                $('#fullName').parent('div').next('.block align-left error-input-message').text( `` );
                if(userProfile.fullName.trim()!==e.target.value.trim()){
                   
                    let cData = changedProfileData;
                    cData[e.target.name] = e.target.value;

                    this.setState({
                        isFullnameValid : true,
                        isFullnameChanged : true,
                        changedProfileData : {...cData}
                    })

                }else{
                    this.setState({
                        isFullnameValid : true,
                        isFullnameChanged : false
                    })
                }
            }
        }

    }

    onLastNameChange = async (e) => {

        const { changedProfileData } = this.state;
        const { userProfile, t } = this.props;

        if(e.target.name==="lastName"){
            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+$/;

            if(regExp.test(e.target.value)){
                $('#lastName').addClass('error-input');
                $('#lastName').parent('div').next('.block align-left error-input-message').text(t('signup_message_incorrect_name'));
                this.setState({
                    isLastnameValid : false
                })
            }else{
                $('#lastName').removeClass('error-input');
                $('#lastName').parent('div').next('.block align-left error-input-message').text( `` );
                if(userProfile.fullName.trim()!==e.target.value.trim()){
                   
                    if(e.target.value.trim()!==""){
                        let cData = changedProfileData;
                        cData[e.target.name] = e.target.value;

                        this.setState({
                            isLastnameValid : true,
                            isLastnameChanged : true,
                            changedProfileData : {...cData}
                        })
                    }else{
                        this.setState({
                            isLastnameValid : false
                        })
                    }

                }else{
                    this.setState({
                        isLastnameValid : true,
                        isLastnameChanged : false
                    })
                }
            }
        }

    }

    onFirstNameChange = async (e) => {

        const { changedProfileData } = this.state;
        const { userProfile, t } = this.props;

        if(e.target.name==="firstName"){
            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+$/;

            if(regExp.test(e.target.value)){
                $('#firstName').addClass('error-input');
                $('#firstName').parent('div').next('.block align-left error-input-message').text( t('signup_message_incorrect_name'));
                this.setState({
                    isFirstnameValid : false
                })
            }else{
                $('#firstName').removeClass('error-input');
                $('#firstName').parent('div').next('.block align-left error-input-message').text( `` );
                if(userProfile.fullName.split(",")[1].trim()!==e.target.value.trim()){
                   
                    if(e.target.value.trim()!==""){
                        let cData = changedProfileData;
                        cData[e.target.name] = e.target.value;

                        this.setState({
                            isFirstnameValid : true,
                            isFirstnameChanged : true,
                            changedProfileData : {...cData}
                        })
                    }else{
                        this.setState({
                            isFirstnameValid : false
                        })
                    }

                }else{
                    this.setState({
                        isFirstnameValid : true,
                        isFirstnameChanged : false
                    })
                }
            }
        }

    }

    saveFullname = () => {

        const { changedProfileData } = this.state;
    
        this.setState({
            isProcessing : true
        }, async () => {

            await this.props.updateCurrentUser({
                fullName : changedProfileData.fullName
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isFullnameChanged : false
                })
            }, 500)
        })
    }

    saveFirstname = () => {

        const { changedProfileData } = this.state;
        const { userProfile } = this.props;

        this.setState({
            isProcessing : true
        }, async () => {

            await this.props.updateCurrentUser({
                fullName : userProfile.fullName.split(",")[0] + ", "+changedProfileData.firstName
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isFirstnameChanged : false
                })
            }, 500)
        })
    }

    saveLastname = () => {

        const { changedProfileData } = this.state;
        const { userProfile } = this.props;

        this.setState({
            isProcessing : true
        }, async () => {

            await this.props.updateCurrentUser({
                fullName : changedProfileData.lastName + ", "+userProfile.fullName.split(",")[1].trim()
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isLastnameChanged : false
                })
            }, 500)
        })
    }


    /**
     * COUNTRY FUNCTIONS
     */
    onCountryChange = (e) => {
        const { userProfile } = this.props;
        const { changedProfileData } = this.state;

        if(e.target.value!==""){
            if(userProfile.country!==e.target.value){

                let cData = changedProfileData;
                cData[e.target.name] = e.target.value;

                this.setState({
                    isCountryChanged : true,
                    isCountryValid : true,
                    phoneCode : myCountryCodesObject[e.target.value],
                    changedProfileData : {...cData}
                })
            }else{
                this.setState({
                    isCountryChanged : false
                })
            }
        }else{
            this.setState({
                isCountryValid : false
            })
        }
    }

    saveCountry = async (e) => {

        this.setState({
            isProcessing : true
        }, async () => {

            const { changedProfileData }  = this.state;

            await this.props.updateCurrentUser({
                country : changedProfileData.country
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isCountryValid : true,
                    isCountryChanged : false
                })
            }, 500)

        })

    }

    /**
     * PHONE NUMBER FUNCTIONS
     */
    onPhoneNumberFocus = (e) => {
        e.target.select();
    }

    onPhoneNumberChange = (e) => {

        const { changedProfileData } = this.state;
        const { userProfile, t } = this.props;

        let regExp = /^[0-9\b]+$/;

        if(!regExp.test(e.target.value)){
            $('#phoneNumber').addClass('error-input');
            $('#phoneNumber').parent('div').next('.block align-left error-input-message').text(t('profile_invalid_phone_number'));
            this.setState({
                isPhoneNumberValid : false
            })
        }else{
            
            const { phoneCode } = this.state;

            if(e.target.value.indexOf(phoneCode)!=-1){
                $('#phoneNumber').addClass('error-input');
                $('#phoneNumber').parent('div').next('.block align-left error-input-message').text(t('profile_invalid_phone_number'));
                this.setState({
                    isPhoneNumberValid : false
                })
            }else{
                $('#phoneNumber').removeClass('error-input');
                $('#phoneNumber').parent('div').next('.block align-left error-input-message').text( `` );
                if(userProfile.phoneNumber!==e.target.value){
                    
                    let cData = changedProfileData;
                    cData[e.target.name] = e.target.value;

                    this.setState({
                        isPhoneNumberChanged : true,
                        isPhoneNumberValid : true,
                        changedProfileData : {...cData}
                    })

                }else{
                    this.setState({
                        isPhoneNumberValid : true,
                        isPhoneNumberChanged : false
                    })
                }
            }
        }

    }

    savePhoneNumber = async () => {

        this.setState({
            isProcessing : true
        }, async () => {

            const { changedProfileData, phoneCode }  = this.state;

            await this.props.updateCurrentUser({
                phoneNumber : phoneCode + "" + changedProfileData.phoneNumber
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isPhoneNumberChanged : false,
                    isPhoneNumberValid : true
                })
            }, 500)

        })

    }
    
    /**
     * PHOTO UPLOAD FUNCTIONS
     */
    openPhotoUpload = (e) => {
        e.preventDefault();
        $('#new_photo').click();
    }

    onFileChange = async (e) => {
        
        let newPhoto = e.target.files.length!== 0 ? e.target.files[0] : null;

        if(newPhoto!==null){

            let newPhotoFileType = newPhoto.type;

            if(acceptedFileTypes.indexOf(newPhotoFileType)!==-1){

                this.setState({
                    isProcessing : true
                })

                let result = await this.props.uploadUserImage({
                    file : newPhoto
                })

                if(result.code===200){
                    this.setState({
                        displayPhoto : result.url,
                        isProcessing : false
                    })
                }
                $('#new_photo').next('span').text('');
            }else{
                $('#new_photo').next('span').text('File type not supported');
                this.setState({
                    isProcessing : false
                })
            }
        }
    }

    /**
     * RE AUTHENTICATION FUNCTIONS
     */
    authChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    authSubmit = (e) => {
        e.preventDefault();
        const { authEmail, authPassword, changedProfileData, reAuthFor } = this.state;
        const { userProfile, t } = this.props;

        if(authEmail==="" && authPassword===""){
            this.setState({
                errorMessage : t('login_message_incomplete_details')
            })
        }else{
            if(reAuthFor==="password"){
                this.setState({
                    isProcessing : true,
                    errorMessage : ""
                }, async () => {
                    let result = await this.props.reAuthenticateUser({
                        email : authEmail,
                        password : authPassword
                    })

                    if(result.code===200){
                        $('#reAuthModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
                        setTimeout( () => {
                            this.setState({
                                isReauthenticateModalActive : false,
                                isPasswordChangeModalActive : false,
                                authEmail : "",
                                authPassword : "",
                                isLoggingIn : false,
                                errorMessage : "",
                                reAuthFor : "",
                                isProcessing : false,
                                isMessageModalActive : true,
                                isPasswordChangeModalActive : false,
                                messageModalHeader : t('profile_password_updated'),
                                messageModalSubHeader : "",
                            })
                        }, 1500)
                    }else{
                        this.setState({
                            isProcessing : false,
                            errorMessage : result.message
                        })
                    }
                })
            }

            if(reAuthFor==="email"){
                this.setState({
                    isProcessing : true
                }, async () => {
                    let result = await this.props.reAuthenticateUser({
                        email : authEmail,
                        password : authPassword
                    })

                    if(result.code===200){
                        const { t } = this.props;

                        $('#reAuthModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

                        setTimeout( () => {
                            this.setState({
                                isReauthenticateModalActive : false,
                                authEmail : "",
                                authPassword : "",
                                isLoggingIn : false,
                                errorMessage : "",
                                reAuthFor : "",
                                isProcessing : false,
                                messageModalHeader : t('profile_modal_updated_header'),
                                messageModalSubHeader : t('profile_modal_updated_sub_header'),
                                isMessageModalActive : true,
                                isPasswordChangeModalActive : false,
                            })
                        }, 1500)
                    }else{
                        this.setState({
                            isProcessing : false,
                            errorMessage : result.message
                        })
                    }
                })
            }
        }
    }

    closeReAuthModal = () => {
        $('#reAuthModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        
        setTimeout( () => {
            this.setState({
                isReauthenticateModalActive : false,
                reAuthFor : ""
            })
        }, 1500)
        
    }

    updateNotifSettings = async (e) => {

        let settingGroup = $(e.target).data('group');
        let settingKey = $(e.target).data('setting');

        let result = await this.props.updateUserSettings({
            isOn : e.target.checked,
            group : settingGroup,
            settings : settingKey
        })

    }

    /**
     * EDIT BILLING ( CREDIT CARD )
     */
    checkBilling = async () => {
    
        const { userProfile } = this.props;

        this.setState({
            translatorBankAccountDetails : userProfile.hasOwnProperty('translatorBankAccountDetails') ? userProfile.translatorBankAccountDetails : null
        })

        // let paymentMethodDetails;

        // if(userProfile.hasOwnProperty('paypalPaymentMethodTokens') && userProfile.paypalPaymentMethodTokens.hasOwnProperty('creditCard')){
        //     let paymentMethodToken = userProfile.paypalPaymentMethodTokens.creditCard;
           
        //     await axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/find_payment_method`,{
        //         paymentMethodToken : paymentMethodToken
        //     }).then( res => {
        //         paymentMethodDetails = res.data.details
                
        //         this.setState({
        //             paymentMethodDetails : paymentMethodDetails,
        //             isProcessing : false
        //         })
        //     })
        // }
    }

    editBillingMethod = () => {

        const { userProfile } = this.props;

        if(userProfile.hasOwnProperty('translatorBankAccountDetails')){
            this.setState({
                translatorBankAccountDetails : userProfile.translatorBankAccountDetails,
                accountName : userProfile.translatorBankAccountDetails.accountName,
                accountNumber : userProfile.translatorBankAccountDetails.accountNumber,
                bankName : userProfile.translatorBankAccountDetails.bankName,
                isEditingBillingMethod : true
            })
        }else{
            this.setState({
                isEditingBillingMethod : true
            }) 
        }
         
    }

    updateBillingMethod = (e) => {
        e.preventDefault();

        const { accountName, accountNumber, bankName } = this.state;

        let billingError = 0;
        const { t } = this.props;
    
        if(accountName.trim()===""){
            $('#accountName').next('span.block align-left error-input-message').text(t('profile_billing_input_acct_name'));
            billingError++;
        }else{
            $('#accountName').next('span.block align-left error-input-message').text("");
        }

        if(accountNumber.trim()===""){
            $('#accountNumber').next('span.block align-left error-input-message').text(t('profile_billing_input_acct_no'));
            billingError++;
        }else{
            $('#accountNumber').next('span.block align-left error-input-message').text("");
        }

        if(bankName.trim()===""){
            $('#bankName').next('span.block align-left error-input-message').text(t('profile_billing_input_bank_name'));
            billingError++;
        }else{
            $('#bankName').next('span.block align-left error-input-message').text("");
        }


        if(billingError===0){

            this.setState({
                isProcessing : true
            }, () => {

                const { accountName, accountNumber, bankName } = this.state;

                this.props.updateCurrentUser({
                    translatorBankAccountDetails : {
                        accountName : accountName,
                        accountNumber : accountNumber,
                        bankName : bankName
                    }
                }).then( () => {
                    this.setState({
                        isProcessing : false,
                        isEditingBillingMethod : false,
                        translatorBankAccountDetails : {
                            accountName : accountName,
                            accountNumber : accountNumber,
                            bankName : bankName
                        }
                    })
                }).catch( error => {
                    this.setState({
                        isProcessing : false
                    })
                })

            })

        }

    }

    billingDetailsOnChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
 
    /**
     * EDIT BILLING ( PAYPAL )
     */
    checkPaypal = async () => {
        const { userProfile } = this.props;
        let paypalMethodDetails;

        if(userProfile.hasOwnProperty('paypalPaymentMethodTokens') && userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal')){
            let paymentMethodToken = userProfile.paypalPaymentMethodTokens.paypal;
           
            await axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/find_payment_method`,{
                paymentMethodToken : paymentMethodToken
            }).then( res => {
                paypalMethodDetails = res.data.details
                this.setState({
                    paypalMethodDetails : paypalMethodDetails,
                    isProcessing : false
                })
            })
        }
    }

    editPaypalMethod = () => {

        const { userProfile } = this.props;

        this.setState({
            isEditingPaypalAccount : true,
            isProcessing : true
        }, async () => {

            let customerId = {}

            if(userProfile.paypalCustomerID!==undefined){
                customerId = {
                    customerId : userProfile.paypalCustomerID
                }
            }

            var paypalButton = document.querySelector('#paypal-button');
            
            axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/generate_client_token`, customerId)
            .then( res => {
                const { data } = res;
                const clientToken = data.clientToken;

                braintree.client.create({
                    authorization: clientToken
                }, (clientErr, clientInstance) => {
                    if (clientErr) {
                    console.error('Error creating client:', clientErr);
                    return;
                    }
                
                    braintree.paypal.create({
                        client: clientInstance
                    }, (paypalErr, paypalInstance) => {
                        if (paypalErr) {
                            console.error('Error creating PayPal:', paypalErr);
                            return;
                        }

                        paypalButton.removeAttribute('disabled');
                
                        // When the button is clicked, attempt to tokenize.
                        paypalButton.addEventListener('click', (event) => {
                            this.setState({
                                isProcessing : true
                            }, () => {

                                const { auth, userProfile } = this.props;
                                
                                paypalInstance.tokenize({
                                    flow: 'vault',
                                    currency : 'USD'
                                }, (tokenizeErr, payload) => {


                                    if (tokenizeErr) {
                                    // Handle tokenization errors or premature flow closure

                                        switch (tokenizeErr.code) {
                                            case 'PAYPAL_POPUP_CLOSED':
                                                console.error('Customer closed PayPal popup.');
                                            break;
                                            case 'PAYPAL_ACCOUNT_TOKENIZATION_FAILED':
                                                console.error('PayPal tokenization failed. See details:', tokenizeErr.details);
                                            break;
                                            case 'PAYPAL_FLOW_FAILED':
                                                console.error('Unable to initialize PayPal flow. Are your options correct?', tokenizeErr.details);
                                            break;
                                            default:
                                            console.error('Error!', tokenizeErr);
                                        }

                                        this.setState({
                                            isProcessing : false
                                        })

                                    } else {
                                        
                                        let nonce = payload.nonce;
                                        let userUid = auth.uid;
                                        
                                        if(userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal')){

                                            let requestBody = {
                                                nonce : payload.nonce,
                                                token : userProfile.paypalPaymentMethodTokens.paypal,
                                                userUid : userProfile.uid,
                                                paymentType : "paypal",
                                                paypalCustomerID : userProfile.paypalCustomerID
                                            }
                            
                                            axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/update_payment`,requestBody)
                                            .then( res => {
                                                setTimeout( () => {
                                                    this.setState({
                                                        isProcessing : false,
                                                        isEditingPaypalAccount : false
                                                    }, () => {
                                                        this.checkBilling();
                                                        this.checkPaypal();
                                                    })
                                
                                                }, 3000)
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    isProcessing : false,
                                                    isErrorMessageModalActive : true
                                                })
                                                console.log(err)
                                            })
                    
                                        }else{
                    
                                            let requestBody = {
                                                paymentMethodNonce : payload.nonce,
                                                userUid : userProfile.uid,
                                                paymentType : "paypal",
                                                paypalCustomerID : userProfile.paypalCustomerID
                                            }
                            
                                            axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/add_payment`,requestBody)
                                            .then( res => {
                                                setTimeout( () => {
                                                    this.setState({
                                                        isProcessing : false,
                                                        isEditingPaypalAccount : false
                                                    }, () => {
                                                        this.checkBilling();
                                                        this.checkPaypal();
                                                    })
                                
                                                }, 3000)
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    isProcessing : false,
                                                    isErrorMessageModalActive : true
                                                })
                                                console.log(err)
                                            })
                                        }
                                    }                
                                })
                            })
                        }, false);
                    })
                });

                this.setState({
                    isProcessing : false
                })

                $('#paypal-button').trigger('click');

               
            })
        })  
    }

    updatePaypalMethod = () => {
        $('#paypal-button').trigger('click');
    }

    onWindowResize = () => {

    }

    render() {
        
        const { isMobileView } = this.props;
        const { auth, userProfile, isLoggingOut } = this.props;
        const { isFullnameChanged, isFullnameValid } = this.state;
        const { isFirstnameChanged, isFirstnameValid } = this.state;
        const { isLastnameChanged, isLastnameValid } = this.state;
        const { isCountryChanged, isCountryValid } = this.state;
        const { isPhoneNumberChanged, isPhoneNumberValid } = this.state;
        const { isEmailChanged, isEmailValid } = this.state;
        const { paymentMethodDetails, translatorBankAccountDetails, isEditingBillingMethod } = this.state;
        const { paypalMethodDetails, isEditingPaypalAccount } = this.state;
        const { isEmailEditable, isPasswordHidden } = this.state;

        const { currentLanguage, t } = this.props;

        const { isErrorMessageModalActive, isPasswordChangeModalActive, errorMessage, isProcessing, isReauthenticateModalActive, isMessageModalActive, messageModalHeader, messageModalSubHeader, isAuthenticated, displayPhoto, phoneCode } = this.state;

        let imageUrl = userProfile.hasOwnProperty('imageUrl') ? userProfile.imageUrl === "" ? require('../../images/default-user.jpg') : userProfile.imageUrl : require('../../images/default-user.jpg')

        if(displayPhoto!==""){
            imageUrl = displayPhoto
        }
        
        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }

        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid){
            return <Redirect to="/" />
        }else{
            if(userProfile.type==="translator"){
                if(!auth.emailVerified){
                    return <Redirect to="/" />
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    (isAuthenticated && isMobileView) ?
                    <div style={{ position : "absolute", top : "0" }}>
                        <nav className="navbar navbar-expand-lg fixed-top">
                            <span className="page-mobile-header"><img onClick={ () => this.props.history.goBack() } style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../images/back.png')}/>{t('profile_settings')}</span>
                        </nav>
                    </div>:
                    ""
                }
                {
                    (isAuthenticated && isMobileView) ?
                    <div className="div-holder">
                        <div className="profile-mobile">
                            <div className="profile-box-display-photo" style={ isMobileView ? { borderBottom : "1px solid #E6E4E8"}  : { borderRight : "1px solid #E6E4E8" } } >
                                <img style={ isMobileView ? { width : "200px", height : "200px" } : { width : "130px" , height : "130px" }} src={imageUrl}/>
                                <input onChange={this.onFileChange} type="file" name="new_photo" id="new_photo" style={{ display : "none" }} />
                                <span style={{ fontSize : ".65rem" }} className="block align-left error-input-message"></span>
                                <button style={{ width : "80%" }} onClick={this.openPhotoUpload}>{t('profile_replace')}</button>
                            </div>
                            {/* <div style={{ marginBottom : "20px" }} className="form-group">
                                <label className="align-left custom-label block">Name</label>
                                <div style={{ display : "flex" }}>
                                    <input onChange={this.onFullNameChange} defaultValue={userProfile.hasOwnProperty('fullName') ? userProfile.fullName : ""} id="fullName" name="fullName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter your name"/>
                                    { (isFullnameValid && isFullnameChanged) && <button onClick={this.saveFullname} >{t('common_save')}</button> }
                                </div>
                                <span className="block align-left error-input-message"></span>
                            </div> */}
                            <div style={{ marginBottom : "20px" }} className="form-group">
                                <label className="align-left custom-label block align-left">{t('common_first_name')}</label>
                                <div style={{ display : "flex" }}>
                                    <input onChange={this.onFirstNameChange} defaultValue={userProfile.hasOwnProperty('fullName') ? userProfile.fullName.split(",")[1].trim() : ""} id="firstName" name="firstName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                    { (isFirstnameValid && isFirstnameChanged) && <button onClick={this.saveFirstname} >{t('common_save')}</button> }
                                </div>
                                <span className="block align-left error-input-message"></span>
                            </div>
                            <div style={{ marginBottom : "20px" }} className="form-group">
                                <label className="align-left custom-label block align-left">{t('common_last_name')}</label>
                                <div style={{ display : "flex" }}>
                                    <input onChange={this.onLastNameChange} defaultValue={userProfile.hasOwnProperty('fullName') ? userProfile.fullName.split(",")[0] : ""} id="lastName" name="lastName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                    { (isLastnameValid && isLastnameChanged) && <button onClick={this.saveLastname} >{t('common_save')}</button> }
                                </div>
                                <span className="block align-left error-input-message"></span>
                            </div>
                            <div style={{ marginBottom : "20px" }} className="form-group">
                                <label className="align-left custom-label block align-left">{t('common_email')}</label>
                                <div style={{ display : "flex"}}>
                                    <input disabled={!isEmailEditable} onChange={this.onEmailChange} defaultValue={userProfile.hasOwnProperty('emailAddress') ? userProfile.emailAddress : ""} id="emailAddress" name="emailAddress" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter your email address"/>
                                    { (isEmailValid && isEmailChanged) && <button onClick={this.saveEmail} >{t('common_save')}</button> }
                                </div>
                                <span className="block align-left error-input-message"></span>
                            </div>
                            {
                                !isPasswordHidden &&
                                <div style={{ marginBottom : "0" }} className="form-group">
                                    <label className="align-left custom-label block align-left">{t('common_password')}</label>
                                    <input onClick={this.onPasswordClick} defaultValue={"Your masked password"} id="password" name="password" style={{ width: "100%" }} className="custom-input" type="password" placeholder="Enter your password"/>
                                </div>
                            }
                            <div style={{ marginBottom : "20px" }} className="form-group">
                                <label className="align-left custom-label block align-left">{t('common_country')}</label>
                                <div style={{ display : "flex" }}>
                                    <select onChange={this.onCountryChange} placeholder={t('common_select_here')} className="custom-select form-control" id="country" name="country">
                                        <option value="">{t('common_select_here')}</option>
                                        {
                                            Object.keys(myCountryCodesObject).map( (data, i) => (
                                                <option selected={userProfile.country === data} key={i} value={data}>{data}</option>
                                            ))
                                        }
                                    </select>
                                    { (isCountryValid && isCountryChanged) && <button onClick={this.saveCountry} >{t('common_save')}</button> }
                                </div>
                            </div>
                            <label className="align-left custom-label block align-left">{t('common_phone_number')}</label>
                            <div style={{ marginBottom : "0", borderBottom : "1px solid #E6E4E8", paddingBottom : "20px" }} className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic-addon1">{phoneCode}</span>
                                </div>
                                <input onChange={this.onPhoneNumberChange} onFocus={this.onPhoneNumberFocus} aria-describedby="basic-addon1" defaultValue={userProfile.hasOwnProperty('phoneNumber') ? userProfile.phoneNumber.replace(myCountryCodesObject[userProfile.country],'') : ""} id="phoneNumber" name="phoneNumber" style={{ width: "100%", flex : "1" }} className="custom-input" type="text" placeholder="Enter your phone number"/>
                                { (isPhoneNumberValid && isPhoneNumberChanged) && <button onClick={this.savePhoneNumber} >{t('common_save')}</button> }
                            </div>
                            <span className="block align-left error-input-message"></span>
                            <div style={{ borderBottom : "1px solid #E6E4E8", paddingBottom : "20px", paddingTop : "20px" }}>
                            {
                                userProfile.type==="translator" &&
                                    <div>
                                    {
                                        (!userProfile.hasOwnProperty('translatorBankAccountDetails') && !isEditingBillingMethod) &&
                                        <div style={{ display : "flex", width : "100%" }}>
                                            <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold", marginTop : "15px" }}>{t('profile_billing_information')}</p>
                                            <button style={{ width : "100%" }} onClick={this.editBillingMethod} className="add-billing-method">{t('profile_add_billing_information')}</button>
                                        </div>
                                    }
                                    {
                                            (userProfile.hasOwnProperty('translatorBankAccountDetails') && userProfile.translatorBankAccountDetails.hasOwnProperty('accountName') && !isEditingBillingMethod) && 
                                            <div style={{ width : "100%" }}>
                                                    <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold"}}>{t('profile_billing_information')}</p>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="align-left custom-label block">{t('profile_account_name')}</label>
                                                                <input disabled defaultValue={translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountName : ""} name="accountName" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text"/>
                                                                <span className="block align-left error-input-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="align-left custom-label block">{t('profile_account_number')}</label>
                                                                <input disabled defaultValue={ translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountNumber : "" } name="accountNumber" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text"/>
                                                                <span className="block align-left error-input-message"></span>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="align-left custom-label block">{t('profile_bank_name')}</label>
                                                                <input disabled defaultValue={ translatorBankAccountDetails !== null ? translatorBankAccountDetails.bankName : "" } name="bankName" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder=""/>
                                                                <span className="block align-left error-input-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button style={{ width : "100%" }} onClick={this.editBillingMethod} className="edit-billing-method">{t('profile_edit_billing_information')}</button>
                                            </div>
                                        }
                                        {
                                            isEditingBillingMethod &&
                                            <div style={{ width: "100%"}}>
                                                <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold"}}>{t('profile_billing_information')}</p>
                                                <form id="card-form">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label class="align-left custom-label block" for="accountName">{t('profile_account_name')}</label>
                                                                <input defaultValue={translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountName : ""} onChange={this.billingDetailsOnChange} className="custom-input" name="accountName" id="accountName" style={{ width : "100%" }} />
                                                                <span className="block align-left error-input-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label class="align-left custom-label block" for="accountNumber">{t('profile_account_number')}</label>
                                                                <input defaultValue={ translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountNumber : "" } onChange={this.billingDetailsOnChange} className="custom-input" name="accountNumber" id="accountNumber"  style={{ width : "100%" }} />
                                                                <span className="block align-left error-input-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label class="align-left custom-label block" for="bankName">{t('profile_bank_name')}</label>
                                                                <input defaultValue={ translatorBankAccountDetails !== null ? translatorBankAccountDetails.bankName : "" }  onChange={this.billingDetailsOnChange} className="custom-input" name="bankName" id="bankName"  style={{ width : "100%" }} />
                                                                <span className="block align-left error-input-message"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button id="card-form-submit" name="card-form-submit" onClick={this.updateBillingMethod} className="update-billing-method">{t('profile_update_billing_information')}</button>
                                                </form>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="form-group" style={{ paddingTop : "20px" }}>
                                <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold"}}>{t('profile_email_notif')}</p>
                                <ul className="notification-setting-list">
                                    <li>
                                        <label className="checkbox-container">{t('profile_order_completed')}
                                            <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenOrderCompleted') ? userProfile.settings.email.whenOrderCompleted : false }  name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenOrderCompleted"} className="reason" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('profile_new_message')}
                                            <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenNewMessage') ? userProfile.settings.email.whenNewMessage : false }name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenNewMessage"} className="reason" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('profile_stage_completed')}
                                            <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenStageCompleted') ? userProfile.settings.email.whenStageCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenStageCompleted"} className="reason" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                                <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold", marginTop : "28px" }}>{t('profile_sms')}</p>
                                <ul className="notification-setting-list">
                                    <li>
                                        <label className="checkbox-container">{t('profile_order_completed')}
                                            <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenOrderCompleted') ? userProfile.settings.sms.whenOrderCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenOrderCompleted"} className="reason" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('profile_new_message')}
                                            <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenNewMessage') ? userProfile.settings.sms.whenNewMessage : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenNewMessage"} className="reason" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('profile_stage_completed')}
                                            <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenStageCompleted') ? userProfile.settings.sms.whenStageCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenStageCompleted"} className="reason" type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {
                            isMessageModalActive && 
                            <Message 
                                t={t}
                                isMobileView={isMobileView}
                                closeMessageModal={this.closeMessageModal} 
                                isOpened={isMessageModalActive} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                                
                        }
                        {
                            isErrorMessageModalActive && 
                            <Message 
                                t={t}
                                isMobileView={isMobileView}
                                closeMessageModal={this.closeErrorMessageModal} 
                                isOpened={isErrorMessageModalActive} 
                                header={"Error"} 
                                subHeader={"Something went wrong. Please try again."}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                                
                        }
                        {
                            isReauthenticateModalActive && 
                            <Reauthenticate
                                isMobileView={isMobileView}
                                t={t}
                                isOpened={isReauthenticateModalActive}
                                authSubmit={this.authSubmit}
                                authChange={this.authChange}
                                errorMessage={errorMessage}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeReAuthModal={this.closeReAuthModal}
                            />
                        }
                        {
                            isPasswordChangeModalActive && 
                            <NewPassword
                                t={t}
                                isMobileView={isMobileView}
                                onPasswordChange={this.onPasswordChange}
                                onPasswordConfirmChange={this.onPasswordConfirmChange}
                                onSavePasswordChange={this.onSavePasswordChange}
                                isOpened={isPasswordChangeModalActive}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeNewPasswordModal={this.closeNewPasswordModal}
                            />
                        }
                        {
                            isProcessing &&
                            <SpinnerModal 
                                isOpened={isProcessing}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                        {
                            isLoggingOut &&
                            <SpinnerModal
                                isOpened={isLoggingOut}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                    </div>
                    
                    :
                    <div className="div-holder">
                        <div className="row profile-box">
                            <div className="col-xs-12 col-sm-12 col-md-2">
                                <div className="profile-box-display-photo" style={ isMobileView ? { borderBottom : "1px solid #E6E4E8"}  : { borderRight : currentLanguage === "ar" ? "none" : "1px solid #E6E4E8", borderLeft : currentLanguage === "ar" ? "1px solid #E6E4E8" : "none" } } >
                                    <img style={ isMobileView ? { width : "200px", height : "200px" } : { width : "130px" , height : "130px" }} src={imageUrl}/>
                                    <input onChange={this.onFileChange} type="file" name="new_photo" id="new_photo" style={{ display : "none" }} />
                                    <span style={{ fontSize : ".65rem" }} className="block align-left error-input-message"></span>
                                    <button onClick={this.openPhotoUpload}>{t('profile_replace')}</button>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-10">
                                <div className="profile-box-user-data">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div style={{ marginBottom : "20px" }} className="form-group">
                                                <div style={{ display : "flex" }}>
                                                    <div style={{ marginRight : currentLanguage === "ar" ? "unset" : "30px", marginLeft : currentLanguage === "ar" ? "30px" : "unset", width : "100%" }}>
                                                        <label className="align-left custom-label block align-left">{t('common_first_name')}</label>
                                                        <div style={{ display : "flex" }}>
                                                            <input onChange={this.onFirstNameChange} defaultValue={userProfile.hasOwnProperty('fullName') ? userProfile.fullName.split(",")[1].trim() : ""} id="firstName" name="firstName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                                            { (isFirstnameValid && isFirstnameChanged) && <button onClick={this.saveFirstname} >{t('common_save')}</button> }
                                                        </div>
                                                        <span className="block align-left error-input-message"></span>
                                                    </div>
                                                    <div style={{  width : "100%" }}>
                                                        <label className="align-left custom-label block">{t('common_last_name')}</label>
                                                        <div style={{ display : "flex" }}>
                                                            <input onChange={this.onLastNameChange} defaultValue={userProfile.hasOwnProperty('fullName') ? userProfile.fullName.split(",")[0] : ""} id="lastName" name="lastName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter here"/>
                                                            { (isLastnameValid && isLastnameChanged) && <button onClick={this.saveLastname} >{t('common_save')}</button> }
                                                        </div>
                                                        <span className="block align-left error-input-message"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginBottom : "20px" }} className="form-group">
                                                <label className="align-left custom-label block">{t('common_email')}</label>
                                                <div style={{ display : "flex"}}>
                                                    <input disabled={!isEmailEditable} onChange={this.onEmailChange} defaultValue={userProfile.hasOwnProperty('emailAddress') ? userProfile.emailAddress : ""} id="emailAddress" name="emailAddress" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter your email address"/>
                                                    { (isEmailValid && isEmailChanged) && <button onClick={this.saveEmail} >{t('common_save')}</button> }
                                                </div>
                                                <span className="block align-left error-input-message"></span>
                                            </div>
                                            {
                                                !isPasswordHidden && 
                                                <div style={{ marginBottom : "0" }} className="form-group">
                                                    <label className="align-left custom-label block">{t('common_password')}</label>
                                                    <input onClick={this.onPasswordClick} defaultValue={"Your masked password"} id="password" name="password" style={{ width: "100%" }} className="custom-input" type="password" placeholder="Enter your password"/>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div style={{ marginBottom : "20px" }} className="form-group">
                                                <label className="align-left custom-label block">{t('common_country')}</label>
                                                <div style={{ display : "flex" }}>
                                                    <select onChange={this.onCountryChange} placeholder={t('common_select_here')} className="custom-select form-control" id="country" name="country">
                                                        <option value="">{t('common_select_here')}</option>
                                                        {
                                                            Object.keys(myCountryCodesObject).map( (data, i) => (
                                                                <option selected={userProfile.country === data} key={i} value={data}>{data}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    { (isCountryValid && isCountryChanged) && <button onClick={this.saveCountry} >{t('common_save')}</button> }
                                                </div>
                                            </div>
                                            <label className="align-left custom-label block">{t('common_phone_number')}</label>
                                            <div style={{ marginBottom : "0" }} className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">{phoneCode}</span>
                                                </div>
                                                <input onChange={this.onPhoneNumberChange} onFocus={this.onPhoneNumberFocus} aria-describedby="basic-addon1" defaultValue={userProfile.hasOwnProperty('phoneNumber') ? userProfile.phoneNumber.replace(myCountryCodesObject[userProfile.country],'') : ""} id="phoneNumber" name="phoneNumber" style={{ width: "100%", flex : "1" }} className="custom-input" type="text" placeholder="Enter your phone number"/>
                                                { (isPhoneNumberValid && isPhoneNumberChanged) && <button onClick={this.savePhoneNumber} >{t('common_save')}</button> }
                                            </div>
                                            <span className="block align-left error-input-message"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="div-holder" style={{ display : "flex", marginTop : "40px" }}>
                            {
                                userProfile.type==="translator" &&
                                <div className="profile-box" style={{ minWidth : "50%", marginRight : "16px"}}>
                                {
                                    (!userProfile.hasOwnProperty('translatorBankAccountDetails') && !isEditingBillingMethod) &&
                                    <div style={{ display : "flex", width : "100%" }}>
                                        <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left", marginTop : "15px" }}>{t('profile_billing_information')}</p>
                                        <button style={{ width : "100%" }} onClick={this.editBillingMethod} className="add-billing-method">{t('profile_add_billing_information')}</button>
                                    </div>
                                }
                                {
                                        (userProfile.hasOwnProperty('translatorBankAccountDetails') && userProfile.translatorBankAccountDetails.hasOwnProperty('accountName') && !isEditingBillingMethod) && 
                                        <div style={{ width : "100%" }}>
                                                <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>{t('profile_billing_information')}</p>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="align-left custom-label block">{t('profile_account_name')}</label>
                                                            <input disabled defaultValue={translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountName : ""} name="accountName" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text"/>
                                                            <span className="block align-left error-input-message"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="align-left custom-label block">{t('profile_account_number')}</label>
                                                            <input disabled defaultValue={ translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountNumber : "" } name="accountNumber" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text"/>
                                                            <span className="block align-left error-input-message"></span>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="align-left custom-label block">{t('profile_bank_name')}</label>
                                                            <input disabled defaultValue={ translatorBankAccountDetails !== null ? translatorBankAccountDetails.bankName : "" } name="bankName" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder=""/>
                                                            <span className="block align-left error-input-message"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button onClick={this.editBillingMethod} className="edit-billing-method">{t('profile_edit_billing_information')}</button>
                                        </div>
                                    }
                                    {
                                        isEditingBillingMethod &&
                                        <div style={{ width: "100%"}}>
                                            <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>{t('profile_billing_information')}</p>
                                            <form id="card-form">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label class="align-left custom-label block" for="accountName">{t('profile_account_name')}</label>
                                                            <input defaultValue={translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountName : ""} onChange={this.billingDetailsOnChange} className="custom-input" name="accountName" id="accountName" style={{ width : "100%" }} />
                                                            <span className="block align-left error-input-message"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label class="align-left custom-label block" for="accountNumber">{t('profile_account_number')}</label>
                                                            <input defaultValue={ translatorBankAccountDetails!== null ? translatorBankAccountDetails.accountNumber : "" } onChange={this.billingDetailsOnChange} className="custom-input" name="accountNumber" id="accountNumber"  style={{ width : "100%" }} />
                                                            <span className="block align-left error-input-message"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label class="align-left custom-label block" for="bankName">{t('profile_bank_name')}</label>
                                                            <input defaultValue={ translatorBankAccountDetails !== null ? translatorBankAccountDetails.bankName : "" }  onChange={this.billingDetailsOnChange} className="custom-input" name="bankName" id="bankName"  style={{ width : "100%" }} />
                                                            <span className="block align-left error-input-message"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button id="card-form-submit" name="card-form-submit" onClick={this.updateBillingMethod} className="update-billing-method">{t('profile_update_billing_information')}</button>
                                            </form>
                                        </div>
                                    }
                                </div>
                            }
                            <div className="profile-box" style={{ minWidth : "47.5%", marginLeft : "16px" }}>
                                <div className="form-group">
                                    <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold"}}>{t('profile_email_notif')}</p>
                                    <ul className="notification-setting-list">
                                        <li>
                                            <label className="checkbox-container">{t('profile_order_completed')}
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenOrderCompleted') ? userProfile.settings.email.whenOrderCompleted : false }  name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenOrderCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('profile_new_message')}
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenNewMessage') ? userProfile.settings.email.whenNewMessage : false }name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenNewMessage"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('profile_stage_completed')}
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenStageCompleted') ? userProfile.settings.email.whenStageCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenStageCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                    <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold", marginTop : "28px" }}>{t('profile_sms')}</p>
                                    <ul className="notification-setting-list">
                                        <li>
                                            <label className="checkbox-container">{t('profile_order_completed')}
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenOrderCompleted') ? userProfile.settings.sms.whenOrderCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenOrderCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('profile_new_message')}
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenNewMessage') ? userProfile.settings.sms.whenNewMessage : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenNewMessage"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('profile_stage_completed')}
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenStageCompleted') ? userProfile.settings.sms.whenStageCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenStageCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="div-holder" style={{ display : "flex", marginTop : "40px" }}>
                            <div className="profile-box" style={{ minWidth : "50%" }}>
                            {
                                (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && !userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal') && !isEditingPaypalAccount) &&
                                <div style={{ display : "flex", width : "100%" }}>
                                    <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>Billing method to pay</p>
                                    <button onClick={this.editPaypalMethod} className="add-billing-method">ADD PAYPAL</button>
                                </div>
                            }
                            {
                                (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && isEditingPaypalAccount) && 
                                <div style={{ width : "100%" }}>
                                        <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>{t('profile_billing_information')}</p>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="align-left custom-label block">Email</label>
                                                    <input disabled defaultValue={paypalMethodDetails!== null ? paypalMethodDetails.email : ""} name="paypalEmail" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder="Click the button below to add email here"/>
                                                    <span className="block align-left error-input-message"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <button onClick={this.editPaypalMethod} className="edit-billing-method">EDIT PAYPAL INFORMATION</button>
                                        <button id="paypal-button" style={{ display : "none"}}></button>
                                </div>
                            }
                            {
                                (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal') && !isEditingPaypalAccount) && 
                                <div style={{ width : "100%" }}>
                                        <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>{t('profile_billing_information')}</p>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label className="align-left custom-label block">Email</label>
                                                    <input disabled defaultValue={paypalMethodDetails!== null ? paypalMethodDetails.email : ""} name="paypalEmail" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder="Click the button below to add email here"/>
                                                    <span className="block align-left error-input-message"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <button onClick={this.editPaypalMethod} className="edit-billing-method">EDIT PAYPAL INFORMATION</button>
                                        <button id="paypal-button" style={{ display : "none"}}></button>
                                </div>
                            }
                            </div>
                        </div> */}
                        {
                            isMessageModalActive && 
                            <Message 
                                closeMessageModal={this.closeMessageModal} 
                                isOpened={isMessageModalActive} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                t={t}
                            />
                                
                        }
                        {
                            isErrorMessageModalActive && 
                            <Message 
                                closeMessageModal={this.closeErrorMessageModal} 
                                isOpened={isErrorMessageModalActive} 
                                header={"Error"} 
                                subHeader={"Something went wrong. Please try again."}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                t={t}
                            />
                                
                        }
                        {
                            isReauthenticateModalActive && 
                            <Reauthenticate
                                isMobileView={isMobileView}
                                t={t}
                                isOpened={isReauthenticateModalActive}
                                authSubmit={this.authSubmit}
                                authChange={this.authChange}
                                errorMessage={errorMessage}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeReAuthModal={this.closeReAuthModal}
                            />
                        }
                        {
                            isPasswordChangeModalActive && 
                            <NewPassword
                                t={t}
                                isMobileView={isMobileView}
                                onPasswordChange={this.onPasswordChange}
                                onPasswordConfirmChange={this.onPasswordConfirmChange}
                                onSavePasswordChange={this.onSavePasswordChange}
                                isOpened={isPasswordChangeModalActive}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeNewPasswordModal={this.closeNewPasswordModal}
                            />
                        }
                        {
                            isProcessing &&
                            <SpinnerModal 
                                isOpened={isProcessing}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                        {
                            isLoggingOut &&
                            <SpinnerModal
                                isOpened={isLoggingOut}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                    </div>
                    
                }
            </div>
        )


        
    }

}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isLoggingOut : state.auth.isLoggingOut,
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        uploadUserImage,
        updateUserSettings,
        updateCurrentUser,
        checkForDuplicateEmail,
        updateUserEmail,
        updateUserPassword,
        signOutUser,
        reAuthenticateUser,
        addModal,
        popModal,
        setUserPresence,
        getSignInMethods
    })
)(Index)