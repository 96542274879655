import React, { Component } from 'react'
import $ from 'jquery'

export class ConversationFooter extends Component {

    constructor(props){
        super(props);
        this.state = {
            message : "",
            droppedFiles : [],
            files : [],
            filesUrl : []
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.droppedFiles!==this.props.droppedFiles){
            this.setState({
                droppedFiles : [...this.props.droppedFiles]
            })
        }
    }

    openFileUpload = (e) => {
        e.preventDefault();
        $('#message_attachment').click();
    }

    sendMessage = async () => {
        const { message, files, droppedFiles } = this.state;
        this.props.sendMessage({
            message : message,
            files : [...files, ...droppedFiles]
        })
        this.props.clearDroppedFiles();
        this.setState({
            message : "",
            files : [],
            droppedFiles : []
        })
        $('#message').val("").change();
    }

    onKeyPress = (e) => {
        if (e.key !== 'Enter') return;
        this.sendMessage();
        e.preventDefault();
    }

    onFileChange = (e) => {
        if(e.target.files!==null && e.target.files!==undefined){
            
            this.setState({
                files : [ ...this.state.files, ...e.target.files]
            })
        }
    }

    removeToFileList = (key) => {
        const { files } = this.state;
        let newFiles = files;

        newFiles.splice(key,1);

        this.setState({
            files : [...newFiles]
        })

    }

    onChange = (e) => {
        this.setState({message: e.target.value});
    }



    render(){

        const { activeConversation, isMobileView, t } = this.props;
        const { message, files, droppedFiles } = this.state;
        
        if(isMobileView){
            return(
                <div className="mobile-conversation-footer">
                    <div style={{ display : "flex", marginBottom : "20px" }}>
                        <input onClick={this.props.updateUserOpenedDate} value={message} onChange ={this.onChange} onKeyPress={this.onKeyPress} style={{ width: "100%" }} type="text" name="message" id="message" className="custom-input" placeholder={t('task_modal_details_place_text')}/>
                        <input multiple onChange={this.onFileChange} type="file" name="message_attachment" id="message_attachment" style={{ display : "none" }} />
                        <img src={require('../../images/icon-message-attach.png')} onClick={this.openFileUpload} style={{ width: "20px", height: "20px", marginTop : "5px", marginLeft : "10px", cursor : "pointer" }} />
                    </div>
                    <div >
                        <ul className="chat-file-list">
                        {
                            files.length !== 0 && files.map( (data,i) => (
                                <li key={i}>
                                    <span className="message-file"><img style={{ width: "10px", height : "10px", marginRight : "10px" }} src={require('../../images/icon-task-file.png')}/>{data.name}<img onClick={ () => this.removeToFileList(i)} style={{ cursor : "pointer", width: "10px", height : "10px", marginRight : "10px", float : "right" }} src={require('../../images/chat-file-remove.png')}/></span>
                                </li>
                            ))
                        }
                        </ul>
                    </div>
                </div>
            )

        }else{
            return (
                <div className="conversation-footer">
                {
                    (activeConversation && (files.length!==0 || droppedFiles.length!==0)) &&
                    <div className="chat-message-files">
                        {
                            files.length !== 0 && <div >
                                <ul className="chat-file-list">
                                {
                                    files.map( (data,i) => (
                                        <li key={i}>
                                            <span className="chat-message-file"><img style={{ width: "10px", height : "10px", marginRight : "10px" }} src={require('../../images/icon-task-file.png')}/>{data.name}<img onClick={ () => this.removeToFileList(i)} style={{ cursor : "pointer", width: "10px", height : "10px", marginRight : "10px", float : "right" }} src={require('../../images/chat-file-remove.png')}/></span>
                                        </li>
                                    ))
                                }
                                </ul>
                            </div>
                        }
                        {
                            droppedFiles.length!== 0 && <div>
                                <ul className="chat-file-list">
                                {
                                    droppedFiles.map( (data,i) => (
                                        <li key={i}>
                                            <span className="chat-message-file"><img style={{ width: "10px", height : "10px", marginRight : "10px" }} src={require('../../images/icon-task-file.png')}/>{data.name}<img onClick={ () => this.props.removeToDroppedFileList(i)} style={{ cursor : "pointer", width: "10px", height : "10px", marginRight : "10px", float : "right" }} src={require('../../images/chat-file-remove.png')}/></span>
                                        </li>
                                    ))
                                }
                                </ul>
                            </div>
                        }
                    </div>
                }         
                {
                    activeConversation && <div className="message-container">
                        <img  onClick={this.openFileUpload} className="message-attach" src={require('../../images/icon-message-attach.png')}/>
                        <input multiple onChange={this.onFileChange} type="file" name="message_attachment" id="message_attachment" style={{ display : "none" }} />
                        <textarea onClick={this.props.updateUserOpenedDate} id="message" onChange ={this.onChange} onKeyPress={this.onKeyPress} placeholder="Message" className="message-send-input"></textarea>
                        <img onClick={this.sendMessage} className="message-send" src={require('../../images/icon-send.png')}/>
                    </div>
                }
                </div>
            )
        }
    }
}

export default ConversationFooter