import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import moment from 'moment'

import { 
    getAllTranslatorTasks,
    setIncomeReportsFilter,
    resetIncomeReportsFilter,
    updateTasksPaid,
    getPrepaidTasksTranslator,
    loadMorePrepaidTasksTranslator,
    getFullyPaidTasksTranslator,
    loadMoreFullyPaidTasksTranslator,
    getTranslatorBalance,
    saveEarnings
} from '../../../../actions/taskAction'

import {
    addModal,
    popModal
} from '../../../../actions/appAction'


import {
    updateTranslatorAmountEarned
} from '../../../../actions/authAction'

import TaskList from './List'
import MobileList from './MobileList'
import SpinnerModal from '../../../Spinner/SpinnerModal';
import Message from '../../../Modals/Message';
import Confirmation from '../../../Modals/Confirmation';
import GetPaidConfirmationModal from '../../Modals/GetPaidConfirmation';
import DateFilter from './DateFilter'

import axios from 'axios'
import exactMath from 'exact-math'

import {
    translate
} from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state= {
            isProcessing : false,
            isAuthenticated : false,
            isMessageModalActive : false,
            isMessageModalActivePaid : false,
            messageModalHeader : "",
            messageModalSubHeader : "",
            isConfirmationModalActive : false,
            confirmationHeader : "",
            dateFrom : new Date( moment().startOf('month')),
            dateTo : new Date( moment().endOf('month')),
            totalBalance : 0,
            totalEarnings : 0,
            paymentMethodDetails : null,
            statusFilter : "inProgress"
        }
    }

    componentDidMount(){
       
        const { auth , userProfile } = this.props;

        if(auth.uid){
            this.props.getTranslatorBalance();

            const { dateFrom, dateTo } = this.state;

            if(dateFrom!==null && dateTo!==null){
                this.props.setIncomeReportsFilter({
                    dateFrom: dateFrom,
                    dateTo : dateTo
                })

                this.loadPrepaidTasks();
                this.loadFullyPaidTasks();
            }

        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
        
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    scrollToBottom = () => {
        const scrollHeight = $('html')[0].scrollHeight;
        const height = $('html')[0].clientHeight;
        const maxScrollTop = scrollHeight - height;
        $('html')[0].scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    onDateFromChange = (date) => {
        
        if(date!==undefined){
            this.setState({
                dateFrom : date
            }, () => {
                const { dateFrom, dateTo } = this.state;

                if(dateFrom!==null && dateTo!==null){
                   
                    this.props.setIncomeReportsFilter({
                        dateFrom: dateFrom,
                        dateTo : dateTo
                    })

                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }

                if(dateFrom===null && dateTo===null){
                    this.props.resetIncomeReportsFilter();
                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }
               
            })
        }
    }

    onDateToChange = (date) =>{
        if(date!==undefined){
            this.setState({
                dateTo : date
            }, () => {
                const { dateFrom, dateTo } = this.state;

                if(dateFrom!==null && dateTo!==null){
                    this.props.setIncomeReportsFilter({
                        dateFrom: dateFrom,
                        dateTo : dateTo
                    })
                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }

                if(dateFrom===null && dateTo===null){
                    this.props.resetIncomeReportsFilter();
                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }
                
            })
        }
    }

    loadTasks = () => {
        this.props.getAllTranslatorTasks();
    }

    loadPrepaidTasks = () =>{
        this.props.getPrepaidTasksTranslator();
    }

    loadMoreTranslatorPrepaidTasks = () => {
        this.props.loadMorePrepaidTasksTranslator();
    }

    loadFullyPaidTasks = () =>{
        this.props.getFullyPaidTasksTranslator();
    }

    loadMoreTranslatorFullyPaidTasks = () => {
        this.props.loadMoreFullyPaidTasksTranslator();
    }

    getPaid = () => {

        const { t } = this.props;
        const { userProfile } = this.props;
        const { translatorTransactionBalance } = this.props;
        
        if(!userProfile.hasOwnProperty('translatorBankAccountDetails')){
            this.setState({
                isMessageModalActive : true,
                messageModalHeader : "",
                messageModalSubHeader : t('income_no_billing_details'),
            })
        }else{
            this.setState({
                isConfirmationModalActive : true,
                confirmationHeader : `${t('common_get_paid')} $${ Number.isInteger(translatorTransactionBalance) ? translatorTransactionBalance.toFixed(2) : translatorTransactionBalance } now?`
            })
        }

    }

    closeMessageModal = () => {
        this.props.history.push('/profile')
    }

    closeMessageModalPaid = () => {
        this.setState({
            isMessageModalActivePaid : false,
            isConfirmationModalActive : false,
            isProcessing : false
        })
    }

    closeGetPaidConfirmationModal = () => {
        this.setState({
            isConfirmationModalActive : false
        })
    }

    confirm = () => {

        const { t } = this.props;

        this.setState({
            isProcessing : true
        }, async () => {
            const { userProfile } = this.props;
            const { translatorTasks, translatorPrepaidTasks, translatorTransactionBalance, translatorFullyPaidTasks } = this.props;

            let fullName = userProfile.fullName.split(",");
            userProfile.firstName = fullName[1].trim();
            userProfile.lastName = fullName[0].trim();
            let unServed = translatorFullyPaidTasks.filter ( data => data.isPaidToTranslator===false && data.status==="completed" )
            let tasksArr = [];
            let earnings = 0;
            
            translatorFullyPaidTasks.map( data => {
                tasksArr.push({
                    uid : data.uid,
                    orderID : data.orderID,
                    title : data.title,
                    translatorEarnings : data.translatorEarnings
                })
                earnings = exactMath.add(earnings,data.translatorEarnings)
            })

            let result = await this.props.saveEarnings({
                tasks : tasksArr,
                amount : earnings
            })

            if(result.code===200){
                this.setState({
                    totalBalance : 0,
                    isProcessing : false,
                    isMessageModalActivePaid : true,
                    messageModalHeader : "",
                    messageModalSubHeader : t('income_processed'),
                }, () => {
                    this.loadTasks();
                    this.props.updateTasksPaid(unServed);
                    // this.props.updateTranslatorAmountEarned({amount : earnings});
                })
            }else{
                this.setState({
                    isProcessing : false,
                    isMessageModalActive : true,
                    messageModalHeader : "",
                    messageModalSubHeader : t('common_something_went_wrong'),
                })
            }
        })
    }

    filterByStatus = (filter) => {

        this.setState({
            statusFilter : filter
        })

    }

    render() {

        const { isMobileView } = this.props;

        const { isAuthenticated, isProcessing } = this.state;
        const { isMessageModalActivePaid, isMessageModalActive, messageModalHeader, messageModalSubHeader } = this.state;
        const { isConfirmationModalActive, confirmationHeader } = this.state;

        const { isLoggingOut, auth, userProfile } = this.props;
        const { isGettingTasks , translatorTasks, translatorTransactionBalance } = this.props;

        const { dateFrom, dateTo } = this.state;
        const { translatorPrepaidTasks, isGettingTranslatorPrepaidTasks, isLoadingMoreTranslatorPrepaidTasks } = this.props;
        const { translatorFullyPaidTasks, isGettingTranslatorFullyPaidTasks, isLoadingMoreTranslatorFullyPaidTasks } = this.props;

        const { statusFilter } = this.state;

        const { t, currentLanguage } = this.props;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }
        
        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="translator"){
                    return "NOT ALLOWED";
                }
            }
        }

        let totalBalance = 0;

        translatorPrepaidTasks.map( data => {
            if(!data.isPaidToTranslator){
                totalBalance = exactMath.add(totalBalance,data.translatorEarnings)
            }
        })

        let totalEarnings = 0;

        translatorTasks.map( data => {
            if(data.isPaidToTranslator && data.status==="completed"){
                totalEarnings = totalEarnings + data.translatorEarnings
            }
        })

        return (
            <div className="div-holder">
                {
                    (isAuthenticated && isMobileView) ?
                    <div style={{ position : "absolute", top : "0" }}>
                        <nav className="navbar navbar-expand-lg fixed-top">
                            <span className="page-mobile-header"><img onClick={ () => this.props.history.goBack() } style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../../../images/back.png')}/>{t('income_header')}</span>
                        </nav>
                    </div>:
                    ""
                }
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        {
                            isMobileView ?
                            <div>
                                <div className="align-left" style={{ marginTop : "20px" }}>
                                <p className="translator-balance align-left">{t('income_balance')} ${ Number.isInteger(translatorTransactionBalance) ? translatorTransactionBalance.toFixed(2) : translatorTransactionBalance }</p>
                                   
                                </div>
                                <div style={{ textAlign : "center", marginBottom : "20px" }}>
                                    <div>
                                        <DateFilter t={t} isMobileView={isMobileView} onDateToChange={this.onDateToChange} onDateFromChange={this.onDateFromChange} dateTo={dateTo} dateFrom={dateFrom} />
                                    </div>
                                </div>
                                { translatorTransactionBalance!=0 &&  <button className="translator-get-paid" style={{ width : "100%" }} onClick={this.getPaid}>{t('common_get_paid')}</button> }
                                <div style={{ flex : "1", marginTop : "20px", marginBottom : "20px" }}>
                                    <ul className="status-filters" style={{ paddingLeft : "0" }}>
                                        <li onClick={ () => this.filterByStatus('inProgress')} style={ { marginRight : "15px", flex : "1" } }><span style={ statusFilter==="inProgress" ? {  background: "#7AB7B6", color : "white", width : "100%", textAlign : "center" } : {   background: "#F5F4F6", color : "#7D7C7F", width : "100%", textAlign : "center" } } className="status-filter">{t('common_stat_in_progress')}</span></li>
                                        <li onClick={ () => this.filterByStatus('completed')} style={ { flex : "1" } }><span style={ statusFilter==="completed" ? {  background: "#7AB7B6", color : "white", width : "100%", textAlign : "center" } : {   background: "#F5F4F6", color : "#7D7C7F", width : "100%", textAlign : "center" }  } className="status-filter">{t('income_ready_projects')}</span></li>
                                    </ul>
                                </div>
                            </div>
                            :
                            <div style={{ display : "flex"}}>
                                <div className="align-left" style={{ flex : "1", marginBottom : "20px" }}>
                                    { !isMobileView &&  <p className="module-header">{t('income_header')}</p> }
                                    <div>
                                        <DateFilter t={t} onDateToChange={this.onDateToChange} onDateFromChange={this.onDateFromChange} dateTo={dateTo} dateFrom={dateFrom} />
                                    </div>
                                </div>
                                    <div className="align-right" style={{ flex : "1",marginTop : "20px" }}>
                                        <p className="translator-balance">{t('income_balance')} ${ Number.isInteger(translatorTransactionBalance) ? translatorTransactionBalance.toFixed(2) : translatorTransactionBalance }</p>
                                        { translatorTransactionBalance!=0 &&  <button className="translator-get-paid" onClick={this.getPaid}>{t('common_get_paid')}</button> }
                                    </div>
                            </div>
                            
                        }
                        {
                            isGettingTranslatorPrepaidTasks ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            isMobileView ?

                                translatorPrepaidTasks.length!== 0 && statusFilter==="inProgress" ?
                                <MobileList
                                    t={t}
                                    taskSet={"inProgress"}
                                    isLoadingMoreTranslatorPrepaidTasks={isLoadingMoreTranslatorPrepaidTasks}
                                    tasks={translatorPrepaidTasks}
                                    loadMoreTranslatorPrepaidTasks={this.loadMoreTranslatorPrepaidTasks}
                                />
                                : ""

                            :
                                translatorPrepaidTasks.length!== 0 ?
                                <TaskList
                                    t={t}
                                    isMobileView={isMobileView}
                                    taskSet={"prepaid"}
                                    isLoadingMoreTranslatorPrepaidTasks={isLoadingMoreTranslatorPrepaidTasks}
                                    tasks={translatorPrepaidTasks}
                                    loadMoreTranslatorPrepaidTasks={this.loadMoreTranslatorPrepaidTasks}
                                />
                                : ""
                            
                        }
                        { !isMobileView && <span className="align-left" style={{ display: "block", marginTop: "20px", marginBottom : "15px" }}>{t('income_old_projects')}</span> }
                        {
                            isGettingTranslatorFullyPaidTasks ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            isMobileView ? 
                                translatorFullyPaidTasks.length!== 0 && statusFilter==="completed"  ?

                                <MobileList
                                    t={t}
                                    isMobileView={isMobileView}
                                    taskSet={"fullyPaid"}
                                    isLoadingMoreTranslatorFullyPaidTasks={isLoadingMoreTranslatorFullyPaidTasks}
                                    tasks={translatorFullyPaidTasks}
                                    loadMoreTranslatorFullyPaidTasks={this.loadMoreTranslatorFullyPaidTasks}
                                    totalEarnings={userProfile.amountEarned}
                                />
                                : ""

                            :
                                translatorFullyPaidTasks.length!== 0 ?
                                <TaskList
                                    t={t}
                                    isMobileView={isMobileView}
                                    taskSet={"fullyPaid"}
                                    isLoadingMoreTranslatorFullyPaidTasks={isLoadingMoreTranslatorFullyPaidTasks}
                                    tasks={translatorFullyPaidTasks}
                                    loadMoreTranslatorFullyPaidTasks={this.loadMoreTranslatorFullyPaidTasks}
                                    totalEarnings={userProfile.amountEarned}
                                />
                                : ""
                            
                        }
                        <div className="total-earnings-div align-right">
                            <span className="total-earnings-text">{t('income_total_earnings')} </span>
                            <span className="total-earnings">
                                ${ Number.isInteger(userProfile.amountEarned) ? userProfile.amountEarned.toFixed(2) : userProfile.amountEarned }
                            </span>
                        </div>
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                        {
                            isConfirmationModalActive &&
                            <GetPaidConfirmationModal
                                t={t}
                                confirm={this.confirm}
                                closeGetPaidConfirmationModal={this.closeGetPaidConfirmationModal}
                                isOpened={isConfirmationModalActive}
                                balance={translatorTransactionBalance}
                            />
                        }
                        {
                            isMessageModalActive && 
                            <Message 
                                t={t}
                                closeMessageModal={this.closeMessageModal} 
                                isOpened={isMessageModalActive} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                        {
                            isMessageModalActivePaid && 
                            <Message 
                                t={t}
                                closeMessageModal={this.closeMessageModalPaid} 
                                isOpened={isMessageModalActivePaid} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                        {
                            isProcessing &&
                            <SpinnerModal 
                                isOpened={isProcessing}
                            />
                        }
                        
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isGettingTasks : state.task.isGettingTasks,
        translatorTasks : state.task.translatorTasks,
        translatorPrepaidTasks : state.task.translatorPrepaidTasks,
        isGettingTranslatorPrepaidTasks : state.task.isGettingTranslatorPrepaidTasks,
        isLoadingMoreTranslatorPrepaidTasks : state.task.isLoadingMoreTranslatorPrepaidTasks,
        translatorFullyPaidTasks : state.task.translatorFullyPaidTasks,
        isGettingTranslatorFullyPaidTasks : state.task.isGettingTranslatorFullyPaidTasks,
        isLoadingMoreTranslatorFullyPaidTasks : state.task.isLoadingMoreTranslatorFullyPaidTasks,
        isLoggingOut : state.auth.isLoggingOut,
        modalInstances : state.app.modalInstances,
        translatorTransactionBalance : state.task.translatorTransactionBalance,
        isMobileView : state.app.isMobileView
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {   
        getAllTranslatorTasks,
        getPrepaidTasksTranslator,
        loadMorePrepaidTasksTranslator,
        getFullyPaidTasksTranslator,
        loadMoreFullyPaidTasksTranslator,
        setIncomeReportsFilter,
        resetIncomeReportsFilter,
        updateTasksPaid,
        updateTranslatorAmountEarned,
        getTranslatorBalance,
        saveEarnings
    })
)(Index)