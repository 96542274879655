export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const CHECK_FOR_DUPLICATE_EMAIL = "CHECK_FOR_DUPLICATE_EMAIL"; 
export const CHECK_FOR_DUPLICATE_EMAIL_RESULT = "CHECK_FOR_DUPLICATE_EMAIL_RESULT";
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const VERIFY = "VERIFY";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VALIDATE_EMAIL_ERROR = "VALIDATE_EMAIL_ERROR"
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS"
export const SEND_PASSWORD_RESET = "SEND_PASSWORD_RESET"
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS"
export const SEND_PASSWORD_RESET_ERROR = "SEND_PASSWORD_RESET_ERROR"
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const GET_AUTH_USER_DATA = "GET_AUTH_USER_DATA"
export const GET_AUTH_USER_DATA_ERROR = "GET_AUTH_USER_DATA_ERROR"
export const GET_AUTH_USER_DATA_SUCCESS = "GET_AUTH_USER_DATA_SUCCESS"

/**
 * TASKS
 */

export const SAVE_TASK_CREATION_STATE = "SAVE_TASK_CREATION_STATE"
export const CLEAR_TASK_CREATION_STATE = "CLEAR_TASK_CREATION_STATE"

export const GET_PUBLISHED_TASKS = "GET_PUBLISHED_TASKS"
export const GET_PUBLISHED_TASKS_ERROR = "GET_PUBLISHED_TASKS_ERROR"
export const GET_PUBLISHED_TASKS_SUCCESS = "GET_PUBLISHED_TASKS_SUCCESS"
export const LOAD_MORE_PUBLISHED_TASKS = "LOAD_MORE_PUBLISHED_TASKS"
export const LOAD_MORE_PUBLISHED_TASKS_ERROR = "LOAD_MORE_PUBLISHED_TASKS_ERROR"
export const LOAD_MORE_PUBLISHED_TASKS_SUCCESS = "LOAD_MORE_PUBLISHED_TASKS_SUCCESS"

export const GET_BASKET_TASKS = "GET_BASKET_TASKS"
export const GET_BASKET_TASKS_ERROR = "GET_BASKET_TASKS_ERROR"
export const GET_BASKET_TASKS_SUCCESS = "GET_BASKET_TASKS_SUCCESS"
export const LOAD_MORE_BASKET_TASKS = "LOAD_MORE_BASKET_TASKS"
export const LOAD_MORE_BASKET_TASKS_ERROR = "LOAD_MORE_BASKET_TASKS_ERROR"
export const LOAD_MORE_BASKET_TASKS_SUCCESS = "LOAD_MORE_BASKET_TASKS_SUCCESS"
export const GET_BASKET_COUNT = "GET_BASKET_COUNT" 
export const GET_BASKET_COUNT_SUCCESS = "GET_BASKET_COUNT_SUCCESS" 
export const GET_BASKET_COUNT_ERROR = "GET_BASKET_COUNT_ERROR" 

export const GET_T_INPROGRESS_TASKS = "GET_T_INPROGRESS_TASKS"
export const GET_T_INPROGRESS_TASKS_ERROR = "GET_T_INPROGRESS_TASKS_ERROR"
export const GET_T_INPROGRESS_TASKS_SUCCESS = "GET_T_INPROGRESS_TASKS_SUCCESS"
export const LOAD_MORE_T_INPROGRESS_TASKS = "LOAD_MORE_T_INPROGRESS_TASKS"
export const LOAD_MORE_T_INPROGRESS_TASKS_ERROR = "LOAD_MORE_T_INPROGRESS_TASKS_ERROR"
export const LOAD_MORE_T_INPROGRESS_TASKS_SUCCESS = "LOAD_MORE_T_INPROGRESS_TASKS_SUCCESS"

export const UPLOAD_TASK_FILE = "UPLOAD_TASK_FILE"
export const UPLOAD_TASK_FILE_SUCCESS = "UPLOAD_TASK_FILE_SUCCESS"
export const UPLOADING_TASK_FILE = "UPLOADING_TASK_FILE"
export const UPLOADING_TASK_FILE_ERROR = "UPLOADING_TASK_FILE_ERROR"
export const UPLOADING_TASK_FILE_SUCCESS = "UPLOADING_TASK_FILE_SUCCESS"
export const REMOVE_TASK_FILE = "REMOVE_TASK_FILE"
export const REMOVE_TASK_FILE_ERROR = "REMOVE_TASK_FILE_ERROR"
export const REMOVE_TASK_FILE_SUCCESS = "REMOVE_TASK_FILE_SUCCESS"
export const SET_UPLOAD_TASK_FILES = "SET_UPLOAD_TASK_FILES"
export const RESET_UPLOAD_TASK_FILES = "RESET_UPLOAD_TASK_FILES"

export const GET_ARCHIVED_TASKS = "GET_ARCHIVED_TASKS"
export const GET_ARCHIVED_TASKS_SUCCESS = "GET_ARCHIVED_TASKS_SUCCESS"
export const GET_ARCHIVED_TASKS_ERROR = "GET_ARCHIVED_TASKS_ERROR"
export const LOAD_MORE_ARCHIVED_TASKS = "LOAD_MORE_ARCHIVED_TASKS"
export const LOAD_MORE_ARCHIVED_TASKS_SUCCESS = "LOAD_MORE_ARCHIVED_TASKS_SUCCESS"
export const LOAD_MORE_ARCHIVED_TASKS_ERROR = "LOAD_MORE_ARCHIVED_TASKS_ERROR"

export const GET_ARCHIVED_TASKS_PAGINATE = "GET_ARCHIVED_TASKS_PAGINATE"
export const GET_ARCHIVED_TASKS_PAGINATE_SUCCESS = "GET_ARCHIVED_TASKS_PAGINATE_SUCCESS"
export const GET_ARCHIVED_TASKS_PAGINATE_ERROR = "GET_ARCHIVED_TASKS_PAGINATE_ERROR"
export const CLEAR_ARCHIVED_TASKS_LAST_REF = "CLEAR_ARCHIVED_TASKS_LAST_REF"

export const FILTER_TRANSLATOR_INCOME_REPORTS = "FILTER_TRANSLATOR_INCOME_REPORTS"
export const RESET_TRANSLATOR_INCOME_REPORTS_FILTER = "RESET_TRANSLATOR_INCOME_REPORTS_FILTER"

export const GET_TRANSLATOR_TASKS = "GET_TRANSLATOR_TASKS"
export const GET_TRANSLATOR_TASKS_SUCCESS = "GET_TRANSLATOR_TASKS_SUCCESS"
export const GET_TRANSLATOR_TASKS_ERROR = "GET_TRANSLATOR_TASKS_ERROR"

export const DISPLAY_NEW_TASK_INPROGRESS = "DISPLAY_NEW_TASK_INPROGRESS"
export const REMOVE_NEW_TASK_INPROGRESS = "REMOVE_NEW_TASK_INPROGRESS"

export const DISPLAY_NEW_TASK_COMPLETED = "DISPLAY_NEW_TASK_COMPLETED"
export const REMOVE_NEW_TASK_COMPLETED = "REMOVE_NEW_TASK_COMPLETED"

export const FILTER_CUSTOMER_OUTCOME_REPORTS = "FILTER_CUSTOMER_OUTCOME_REPORTS"
export const FILTER_PAYMENT_CUSTOMER_OUTCOME_REPORTS = "FILTER_PAYMENT_CUSTOMER_OUTCOME_REPORTS"
export const RESET_CUSTOMER_OUTCOME_REPORTS_FILTER = "RESET_CUSTOMER_OUTCOME_REPORTS_FILTER"
export const RESET_CUSTOMER_OUTCOME_REPORTS_PAYMENT_FILTER = "RESET_CUSTOMER_OUTCOME_REPORTS_PAYMENT_FILTER"

export const GET_PREPAID_TASKS = "GET_PREPAID_TASKS"
export const GET_PREPAID_TASKS_ERROR = "GET_PREPAID_TASKS_ERROR"
export const GET_PREPAID_TASKS_SUCCESS = "GET_PREPAID_TASKS_SUCCESS"
export const LOAD_MORE_PREPAID_TASKS = "LOAD_MORE_PREPAID_TASKS"
export const LOAD_MORE_PREPAID_TASKS_ERROR = "LOAD_MORE_PREPAID_TASKS_ERROR"
export const LOAD_MORE_PREPAID_TASKS_SUCCESS = "LOAD_MORE_PREPAID_TASKS_SUCCESS"

export const GET_FULLY_PAID_TASKS = "GET_FULLY_PAID_TASKS"
export const GET_FULLY_PAID_TASKS_ERROR = "GET_FULLY_PAID_TASKS_ERROR"
export const GET_FULLY_PAID_TASKS_SUCCESS = "GET_FULLY_PAID_TASKS_SUCCESS"
export const LOAD_MORE_FULLY_PAID_TASKS = "LOAD_MORE_FULLY_PAID_TASKS"
export const LOAD_MORE_FULLY_PAID_TASKS_ERROR = "LOAD_MORE_FULLY_PAID_TASKS_ERROR"
export const LOAD_MORE_FULLY_PAID_TASKS_SUCCESS = "LOAD_MORE_FULLY_PAID_TASKS_SUCCESS"

export const GET_PREPAID_TASKS_TRANSLATOR = "GET_PREPAID_TASKS_TRANSLATOR"
export const GET_PREPAID_TASKS_TRANSLATOR_ERROR = "GET_PREPAID_TASKS_TRANSLATOR_ERROR"
export const GET_PREPAID_TASKS_TRANSLATOR_SUCCESS = "GET_PREPAID_TASKS_TRANSLATOR_SUCCESS"
export const LOAD_MORE_PREPAID_TASKS_TRANSLATOR = "LOAD_MORE_PREPAID_TASKS_TRANSLATOR"
export const LOAD_MORE_PREPAID_TASKS_TRANSLATOR_ERROR = "LOAD_MORE_PREPAID_TASKS_TRANSLATOR_ERROR"
export const LOAD_MORE_PREPAID_TASKS_TRANSLATOR_SUCCESS = "LOAD_MORE_PREPAID_TASKS_TRANSLATOR_SUCCESS"

export const GET_FULLY_PAID_TASKS_TRANSLATOR = "GET_FULLY_PAID_TASKS_TRANSLATOR"
export const GET_FULLY_PAID_TASKS_TRANSLATOR_ERROR = "GET_FULLY_PAID_TASKS_TRANSLATOR_ERROR"
export const GET_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS = "GET_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS"
export const LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR = "LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR"
export const LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_ERROR = "LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_ERROR"
export const LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS = "LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS"

export const GET_TRANSLATOR_BALANCE = "GET_TRANSLATOR_BALANCE"
export const GET_TRANSLATOR_BALANCE_SUCCESS = "GET_TRANSLATOR_BALANCE_SUCCESS"
export const GET_TRANSLATOR_BALANCE_ERROR = "GET_TRANSLATOR_BALANCE_ERROR"
export const RESET_TRANSLATOR_BALANCE = "RESET_TRANSLATOR_BALANCE"

export const GET_CUSTOMER_PAYABLE = "GET_CUSTOMER_PAYABLE"
export const GET_CUSTOMER_PAYABLE_SUCCESS = "GET_CUSTOMER_PAYABLE_SUCCESS"
export const GET_CUSTOMER_PAYABLE_ERROR = "GET_CUSTOMER_PAYABLE_ERROR"

export const PAY_TRANSLATOR = "PAY_TRANSLATOR"
export const PAY_TRANSLATOR_SUCCESS = "PAY_TRANSLATOR_SUCCESS"
export const PAY_TRANSLATOR_ERROR = "PAY_TRANSLATOR_ERROR"

export const REMOVE_UPLOAD_TASK_FILES = "REMOVE_UPLOAD_TASK_FILES"

/**
 * CONVERSATION
 */
export const CHECK_CONVERSATION = "CHECK_CONVERSATION" 
export const CHECK_CONVERSATION_SUCCESS = "CHECK_CONVERSATION_SUCCESS" 
export const CHECK_CONVERSATION_ERROR = "CHECK_CONVERSATION_ERROR" 
export const CREATE_CONVERSATION = "CREATE_CONVERSATION"
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS"
export const CREATE_CONVERSATION_ERROR = "CREATE_CONVERSATION_ERROR"
export const GET_CONVERSATIONS = "GET_CONVERSATIONS" 
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS" 
export const GET_CONVERSATIONS_ERROR = "GET_CONVERSATIONS_ERROR"
export const LOAD_MORE_CONVERSATIONS = "LOAD_MORE_CONVERSATIONS"
export const LOAD_MORE_CONVERSATIONS_SUCCESS = "LOAD_MORE_CONVERSATIONS_SUCCESS"
export const LOAD_MORE_CONVERSATIONS_ERROR = "LOAD_MORE_CONVERSATIONS_ERROR"
export const GET_CONVERSATION_MESSAGES = "GET_CONVERSATION_MESSAGES"
export const GET_CONVERSATION_MESSAGES_SUCCESS = "GET_CONVERSATION_MESSAGES_SUCCESS"
export const GET_CONVERSATION_MESSAGES_ERROR = "GET_CONVERSATION_MESSAGES_ERROR"
export const GET_CONVERSATION_MESSAGES_PAGINATE = "GET_CONVERSATION_MESSAGES_PAGINATE"
export const GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS = "GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS"
export const GET_CONVERSATION_MESSAGES_PAGINATE_ERROR = "GET_CONVERSATION_MESSAGES_PAGINATE_ERROR"
export const CLEAR_ACTIVE_CONVERSATION = "CLEAR_ACTIVE_CONVERSATION"
export const CREATE_CONVERSATION_MESSAGE = "CREATE_CONVERSATION_MESSAGE"
export const CREATE_CONVERSATION_MESSAGE_SUCCESS = "CREATE_CONVERSATION_MESSAGE_SUCCESS"
export const CREATE_CONVERSATION_MESSAGE_ERROR = "CREATE_CONVERSATION_MESSAGE_ERROR"
export const RELOAD_CONVERSATION_MESSAGES = "RELOAD_CONVERSATION_MESSAGES"
export const SET_ACTIVE_CONVERSATION = "SET_ACTIVE_CONVERSATION"
export const RELOAD_ACTIVE_CONVERSATION = "RELOAD_ACTIVE_CONVERSATION"
export const REMOVE_ACTIVE_CONVERSATION = "REMOVE_ACTIVE_CONVERSATION"
export const REMOVE_ACTIVE_CONVERSATION_SUCCESS = "REMOVE_ACTIVE_CONVERSATION_SUCCESS"
export const REMOVE_ACTIVE_CONVERSATION_ERROR = "REMOVE_ACTIVE_CONVERSATION_ERROR"
export const SEND_MESSAGE = "SEND_MESSAGE"
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS"
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR"
export const SEARCH_CONTACTS = "SEARCH_CONTACTS" 
export const SEARCH_CONTACTS_SUCCESS = "SEARCH_CONTACTS_SUCCESS" 
export const SEARCH_CONTACTS_ERROR = "SEARCH_CONTACTS_ERROR"
export const SEARCH_MESSAGES = "SEARCH_MESSAGES" 
export const SEARCH_MESSAGES_SUCCESS = "SEARCH_MESSAGES_SUCCESS" 
export const SEARCH_MESSAGES_ERROR = "SEARCH_MESSAGES_ERROR" 
export const DISPLAY_MESSAGE_BADGE = "DISPLAY_MESSAGE_BADGE"
export const RESET_MESSAGE_BADGE = "RESET_MESSAGE_BADGE" 
export const WATCH_CONVERSATIONS = "WATCH_CONVERSATIONS"
export const RELOAD_CONVERSATIONS = "RELOAD_CONVERSATIONS" 

export const ADD_MODAL = "ADD_MODAL"
export const POP_MODAL = "POP_MODAL"
export const SET_HEADER = "SET_HEADER"
export const SET_DEVICE = "SET_DEVICE"
export const SET_CURR_LANG = "SET_CURR_LANG" 

export const CHECK_TRANSLATOR_BALANCE = "CHECK_TRANSLATOR_BALANCE"
export const WATCH_TRANSLATOR_TRANSACTIONS = "WATCH_TRANSLATOR_TRANSACTIONS"

export const RELOAD_NOTIFICATIONS = "RELOAD_NOTIFICATIONS"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"