import React, { Component } from 'react'

export class ConversationHeader extends Component {

    constructor(){
        super();
    }

    render(){

        const {  activeConversation, userProfile, isMobileView, t, currentLanguage } = this.props;

        let imageUrl;
        let userName;
        let recipient;

        if( activeConversation!== null ){
            Object.keys(activeConversation.memberData).map( i=> {
                if(i!==userProfile.uid){
                    recipient = activeConversation.memberData[i]
                }
            })
    
            imageUrl = recipient.imageUrl === "" ? require('../../images/default-user.jpg') : recipient.imageUrl
            userName = recipient.fullName.split(",");
        }

  

        return (
            <div className="conversation-header">
                <div className="align-left recipient-details">
                    {   
                        (activeConversation && !isMobileView) &&
                        <img className="display-photo" src={imageUrl}/> 
                    }
                    {   
                        (activeConversation && isMobileView) &&
                        <div>
                            <img onClick={ () => this.props.history.goBack() } style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: "15px"}} src={require('../../images/back.png')}/><img className="display-photo" src={imageUrl}/> 
                        </div>

                    }
                    <div className="details">
                        <span className="username">{activeConversation !== null ? userName[1]+' '+userName[0] : ""}</span>
                        <span className="task-title">{activeConversation !== null ? activeConversation.taskTitle : "" }</span>
                    </div>
                </div>
                <div className="recipient-actions">
                    {
                        activeConversation && <div class="btn-group">
                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            . . .
                            </button>
                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                            { (userProfile.type ==="customer" && activeConversation.hasOwnProperty('taskObject') && activeConversation.taskObject.status!=="completed") && <button onClick={ () => this.props.openPauseTaskModal({ uid : activeConversation.task})} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_pause')}</button> }
                            { (userProfile.type ==="customer" && activeConversation.hasOwnProperty('taskObject') && activeConversation.taskObject.status!=="completed") &&  <button onClick={ () => this.props.openStopTaskModal({ uid : activeConversation.task})} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_stop')}</button> }
                            <button onClick={ () => this.props.openEscalateTaskModal({ uid : activeConversation.task})} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                            </div>
                        </div>
                    }
                  
                </div>
            </div>
        )

    }

}

export default ConversationHeader