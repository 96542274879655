import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}


export class List extends Component {

    scrollToBottom = () => {
        const { taskList } = this.refs;
        const scrollHeight = taskList.scrollHeight;
        const height = taskList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(taskList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render(){

        const { tasks, taskSet, totalEarnings, t } = this.props;
        const { isLoadingMoreTranslatorPrepaidTasks, isLoadingMoreTranslatorFullyPaidTasks } = this.props;

        // let uncompletedTasks = tasks.length!==0 && tasks.filter( data => data.status!== "completed");
        // let completedTasks = tasks.length!==0 && tasks.filter( data=> data.status ==="completed");

        return (
            
            <div className="div-holder translator-tasks">
                {
                    taskSet==="prepaid" &&
                    <div className="task-table-div">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "100px", textTransform : "uppercase" }}>{t('common_date')}</th>
                                    <th style={{ width: "150px", textTransform : "uppercase" }}>{t('common_order_num')}</th>
                                    <th style={{ width: "150px", textTransform : "uppercase" }}>{t('common_task_type')}</th>
                                    <th style={{ width: "400px", textTransform : "uppercase" }}>{t('common_task_name')}</th>
                                    <th style={{ width: "150px", textTransform : "uppercase" }}>{t('income_earnings')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                tasks.map( (data, i) => (
                                    <tr className="uncompleted-task animated fadeInUp">
                                        <td>{ moment.unix(data.dateAdded.seconds).format("MMM D, Y") }</td>
                                        <td>{ data.orderID }</td>
                                        <td><span style={taskTypeColor(data.taskType)} className="task-type">{data.taskType}</span></td>
                                        <td>{ data.title }</td>
                                        <td style={{ fontWeight : "bold" }}>${ data.translatorEarnings.toFixed(2) }</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        {
                            isLoadingMoreTranslatorPrepaidTasks ? <div style={{ paddingTop : "15px" }}>
                                <div class="custom-get-more-spinner spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                                <button style={{  marginBottom : "15px"  }} onClick={this.props.loadMoreTranslatorPrepaidTasks} className="load-more-button">{t('common_load_more')}</button>
                            </div>
                        }
                    </div>
                }
                {
                    taskSet==="fullyPaid" &&
                    <div style={{ marginTop : "20px" }} className="task-table-div" >
                        <table>
                            <tbody>
                            {
                                tasks.map( (data, i) => (
                                    <tr className="completed-task animated fadeInUp">
                                        <td style={{ width: "100px" }}>{ moment.unix(data.dateAdded.seconds).format("MMM D, Y") }</td>
                                        <td style={{ width: "150px" }}>{ data.orderID }</td>
                                        <td style={{ width: "150px" }}><span style={taskTypeColor(data.taskType)} className="task-type">{data.taskType}</span></td>
                                        <td  style={{ width: "400px" }}>{ data.title }</td>
                                        <td  style={{ fontWeight : "bold", width: "150px" }}>${ data.translatorEarnings.toFixed(2) }</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        {
                            isLoadingMoreTranslatorFullyPaidTasks ? <div style={{ paddingTop : "15px" }}>
                                <div class="custom-get-more-spinner spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div> : <div className="text-center mb-3" style={{ marginTop : "15px" }}>
                                <button style={{ marginBottom : "15px"}} onClick={this.props.loadMoreTranslatorFullyPaidTasks} className="load-more-button">LOAD MORE</button>
                            </div>
                        }
                    </div>

                }
            </div>

            // <div className="div-holder translator-tasks">
            //     <div className="task-table-div">
            //         <table>
            //             <tbody>
            //             {
            //                 uncompletedTasks.map( (data, i) => (
                                
            //                 ))
            //             }
            //             {
            //                 completedTasks.length!== 0 && <tr><td colspan="5">Old</td></tr>
            //             }
            //             {
            //                 completedTasks.map( (data, i) => (
                                
            //                 ))
            //             }
            //             </tbody>
            //         </table>
            //     </div>
                
            // </div>
        )
    }

}

export default List;
