import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../actions/appAction';

export class Confirmation extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){

        const { isOpened } = this.props;
        
        if(isOpened){
            $('#confirmationModal').modal({backdrop:'static', keyboard:false});
            $('#confirmationModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#confirmationModal').modal('show');
            this.props.addModal('confirmation-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("cf-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="confirmation-modal");
            if(modalInstance!==undefined){
                $('.cf-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        $('#confirmationModal').modal('hide');
        this.props.popModal()
    }

    render () {

        const { modalZ } = this.state;
        const { header, btnYes, btnCancel } = this.props;

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} confirmation-modal modal fade modal-opaque`} id="confirmationModal" tabIndex="-1" role="dialog" aria-labelledby="helpTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeConfirmationModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="modal-header">
                                <button onClick={this.props.closeConfirmationModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        <div className="modal-body">
                            <p className="confirmation-header">{header}</p>
                            <div className="text-center" style={{ display : "flex" }}>
                                <button className="btn-ok" onClick={this.props.confirm} style={{ flex : "1" }}>{btnYes}</button>    
                                <button className="btn-cancel" onClick={this.props.closeConfirmationModal} style={{ flex : "1" }}>{btnCancel}</button>    
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(Confirmation)