import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

export class PaymentFilter extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){

        const { isOpened } = this.props;
        
        if(isOpened){
            $('#paymentFilterModal').modal({backdrop:'static', keyboard:false});
            $('#paymentFilterModal').modal('show');
            this.props.addModal('payment-filter-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("pyf-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="payment-filter-modal");
            if(modalInstance!==undefined){
                $('.pyf-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }


    componentWillUnmount(){
        $('#paymentFilterModal').modal('hide');
        this.props.popModal()
    }

    render () {

        const { modalZ } = this.state;
        const { header, btnYes, btnCancel } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className="payment-filter-modal modal fade modal-opaque" id="paymentFilterModal" tabIndex="-1" role="dialog" aria-labelledby="helpTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            
                        </div>
                        <div className="modal-body">
                            <p className="text-header">PAYMENT METHODS</p>
                            <div className="form-group mt-3">
                                <ul className="reason-list">
                                    <li>
                                        <label className="checkbox-container">Paypal
                                            <input name="paymentFilter" onClick={this.props.selectPaymentFilter} className="paymentFilter" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">Credit Card
                                            <input name="paymentFilter" onClick={this.props.selectPaymentFilter} className="paymentFilter" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>                      
                        </div>
                    
                    </div>
                </div>
            </div>
        )

    }


}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(PaymentFilter)