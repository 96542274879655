import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import MobileItem from './MobileItem';

export class MobileList extends Component {

    render(){

        const { tasks, taskSet, isMobileView } = this.props;
        const { isLoadingMorePrepaidTasks ,  isLoadingMoreFullyPaidTasks } = this.props;
        const { t, currentLanguage } = this.props;

        let totalToPay = 0;
        if(taskSet==="prepaid"){
            for(let c=0; c<tasks.length; c++){
                totalToPay = totalToPay + (tasks[c].remainingAmount)
            }
        }
        
        if(taskSet==="prepaid"){
            return (
                <div className="customer-outcome-report" ref="outcomeReportList">
                    <div>
                        <p className="align-left" style={{ fontSize : "14px", fontWeight : "bold" }}>{t('in_progress_header')}</p>
                    </div>
                    {
                        tasks.map( (data, i) => <MobileItem displayDelay={500} t={t} currentLanguage={currentLanguage} key={data.uid} task={data}/> )
                    }
                    <div className="text-center">
                    {
                        isLoadingMorePrepaidTasks ? <div style={{ paddingTop : "15px" }}>
                            <div class="custom-get-more-spinner spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                            <button onClick={this.props.loadMoreTranslatorPrepaidTasks} className="load-more-button">{t('common_load_more')}</button>
                        </div>
                    }
                    </div>
                    <div className="total-to-pay-div">
                        <span className="total-to-pay-text">{t('outcome_to_pay')}</span>
                        <span className="total-to-pay">
                            ${ totalToPay.toFixed(2) }
                        </span>
                        <button className="pay-total" onClick={this.props.payAllAmount}>{t('common_pay')}</button>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="" ref="outcomeReportList">
                    <div>
                        <p className="align-left" style={{ fontSize : "14px", fontWeight : "bold" }}>{t('outcome_old_projects')}</p>
                    </div>
                    {
                        tasks.map( (data, i) => <MobileItem displayDelay={500} t={t} currentLanguage={currentLanguage} key={data.uid} task={data}/> )
                    }
                    <div className="text-center">
                    {
                        isLoadingMoreFullyPaidTasks ? <div style={{ paddingTop : "15px" }}>
                            <div class="custom-get-more-spinner spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                            <button onClick={this.props.loadMoreTranslatorFullyPaidTasks} className="load-more-button">{t('common_load_more')}</button>
                        </div>
                    }
                    </div>  
                </div>
            )
        }

    }

}

export default MobileList;
