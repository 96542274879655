import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'
import exactMath from 'exact-math'

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}


export class List extends Component {

    render(){

        const { tasks, taskSet } = this.props;
        const { isLoadingMorePrepaidTasks, isLoadingMoreFullyPaidTasks } = this.props;
        const { t, currentLanguage } = this.props;

        let totalToPay = 0;

        for(let c=0; c<tasks.length; c++){
            totalToPay = exactMath.add(totalToPay,(tasks[c].remainingAmount))
        }

        return (
            
            <div className="div-holder customer-outcome-report">
                {
                    taskSet==="prepaid" &&
                    <div className="task-table-div">
                        <table>
                            <thead>
                                <tr>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_date')}</th>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_order_number')}</th>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_task_type')}</th>
                                    <th className="align-left" style={{ width: "400px" }}>{t('common_task_name')}</th>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_paid')}</th>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_need_to_pay')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                tasks.map( (data, i) => (
                                    <tr className="prepaid-task animated fadeInUp" >
                                        <td className="align-left">{ moment.unix(data.dateAdded.seconds).format("MMM D, Y") }</td>
                                        <td className="align-left">{ data.orderID }</td>
                                        <td className="align-left"><span style={taskTypeColor(data.taskType)} className="task-type">{t(`common_${data.taskType}`)}</span></td>
                                        <td className="align-left">{ data.title }</td>
                                        <td className="align-left">${ Number.isInteger((data.price - data.remainingAmount)) ? (data.price - data.remainingAmount).toFixed(2) : (data.price - data.remainingAmount) }</td>
                                        <td className="align-left" style={{ fontWeight : "bold" }}>${ Number.isInteger(data.remainingAmount) ? data.remainingAmount.toFixed(2) : data.remainingAmount }</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        {
                            isLoadingMorePrepaidTasks ? <div style={{ paddingTop : "15px" }}>
                                <div class="custom-get-more-spinner spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                                <button onClick={this.props.loadMorePrepaidTasks} className="load-more-button">{t('common_load_more')}</button>
                            </div>
                        }
                        <div className="total-to-pay-div">
                            <span className="total-to-pay-text">{t('outcome_to_pay')}</span>
                            <span className="total-to-pay">
                                ${ Number.isInteger(totalToPay) ? totalToPay.toFixed(2) : totalToPay }
                            </span>
                            <button className="pay-total" onClick={this.props.payAllAmount}>{t('common_pay')}</button>
                        </div>
                        
                    </div>
                }
                {
                    taskSet==="fullyPaid" &&
                    <div style={{ marginTop : "20px" }} className="task-table-div" >
                        <table>
                            <thead>
                                <tr>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_date')}</th>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_order_number')}</th>
                                    <th className="align-left" style={{ width: "150px" }}>{t('common_task_type')}</th>
                                    <th className="align-left" style={{ width: "400px" }}>{t('common_task_name')}</th>
                                    <th className="align-left" style={{ width: "150px" }}>{t('outcome_full_amount')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                tasks.map( (data, i) => (
                                    <tr className="animated fadeInUp">
                                        <td className="align-left">{ moment.unix(data.dateAdded.seconds).format("MMM D, Y") }</td>
                                        <td className="align-left">{ data.orderID }</td>
                                        <td className="align-left"><span style={taskTypeColor(data.taskType)} className="task-type">{t(`common_${data.taskType}`)}</span></td>
                                        <td className="align-left">{ data.title }</td>
                                        <td className="align-left" style={{ fontWeight : "bold" }}>${ Number.isInteger(data.price) ? data.price.toFixed(2) : data.price }</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        {
                            isLoadingMoreFullyPaidTasks ? <div style={{ paddingTop : "15px" }}>
                                <div class="custom-get-more-spinner spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div> : <div className="text-center mb-3" style={{ marginTop : "15px" }}>
                                <button style={{ marginBottom : "15px"}} onClick={this.props.loadMoreFullyPaidTasks} className="load-more-button">{t('common_load_more')}</button>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

}

export default List;
