import React, { Component } from 'react'
import $ from 'jquery'

export class TranslatorPage1 extends Component {

    state = {
        resumeFile : null
    }

    openFileUpload = (e) => {
        e.preventDefault();
        $('#cvFile').click();
    }

    onFileChange = (e) => {
        
        let allowedMimeTypes = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"]

        let file = e.target.files;

        if(file.length!==0){
            
            let invalidFiles = 0;
            Object.keys(e.target.files).map( (data,i) => {
                if(allowedMimeTypes.indexOf(e.target.files[i].type)==-1){
                    invalidFiles++;
                }
            })

            if(invalidFiles==0){
                this.props.onFileChange(file[0]);
                $('.btn-upload-cv').text(file[0].name)
            }
           
        }else{
            this.props.onFileChange(null);
        }
    }

    render () {
        const { values, t } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="align-left custom-label block">{t('common_first_name')}</label>
                            <input defaultValue={values.firstName} onFocus={this.props.inputFocus} onChange={this.props.onChange('firstName')} id="firstName" name="firstName" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_here')}/>
                            <span className="align-left block error-input-message">{this.props.firstNameValidation}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label className="align-left custom-label block">{t('common_last_name')}</label>
                            <input defaultValue={values.lastName} onFocus={this.props.inputFocus} onChange={this.props.onChange('lastName')} id="lastName" name="lastName" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_here')}/>
                            <span className="align-left block error-input-message">{this.props.lastNameValidation}</span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="align-left custom-label block">{t('common_email')}</label>
                    <input disabled={values.isEmailDisabled} defaultValue={values.emailAddress} onChange={this.props.onChange('email')} id="emailAddress" name="emailAddress" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_here')}/>
                    <span className="align-left block">{this.props.emailValidation}</span>
                </div>
                {
                    !values.isPasswordDisabled &&
                    <div className="form-group">
                        <label className="align-left custom-label block">{t('common_password')}</label>
                        <input  defaultValue={values.password} onChange={this.props.onChange('password')} id="password" name="password" style={{ width: "100%" }} className="custom-input" type="password" placeholder={t('common_enter_here')}/>
                        <span className="align-left block">{this.props.passValidation}</span>
                    </div>
                }
                <div className="cv-div">
                    <input onChange={this.onFileChange} accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" type="file" name="cvFile" id="cvFile" style={{ display : "none" }} />
                    <button onClick={this.openFileUpload} style={{ width: "100%" }} className="align-left btn-upload-cv">{t('signup_label_upload_cv')}</button>
                    <span class="align-left block error-input-message">{this.props.uploadValidation}</span>
                </div>
            </div>
        )
    }

}

export default TranslatorPage1;