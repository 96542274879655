import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import { getBasketCount, getBasketTasks, loadMoreBasketTasks, updateTask, resetTaskCreationState } from '../../../../actions/taskAction'
import TaskBasketList from './TaskBasketList';
import DeleteTaskModal from '../../Modals/DeleteTaskModal'
import SpinnerModal from '../../../Spinner/SpinnerModal'
import $ from 'jquery'

import {
    translate
} from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isDeleteTaskModalActive : false,
            isProcessing : false,
            selectedTask : {},
            isAuthenticated : false
        }
    }

    async componentDidMount(){

        const { auth, userProfile } = this.props;
        if(auth.uid){
            this.loadTasks();
            this.props.resetTaskCreationState();
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    loadTasks = async () => {
        this.props.getBasketTasks();
    }

    loadMoreBasketTasks = () => {
        this.props.loadMoreBasketTasks();
    }

    openDeleteTaskModal = (task) => {
        this.setState({
            isDeleteTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeDeleteTaskModal = () => {
        $('#deleteTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout(() => {
            this.setState({
                isDeleteTaskModalActive : false,
                selectedTask : {}
            })
        }, 1500)
    }

    deleteTask = async () => {
        const { selectedTask } = this.state;

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.updateTask({
                ...selectedTask,
                isDeleted : true
            });

            this.loadTasks();
            this.props.getBasketCount();
            $('#deleteTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
            setTimeout( () => {
                this.setState({
                    isDeleteTaskModalActive : false,
                    isProcessing : false
                })
            }, 1500)
            
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

        if(prevProps.tasks !== this.props.tasks){
            this.scrollToBottom();
        }
    }

    scrollToBottom = () => {
        const scrollHeight = $('html')[0].scrollHeight;
        const height = $('html')[0].clientHeight;
        const maxScrollTop = scrollHeight - height;
        $('html')[0].scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    render() {
        const { isDeleteTaskModalActive, isProcessing, isAuthenticated } = this.state;
        const { tasks, isGettingTasks, isLoadingMoreBasketTasks } = this.props;
        const { isLoggingOut, auth, userProfile } = this.props;
        const { t, currentLanguage } = this.props;
        const { isMobileView } = this.props;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="customer"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <p className="module-header">{t('basket_header')}</p>
                            </div>
                        </div>
                        <div className="div-holder">
                            {
                                isGettingTasks ? 
                                <div className="d-flex justify-content-center">
                                    <div style={{ width: "5rem", height : "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                tasks.length!==0 ?  
                                <TaskBasketList 
                                    t={t}
                                    currentLanguage={currentLanguage}
                                    isMobileView={isMobileView}
                                    loadTasks={this.loadTasks} 
                                    loadMoreBasketTasks={this.loadMoreBasketTasks}
                                    isLoadingMoreBasketTasks={isLoadingMoreBasketTasks}
                                    openDeleteTaskModal={this.openDeleteTaskModal} 
                                    tasks={tasks}
                                    /> : 
                                <div className="filter-no-task">
                                    <img style={{ width : isMobileView  ? "50%" : "25%" }} src={require('../../../../images/no-task.png')}/>
                                    <p className="filter-no-task-text">{t('published_empty_list_header')}</p>
                                    <Link to="/task/create"><button className="filter-no-task-button">{t('common_create_task')}</button></Link>
                                </div>
                            }
                        </div>
                        {
                            isDeleteTaskModalActive && <DeleteTaskModal t={t} currentLanguage={currentLanguage} deleteTask={this.deleteTask} closeDeleteTaskModal={this.closeDeleteTaskModal} isOpened={isDeleteTaskModalActive}/>
                        }
                        {
                            isProcessing && 
                            <SpinnerModal
                                isOpened={isProcessing}
                            />
                        }
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        tasks : state.task.basketTasks,
        isGettingTasks : state.task.isGettingTasks,
        isLoadingMoreBasketTasks : state.task.isLoadingMoreBasketTasks,
        userProfile : state.firebase.profile,
        isLoggingOut : state.auth.isLoggingOut,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { getBasketCount, getBasketTasks, updateTask, loadMoreBasketTasks, resetTaskCreationState })
)(Index)

