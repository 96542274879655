import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';
import $ from 'jquery';

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895", marginTop : "15px" }
        case "essay":
            return { background : "#7E57C2", marginTop : "15px" }
        default:
            return { background : "#29B6F6", marginTop : "15px" }
    }
}

export class MobileItem extends Component {

    state = {
        shouldRender : true
    }

    componentDidMount(){
        const { displayDelay } = this.props;
        this.timer = setTimeout(() => {
            this.setState({ shouldRender: true }, () => {
                const scrollHeight = $('html')[0].scrollHeight;
                const height = $('html')[0].clientHeight;
                const maxScrollTop = scrollHeight - height;
                // $("html, body").animate({ scrollTop : maxScrollTop > 0 ? maxScrollTop : 0 }, 200);
            });
        }, displayDelay);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){
        const { shouldRender }  = this.state;
        const { task, t } = this.props;
    
        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
       
        return (
            <div>
                {
                    shouldRender &&
                    <div style={{ padding : "20px" }} className="task-item animated fadeInUp">
                        <div className="row">
                            <div className="col">
                                <span className="field-value" style={{ display : "block", textAlign : "left"}}>{ moment.unix(dateAdded).format("Y/MM/DD")}</span>
                            </div>
                            <div className="col">
                                <span style={{ fontSize: ".80rem", color: "black", display : "block", textAlign : "right" }}>{task.orderID}</span>
                            </div>
                        </div>
                        <div className="row" style={{ paddingBottom : "15px" }}>
                            <div className="col" style={{ position : "relative" }}>
                                <span style={taskTypeColor(task.taskType)} className="task-type">{task.taskType}</span>
                                <p style={{ marginBottom : "15px", marginTop : "15px" }} className="task-title">{task.title}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p style={{ fontSize : "12px" }}>{t('income_earnings')}</p>
                                <p className="task-payed">${ task.translatorEarnings.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default MobileItem;
