import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Item from './Item';

export class List extends Component {

    componentDidUpdate(prevProps, prevState){
    }

    openPauseTaskModal = (uid) => {
        this.props.openPauseTaskModal(uid);
    }

    openStopTaskModal = (uid) => {
        this.props.openStopTaskModal(uid);
    }

    openEscalateTaskModal = (uid) => {
        this.props.openEscalateTaskModal(uid);
    }

    openTaskDetailsModal = (uid, activeTab) => {
        this.props.openTaskDetailsModal(uid, activeTab);
    }

    render(){

        const { tasks, isLoadingMorePublishedTasks, isMobileView, currentLanguage, t } = this.props;
        return (
            
            <div className="" ref="taskBasketList">
                {
                    tasks.map( (data, i) => <Item displayDelay={500} t={t} currentLanguage={currentLanguage} isMobileView={isMobileView} giveFeedback={this.props.giveFeedback} openTaskDetailsModal={this.openTaskDetailsModal} openPauseTaskModal={ () => this.openPauseTaskModal(data)} openStopTaskModal={ () => this.openStopTaskModal(data)} openEscalateTaskModal={ () => this.openEscalateTaskModal(data)}  key={data.uid} task={data}/> )
                }
                <div className="text-center">
                {
                    isLoadingMorePublishedTasks ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreTasks} className="load-more-button">{t('common_load_more')}</button>
                    </div>
                }
                </div>  
            </div>

           

        )

    }

}

export default List;
