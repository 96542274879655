import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import SpinnerModal from '../../../Spinner/SpinnerModal'
import Message from '../../../Modals/Message';
import './payment.css';
import axios from 'axios'
import braintree from 'braintree-web';
import $ from 'jquery'
import PaymentConfirmationModal from '../../Modals/PaymentConfirmationModal';
import { 
    createPaymentEvent,
    resetTaskCreationState,
    getBasketCount,
    prepareTransaction,
    createTransaction
} from '../../../../actions/taskAction'
import { 
    updateCustomerAmountSpent
} from '../../../../actions/authAction'
import exactMath from 'exact-math'
import {IMaskInput} from 'react-imask'
import IMask from 'imask'
import {
    translate
} from 'react-switch-lang'

export class EntryForm extends Component {

    constructor(){
        super();
        this.state = {
            isMultiple : false,
            selectedTask : null,
            payingWithCreditCard : false,
            payingWithDebitCard : false,
            isProcessing : false,
            loadingTextHeader : "",
            isHalf : false,
            acceptTerms : false,
            hostedFieldsIntance : null,
            paymentConfirmationModalActive : false,
            resultTask : null,
            paymentMethodDetails : null,
            isAuthenticated : false,
            isMessageModalActive : false,
            messageModalHeader : "",
            messageModalSubHeader : "",
            totalAmount : 0,
            serviceChargeAmount : 0,
            adjustedTotalAmount : 0,
            billingMethod : [],
            isNewCard : true,
            cardNumber : "",
            cardExpiration : "",
            cardCVV : "",
            paymentUrl : null
        }
    }

    componentWillMount(){

        let isMultiple = this.props.history.location.state===undefined ? false : this.props.history.location.state.multiple;
        let tasks = this.props.history.location.state=== undefined ? null : this.props.history.location.state.tasks;

        if(tasks===null){
            this.props.history.goBack();
        }

        const { userProfile } = this.props;

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }

        if(userProfile.hasOwnProperty('billingMethod')){
            if(userProfile.billingMethod.length!==0){
                this.setState({
                    cardNumber : userProfile.billingMethod[userProfile.billingMethod.length-1].cardNumber,
                    cardExpiration : userProfile.billingMethod[userProfile.billingMethod.length-1].expiry,
                    billingMethod : userProfile.billingMethod,
                    isNewCard : userProfile.hasOwnProperty('billingMethod') ? false : true
                })
            }
        }

        if(tasks!==null){
           
            let amount = 0;
            let singleTask = null;
            if(isMultiple){
                for(let t=0; t<tasks.length; t++){
                    amount = exactMath.add(amount,tasks[t].remainingAmount);
                }
            }else{
                amount = tasks[0].transactions.length===1 ? tasks[0].remainingAmount : tasks[0].price;
                
            }

            if(!isMultiple){
                this.setState({
                    resultTask : tasks[0]
                })
            }

            this.setState({
                tasks : tasks,
                isMultiple : isMultiple,
                totalAmount : amount
            })
        }
    }

    componentWillUnmount(){
        this.props.resetTaskCreationState();
    }

    componentDidMount(){   
        
        let isMultiple = this.props.history.location.state===undefined ? false : this.props.history.location.state.multiple;
        let tasks = this.props.history.location.state=== undefined ? null : this.props.history.location.state.tasks;

        const { userProfile } = this.props;

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }

        if(userProfile.hasOwnProperty('billingMethod')){
            if(userProfile.billingMethod.length!==0){
                this.setState({
                    cardNumber : userProfile.billingMethod[userProfile.billingMethod.length-1].cardNumber,
                    cardExpiration : userProfile.billingMethod[userProfile.billingMethod.length-1].expiry,
                    billingMethod : userProfile.billingMethod,
                    isNewCard : userProfile.hasOwnProperty('billingMethod') ? false : true
                })
            }
        }

        if(tasks!==null){
           
            let amount = 0;
            let singleTask = null;
            if(isMultiple){
                for(let t=0; t<tasks.length; t++){
                    amount = exactMath.add(amount,tasks[t].remainingAmount);
                }
            }else{
                amount = tasks[0].transactions.length===1 ? tasks[0].remainingAmount : tasks[0].price;
                
            }

            if(!isMultiple){
                this.setState({
                    resultTask : tasks[0]
                })
            }

            this.setState({
                tasks : tasks,
                isMultiple : isMultiple,
                totalAmount : amount
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.userProfile!==this.props.userProfile){
            
            this.setState({
                isAuthenticated : true,
                billingMethod : this.props.userProfile.hasOwnProperty('billingMethod') ? this.props.userProfile.billingMethod : [],
                isNewCard : this.props.userProfile.hasOwnProperty('billingMethod') ? false : true
            }, () => {
                if(this.props.userProfile.billingMethod!==undefined && this.props.userProfile.billingMethod.length!==0){
                    this.setState({
                        cardNumber : this.props.userProfile.billingMethod[this.props.userProfile.billingMethod.length-1].cardNumber,
                        cardExpiration : this.props.userProfile.billingMethod[this.props.userProfile.billingMethod.length-1].expiry,
                        billingMethod : this.props.userProfile.billingMethod
                    })
                }
            })
        }
    }

    onChange = (e) => {
        this.setState({
            isHalf : e.target.checked
        })
    }

    getDecimalCount = (value) => {
        if(Math.floor(value) === value) {
            return value;
        }else{
            let l = value.toString().split(".")[1].length || 0;
           
            if(l===4){
                return parseFloat(value.toFixed(3))
            }else{
                return value;
            }
        }
    }

    onCard = (isDebitCard = false) => {

        const { tasks, isHalf, isMultiple, totalAmount, acceptTerms } = this.state;
       
        const { auth, userProfile } = this.props;
        const { t } = this.props;

        if(isDebitCard){
            this.setState({
                cardNumber : "",
                cardExpiration : "",
                billingMethod : [],
                isNewCard : true
            })
        }

        this.setState({
            payingWithCreditCard : isDebitCard ? false : true,
            payingWithDebitCard : isDebitCard ? true : false,
            isProcessing : true,
            loadingTextHeader : t('task_payment_preparing_payment')
        }, async ()=> {

            const { isHalf } = this.state;

            let requestBody = {
                userUid : userProfile.uid,
                tasks : tasks,
                totalAmount : isHalf ? this.getDecimalCount(exactMath.mul(totalAmount, 0.5)) : this.getDecimalCount(totalAmount),
                partialPayment : this.state.isHalf,
                isDebitCard : isDebitCard
            }

            let result = await this.props.prepareTransaction(requestBody);

            if(result!==undefined){
                const { data } = result.data;

                this.setState({
                    paymentUrl : data,
                    isProcessing : false,
                    loadingTextHeader : ""
                })
            }else{
                this.setState({
                    paymentUrl : "",
                    isProcessing : false,
                    loadingTextHeader : ""
                })
            }
        })
    }

    newCard = () => {

        this.setState({
            isNewCard : true,
            cardNumber : "",
            cardExpiration : "",
            billingMethod : []
        })

    }

    payWithCard = () => {

        const { userProfile } = this.props;
        const { tasks, isHalf, totalAmount, paymentUrl, payingWithDebitCard, payingWithCreditCard } = this.state;
        const { cardNumber, cardExpiration, cardCVV, isNewCard, acceptTerms } = this.state;
        const { t } = this.props;

        let paymentError = 0;

        if(cardNumber.length!==16){
            $('#card-number').next('span.error-input-message').text(t('task_payment_invalid_card_number'))
            paymentError++;
        }else{
            $('#card-number').next('span.error-input-message').text('')
        }

        if(cardExpiration.indexOf("/")===-1){
            $('#card-expiration').next('span.error-input-message').text(t('task_payment_invalid_exp_date'))
            paymentError++;
        }else{
            let expiry = cardExpiration.split("/")

            if(expiry[1].length==1){
                $('#card-expiration').next('span.error-input-message').text(t('task_payment_invalid_exp_date'))
                paymentError++;
            }else{
                $('#card-expiration').next('span.error-input-message').text("")
            }
        }

        if(cardCVV.length<=2){
            $('#card-cvv').next('span.error-input-message').text(t('task_payment_invalid_cvv'))
            paymentError++;
        }else{
            $('#card-cvv').next('span.error-input-message').text("")
        }


        let sumAmount = isHalf ? exactMath.mul(totalAmount,0.5) : totalAmount;

        let requestBody = {
            userUid : userProfile.uid,
            tasks : tasks,
            totalAmount : isHalf ? this.getDecimalCount(exactMath.mul(totalAmount,0.5)) : this.getDecimalCount(totalAmount),
            partialPayment : isHalf,
            isDebitCard : payingWithDebitCard
        }

        if(isNewCard || payingWithDebitCard){
            requestBody.creditCardDetails = {
                cardNumber : cardNumber,
                cardExpiry : cardExpiration,
                cardExpiryYear : cardExpiration.split("/")[1],
                cardExpiryMonth : cardExpiration.split("/")[0],
                cardSecurityCode : cardCVV
            }
        }

        if(paymentUrl!==null){
            requestBody.paymentUrl = paymentUrl;
        }

        if(paymentError===0){

            if(acceptTerms){
                this.setState({
                    isProcessing : true,
                    loadingTextHeader : t('task_payment_processing_payment')
                }, async () => {
    
                    let result = await this.props.createTransaction(requestBody);
                    if(result!== undefined && result.data.code===200){
                        await this.props.getBasketCount();

                        if(payingWithDebitCard){
                            this.setState({
                                isProcessing : false,
                                loadingTextHeader : "",
                                payingWithCreditCard : false,
                                payingWithDebitCard : false
                            }, () => {
                                this.props.history.push({
                                    pathname : "/tasks/published",
                                    state : null
                                })
                                window.open(result.data.redirectURL)
                                
                            })
                        }else{
                            this.setState({
                                isProcessing : false,
                                loadingTextHeader : "",
                                payingWithCreditCard : false,
                                paymentConfirmationModalActive : true
                            })
                        }
                        
                    }else{
                        this.setState({
                            isProcessing : false,
                            loadingTextHeader : "",
                            isMessageModalActive : true,
                            messageModalHeader : "",
                            messageModalSubHeader : result.data.message==="System error. Please check logs." ?  t('task_payment_went_wrong') : result.data.message
                        })
                    }
                })
            }else{
                this.setState({
                    isMessageModalActive : true,
                    messageModalHeader : t('task_payment_unaccepted_terms_header'),
                    messageModalSubHeader : t('task_payment_unaccepted_sub_header')
                })
            }

            
        }

    }

    onAccept = (e) => {
        this.setState({
            acceptTerms : e.target.checked
        })
    }

    backToPaymentMain = (payment) => {

        this.setState({
            payingWithCreditCard : false,
            payingWithDebitCard : false
        })

    }

    goToTaskPage = () => {
        
        const { resultTask } = this.state;
        
        this.props.resetTaskCreationState();

        this.props.history.push({
            pathname : "/tasks/published",
            state : { task : resultTask }
        })

    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        })
    }

    render() {

        const { t, currentLanguage } = this.props;

        const paymentHeader = {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "40px",
            lineHeight: "64px",
            color: "#414042",
            marginBottom : "10px",
            textAlign : currentLanguage === "ar" ? "right" : "left"
        }

        const { isMobileView } = this.props;
        const { isMessageModalActive, messageModalHeader, messageModalSubHeader } = this.state;
        
        const { isLoggingOut, auth, userProfile } = this.props;
        const { isAuthenticated, paymentMethodDetails, payingWithCreditCard , payingWithDebitCard, isProcessing, isHalf, acceptTerms, isMultiple, paymentConfirmationModalActive } = this.state;
        
        const { billingMethod } = this.state;
        const { totalAmount } = this.state;
        const { cardNumber, cardExpiration } = this.state;
        
        const { loadingTextHeader, tasks } = this.state;

        // let tasks = this.props.history.location.state=== undefined ? null : this.props.history.location.state.tasks;
        
        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="customer"){
                    return "NOT ALLOWED";
                }
            }
        }
        
        if(!payingWithCreditCard && !payingWithDebitCard){
            return (
                tasks !== null &&
                    <div className="payment-form">
                        {
                            (isAuthenticated && isMobileView) ?
                            <div style={{ position : "absolute", top : "0" }}>
                                <nav className="navbar navbar-expand-lg fixed-top">
                                    <span className="align-left page-mobile-header"><img onClick={ () => this.props.history.goBack() } style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../../../images/back.png')}/>{t('task_payment_confirm_order')}</span>
                                </nav>
                            </div>:
                            ""
                        }

                        
                        <div className="row">
                            <div className={`col-md-6 ${currentLanguage==="ar"? "ml-auto" : "mr-auto"}`}>
                                <div className="row">
                                    <div className="col">
                                       
                                    </div>
                                    <div className="col">
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={`col-md-6 ${currentLanguage==="ar"? "ml-auto" : "mr-auto"}`} style={{ paddingBottom : "24px", borderBottom : "1px solid #DFDFE0" }}>
                            <p className="align-left" style={{ marginBottom: "5px", fontSize : "20px", fontWeight: "500", color : "#414402" }}>{t('task_payment_your_price')}</p>
                            <p className="align-left" style={{ fontSize : "32px", fontWeight: "600", color : "#414402" }}>${ Number.isInteger(totalAmount) ? totalAmount.toFixed(2) : totalAmount }</p>
                                <div style={{ display : "flex" }}>
                                <input style={{ width : "70%" }} name="couponCode" id="couponCode" className="task-coupon-input" placeholder={t('common_enter_coupon')}/>
                                <button style={{ width: "30%" }} className="task-coupon-apply">{t('task_payment_apply_coupon')}</button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className={`col-md-6 ${currentLanguage==="ar"? "ml-auto" : "mr-auto"}`} style={{ paddingBottom: "20px"}}>
                                {
                                    !isMultiple && ( tasks!== undefined && tasks[0].hasOwnProperty('transactions') && tasks[0].transactions.length===0) ?
                                    <div>
                                        <label className="align-left checkbox-container">{t('task_payment_prepay_only')}
                                            <input onChange={this.onChange} name="prepay" type="checkbox" class="form-check-input" id="prepay"/>
                                            <span className="checkmark"></span>
                                        </label>
                                        {
                                            isHalf && <p className="align-left" style={{ fontSize : "16px", fontWeight : "bold", color : "#293846", marginTop: "20px" }}>{t('task_payment_to_pay_fifty')}</p>
                                        }
                                        {
                                            isHalf && <p className="align-left" style={{ fontSize : "48px", fontWeight : "bold", color : "#464646", marginBottom : "5px" }}>${ Number.isInteger((totalAmount * .5)) ? (totalAmount * .5).toFixed(2) : this.getDecimalCount(totalAmount * .5) }</p>
                                        }
                                    </div> :
                                    <p className="align-left" style={{ fontSize : "48px", fontWeight : "bold", color : "#464646", marginBottom : "5px" }}>${ Number.isInteger(totalAmount) ? totalAmount.toFixed(2) : totalAmount }</p>
                                }  
                                
                                <div style={{ display: "flex", flexDirection : isMobileView ? "column" : "unset" }}>            
                                    <button style={{ width : "100%" }} onClick={ () => this.onCard()} className="task-pay-with-card">{t('task_payment_pay_with')}</button>
                                    <button style={{ width : "100%", marginTop : isMobileView ? "15px" : "0" }} onClick={ () => this.onCard(true)}  className="task-pay-with-paypal">{t('task_payment_pay_with_debit')}</button>
                                </div>
                                {
                                    isMobileView &&
                                    <div style={{ marginTop : "30px" }}>
                                        <img style={{ width  : "100%"}} src={require('../../../../images/payment-bg.png')}/>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            isProcessing && 
                            <SpinnerModal
                                isOpened={isProcessing}
                                loadingTextHeader={loadingTextHeader}
                            />
                        }
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                        {
                            paymentConfirmationModalActive && 
                            <PaymentConfirmationModal
                                t={t}
                                isOpened={paymentConfirmationModalActive}
                                goToTaskPage={this.goToTaskPage}
                            />
                        }
                        {
                            isMessageModalActive &&
                            <Message 
                                closeMessageModal={this.closeMessageModal} 
                                isOpened={isMessageModalActive} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                t={t}
                            />
                        }
                    </div>
            )
        }

        if(payingWithDebitCard){
            return (
                tasks !== null &&
                    <div className="payment-form">
                        {
                            (isAuthenticated && isMobileView) ?
                            <div style={{ position : "absolute", top : "0" }}>
                                <nav className="navbar navbar-expand-lg fixed-top">
                                    <span className="align-left page-mobile-header"><img onClick={ ()=> this.backToPaymentMain('card')} style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../../../images/back.png')}/>{t('task_payment_payment_process')}</span>
                                </nav>
                            </div>:
                            ""
                        }
                        <div className="row">
                            <div className={`col-md-6 ${currentLanguage==="ar"? "ml-auto" : "mr-auto"}`}>
                                { !isMobileView && <p className="align-left" style={paymentHeader}><img onClick={ ()=> this.backToPaymentMain('card')} style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage==="ar" ? "unset" : "15px", marginLeft: currentLanguage==="ar" ? "15px" : "unset" }} src={require('../../../../images/back.png')}/>{t('task_payment_payment_process')}</p> }
                                <div id="card-form">
                                    <div className="row">
                                        <div className="col">
                                            <p className="align-left" style={{ color : "#939097", fontSize : "16px", fontWeight : "bold"}}>{t('task_payment_your_price')}</p>
                                            <p className="align-left" style={{ color : "#080708", fontSize : "32px", fontWeight : "600"}}>${ isHalf ? ( Number.isInteger((totalAmount * .5)) ? (totalAmount * .5).toFixed(2) : this.getDecimalCount(totalAmount * .5) ) : Number.isInteger(totalAmount) ? totalAmount.toFixed(2) : totalAmount }</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label class="align-left custom-label block" for="card-number">{t('task_payment_card_number')}</label>
                                                <IMaskInput
                                                    placeholder="---- ---- ---- ----"
                                                    id="card-number"
                                                    value={cardNumber}
                                                    mask={
                                                        [
                                                            {
                                                                mask: '0000 0000 0000 0000',
                                                                regex: '^4\\d{0,15}',
                                                                cardtype: 'visa'
                                                            },
                                                            {
                                                                mask: '0000 0000 0000 0000',
                                                                regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
                                                                cardtype: 'mastercard'
                                                            }
                                                        ]
                                                    }
                                                    onAccept={
                                                        (value,mask) => {
                                                            this.setState({
                                                                cardNumber : mask.unmaskedValue
                                                            })
                                                        }
                                                    }
                                                    className="custom-input" 
                                                    style={{ height : "52px", width : "100%" }}
                                                />
                                                {/* <input id="card-number" onChange={this.cardOnChange} placeholder="- - - -  - - - -  - - - -  - - - -" className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                <span className="align-left error-input-message block"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label class="align-left custom-label block" for="expiration-date">{t('task_payment_expiration_date')}</label>
                                                <IMaskInput
                                                    placeholder="--/--"
                                                    id="card-expiration"
                                                    value={cardExpiration}
                                                    mask={
                                                        [
                                                            {
                                                                mask: 'MM{/}YY',
                                                                pattern : 'MM{/}YY',
                                                                blocks : {
                                                                    YY : {
                                                                        mask : IMask.MaskedRange,
                                                                        from : 0,
                                                                        to : 99,
                                                                        maxLength : 2
                                                                    },
                                                                    MM : {
                                                                        mask : IMask.MaskedRange,
                                                                        from : 1,
                                                                        to : 12,
                                                                        maxLength : 2
                                                                    }
                                                                }
                                                            }
                                                        ]
                                                    }
                                                    onAccept={
                                                        (value,mask) => {
                                                            this.setState({
                                                                cardExpiration : value
                                                            })
                                                        }
                                                    }
                                                    className="custom-input" 
                                                    style={{ height : "52px", width : "100%" }}
                                                />
                                                {/* <input id="card-expiration" placeholder="- - / - -" className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                <span className="align-left error-input-message block"></span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label class="align-left custom-label block" for="cvv">{t('task_payment_cvv')}</label>
                                                <IMaskInput
                                                    placeholder="----"
                                                    id="card-cvv"
                                                    mask={
                                                        [
                                                            {
                                                                mask: '0000',
                                                            }
                                                        ]
                                                    }
                                                    onAccept={
                                                        (value,mask) => {
                                                            this.setState({
                                                                cardCVV : value
                                                            })
                                                        }
                                                    }
                                                    className="custom-input" 
                                                    style={{ height : "52px", width : "100%" }}
                                                />
                                                {/* <input id="card-cvv" placeholder="- - - " className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                <span className="align-left error-input-message block"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label className="align-left checkbox-container"><span style={{ fontSize : "12px", color: "#464646"}}>{t('task_payment_terms_agree')}</span>
                                                <input checked={acceptTerms} onChange={this.onAccept} name="terms" type="checkbox" class="form-check-input" id="terms"/>
                                                <span className="checkmark"></span>
                                                <span className="align-left error-input-message block"></span>
                                            </label>
                                            <button onClick={this.payWithCard} id="card-form-submit" className="pay-task">PAY</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isMobileView &&
                                    <div style={{ marginTop : "30px" }}>
                                        <img style={{ width  : "100%"}} src={require('../../../../images/payment-bg.png')}/>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            isProcessing && 
                            <SpinnerModal
                                isOpened={isProcessing}
                                loadingTextHeader={loadingTextHeader}
                            />
                        }
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                        {
                            paymentConfirmationModalActive && 
                            <PaymentConfirmationModal
                                t={t}
                                isOpened={paymentConfirmationModalActive}
                                goToTaskPage={this.goToTaskPage}
    
                            />
                        }
                        {
                            isMessageModalActive &&
                            <Message 
                                closeMessageModal={this.closeMessageModal} 
                                isOpened={isMessageModalActive} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                t={t}
                            />
                        }
                    </div>
            )
        }

        if(payingWithCreditCard){
            if(billingMethod.length==0){
                return (
                    tasks !== null &&
                        <div className="payment-form">
                            {
                                (isAuthenticated && isMobileView) ?
                                <div style={{ position : "absolute", top : "0" }}>
                                    <nav className="navbar navbar-expand-lg fixed-top">
                                        <span className="align-left page-mobile-header"><img onClick={ ()=> this.backToPaymentMain('card')} style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../../../images/back.png')}/>{t('task_payment_payment_process')}</span>
                                    </nav>
                                </div>:
                                ""
                            }
                            <div className="row">
                                <div className={`col-md-6 ${currentLanguage==="ar"? "ml-auto" : "mr-auto"}`}>
                                    { !isMobileView && <p className="align-left" style={paymentHeader}><img onClick={ ()=> this.backToPaymentMain('card')} style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage==="ar" ? "unset" : "15px", marginLeft: currentLanguage==="ar" ? "15px" : "unset" }} src={require('../../../../images/back.png')}/>{t('task_payment_payment_process')}</p> }
                                    <div id="card-form">
                                        <div className="row">
                                            <div className="col">
                                                <p className="align-left" style={{ color : "#939097", fontSize : "16px", fontWeight : "bold"}}>{t('task_payment_your_price')}</p>
                                                <p className="align-left" style={{ color : "#080708", fontSize : "32px", fontWeight : "600"}}>${ isHalf ? ( Number.isInteger((totalAmount * .5)) ? (totalAmount * .5).toFixed(2) : this.getDecimalCount(totalAmount * .5) ) : Number.isInteger(totalAmount) ? totalAmount.toFixed(2) : totalAmount }</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label class="align-left custom-label block" for="card-number">{t('task_payment_card_number')}</label>
                                                    <IMaskInput
                                                        placeholder="---- ---- ---- ----"
                                                        id="card-number"
                                                        value={cardNumber}
                                                        mask={
                                                            [
                                                                {
                                                                    mask: '0000 0000 0000 0000',
                                                                    regex: '^4\\d{0,15}',
                                                                    cardtype: 'visa'
                                                                },
                                                                {
                                                                    mask: '0000 0000 0000 0000',
                                                                    regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
                                                                    cardtype: 'mastercard'
                                                                }
                                                            ]
                                                        }
                                                        onAccept={
                                                            (value,mask) => {
                                                                this.setState({
                                                                    cardNumber : mask.unmaskedValue
                                                                })
                                                            }
                                                        }
                                                        className="custom-input" 
                                                        style={{ height : "52px", width : "100%" }}
                                                    />
                                                    {/* <input id="card-number" onChange={this.cardOnChange} placeholder="- - - -  - - - -  - - - -  - - - -" className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                    <span className="align-left error-input-message block"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label class="align-left custom-label block" for="expiration-date">{t('task_payment_expiration_date')}</label>
                                                    <IMaskInput
                                                        placeholder="--/--"
                                                        id="card-expiration"
                                                        value={cardExpiration}
                                                        mask={
                                                            [
                                                                {
                                                                    mask: 'MM{/}YY',
                                                                    pattern : 'MM{/}YY',
                                                                    blocks : {
                                                                        YY : {
                                                                            mask : IMask.MaskedRange,
                                                                            from : 0,
                                                                            to : 99,
                                                                            maxLength : 2
                                                                        },
                                                                        MM : {
                                                                            mask : IMask.MaskedRange,
                                                                            from : 1,
                                                                            to : 12,
                                                                            maxLength : 2
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                        onAccept={
                                                            (value,mask) => {
                                                                this.setState({
                                                                    cardExpiration : value
                                                                })
                                                            }
                                                        }
                                                        className="custom-input" 
                                                        style={{ height : "52px", width : "100%" }}
                                                    />
                                                    {/* <input id="card-expiration" placeholder="- - / - -" className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                    <span className="align-left error-input-message block"></span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label class="align-left custom-label block" for="cvv">{t('task_payment_cvv')}</label>
                                                    <IMaskInput
                                                        placeholder="----"
                                                        id="card-cvv"
                                                        mask={
                                                            [
                                                                {
                                                                    mask: '0000',
                                                                }
                                                            ]
                                                        }
                                                        onAccept={
                                                            (value,mask) => {
                                                                this.setState({
                                                                    cardCVV : value
                                                                })
                                                            }
                                                        }
                                                        className="custom-input" 
                                                        style={{ height : "52px", width : "100%" }}
                                                    />
                                                    {/* <input id="card-cvv" placeholder="- - - " className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                    <span className="align-left error-input-message block"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label className="align-left checkbox-container"><span style={{ fontSize : "12px", color: "#464646"}}>{t('task_payment_terms_agree')}</span>
                                                    <input checked={acceptTerms} onChange={this.onAccept} name="terms" type="checkbox" class="form-check-input" id="terms"/>
                                                    <span className="checkmark"></span>
                                                    <span className="align-left error-input-message block"></span>
                                                </label>
                                                <button onClick={this.payWithCard} id="card-form-submit" className="pay-task">PAY</button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isMobileView &&
                                        <div style={{ marginTop : "30px" }}>
                                            <img style={{ width  : "100%"}} src={require('../../../../images/payment-bg.png')}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                isProcessing && 
                                <SpinnerModal
                                    isOpened={isProcessing}
                                    loadingTextHeader={loadingTextHeader}
                                />
                            }
                            {
                                isLoggingOut && 
                                <SpinnerModal
                                    isOpened={isLoggingOut}
                                />
                            }
                            {
                                paymentConfirmationModalActive && 
                                <PaymentConfirmationModal
                                    t={t}
                                    isOpened={paymentConfirmationModalActive}
                                    goToTaskPage={this.goToTaskPage}
        
                                />
                            }
                            {
                                isMessageModalActive &&
                                <Message 
                                    closeMessageModal={this.closeMessageModal} 
                                    isOpened={isMessageModalActive} 
                                    header={messageModalHeader} 
                                    subHeader={messageModalSubHeader}
                                    addModal={this.props.addModal}
                                    popModal={this.props.popModal}
                                    modalInstances={this.props.modalInstances}
                                    t={t}
                                />
                            }
                        </div>
                )
            }else{
                return (
                    tasks !== null &&
                        <div className="payment-form">
                            {
                                (isAuthenticated && isMobileView) ?
                                <div style={{ position : "absolute", top : "0" }}>
                                    <nav className="navbar navbar-expand-lg fixed-top">
                                        <span className="page-mobile-header"><img onClick={ ()=> this.backToPaymentMain('card')} style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../../../images/back.png')}/>{t('task_payment_payment_process')}</span>
                                    </nav>
                                </div>:
                                ""
                            }
                            <div className="row">
                                <div className={`col-md-6 ${currentLanguage==="ar"? "ml-auto" : "mr-auto"}`}>
                                    { !isMobileView && <p style={paymentHeader}><img onClick={ ()=> this.backToPaymentMain('card')} style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage==="ar" ? "unset" : "15px", marginLeft: currentLanguage==="ar" ? "15px" : "unset"}} src={require('../../../../images/back.png')}/>{t('task_payment_payment_process')}</p> }
                                    <div id="card-form">
                                        <div className="row">
                                            <div className="col">
                                                <p className="align-left" style={{ color : "#939097", fontSize : "16px", fontWeight : "bold"}}>{t('task_payment_your_price')}</p>
                                                <p className="align-left" style={{ color : "#080708", fontSize : "32px", fontWeight : "600"}}>${ isHalf ? ( Number.isInteger((totalAmount * .5)) ? (totalAmount * .5).toFixed(2) : this.getDecimalCount(totalAmount * .5) ) : Number.isInteger(totalAmount) ? totalAmount.toFixed(2) : totalAmount }</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label class="align-left custom-label block" for="card-number">{t('task_payment_card_number')}</label>
                                                    <input
                                                        placeholder="---- ---- ---- ----"
                                                        id="card-number"
                                                        disabled
                                                        className="custom-input" 
                                                        style={{ height : "52px", width : "100%" }}
                                                        defaultValue={cardNumber}
                                                    />
                                                    <span className="align-left error-input-message block"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label class="align-left custom-label block" for="expiration-date">{t('task_payment_expiration_date')}</label>
                                                    <input
                                                        placeholder="--/--"
                                                        id="card-expiration"
                                                        disabled
                                                        className="custom-input" 
                                                        style={{ height : "52px", width : "100%" }}
                                                        defaultValue={cardExpiration}
                                                    />
                                                    {/* <input id="card-expiration" placeholder="- - / - -" className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                    <span className="align-left error-input-message block"></span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-group">
                                                    <label class="align-left custom-label block" for="cvv">{t('task_payment_cvv')}</label>
                                                    <IMaskInput
                                                        placeholder="----"
                                                        id="card-cvv"
                                                        mask={
                                                            [
                                                                {
                                                                    mask: '0000',
                                                                }
                                                            ]
                                                        }
                                                        onAccept={
                                                            (value,mask) => {
                                                                this.setState({
                                                                    cardCVV : value
                                                                })
                                                            }
                                                        }
                                                        className="custom-input" 
                                                        style={{ height : "52px", width : "100%" }}
                                                    />
                                                    {/* <input id="card-cvv" placeholder="- - - " className="custom-input" style={{ height : "52px", width : "100%" }}/> */}
                                                    <span className="align-left error-input-message block"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label className="align-left checkbox-container"><span style={{ fontSize : "12px", color: "#464646"}}>{t('task_payment_terms_agree')}</span>
                                                    <input checked={acceptTerms} onChange={this.onAccept} name="terms" type="checkbox" class="form-check-input" id="terms"/>
                                                    <span className="checkmark"></span>
                                                    <span className="align-left error-input-message block"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                                
                                            <div className="col">
                                                <button onClick={this.payWithCard} id="card-form-submit" className="pay-task" style={{  padding : isMobileView ? "16px 10px" : "16px 40px" }}>{t('common_pay')}</button>
                                            </div>
                                            <div className="col">
                                                <button onClick={this.newCard} id="card-form-submit" className="task-pay-with-paypal" style={{ width: "100%", padding : isMobileView ? "16px 10px" : "16px 40px" }}>{t('task_payment_use_different_card')}</button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isMobileView &&
                                        <div style={{ marginTop : "30px" }}>
                                            <img style={{ width  : "100%"}} src={require('../../../../images/payment-bg.png')}/>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                isProcessing && 
                                <SpinnerModal
                                    isOpened={isProcessing}
                                    loadingTextHeader={loadingTextHeader}
                                />
                            }
                            {
                                isLoggingOut && 
                                <SpinnerModal
                                    isOpened={isLoggingOut}
                                />
                            }
                            {
                                paymentConfirmationModalActive && 
                                <PaymentConfirmationModal
                                    t={t}
                                    isOpened={paymentConfirmationModalActive}
                                    goToTaskPage={this.goToTaskPage}
        
                                />
                            }
                            {
                                isMessageModalActive &&
                                <Message 
                                    closeMessageModal={this.closeMessageModal} 
                                    isOpened={isMessageModalActive} 
                                    header={messageModalHeader} 
                                    subHeader={messageModalSubHeader}
                                    addModal={this.props.addModal}
                                    popModal={this.props.popModal}
                                    modalInstances={this.props.modalInstances}
                                    t={t}
                                />
                            }
                        </div>
                )
            }
        }

        
    }
       
}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isLogging : state.auth.isLoggingOut,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        createPaymentEvent,
        updateCustomerAmountSpent,
        resetTaskCreationState,
        getBasketCount,
        prepareTransaction,
        createTransaction
    })
)(EntryForm)