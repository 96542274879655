import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
import {
  MobileView,
  BrowserView,
  isMobile,
  isBrowser
} from 'react-device-detect'

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import TaskIndex from "./components/Tasks/Customer/Published/Index";
import TaskBasketIndex from "./components/Tasks/Customer/Basket/Index";
import CreateTaskForm from "./components/Tasks/Customer/Create/EntryForm";
import EditTaskForm from "./components/Tasks/Customer/Edit/EntryForm";
import PaymentForm from "./components/Tasks/Customer/Payment/EntryForm";
import SuccessPage from "./components/Tasks/Customer/Payment/SuccessPage";
import ErrorPage from "./components/Tasks/Customer/Payment/ErrorPage";

import TaskAssignedIndex from "./components/Tasks/Translator/Assigned/Index";
import InProgressTasksIndex from "./components/Tasks/Translator/InProgress/Index";
import ArchivedTasksIndex from './components/Tasks/Archived/Index';
import CustomerArchivedTasksIndex from "./components/Tasks/Customer/Archive/Index";
import TranslatorArchivedTasksIndex from "./components/Tasks/Translator/Archive/Index";
import ChatIndex from './components/Chat/Index';
import ProfileIndex from './components/Profile/Index';
import CustomerProfileIndex from './components/Profile/CustomerProfileIndex';
import TranslatorProfileIndex from './components/Profile/TranslatorProfileIndex';

import IncomeReport from './components/Tasks/Translator/Income/Index'
import OutcomeReport from './components/Tasks/Customer/Outcome/Index'

import $ from 'jquery'

import {
  setHeader,
  setDevice,
  setCurrentLanguage
} from './actions/appAction'

import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
  getLanguage
} from 'react-switch-lang';

import en from './locales/en.json'
import ar from './locales/ar.json'

import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'

import axios from 'axios';

import {
  setUserPresence
} from './actions/authAction'

// Do this two lines only when setting up the application
setTranslations({ en, ar });

// If you want to remember selected language
setLanguageCookie();

export class App extends Component {

  componentDidMount() {

    if(!$('.navbar-toggler').hasClass('collapsed')){
      $('.navbar-toggler').removeClass('collapsed');
    }

    if(this.props.location.search!==""){
      let splitSearch = this.props.location.search.split("=");
      
      let langIndex = splitSearch.findIndex( s => s==="?lang");

      if(langIndex!==-1){
        if(splitSearch[langIndex+1]==="ar" || splitSearch[langIndex+1]==="en"){
          if(splitSearch[langIndex+1]==="ar"){
            $('#mainStyle').attr("href","/css/ar-index.css")
          }else{
            $('#mainStyle').attr("href","/css/en-index.css")
          }    
    
          localStorage.setItem('currentLanguage',splitSearch[langIndex+1])
          setLanguage(splitSearch[langIndex+1]);
          this.props.setCurrentLanguage(splitSearch[langIndex+1]);
        }
      }
    }

    window.addEventListener("resize", this.resize.bind(this));

    if(localStorage.getItem('currentLanguage')===null){
      const navLanguage = window.navigator.language.substr(0, 2);

      if(navLanguage==="ar"){
        $('#mainStyle').attr("href","/css/ar-index.css")
      }else{
        $('#mainStyle').attr("href","/css/en-index.css")
      }    
  
      localStorage.setItem('currentLanguage',navLanguage)
      setLanguage(navLanguage);
      this.props.setCurrentLanguage(navLanguage);
    }else{
      const navLanguage = localStorage.getItem('currentLanguage')

      if(navLanguage==="ar"){
        $('#mainStyle').attr("href","/css/ar-index.css")
      }else{
        $('#mainStyle').attr("href","/css/en-index.css")
      }    
  
      localStorage.setItem('currentLanguage',navLanguage)
      setLanguage(navLanguage);
      this.props.setCurrentLanguage(navLanguage);
    }

    this.resize();

    $(document).on('keydown',".right-modal", function ( e ) {
      var key = e.which || e.keyCode;
      if (key == 27) {
        $(this).find('.modal-content .modal-header #td-close').click(); // <----use the DOM click this way!!!
      }
    });

    $(document).on('keydown',".task-for-review-modal", function ( e ) {
      var key = e.which || e.keyCode;
      if (key == 27) {
        $($('.task-for-review-modal')[$('.task-for-review-modal').length-1]).find('.modal-content .modal-header #modal-closer').click(); // <----use the DOM click this way!!!
      }
    });

    $(document).on('keydown',".task-inprogress-modal", function ( e ) {
      var key = e.which || e.keyCode;
      if (key == 27) {
        $($('.task-inprogress-modal')[$('.task-inprogress-modal').length-1]).find('.modal-content .modal-header #modal-closer').click(); // <----use the DOM click this way!!!
      }
    });
  }

  componentDidUpdate(prevProps){
    if(this.props.currentLanguage!==prevProps.currentLanguage){
        const { currentLanguage } = this.props;
        setLanguage(currentLanguage);
        localStorage.setItem('currentLanguage',currentLanguage)
        if(currentLanguage==="ar"){
            $('#mainStyle').attr('href','/css/ar-index.css')
        }else{
            $('#mainStyle').attr('href','/css/en-index.css')
        }      
    }
  }

  resize() {
    this.props.setDevice( window.innerWidth<1024 ? true : false)
  }

  render(){

    const { isMobileView, location } = this.props;

    return (
      <div className="app">
        <Header />
        <div className={ isMobileView ? "mobile-content" : "content" }>
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={{ enter: 500, exit: 500 }}
                classNames="fade"
              >
              <Switch location={location}>
                <Route path="/" exact component={Login}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/signup" exact component={Signup}/>
                <Route path="/tasks/published" exact component={TaskIndex}/>
                <Route path="/tasks/basket" exact component={TaskBasketIndex}/>
                <Route path="/task/create" exact component={CreateTaskForm}/>
                <Route path="/task/edit" exact component={EditTaskForm}/>
                <Route path="/task/payment" exact component={PaymentForm}/>
                <Route path="/tasks/assigned" exact component={TaskAssignedIndex}/>
                <Route path="/tasks/in_progress" exact component={InProgressTasksIndex}/>
                <Route path="/tasks/archived" exact component={ArchivedTasksIndex}/>
                <Route path="/chat" exact component={ChatIndex}/>
                <Route path="/profile" exact component={ProfileIndex} />
                <Route path="/income_reports" exact component={IncomeReport}/>
                <Route path="/outcome_reports" exact component={OutcomeReport}/>
              </Switch>
              </CSSTransition>
            </TransitionGroup>
          
          <span className="version">v1.0.2</span>
        </div>
        {
          (isMobileView &&
          (
            this.props.location.pathname==="/tasks/published" ||
            this.props.location.pathname==="/tasks/in_progress" ||
            this.props.location.pathname==="/tasks/assigned" ||
            this.props.location.pathname==="/tasks/basket" ||
            this.props.location.pathname==="/tasks/archived" ||
            this.props.location.pathname==="/profile" ||
            this.props.location.pathname==="/chat"
          )
          ) && 
          <Footer/>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth : state.firebase.auth,
    userProfile : state.firebase.profile,
    isMobileView : state.app.isMobileView,
    currentLanguage : state.app.currentLanguage
  }
}

export default compose(
  withRouter,
  translate,
  connect(mapStateToProps, {
    setDevice,
    setCurrentLanguage,
    setUserPresence
  })
)(App)
