import React, { Component } from 'react'

export class NextStep extends Component {

    render(){

        const { step } = this.props;

        return (
            <button onClick={this.props.proceedToNext} style={{ width : "100%", background :"transparent", padding :"16px 20px", fontSize : "12px", color : "#22C9C7", border : "2px solid #22C9C7", borderRadius : "8px", textAlign : "center" }}>NEXT STEP</button>
        )

    }

}

export default NextStep