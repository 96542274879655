import React, { Component } from 'react'
import moment from 'moment';

export class SearchedContact extends Component {

    constructor(){
        super();
        this.state = {
            shouldRender : false
        }
    }

    componentDidMount(){
        const { displayDelay } = this.props;
        this.timer = setTimeout(() => {
            this.setState({ shouldRender: true });
        }, displayDelay);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){
        
        const { shouldRender } = this.state;

        const { searched, userProfile } = this.props;

        let conversationClass = '';

        // let contactUid = searched.members.find(data => data!== userProfile.uid)
        // let contactName = searched.memberData[contactUid].fullName.split(",");
        let contactImage = searched.contactImage;
        let imageUrl = contactImage === "" ? require('../../images/default-user.jpg') : contactImage

        return (
            <div>
                {
                    shouldRender &&
                    <div onClick={ () => this.props.getAndSetActiveConversation(searched.conversation) } className={`animated fadeIn chat-item ${conversationClass} `}>
                        <div className="chat-item-body">
                            <img className="display-photo" src={imageUrl}/>
                            <div className="details">
                                <span className="username">{searched.contactName}</span>
                                <span className="task-title">{searched.taskTitle}</span>
                                <span className="chat-date" style={{ textTransform : "capitalize"}}>{searched.presence}</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )

    }

}

export default SearchedContact