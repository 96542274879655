import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import {IMaskInput} from 'react-imask'
import IMask from 'imask'

const countryList = require('country-list');

export class CustomerPage2 extends Component {

    state = {
        gender : "",
        country : "",
        selectedDate : null,
        imageFile : null
    }
    
    nextPage = (e) => {
        e.preventDefault();
        this.props.nextStep();
    }

    openFileUpload = (e) => {
        e.preventDefault();
        $('#userPhoto').click();
    }

    onFileChange = (e) => {
        
        let file = e.target.files;

        if(e===null){
            $('.image-placeholder').removeClass('border-0');
        }else{

            let invalidFiles = 0;
            Object.keys(e.target.files).map( (data,i) => {
                if(e.target.files[i].type.indexOf("image")==-1){
                    invalidFiles++;
                }
            })
           
            if(invalidFiles==0){
                this.setState({
                    imageFile : URL.createObjectURL(file[0])
                }, () => {
                    this.props.onFileChange(file[0]);
                    $('.image-placeholder').addClass('border-0');
                })
            }
            
        }
    }

    onDateChange = (date) => {

        let d = moment(date)
       
        this.setState({
            selectedDate : date
        }, () => {
            if(date===null || date===""){
                this.props.onDateChange({dateOfBirth : null})
            }else{
                this.props.onDateChange({dateOfBirth : d})
            }
        })
        
    }

    render () {
        const { values, t } = this.props;
        const { imageFile, selectedDate } = this.state;
        let imgFile = values.imageFile !== null ? values.imageFile : imageFile;
        if(typeof imgFile === "object" && imgFile !== null){
            imgFile = URL.createObjectURL(imgFile)
        }
        let countries = countryList.getNames();

        const { isMobileView } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6">
                        {
                            isMobileView ?
                            <div className="row" style= {{ marginBottom : "15px" }}>
                                <div className="col signup-image-up">
                                    <div className="image-placeholder">
                                        <img style={{ width: "100%", height: "100%" }} src={imgFile} className="rounded-circle" />
                                    </div>
                                </div>
                                <div className="col">
                                    <input onChange={this.onFileChange} accept="image/*" type="file" name="userPhoto" id="userPhoto" style={{ display : "none" }} />
                                    <button onClick={this.openFileUpload} style={{ width: "100%", marginTop : "calc(100% - 120px)" }} className="btn-upload">{t('common_upload_photo')}</button>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-xs-6 signup-image-up">
                                    <div className="image-placeholder">
                                        <img style={{ width: "100%", height: "100%" }} src={imgFile} className="rounded-circle" />
                                    </div>
                                </div>
                                <div className="col-md-12 col-sm-6 col-xs-6">
                                    <input onChange={this.onFileChange} accept="image/*" type="file" name="userPhoto" id="userPhoto" style={{ display : "none" }} />
                                    <button onClick={this.openFileUpload} style={{ width: "100%" }} className="btn-upload">{t('common_upload_photo')}</button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                        <div className="form-group">
                            <label className="align-left custom-label block">{t('common_gender')}</label>
                            <select onChange={this.props.onChange('gender')} placeholder={t('common_select_here')} class="custom-select form-control" id="gender" name="gender">
                                <option value="">{t('common_select_here')}</option>
                                <option value="Male" selected={values.gender === "Male"}>{t('common_male')}</option>
                                <option value="Female" selected={values.gender === "Female"}>{t('common_female')}</option>
                                <option value="Not Specified" selected={values.gender === "Not Specified"}>{t('common_not_specified')}</option>
                            </select>
                        </div>
                        {/* <div className="form-group">
                            <label className="align-left custom-label block">{t('common_dob')}</label>
                            <IMaskInput
                                placeholder="DD/MM/YYYY"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                mask={
                                    [
                                        {
                                            mask: 'dd{/}MM{/}yyyy',
                                            pattern : 'dd{/}MM{/}yyyy',
                                            blocks : {
                                                dd : {
                                                    mask : IMask.MaskedRange,
                                                    from : 1,
                                                    to : 31,
                                                    minLength : 2,
                                                    maxLength : 2
                                                },
                                                yyyy : {
                                                    mask : IMask.MaskedRange,
                                                    from : 1900,
                                                    to : new Date().getFullYear(),
                                                    minLength : 4,
                                                    maxLength : 4
                                                },
                                                MM : {
                                                    mask : IMask.MaskedRange,
                                                    from : 1,
                                                    to : 12,
                                                    minLength : 2,
                                                    maxLength : 2
                                                }
                                            }
                                        }
                                    ]
                                }
                                onAccept={
                                    (value,mask) => {
                                        this.props.onDateChange(value)
                                    }
                                }
                                className="custom-input" 
                                style={{ height : "52px", width : "100%" }}
                            />
                        </div> */}
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <div className="form-group">
                            <label className="align-left custom-label block">{t('common_country')}</label>
                            <select onChange={this.props.onChange('gender')} placeholder={t('common_select_here')} class="custom-select form-control" id="country" name="country">
                                <option value="">{t('common_select_here')}</option>
                                {
                                    countries.map( (data, i) => (
                                        <option selected={values.country === data} key={i} value={data}>{data}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                {/* <div className="row mt-3">
                    <div className="col">
                        <div className="form-group">
                            <label className="custom-label block">A few words about your interests</label>
                            <input defaultValue={values.interests} onChange={this.props.onChange('interests')} id="interests" name="interests" style={{ width: "100%" }} className="custom-input" placeholder="Write here"/>
                            <span className="error-input-message block"></span>
                        </div>
                    </div>
                </div> */}
                <div className="row mt-3">
                    <div className="col">
                        <label className="align-left checkbox-container">{t('signup_label_terms')}<a onClick={this.props.setTermsAndConditionsModal} href="javascript:void(0)" style={{ textDecoration : "none" }}><span style={{ color : "#22C9C7" }}>&nbsp;{t('signup_label_terms_conditions')}</span></a>
                            <input checked={values.acceptTerms} onChange={this.props.onChange('terms')} name="terms" type="checkbox" class="form-check-input" id="terms"/>
                            <span className="checkmark"></span>
                        </label>
                        <span className="align-left error-input-message block">{this.props.termsValidation}</span>
                    </div>
                </div>
            </div>
        )
    }

}

export default CustomerPage2;