import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import _ from 'lodash/'
import axios from 'axios'
import braintree from 'braintree-web';

import {
    updateCurrentUser,
    uploadUserImage,
    checkForDuplicateEmail,
    updateUserEmail,
    updateUserPassword,
    signOutUser,
    reAuthenticateUser,
    updateUserSettings
} from '../../actions/authAction';

import {
    addModal,
    popModal
} from '../../actions/appAction';

import {
    Message
} from '../Modals/Message'
import {
    Reauthenticate
} from './Modals/Reauthenticate'
import {
    NewPassword
} from './Modals/NewPassword'
import {
    SpinnerModal
} from '../Spinner/SpinnerModal'

const countryList = require('country-list');
const countryCodes = require('country-codes-list')
const myCountryCodesObject = countryCodes.customList('countryNameEn','+{countryCallingCode}')

const acceptedFileTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg"
]

export class CustomerProfileIndex extends Component {

    constructor(){
        super();
        this.state = {
            isAuthenticated : false,
            displayPhoto : "",
            phoneCode : "",
            profileData : {},
            changedProfileData : {},
            changeCount : 0,
            isChanged : false,
            errorCount : 0,
            isFullnameValid : true,
            isFullnameChanged : false,
            isMessageModalActive : false,
            messageModalHeader : "",
            messageModalSubHeader : "",
            isReauthenticateModalActive : false,
            isPasswordChangeModalActive : false,
            isEmailValid : true,
            isEmailChanged : false,
            isCountryChanged : false,
            isCountryValid : true,
            isPhoneNumberChanged : false,
            isPhoneNumberValid : true,
            isProcessing : false,
            
            authEmail : "",
            authPassword : "",
            isLoggingIn : false,
            errorMessage : "",
            reAuthFor : "",
            newAuthPassword : "",
            passwordError : false,
            passwordConfirmError : false,

            paymentMethodDetails : null,
            clientInstance : null,
            hostedFieldsInstance : null,
            isEditingBillingMethod : false,
            isEditingPaypalAccount : false,
            paypalMethodDetails : null,
        }

        this.checkForEmailDuplication = _.debounce(this.checkForEmailDuplication,500);
    }

    componentDidMount(){
        
        const { auth, userProfile } = this.props;

        if(userProfile.hasOwnProperty('fullName')){
            
            let profileData = {
                fullName : userProfile.fullName,
                country : userProfile.country,
                emailAddress : userProfile.emailAddress,
                phoneNumber : userProfile.phoneNumber
            }

            this.setState({
                isAuthenticated : true,
                phoneCode : myCountryCodesObject[userProfile.country],
                profileData : profileData,
                changedProfileData : profileData
            })

            this.checkBilling();
            this.checkPaypal();
        }

    }

    componentDidUpdate(prevProps){

        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true,
                phoneCode : myCountryCodesObject[this.props.userProfile.country],
            })

            this.checkBilling();
            this.checkPaypal();
        }

    }

    closeMessageModal = () => {
        this.props.signOutUser();
    }

    /**
     * PASSWORD FUNCTIONS
     */
    onPasswordClick = () => {
        this.setState({
            isPasswordChangeModalActive : true,
            reAuthFor : "password"
        })
    }

    onPasswordChange = async (e) => {

        let regExp = /^[a-zA-Z0-9]+$/i;
        $('#newAuthPassword').removeClass('error-input');
        $('#newAuthPassword').next('span').text('');

        if(e.target.value.length===0){
            return
        }

        if(!regExp.test(e.target.value)){
            $('#newAuthPassword').addClass('error-input');
            $('#newAuthPassword').next('span').text('Password should not contain any symbols or spaces.');
            this.setState({
                passwordError : true
            })
        }else{
            if(e.target.value.length<6 || e.target.value.length>8){
                $('#newAuthPassword').addClass('error-input');
                $('#newAuthPassword').next('span').text('Password should be 6-8 characters long');
                this.setState({
                    passwordError : true
                })
            }else{
                $('#newAuthPassword').removeClass('error-input')
                $('#newAuthPassword').next('span').text('');
                this.setState({
                    newAuthPassword : e.target.value,
                    passwordError : false
                })
            }  
        }
    }

    onPasswordConfirmChange = async (e) => {

        $('#authPasswordConfirm').removeClass('error-input');
        $('#authPasswordConfirm').next('span').text('');

        const { newAuthPassword } = this.state;

        if(e.target.value!==newAuthPassword){
            $('#authPasswordConfirm').addClass('error-input');
            $('#authPasswordConfirm').next('span').text(`Passwords don't match`);
            this.setState({
                passwordConfirmError : true
            })
        }else{
            $('#authPasswordConfirm').removeClass('error-input');
            $('#authPasswordConfirm').next('span').text();
            this.setState({
                passwordConfirmError : false
            })
        }
    }
    
    onSavePasswordChange = async () => {

        const { passwordError, passwordConfirmError } = this.state;

        if( !passwordError && !passwordConfirmError ){
            this.setState({
                isProcessing : true
            }, async () => {
                const { newAuthPassword } = this.state;
                
                let result = await this.props.updateUserPassword({
                    password : newAuthPassword
                })
    
                if(result.code===201){
                    setTimeout( () => {
                        this.setState({
                            isReauthenticateModalActive : true,
                            reAuthFor : "password",
                            isProcessing : false
                        })
                    }, 500)
                }else{
                    setTimeout ( () => {
                        this.setState({
                            isMessageModalActive : true,
                            messageModalHeader : "Password updated successfully!",
                            isPasswordChangeModalActive : false,
                            isProcessing : false
                        })
                    }, 500)
                }
            })
        }

    }

    closeNewPasswordModal = () => {
        this.setState({
            isPasswordChangeModalActive : false
        })
    }

    /**
     * EMAIL FUNCTIONS
     */
    onEmailChange = async (e) => {
        const { changedProfileData,  } = this.state;
        const { userProfile } = this.props;
      
        let cData = changedProfileData;
        cData[e.target.name] = e.target.value;

        let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
        let emailVal = e.target.value;

        if(!regExp.test(emailVal)){
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').parent('div').next('.error-input-message').text('Invalid email format');
            this.setState({
                isEmailValid : false
            })
        }else{
            if(userProfile.emailAddress!==emailVal){
                await this.checkForEmailDuplication(emailVal);  
            }else{
                $('#emailAddress').removeClass('error-input');
                $('#emailAddress').parent('div').next('.error-input-message').text('');
                this.setState({
                    isEmailValid : true,
                    isEmailChanged : false
                })
            }
        }

    }

    checkForEmailDuplication = async (email) => {
        let x = await this.props.checkForDuplicateEmail(email);
        if(x.code==="success"){
            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').next('.error-input-message').text('');
            this.setState({
                isEmailValid : true,
                isEmailChanged : true
            })
        }else{
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').next('.error-input-message').text(x.message);
            this.setState({
                isEmailValid : false,
                isEmailChanged : true
            })
        }
    }

    saveEmail = async () => {

        this.setState({
            isProcessing : true
        }, async () => {
            const { changedProfileData } = this.state;

            let result = await this.props.updateUserEmail({
                email : changedProfileData.emailAddress
            })
    
            if(result.code===201){
                setTimeout( () => {
                    this.setState({
                        isReauthenticateModalActive : true,
                        reAuthFor : "email",
                        isProcessing : false
                    })
                }, 500)
            }else{
    
                await this.props.updateCurrentUser({
                    emailAddress : changedProfileData.emailAddress
                })
    
                setTimeout( () => {
                    this.setState({
                        isMessageModalActive : true,
                        messageModalHeader : "Email updated successfully!",
                        messageModalSubHeader : "Please confirm you email address and re-login your account. Please check your spam folder for the verification email as well. Thank you!",
                        isProcessing : false
                    })
                }, 500)
            }   
        })
       
    }

    /**
     * FULL NAME FUNCTIONS
     */
    onFullNameChange = async (e) => {

        const { changedProfileData } = this.state;
        const { userProfile } = this.props;

        if(e.target.name==="fullName"){
            let regExp = /^[\w]*,\s[\w\s]*$/i;

            if(!regExp.test(e.target.value)){
                $('#fullName').addClass('error-input');
                $('#fullName').parent('div').next('.error-input-message').text( `Name should be on the following format, Last Name, First Name` );
                this.setState({
                    isFullnameValid : false
                })
            }else{
                $('#fullName').removeClass('error-input');
                $('#fullName').parent('div').next('.error-input-message').text( `` );
                if(userProfile.fullName.trim()!==e.target.value.trim()){
                   
                    let cData = changedProfileData;
                    cData[e.target.name] = e.target.value;

                    this.setState({
                        isFullnameValid : true,
                        isFullnameChanged : true,
                        changedProfileData : {...cData}
                    })

                }else{
                    this.setState({
                        isFullnameValid : true,
                        isFullnameChanged : false
                    })
                }
            }
        }

    }

    saveFullname = () => {

        const { changedProfileData } = this.state;
    
        this.setState({
            isProcessing : true
        }, async () => {

            await this.props.updateCurrentUser({
                fullName : changedProfileData.fullName
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isFullnameChanged : false
                })
            }, 500)

        })

    }

    /**
     * COUNTRY FUNCTIONS
     */
    onCountryChange = (e) => {
        const { userProfile } = this.props;
        const { changedProfileData } = this.state;

        if(e.target.value!==""){
            if(userProfile.country!==e.target.value){

                let cData = changedProfileData;
                cData[e.target.name] = e.target.value;

                this.setState({
                    isCountryChanged : true,
                    isCountryValid : true,
                    phoneCode : myCountryCodesObject[e.target.value],
                    changedProfileData : {...cData}
                })
            }else{
                this.setState({
                    isCountryChanged : false
                })
            }
        }else{
            this.setState({
                isCountryValid : false
            })
        }
    }

    saveCountry = async (e) => {

        this.setState({
            isProcessing : true
        }, async () => {

            const { changedProfileData }  = this.state;

            await this.props.updateCurrentUser({
                country : changedProfileData.country
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isCountryValid : true,
                    isCountryChanged : false
                })
            }, 500)

        })

    }

    /**
     * PHONE NUMBER FUNCTIONS
     */
    onPhoneNumberChange = (e) => {

        const { changedProfileData } = this.state;
        const { userProfile } = this.props;

        if(e.target.value===""){
            this.setState({
                isPhoneNumberValid : false
            })
        }else{
            if(userProfile.phoneNumber!==e.target.value){

                let cData = changedProfileData;
                cData[e.target.name] = e.target.value;

                this.setState({
                    isPhoneNumberChanged : true,
                    isPhoneNumberValid : true,
                    changedProfileData : {...cData}
                })

            }else{
                this.setState({
                    isPhoneNumberChanged : false
                })
            }
        }

    }

    savePhoneNumber = async () => {

        this.setState({
            isProcessing : true
        }, async () => {

            const { changedProfileData }  = this.state;

            await this.props.updateCurrentUser({
                phoneNumber : changedProfileData.phoneNumber
            })

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    isPhoneNumberChanged : false,
                    isPhoneNumberValid : true
                })
            }, 500)

        })

    }
    
    /**
     * PHOTO UPLOAD FUNCTIONS
     */
    openPhotoUpload = (e) => {
        e.preventDefault();
        $('#new_photo').click();
    }

    onFileChange = async (e) => {
        
        let newPhoto = e.target.files.length!== 0 ? e.target.files[0] : null;

        if(newPhoto!==null){

            let newPhotoFileType = newPhoto.type;

            if(acceptedFileTypes.indexOf(newPhotoFileType)!==-1){
                let result = await this.props.uploadUserImage({
                    file : newPhoto
                })

                if(result.code===200){
                    this.setState({
                        displayPhoto : result.url
                    })
                }
                $('#new_photo').next('span').text('');
            }else{
                $('#new_photo').next('span').text('File type not supported');
            }
        }
    }

    /**
     * RE AUTHENTICATION FUNCTIONS
     */
    authChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    authSubmit = (e) => {
        e.preventDefault();
        const { authEmail, authPassword, changedProfileData, reAuthFor } = this.state;
        const { userProfile } = this.props;

        if(authEmail==="" && authPassword===""){
            this.setState({
                errorMessage : "Please complete all fields to continue."
            })
        }else{
            if(reAuthFor==="password"){
                this.setState({
                    isProcessing : true,
                    errorMessage : ""
                }, async () => {
                    let result = await this.props.reAuthenticateUser({
                        email : authEmail,
                        password : authPassword
                    })

                    if(result.code===200){
                        this.setState({
                            isReauthenticateModalActive : false,
                            isPasswordChangeModalActive : false,
                            authEmail : "",
                            authPassword : "",
                            isLoggingIn : false,
                            errorMessage : "",
                            reAuthFor : "",
                            isProcessing : false,
                            isMessageModalActive : true,
                            isPasswordChangeModalActive : false,
                        })
                    }else{
                        this.setState({
                            isProcessing : false,
                            errorMessage : result.message
                        })
                    }
                })
            }

            if(reAuthFor==="email"){
                this.setState({
                    isProcessing : true
                }, async () => {
                    let result = await this.props.reAuthenticateUser({
                        email : authEmail,
                        password : authPassword
                    })

                    if(result.code===200){
                        this.setState({
                            isReauthenticateModalActive : false,
                            authEmail : "",
                            authPassword : "",
                            isLoggingIn : false,
                            errorMessage : "",
                            reAuthFor : "",
                            isProcessing : false,
                            isMessageModalActive : true,
                            isPasswordChangeModalActive : false,
                        })
                    }else{
                        this.setState({
                            isProcessing : false,
                            errorMessage : result.message
                        })
                    }
                })
            }
        }
    }

    closeReAuthModal = () => {
        this.setState({
            isReauthenticateModalActive : false,
            reAuthFor : ""
        })
    }

    updateNotifSettings = async (e) => {

        let settingGroup = $(e.target).data('group');
        let settingKey = $(e.target).data('setting');

        let result = await this.props.updateUserSettings({
            isOn : e.target.checked,
            group : settingGroup,
            settings : settingKey
        })

    }

    /**
     * EDIT BILLING ( CREDIT CARD )
     */
    checkBilling = async () => {
    
        const { userProfile } = this.props;
        let paymentMethodDetails;

        if(userProfile.paypalPaymentMethodTokens.hasOwnProperty('creditCard')){
            let paymentMethodToken = userProfile.paypalPaymentMethodTokens.creditCard;
           
            await axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/find_payment_method`,{
                paymentMethodToken : paymentMethodToken
            }).then( res => {
                paymentMethodDetails = res.data.details
                
                this.setState({
                    paymentMethodDetails : paymentMethodDetails,
                    isProcessing : false
                })
            })
        }
    }

    editBillingMethod = () => {

        const { userProfile } = this.props;

        let form = $('#card-form')
        let submit = $('#card-form-submit')

        this.setState({
            isEditingBillingMethod : true
        }, async () => {

            let customerId = {}

            if(userProfile.paypalCustomerID!==undefined){
                customerId = {
                    customerId : userProfile.paypalCustomerID
                }
            }
            
            axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/generate_client_token`, customerId)
            .then( res => {
                const { data } = res;
                const clientToken = data.clientToken;
    
                braintree.client.create({
                    authorization : clientToken
                }, async ( err, clientInstance) => {
        
                    if(err){
                        console.log(err)
                        return
                    }
    
                    braintree.hostedFields.create({
                        client : clientInstance,
                        styles : {
                            'input' : {
                                "background"    : "#FAFAFB",
                                "border"        : "1px solid #E6E4E8",
                                "box-sizing"    : "border-box",
                                "border-radius" : "4px",
                                "padding"       : "8px 20px",
                                "font-family"   : "Montserrat",
                                "font-style"    : "normal",
                                "font-weight"   : "500",
                                "font-size"     : "12px",
                                "color"         : "#939097"
                            }
                        },
                        fields : {
                            number: {
                                selector: '#card-number',
                                placeholder: '_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _'
                            },
                            cvv: {
                                selector: '#cvv',
                                placeholder: '_ _ _',
                                
                            },
                            expirationDate: {
                                selector: '#expiration-date',
                                placeholder: 'MM / YY'
                            }
                        }     
                    }, (err, hostedFieldsInstance) => {
                                
                        if(err){
                            console.log(err)
                            return
                        }
            
                        function createInputChangeEventListener(element) {
                            return function () {
                                validateInput(element);
                            }
                        }
            
                        function setValidityClasses(element, validity) {
                            if (validity) {
                                element.removeClass('is-invalid');
                                element.addClass('is-valid');  
                            } else {
                                element.addClass('is-invalid');
                                element.removeClass('is-valid');  
                            }    
                        }
                        
                        function validateInput(element) {
                            // very basic validation, if the
                            // fields are empty, mark them
                            // as invalid, if not, mark them
                            // as valid
                            if (!element.val().trim()) {
                                setValidityClasses(element, false);
                                return false;
                            }
                    
                            setValidityClasses(element, true);
                    
                            return true;
                        }
            
                        hostedFieldsInstance.on('validityChange', function(event) {
        
                            var field = event.fields[event.emittedBy];
                            // Remove any previously applied error or warning classes
                            $(field.container).removeClass('is-valid');
                            $(field.container).removeClass('is-invalid');
                    
                            if (field.isValid) {
                            $(field.container).addClass('is-valid');
                            } else if (field.isPotentiallyValid) {
                            // skip adding classes if the field is
                            // not valid, but is potentially valid
                            } else {
                            $(field.container).addClass('is-invalid');
                            }
                        });
                        
                        this.setState({
                            hostedFieldsInstance : hostedFieldsInstance
                        })
                    })
                })
            })
        })  
    }

    updateBillingMethod = (e) => {
        e.preventDefault();
        
        const { hostedFieldsInstance } = this.state;
        const { userProfile } = this.props;
    
        var formIsInvalid = false;
        var state = hostedFieldsInstance.getState();

        Object.keys(state.fields).forEach(function(field) {
            if (!state.fields[field].isValid) {
                $(state.fields[field].container).addClass('is-invalid');
                formIsInvalid = true;
            }
        });
    
        if (formIsInvalid) {
            // skip tokenization request if any fields are invalid
            return;
        }else{

            this.setState({
                isProcessing : true
            }, () => {

                hostedFieldsInstance.tokenize( (err, payload) => {
                    if (err) {
                        this.setState({
                            isProcessing : false
                        })
                        console.error(err);
                        return;
                    }
                    

                    if(userProfile.paypalPaymentMethodTokens.hasOwnProperty('creditCard')){

                        let requestBody = {
                            nonce : payload.nonce,
                            token : userProfile.paypalPaymentMethodTokens.creditCard
                        }
        
                        axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/update_payment`,requestBody)
                        .then( res => {
                            setTimeout( () => {
                                this.setState({
                                    isProcessing : false,
                                    isEditingBillingMethod : false
                                }, () => {
                                    this.checkBilling();
                                    this.checkPaypal();
                                })
            
                            }, 3000)
                        })
                        .catch(err => {
                            this.setState({
                                isProcessing : false
                            })
                            console.log(err)
                        })

                    }else{

                        let requestBody = {
                            paymentMethodNonce : payload.nonce,
                            userUid : userProfile.uid,
                            paymentType : "creditCard"
                        }
        
                        axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/add_payment`,requestBody)
                        .then( res => {
                            setTimeout( () => {
                                this.setState({
                                    isProcessing : false,
                                    isEditingBillingMethod : false
                                }, () => {
                                    this.checkBilling();
                                    this.checkPaypal()
                                })
            
                            }, 3000)
                        })
                        .catch(err => {
                            this.setState({
                                isProcessing : false
                            })
                            console.log(err)
                        })
                    }
                })
            })

        }
    }
 
    /**
     * EDIT BILLING ( PAYPAL )
     */
    checkPaypal = async () => {
        const { userProfile } = this.props;
        let paypalMethodDetails;

        if(userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal')){
            let paymentMethodToken = userProfile.paypalPaymentMethodTokens.paypal;
           
            await axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/find_payment_method`,{
                paymentMethodToken : paymentMethodToken
            }).then( res => {
                paypalMethodDetails = res.data.details
                this.setState({
                    paypalMethodDetails : paypalMethodDetails,
                    isProcessing : false
                })
            })
        }
    }

    editPaypalMethod = () => {

        const { userProfile } = this.props;

        this.setState({
            isEditingPaypalAccount : true,
            isProcessing : true
        }, async () => {

            let customerId = {}

            if(userProfile.paypalCustomerID!==undefined){
                customerId = {
                    customerId : userProfile.paypalCustomerID
                }
            }

            var paypalButton = document.querySelector('#paypal-button');
            
            axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/generate_client_token`, customerId)
            .then( res => {
                const { data } = res;
                const clientToken = data.clientToken;

                braintree.client.create({
                    authorization: clientToken
                }, (clientErr, clientInstance) => {
                    if (clientErr) {
                        console.error('Error creating client:', clientErr);
                        return;
                    }
                
                    braintree.paypal.create({
                        client: clientInstance
                    }, (paypalErr, paypalInstance) => {
                        if (paypalErr) {
                            console.error('Error creating PayPal:', paypalErr);
                            return;
                        }

                        paypalButton.removeAttribute('disabled');
                
                        // When the button is clicked, attempt to tokenize.
                        paypalButton.addEventListener('click', (event) => {
                            this.setState({
                                isProcessing : true
                            }, () => {

                                const { auth, userProfile } = this.props;
                                
                                paypalInstance.tokenize({
                                    flow: 'vault',
                                    currency : 'USD'
                                }, (tokenizeErr, payload) => {


                                    if (tokenizeErr) {
                                    // Handle tokenization errors or premature flow closure

                                        switch (tokenizeErr.code) {
                                            case 'PAYPAL_POPUP_CLOSED':
                                                console.error('Customer closed PayPal popup.');
                                            break;
                                            case 'PAYPAL_ACCOUNT_TOKENIZATION_FAILED':
                                                console.error('PayPal tokenization failed. See details:', tokenizeErr.details);
                                            break;
                                            case 'PAYPAL_FLOW_FAILED':
                                                console.error('Unable to initialize PayPal flow. Are your options correct?', tokenizeErr.details);
                                            break;
                                            default:
                                            console.error('Error!', tokenizeErr);
                                        }

                                        this.setState({
                                            isProcessing : false
                                        })

                                    } else {
                                        
                                        let nonce = payload.nonce;
                                        let userUid = auth.uid;
                                        
                                        if(userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal')){

                                            let requestBody = {
                                                nonce : payload.nonce,
                                                token : userProfile.paypalPaymentMethodTokens.paypal
                                            }
                            
                                            axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/update_payment`,requestBody)
                                            .then( res => {
                                                setTimeout( () => {
                                                    this.setState({
                                                        isProcessing : false,
                                                        isEditingPaypalAccount : false
                                                    }, () => {
                                                        this.checkBilling();
                                                        this.checkPaypal();
                                                    })
                                
                                                }, 3000)
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    isProcessing : false
                                                })
                                                console.log(err)
                                            })
                    
                                        }else{
                    
                                            let requestBody = {
                                                paymentMethodNonce : payload.nonce,
                                                userUid : userProfile.uid,
                                                paymentType : "paypal"
                                            }
                            
                                            axios.post(`https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/add_payment`,requestBody)
                                            .then( res => {
                                                setTimeout( () => {
                                                    this.setState({
                                                        isProcessing : false,
                                                        isEditingPaypalAccount : false
                                                    }, () => {
                                                        this.checkBilling();
                                                        this.checkPaypal();
                                                    })
                                
                                                }, 3000)
                                            })
                                            .catch(err => {
                                                this.setState({
                                                    isProcessing : false
                                                })
                                                console.log(err)
                                            })
                                        }
                                    }                
                                })
                            })
                        }, false);
                    })
                });

                this.setState({
                    isProcessing : false
                })

                $('#paypal-button').click();

               
            })
        })  
    }

    updatePaypalMethod = () => {
        $('#paypal-button').trigger('click');
    }

    render() {
        
        const { auth, userProfile, isLoggingOut } = this.props;
        const { isFullnameChanged, isFullnameValid } = this.state;
        const { isCountryChanged, isCountryValid } = this.state;
        const { isPhoneNumberChanged, isPhoneNumberValid } = this.state;
        const { isEmailChanged, isEmailValid } = this.state;
        const { paymentMethodDetails, isEditingBillingMethod } = this.state;
        const { paypalMethodDetails, isEditingPaypalAccount } = this.state;

        const { isPasswordChangeModalActive, errorMessage, isProcessing, isReauthenticateModalActive, isMessageModalActive, messageModalHeader, messageModalSubHeader, isAuthenticated, displayPhoto, phoneCode } = this.state;

        let imageUrl = userProfile.hasOwnProperty('imageUrl') ? userProfile.imageUrl === "" ? require('../../images/default-user.jpg') : userProfile.imageUrl : require('../../images/default-user.jpg')

        if(displayPhoto!==""){
            imageUrl = displayPhoto
        }

        if(!auth.uid){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="translator"){
                    return "NOT ALLOWED"
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        <div className="profile-box">
                            <div className="display-photo">
                                <img src={imageUrl}/>
                                <input onChange={this.onFileChange} type="file" name="new_photo" id="new_photo" style={{ display : "none" }} />
                                <span style={{ fontSize : ".65rem" }} className="error-input-message"></span>
                                <button onClick={this.openPhotoUpload}>REPLACE</button>
                            </div>
                            <div className="user-data">
                                <div style={{ flex : '1' }}>
                                    <div style={{ marginBottom : "20px" }} className="form-group">
                                        <label className="custom-label block">Name</label>
                                        <div style={{ display : "flex" }}>
                                            <input onChange={this.onFullNameChange} defaultValue={userProfile.hasOwnProperty('fullName') ? userProfile.fullName : ""} id="fullName" name="fullName" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter your name"/>
                                            { (isFullnameValid && isFullnameChanged) && <button onClick={this.saveFullname} >SAVE</button> }
                                        </div>
                                        <span className="error-input-message"></span>
                                    </div>
                                    <div style={{ marginBottom : "20px" }} className="form-group">
                                        <label className="custom-label block">Email</label>
                                        <div style={{ display : "flex"}}>
                                            <input onChange={this.onEmailChange} defaultValue={userProfile.hasOwnProperty('emailAddress') ? userProfile.emailAddress : ""} id="emailAddress" name="emailAddress" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Enter your email address"/>
                                            { (isEmailValid && isEmailChanged) && <button onClick={this.saveEmail} >SAVE</button> }
                                        </div>
                                        <span className="error-input-message"></span>
                                    </div>
                                    <div style={{ marginBottom : "0" }} className="form-group">
                                        <label className="custom-label block">Password</label>
                                        <input onClick={this.onPasswordClick} defaultValue={"Your masked password"} id="password" name="password" style={{ width: "100%" }} className="custom-input" type="password" placeholder="Enter your password"/>
                                    </div>
                                </div>
                                <div style={{ flex : '1', paddingLeft : "32px" }}>
                                    <div style={{ marginBottom : "20px" }} className="form-group">
                                        <label className="custom-label block">Your country</label>
                                        <div style={{ display : "flex" }}>
                                            <select onChange={this.onCountryChange} placeholder="Select here" className="custom-select form-control" id="country" name="country">
                                                <option value="">Select here</option>
                                                {
                                                    Object.keys(myCountryCodesObject).map( (data, i) => (
                                                        <option selected={userProfile.country === data} key={i} value={data}>{data}</option>
                                                    ))
                                                }
                                            </select>
                                            { (isCountryValid && isCountryChanged) && <button onClick={this.saveCountry} >SAVE</button> }
                                        </div>
                                    </div>
                                    <label className="custom-label block">Phone number</label>
                                    <div style={{ marginBottom : "0" }} className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">{phoneCode}</span>
                                        </div>
                                        <input onChange={this.onPhoneNumberChange} aria-describedby="basic-addon1" defaultValue={userProfile.hasOwnProperty('phoneNumber') ? userProfile.phoneNumber : ""} id="phoneNumber" name="phoneNumber" style={{ width: "100%", flex : "1" }} className="custom-input" type="text" placeholder="Enter your phone number"/>
                                        { (isPhoneNumberValid && isPhoneNumberChanged) && <button onClick={this.savePhoneNumber} >SAVE</button> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="div-holder" style={{ display : "flex", marginTop : "40px" }}>
                            <div className="profile-box" style={{ minWidth : "50%", marginRight : "16px"}}>
                            {
                                (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && !userProfile.paypalPaymentMethodTokens.hasOwnProperty('creditCard') && !isEditingBillingMethod) &&
                                <div style={{ display : "flex", width : "100%" }}>
                                    <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>Billing method to pay</p>
                                    <button onClick={this.editBillingMethod} className="add-billing-method">ADD BILLING METHOD</button>
                                </div>
                            }
                            {
                                    (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && userProfile.paypalPaymentMethodTokens.hasOwnProperty('creditCard') && !isEditingBillingMethod) && 
                                    <div style={{ width : "100%" }}>
                                            <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>Billing information</p>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label className="custom-label block">Card number</label>
                                                        <input disabled defaultValue={paymentMethodDetails!== null ? paymentMethodDetails.maskedNumber : ""} name="cardNumber" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder="_ _ _ _  _ _ _ _  _ _ _ _  _ _ _ _"/>
                                                        <span className="error-input-message"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label className="custom-label block">Date</label>
                                                        <input disabled defaultValue={ paymentMethodDetails!== null ? paymentMethodDetails.expirationMonth + "/" + paymentMethodDetails.expirationYear.substr(2,2) : ""} name="cardNumber" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder="MM / YY"/>
                                                        <span className="error-input-message"></span>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label className="custom-label block">CVV</label>
                                                        <input disabled  defaultValue="***" name="cardNumber" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder="_ _ _"/>
                                                        <span className="error-input-message"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={this.editBillingMethod} className="edit-billing-method">EDIT BILLING INFORMATION</button>
                                    </div>
                                }
                                {
                                    isEditingBillingMethod &&
                                    <div style={{ width: "100%"}}>
                                        <form id="card-form">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label class="custom-label block" for="card-number">Card Number</label>
                                                        <div id="card-number" class="hosted-field"></div>
                                                        <div class="invalid-feedback">
                                                            Credit card number is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label class="custom-label block" for="expiration-date">Expiration Date</label>
                                                        <div id="expiration-date" class="hosted-field"></div>
                                                        <div class="invalid-feedback">
                                                            Expiration date required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label class="custom-label block" for="cvv">CVV</label>
                                                        <div id="cvv" class="hosted-field"></div>
                                                        <div class="invalid-feedback">
                                                            Security code required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button id="card-form-submit" name="card-form-submit" onClick={this.updateBillingMethod} className="update-billing-method">UPDATE BILLING METHOD</button>
                                        </form>
                                    </div>
                                }
                            </div>
                            <div className="profile-box" style={{ minWidth : "47.5%", marginLeft : "16px" }}>
                                <div className="form-group">
                                    <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>Email-notification</p>
                                    <ul className="notification-setting-list">
                                        <li>
                                            <label className="checkbox-container">When order is completed
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenOrderCompleted') ? userProfile.settings.email.whenOrderCompleted : false }  name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenOrderCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">When you get a new message
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenNewMessage') ? userProfile.settings.email.whenNewMessage : false }name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenNewMessage"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">When stage is completed (Get Drafts only)
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('email') && userProfile.settings.email.hasOwnProperty('whenStageCompleted') ? userProfile.settings.email.whenStageCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"email"} data-setting={"whenStageCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                    <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left", marginTop : "28px" }}>SMS</p>
                                    <ul className="notification-setting-list">
                                        <li>
                                            <label className="checkbox-container">When order is completed
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenOrderCompleted') ? userProfile.settings.sms.whenOrderCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenOrderCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">When you get a new message
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenNewMessage') ? userProfile.settings.sms.whenNewMessage : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenNewMessage"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">When stage is completed (Get Drafts only)
                                                <input checked={userProfile.hasOwnProperty('settings') && userProfile.settings.hasOwnProperty('sms') && userProfile.settings.sms.hasOwnProperty('whenStageCompleted') ? userProfile.settings.sms.whenStageCompleted : false } name="reason" onClick={this.updateNotifSettings} data-group={"sms"} data-setting={"whenStageCompleted"} className="reason" type="checkbox"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="div-holder" style={{ display : "flex", marginTop : "40px" }}>
                            <div className="profile-box" style={{ minWidth : "50%" }}>
                            {
                                (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && !userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal') && !isEditingPaypalAccount) &&
                                <div style={{ display : "flex", width : "100%" }}>
                                    <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>Billing method to pay</p>
                                    <button onClick={this.editPaypalMethod} className="add-billing-method">ADD PAYPAL</button>
                                    <button id="paypal-button" style={{ display : "none"}}></button>
                                </div>
                            }
                            {
                                (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && !userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal') && isEditingPaypalAccount) &&
                                <div style={{ display : "flex", width : "100%" }}>
                                    <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>Billing method to pay</p>
                                    <button id="paypal-button"className="add-billing-method">OPEN PAYPAL</button>
                                </div>
                            }
                            {
                                    (userProfile.hasOwnProperty('paypalPaymentMethodTokens') && userProfile.paypalPaymentMethodTokens.hasOwnProperty('paypal')) && 
                                    <div style={{ width : "100%" }}>
                                            <p style={{ fontSize : "16px", fontWeight : "bold", textAlign : "left"}}>Billing information</p>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label className="custom-label block">Email</label>
                                                        <input disabled defaultValue={paypalMethodDetails!== null ? paypalMethodDetails.email : ""} name="paypalEmail" style={{ width: "100%", letterSpacing : "5px"  }} className={`custom-input`} type="text" placeholder="Enter email here"/>
                                                        <span className="error-input-message"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            { !isEditingPaypalAccount && <button onClick={this.editPaypalMethod} className="edit-billing-method">EDIT PAYPAL INFORMATION</button>}
                                            { isEditingPaypalAccount &&  <button id="paypal-button"className="edit-billing-method"></button>}
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            isMessageModalActive && 
                            <Message 
                                closeMessageModal={this.closeMessageModal} 
                                isOpened={isMessageModalActive} 
                                header={messageModalHeader} 
                                subHeader={messageModalSubHeader}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                                
                        }
                        {
                            isReauthenticateModalActive && 
                            <Reauthenticate
                                isOpened={isReauthenticateModalActive}
                                authSubmit={this.authSubmit}
                                authChange={this.authChange}
                                errorMessage={errorMessage}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeReAuthModal={this.closeReAuthModal}
                            />
                        }
                        {
                            isPasswordChangeModalActive && 
                            <NewPassword
                                onPasswordChange={this.onPasswordChange}
                                onPasswordConfirmChange={this.onPasswordConfirmChange}
                                onSavePasswordChange={this.onSavePasswordChange}
                                isOpened={isPasswordChangeModalActive}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                                closeNewPasswordModal={this.closeNewPasswordModal}
                            />
                        }
                        {
                            isProcessing &&
                            <SpinnerModal 
                                isOpened={isProcessing}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                        {
                            isLoggingOut &&
                            <SpinnerModal
                                isOpened={isLoggingOut}
                                addModal={this.props.addModal}
                                popModal={this.props.popModal}
                                modalInstances={this.props.modalInstances}
                            />
                        }
                    </div>
                }
            </div>
        )


        
    }

}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isLoggingOut : state.auth.isLoggingOut,
        modalInstances : state.app.modalInstances
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps, {
        uploadUserImage,
        updateUserSettings,
        updateCurrentUser,
        checkForDuplicateEmail,
        updateUserEmail,
        updateUserPassword,
        signOutUser,
        reAuthenticateUser,
        addModal,
        popModal
    })
)(CustomerProfileIndex)