import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../actions/appAction';

import {
    translate
} from 'react-switch-lang'

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class TaskForReview extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened, uid } = this.props;

        if(isOpened){
            $(`#taskForReviewModal-${uid}`).modal({backdrop:'static', keyboard:false});
            $(`#taskForReviewModal-${uid} .modal-dialog`).attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $(`#taskForReviewModal-${uid}`).modal('show');
            if(this.props.addModal){
                this.props.addModal(`task-for-review-modal-${uid}`)
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass(`task-for-review-modal-${uid}-bd`);
        }

    }

    componentDidUpdate(prevProps){
        const { uid } = this.props;

        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class===`task-for-review-modal-${uid}`);
            if(modalInstance!==undefined){
                $(`.task-for-review-modal-${uid}-bd`).css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        const { uid } = this.props;

        $(`#taskForReviewModal-${uid}`).modal('hide');
        if(this.props.popModal){
            this.props.popModal()
        }
        $('body').removeClass('modal-open');
    }
  
    render(){
        const { task, uid } = this.props;
        const { modalZ } = this.state;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t, currentLanguage } = this.props;
       
        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} task-for-review-modal modal fade`} id={`taskForReviewModal-${uid}`} tabIndex="-1" role="dialog" aria-labelledby="completedTaskLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="custom-modal-header modal-header">
                            <button id="modal-closer" onClick={ () => this.props.closeNotificationModal(uid)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div style={{ textAlign : "center" }}>
                                <img style={{ width: "50%", marginBottom : "30px"}} src={require('../../images/tarjemle-logo.png')}/>
                            </div>
                            <p className="text-header">{t('task_modal_ready_header')}</p>
                            <p style={{ fontWeight : "400", textAlign : "center", marginTop : "12px" }}>{t('task_modal_ready_sub_header')}</p>

                            <div className="task-completed-details task-item">
                                <div style={{ display : "flex" }}>
                                    <div style={{ flex : "1" }}><span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span></div>
                                    <div style={{ textAlign: "right" }}><span style={{ fontSize : "18px", color: "#676A6C" }}>{t('common_order_num')}:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span></div>
                                </div>
                                <p style={{ marginTop: "16px" }} className="task-title">{task.title}</p>
                                <div style={{ display : "flex", marginTop : "24px" }}>
                                    <div style={{ marginRight : "30px"}}>
                                        <span><img src={require('../../images/icon-completed.png')} style={{ width: "10px", marginRight : "5px" }}/>{t('common_stat_for_review')}</span>
                                    </div>
                                    <div style={{ flex : "1" }}>
                                        <span><img src={task.paymentStatus==="fullyPaid" ? require('../../images/icon-paid.png') : require('../../images/icon-unpaid.png') }style={{ width: "10px", marginRight : "5px" }}/>{ task.paymentStatus ==="fullyPaid" ? t('common_pay_stat_paid') : t('common_pay_stat_unpaid') }</span>
                                    </div>
                                </div>
                                <div style={{ display : "flex", marginTop : "24px" }}>
                                    <div style={{ marginRight : "30px"}}>
                                        <span className="field-label">{t('common_task_created')}</span>
                                        <span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">{t('common_deadline')}</span>
                                        <span className="field-value">{ moment.unix(deadline).format("Y/M/DD")}</span>
                                    </div>
                                </div>
                                <div style={{ display : "flex", marginTop : "24px" }}>
                                    <div style={{ marginRight : "30px"}}>
                                        <span className="field-label">{t('common_word_count')}</span>
                                        <span className="field-value">{task.numberOfWords}</span>
                                    </div>
                                    <div style={{ flex : "1"}}>
                                        <span className="field-label">{t('common_days_to_deadline')}</span>
                                        <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                                    </div>
                                </div>
                                <div className="text-center" style={{ marginTop : "56px" }}>
                                    <button onClick={ () => this.props.seeResults(task,uid)} style={{ width: "210px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('task_modal_ready_see_result')}</button>    
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(TaskForReview)