import {
    RELOAD_NOTIFICATIONS,
    REMOVE_NOTIFICATION
} from './types'

export const notifyAccountManager = (data) => (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        var notifyAccountManager = firebase.functions().httpsCallable('notifyAccountManager');

        notifyAccountManager({translatorUid : firebase.auth().currentUser.uid, message : data.message }).then( (result) => {
            resolve(result)
        }).catch( error => {
            console.log(error);
        })
    })
}

export const watchNotifications = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    if(firebase.auth().currentUser!==null){
        const unsubscribeWatchNotificationListener = firestore.collection('notifications')
            .where('recipient','==',firebase.auth().currentUser.uid)
            .where('isViewed','==',false)
            .onSnapshot( querySnapshot => {
                querySnapshot.docChanges().forEach( change => {
                    if(change.type==='added'){
                        dispatch({
                            type : RELOAD_NOTIFICATIONS,
                            payload : {
                                notification : change.doc.data(),
                                notificationRef : change.doc
                            }
                        })
                    }
                })
            })

        return unsubscribeWatchNotificationListener;
    }
}

export const removeNotification = (data) => (dispatch, getState, {getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    let notifRef = firestore.collection('notifications').doc(data.uid)
    notifRef.set({
        isViewed : true
    }, { merge : true})

    dispatch({
        type : REMOVE_NOTIFICATION,
        payload : {
            notification : data
        }
    })

}
