import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

import {
    translate
} from 'react-switch-lang'

export class EscalateTaskModal extends Component {

    constructor(){
        super();
        this.state = {
            escalationReason : "",
            escalationLetter : "",
            showOtherInput : false,
            isReasonEmpty : false,
            isLetterEmpty : false,
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            
            $('#escalateTaskModal').modal({backdrop:'static', keyboard:false});
            $('#escalateTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#escalateTaskModal').modal('show');
            this.props.addModal('escalate-task-modal')
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="escalate-task-modal");
            $('.et-bd').css('z-index',`${modalInstance.z_index-10}`)
            this.setState({
                modalZ : modalInstance.z_index
            })
        }
    }

    componentWillUnmount(){
        $('#escalateTaskModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');

    }

    selectReason = (e) => {
        let text = $(e.target).parent('.checkbox-container').text();

        if(text==="Other"){
            this.setState({
                showOtherInput : true,
                escalationReason : ""
            })
        }else{
            this.setState({
                escalationReason : text,
                showOtherInput : false,
                isReasonEmpty : false
            })
        }
    
    }

    onInputChange = (e) => {

        if(e.target.name==="escalationLetter"){
            if(e.target.value.trim()!==""){
                this.setState({
                    isLetterEmpty : false
                })
            }
        }

        if(e.target.name==="escalationReason"){
            if(e.target.value.trim()!==""){
                this.setState({
                    isReasonEmpty : false
                })
            }
        }

        this.setState({
            [e.target.name] : e.target.value
        })

    }

    escalateTask = () => {

        const { escalationReason, escalationLetter } = this.state;

        if(escalationReason.trim()===""){
            this.setState({
                isReasonEmpty : true
            })
        }else if(escalationLetter.trim()===""){
            this.setState({
                isLetterEmpty : true
            })
        }else{
            this.props.escalateTask({
                escalationReason : escalationReason,
                escalationLetter : escalationLetter
            })
        }
    }

    render(){
        const { modalZ, escalationReason, showOtherInput, isReasonEmpty, isLetterEmpty } = this.state;
        const { userProfile } = this.props;
        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t , currentLanguage } = this.props;
        
        return (
            <div style={{ zIndex : `${modalZ}` }} className={` ${modalDialogClass} escalate-task-modal modal fade modal-opaque`} id="escalateTaskModal" tabIndex="-1" role="dialog" aria-labelledby="helpTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeEscalateTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="custom-modal-header modal-header">
                                <button onClick={this.props.closeEscalateTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        <div className="modal-body">
                            <p className="text-header">{t('task_modal_escalate_header')}</p>
                            <p className="align-left" style={{ fontWeight : "bold", marginLeft : "30px", marginTop : "50px" }}>{t('task_modal_escalate_reason_header')}</p>
                            
                            <div className="form-group">
                                {
                                    userProfile.type==="customer" &&
                                    <ul className="reason-list align-left">
                                        <li>
                                            <label className="checkbox-container">{t('task_modal_escalate_no_money')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('task_modal_escalate_info_updates')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('task_modal_escalate_postponed')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('common_other')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                }
                                {
                                    userProfile.type==="translator" &&
                                    <ul className="reason-list">
                                        <li>
                                            <label className="checkbox-container">{t('task_modal_decline_deadline_too_short')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('task_modal_decline_task_desc_too_short')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('task_modal_decline_too_busy')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label className="checkbox-container">{t('common_other')}
                                                <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                }
                            </div>
                            <div className="form-group align-left" style ={{ paddingLeft : "30px" }}>
                            { showOtherInput && <input style={{ width: "90%" }} onChange={this.onInputChange} id="escalationReason" name="escalationReason" style={{ width: "100%" }}  className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder={t('common_write_reason')}/> }  
                            <div><p style={{ marginTop : "10px" }}>{t('task_modal_escalate_write_letter')}</p><input style={{ width: "90%" }} onChange={this.onInputChange} id="escalationLetter" name="escalationLetter" style={{ width: "100%" }}  className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder={t('common_write_reason')}/></div>
                            </div>
                            <div className="text-center">
                                <button onClick={this.escalateTask} style={{ width: "192px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('task_modal_escalate_button')}</button>    
                            </div>                              
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(EscalateTaskModal)