import React, { Component } from 'react'

export class CustomerPage1 extends Component {

    render () {
        const { values, t } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label className="align-left custom-label block">{t('common_first_name')}</label>
                            <input defaultValue={values.firstName} onFocus={this.props.inputFocus} onChange={this.props.onChange('firstName')} id="firstName" name="firstName" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_here')}/>
                            <span className="align-left block error-input-message">{this.props.firstNameValidation}</span>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label className="align-left custom-label block">{t('common_last_name')}</label>
                            <input defaultValue={values.lastName} onFocus={this.props.inputFocus} onChange={this.props.onChange('lastName')} id="lastName" name="lastName" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_here')}/>
                            <span className="align-left block error-input-message">{this.props.lastNameValidation}</span>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="align-left custom-label block">{t('common_email')}</label>
                    <input disabled={values.isEmailDisabled} defaultValue={values.emailAddress} onChange={this.props.onChange('email')} id="emailAddress" name="emailAddress" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_here')}/>
                    <span className="align-left block">{this.props.emailValidation}</span>
                </div>
                {
                    !values.isPasswordDisabled &&
                    <div className="form-group">
                        <label className="align-left custom-label block">{t('common_password')}</label>
                        <input defaultValue={values.password} onChange={this.props.onChange('password')} id="password" name="password" style={{ width: "100%" }} className="custom-input" type="password" placeholder={t('common_enter_here')}/>
                        <span className="align-left block">{this.props.passValidation}</span>
                    </div>
                }
               
            </div>
        )
    }

}

export default CustomerPage1;