import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';
import $ from 'jquery';

const renderStatus = (status,t) => {

    if(status==="new"){
        return t('common_stat_new');
    }

    if(status==="inProgress"){
        return t('common_stat_in_progress');
    }

    if(status==="completed"){
        return t('common_stat_completed');
    }

}

const renderPaymentStatus = (status, t) => {
    switch(status){
        case "prepaid" :
            return t('common_pay_stat_unpaid');
        case "fullyPaid" :
            return t('common_pay_stat_paid');
        default:
            return t('common_pay_stat_unpaid');
    }
}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class Item extends Component {

    state ={
        shouldRender : false
    }

    acceptTask = (task) => {
        this.props.acceptTask(task);
    }

    openDeclineTaskModal = (task) => {
        this.props.openDeclineTaskModal(task)
    }

    componentDidMount(){
        const { displayDelay } = this.props;
        this.timer = setTimeout(() => {
            this.setState({ shouldRender: true }, () => {
                const scrollHeight = $('html')[0].scrollHeight;
                const height = $('html')[0].clientHeight;
                const maxScrollTop = scrollHeight - height;
                // $("html, body").animate({ scrollTop : maxScrollTop > 0 ? maxScrollTop : 0 }, 200);
            });
        }, displayDelay);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){
        const { shouldRender } = this.state;
        const { task, isMobileView, t, currentLanguage } = this.props;

        const acceptBtnStyle = {
            border : "none",
            background : "#22C9C7",
            borderRadius : "8px",
            color : "#FCFCFC",
            fontWeight : "bold",
            fontSize : ".65rem",
            textTransform : "uppercase",
            padding : "12px 16px",
            marginRight : isMobileView ? "0" : "12px",
            marginLeft : currentLanguage==="ar" ? "12px" : "0",
            width: "100%"
        }
    
        const declineBtnStyle = {
            border: "2px solid #FF6666",
            background : "#FAFAFB",
            borderRadius : "8px",
            color : "#FF6666",
            fontWeight : "bold",
            fontSize : ".65rem",
            textTransform : "uppercase",
            padding : "12px 16px",
            marginRight : isMobileView ? "12px" : "0",
            width: "100%"
        }
        
        
        
        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
        
        let passedDueStyle = {};

        if(moment.unix(deadline).diff(moment(new Date()), 'days')<=0){
            passedDueStyle = {
                color : "#FF6666"
            }
        }

        if(isMobileView){
            return (
                <div>
                    {
                        shouldRender &&
                            <div className="task-item animated fadeInUp">
                                <div className="row" style={{ marginRight : "5px", marginLeft : "5px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                    <div className="col" style={{ position : "relative", padding : "0" }}>
                                        <span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span>
                                        <p style={{ marginBottom : "15px", marginTop : "15px" }} className="task-title">{task.title}</p>
                                        <span style={{ marginBottom : "15px", display: "block", fontSize : "13px", color: "#676A6C" }}>{t('common_order_num')}<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                        <div class="btn-group" style={{ position : "absolute", top : "-7px", right : "15px" }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                                <button onClick={ () => this.props.openEscalateTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{  marginRight : "5px", marginLeft : "5px", paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_task_created')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_deadline')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).format("Y/MM/DD")}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_word_count')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{task.numberOfWords}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_days_to_deadline')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span></div>
                                    </div>
                                </div>
                                <div className="row" style={{  marginRight : "5px", marginLeft : "5px", paddingTop : "15px", paddingBottom : "15px" }}>
                                    <div className="col" style={{ padding : 0 }}>
                                        <p className="task-description"><span style={{ color : "#7e7e7e" }}>{t('common_description')}: &nbsp;</span>{task.detailedInstructions}</p>
                                        <span class="align-left" style={{ display : "block", fontSize:"17px", color : "#464646" }}>{t('common_you_will_earn')} : ${ task.translatorEarnings.toFixed(2) }</span>
                                        <div style={{ display : "flex", marginTop : "20px" }}>
                                            <button onClick={ () => this.openDeclineTaskModal(task) } style={declineBtnStyle}>{t('task_option_decline')}</button>
                                            <button onClick={()=>this.acceptTask(task)} style={acceptBtnStyle}>{t('common_accept_invite')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        }else{
            return (
                <div>
                    {
                        shouldRender &&
                            <div className="task-item animated fadeInUp">
                                <div className="row">
                                    <div style={{ cursor : "pointer", borderRight: "1px solid #E9E9EA"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                
                                        <div className="row pl-2">
                                            <div className="col">
                                                <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={ task.status==="inProgress" || task.status==="new" ? require('../../../../images/icon-inprogress.png') : require('../../../../images/icon-completed.png')}/></span>{renderStatus(task.status, t)}</p>
                                            </div>
                                            <div className="col">
                                                <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={ task.paymentStatus==="fullyPaid" ? require('../../../../images/icon-paid.png') : require('../../../../images/icon-unpaid.png')}/></span>{renderPaymentStatus(task.paymentStatus, t)}</p>
                                            </div>
                                        </div>
                                        <div className="row pl-2">
                                            <div className="col">
                                                <span className="field-label">{t('common_task_created')}</span>
                                                <span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span>
                                            </div>
                                            <div className="col">
                                                <span className="field-label">{t('common_deadline')}</span>
                                                <span className="field-value">{ moment.unix(deadline).format("Y/MM/DD")}</span>
                                            </div>
                                        </div>
                                        <div className="row pl-2 mt-4">
                                            <div className="col">
                                                <span className="field-label">{t('common_word_count')}</span>
                                                <span className="field-value">{task.numberOfWords}</span>
                                            </div>
                                            <div className="col">
                                                <span className="field-label">{t('common_days_to_deadline')}</span>
                                                <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                                            </div>
                                        </div>
                
                                    </div>
                                    <div style={{ cursor: "pointer", borderRight: "1px solid #E9E9EA"}}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="row pl-2">
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <p className="task-title">{task.title}</p>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                                            <span style={{ fontSize : "14px", color: "#676A6C" }}>{t('common_order_num')}:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                            </div>
                                        </div>
                                        <div className="row pl-2">
                                            <div className="col">
                                                <p className="task-description"><span style={{ color : "#7e7e7e" }}>{t('common_description')}: &nbsp;</span>{task.detailedInstructions}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <span class="align-left" style={{ display : "block", fontSize:"20px", color : "#464646" }}>{t('common_you_will_earn')}</span>
                                        <p className="task-remaining-amount">${ parseFloat(task.translatorEarnings).toFixed(2) }</p>
                                        <div style={{ display : "flex", marginTop : "50px" }}>
                                            <button onClick={()=>this.acceptTask(task)} style={acceptBtnStyle}>{t('common_accept_invite')}</button>
                                            <button onClick={ () => this.openDeclineTaskModal(task) } style={declineBtnStyle}>{t('task_option_decline')}</button>
                                        </div>
                                    </div>
                                </div>
                
                
                            </div>
                    }
                </div>
            )
        }

    }

}

export default Item;
