import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Item from './Item';

export class List extends Component {

    render(){

        const { tasks, isLoadingMoreTasks, isMobileView, t, currentLanguage } = this.props;

        return (
            
            <div className="" ref="archivedTaskList">
                {
                    tasks.map( (data, i) => <Item displayDelay={500} t={t} currentLanguage={currentLanguage} isMobileView={isMobileView} key={data.uid} task={data}/> )
                }

                {
                    isLoadingMoreTasks ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreTasks} className="load-more-button">{t('common_load_more')}</button>
                    </div>
                }
               
            </div>

           

        )

    }

}

export default List;
