import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import { getArchivedTasks, loadMoreArchivedTasks } from '../../../../actions/taskAction'
import TaskList from './List';
import TaskItem from './Item';
import SpinnerModal from '../../../Spinner/SpinnerModal'
import $ from 'jquery'

import {
    translate
} from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isAuthenticated : false
        }
    }
    
    componentDidMount(){

        const { auth, userProfile } = this.props;

        if(auth.uid){
            this.loadTasks();
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

        if(prevProps.task!==this.props.tasks){
           this.scrollToBottom();
        }
    }

    scrollToBottom = () => {
        const scrollHeight = $('html')[0].scrollHeight;
        const height = $('html')[0].clientHeight;
        const maxScrollTop = scrollHeight - height;
        $('html')[0].scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    loadTasks = async () => {
        this.props.getArchivedTasks();
    }

    loadMoreTasks = () => {
        this.props.loadMoreArchivedTasks();
    }

    render() {

        const { isLoggingOut, auth, userProfile } = this.props;
        const { tasks, isGettingTasks, isLoadingMoreTasks } = this.props;
        const { isAuthenticated, isProcessing } = this.state;
        const { isMobileView } = this.props;
        const { currentLanguage, t } =this.props;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }

        return (
            <div className="div-holder page">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <p className="module-header">{t('archive_header')}</p>
                            </div>
                        </div>
                        <div className="">
                            {
                                isGettingTasks ?
                                <div className="d-flex justify-content-center">
                                    <div style={{ width: "5rem", height : "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                tasks.length!==0 ?  
                                <TaskList  
                                    t={t}
                                    currentLanguage={currentLanguage}
                                    isMobileView={isMobileView}
                                    loadTasks={this.loadTasks} 
                                    loadMoreTasks={this.loadMoreTasks}
                                    isLoadingMoreTasks={isLoadingMoreTasks}
                                    tasks={tasks}
                                    /> : ""
                            }
                        </div>
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                    </div>
                }
                
            </div>
        )
        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        tasks : state.task.archivedTasks,
        isGettingTasks : state.task.isGettingTasks,
        isLoadingMoreTasks : state.task.isLoadingMoreTasks,
        isLoggingOut : state.auth.isLoggingOut,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { getArchivedTasks, loadMoreArchivedTasks })
)(Index)

