import React, { Component } from 'react'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import moment from 'moment';

export class SearchedMessage extends Component {

    constructor(){
        super();
        this.state = {
            shouldRender : false
        }
    }

    componentDidMount(){
        const { displayDelay } = this.props;
        this.timer = setTimeout(() => {
            this.setState({ shouldRender: true });
        }, displayDelay);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){

        const { shouldRender } = this.state;
        const { searched } = this.props;

        let imageUrl = searched.senderImage === "" ? require('../../images/default-user.jpg') : searched.senderImage
        let conversationClass = '';

        return (
            <div>
                {
                    shouldRender &&
                    <div onClick={ () => this.props.getAndSetActiveConversation(searched.conversation) } className={`animated fadeIn chat-item ${conversationClass} `}>
                        <div className="chat-item-body">
                            <img className="display-photo" src={imageUrl}/>
                            <div className="details">
                                <span className="username">{searched.senderName}</span>
                                <span className="highlighted-text-result">{ ReactHtmlParser(searched._snippetResult.text.value) }</span>
                                <span className="chat-date" style={{ textTransform : "capitalize"}}>{moment.unix(searched.dateAdded._seconds).format("M.DD.YY")}</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )

    }

}

export default SearchedMessage