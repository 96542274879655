import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    SEND_PASSWORD_RESET,
    SEND_PASSWORD_RESET_SUCCESS,
    SEND_PASSWORD_RESET_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    SIGN_UP_SUCCESS,
    VALIDATE_EMAIL_ERROR,
    VALIDATE_EMAIL_SUCCESS
} from './types'

import axios from 'axios'
import exactMath from 'exact-math'
import $ from 'jquery'

const taskTypes = [
    "essay",
    "dissertation",
    "presentation"
]

const paymmentStatuses = [
    "prepaid",
    "fullyPaid"
]

const permittedUsers = [
    "customer",
    "translator"
]

export const signUpUser = (data) => (dispatch, getState, {getFirebase, getFirestore}) => {
    
    return new Promise((resolve, reject) => {

        const firebase = getFirebase();
        const firestore = getFirestore();
        const appState = getState().app;
        
        let userData = {
            fullName : data.fullName,
            type : data.userType,
            presence : "offline",
            loginType : data.provider,
            emailAddress : data.emailAddress,
            country : data.country,
            phoneNumber : data.phoneNumber,
            gender : data.gender,
            yearsOfExperience : data.yearsOfExperience,
            specialty : data.specialty,
            // interests : data.interests,
            aboutYourself : data.aboutYourSelf,
            resumeUrl : "",
            imageUrl : "",
            status : data.userType==="translator" ? "pending" : "available",
            isDeleted : false,
            billingMethod : [],
            dateRegistered : firestore.Timestamp.now(),
            lang : appState.currentLanguage
        }

        if(data.userType==="translator"){
            userData.amountEarned = 0;
            userData.numberOfTasksCompleted = 0;
            userData.numberOfAssignedTasks = 0;
        }

        if(data.userType==="customer"){
            userData.amountSpent = 0;
            userData.numberOfTasksCreated = 0;
        }
        
        if(data.provider=="google" || data.provider=="facebook"){

            firestore.collection('users').doc(firebase.auth().currentUser.uid).set({...userData, uid : firebase.auth().currentUser.uid}).then( async () => {

                let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);
    
                if(data.resumeFile!==null){
                    firebase.storage().ref(`users/${userRef.id}/cv/${data.resumeFile.name}`).put(data.resumeFile)
                    .on('state_changed', (snapshot) => {
                    },
                    (error) =>{
                        console.log(error);
                    },
                    async () => {
                        await firebase.storage().ref(`users/${userRef.id}/cv`).child(data.resumeFile.name).getDownloadURL().then( url => {
                            firestore.runTransaction( t => {
                                return t.get(userRef)
                                    .then( doc => {
                                        t.update(userRef, {resumeUrl : url});
                                    });
                            }).then( result => {
                            }).catch( error => {
                                console.log(error)
                                throw(error);
                            });
                        })
                    });
                }
                
                if(data.imageFile!==null){
                    
                    if(data.imageFile.hasOwnProperty('name')){
                        firebase.storage().ref(`users/${userRef.id}/profilePictures/${data.imageFile.name}`).put(data.imageFile)
                        .on('state_changed', (snapshot) => {
                        },
                        (error) =>{
                            console.log(error);
                        },
                        () => {
                            // complete function .... 
                            firebase.storage().ref(`users/${userRef.id}/profilePictures`).child(data.imageFile.name).getDownloadURL().then( url => {
                                
                                firestore.runTransaction( t => {
                    
                                    return t.get(userRef)
                                        .then( doc => {
                                            t.update(userRef, {imageUrl : url});
                                        });
                            
                                }).then( result => {
                                }).catch( error => {
                                    console.log(error)
                                    throw(error);
                                });
                            })
                        });
                    }else{
                        firestore.runTransaction( t => {
                            return t.get(userRef)
                                .then( doc => {
                                    t.update(userRef, {imageUrl : data.imageFile});
                                });
                    
                        }).then( result => {
                        }).catch( error => {
                            console.log(error)
                            throw(error);
                        });
                    }
                }
                
                //await firebase.auth().currentUser.sendEmailVerification()
    
                let requestBody = {
                    userUid : userRef.id,
                    email : data.emailAddress,
                    lang : appState.currentLanguage
                }
    
                await firebase.auth().currentUser.updateProfile({
                    displayName : data.fullName,
                })
    
                if(data.userType==="customer"){
                    firebase.analytics().logEvent('new_customer');
                }
    
                dispatch({
                    type : SIGN_UP_SUCCESS
                })
                resolve({
                    code : "success"
                })

            })

        }else{
            firebase.auth().createUserWithEmailAndPassword(
                data.emailAddress,
                data.password
            ).then((response) => {
    
                return firestore.collection('users').doc(response.user.uid).set({...userData, uid : response.user.uid})
                
            }).then( async ()=>{
                
                let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);
    
                if(data.resumeFile!==null){
                    firebase.storage().ref(`users/${userRef.id}/cv/${data.resumeFile.name}`).put(data.resumeFile)
                    .on('state_changed', (snapshot) => {
                    },
                    (error) =>{
                        console.log(error);
                    },
                    async () => {
                        await firebase.storage().ref(`users/${userRef.id}/cv`).child(data.resumeFile.name).getDownloadURL().then( url => {
                            firestore.runTransaction( t => {
                                return t.get(userRef)
                                    .then( doc => {
                                        t.update(userRef, {resumeUrl : url});
                                    });
                            }).then( result => {
                            }).catch( error => {
                                console.log(error)
                                throw(error);
                            });
                        })
                    });
                }
                
                if(data.imageFile!==null){
                    firebase.storage().ref(`users/${userRef.id}/profilePictures/${data.imageFile.name}`).put(data.imageFile)
                    .on('state_changed', (snapshot) => {
                    },
                    (error) =>{
                        console.log(error);
                    },
                    () => {
                        // complete function .... 
                        firebase.storage().ref(`users/${userRef.id}/profilePictures`).child(data.imageFile.name).getDownloadURL().then( url => {
                            
                            firestore.runTransaction( t => {
                
                                return t.get(userRef)
                                    .then( doc => {
                                        t.update(userRef, {imageUrl : url});
                                    });
                        
                            }).then( result => {
                            }).catch( error => {
                                console.log(error)
                                throw(error);
                            });
                        })
                    });
                }
                
                //await firebase.auth().currentUser.sendEmailVerification()
    
                let requestBody = {
                    userUid : userRef.id,
                    email : data.emailAddress,
                    lang : appState.currentLanguage
                }
    
                await firebase.auth().currentUser.updateProfile({
                    displayName : data.fullName,
                })
    
                if(data.userType==="customer"){
                    firebase.analytics().logEvent('new_customer');
                }
    
                var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
        
                sendEmailVerification(requestBody).then( (result) => {
                    // firebase.auth().signOut();
                }).catch( error => {
                    console.log(error);
                })
    
                dispatch({
                    type : SIGN_UP_SUCCESS
                })

                resolve({
                    code : "success"
                })
    
            }).catch( error => {
                console.log(error)
            })
        }
    })

}

export const checkForDuplicateEmail = (email) => (dispatch, getState, {getFirebase, getFirestore} ) => {

    return new Promise( async (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        firebase.auth().fetchSignInMethodsForEmail(email).then( (result) => {
            if(result.length!==0){
                dispatch({
                    type : VALIDATE_EMAIL_ERROR,
                    payload : {
                        code : "auth/duplicate-email",
                        message : "This email has already been used. Please use a different email address."
                    }
                })

                resolve({
                    code : "auth/duplicate-email",
                    message : "This email has already been used. Please use a different email address."
                })
            }else{
                dispatch({
                    type : VALIDATE_EMAIL_SUCCESS
                })

                resolve({
                    code : "success",
                    message : "Email is available"
                })
            }
        })
    })

}

export const getSignInMethods = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise( async (resolve) => {
    
        const firebase = getFirebase();

        if(firebase.auth().currentUser!==null){
            firebase.auth().fetchSignInMethodsForEmail(firebase.auth().currentUser.email).then( (result) => {
            
                let can_edit_password = true, can_edit_email = true;
    
                if(result.indexOf("password")===-1){
                    can_edit_password = false;
                }
    
                if(result.indexOf("facebook.com")!==-1 || result.indexOf("google.com")!==-1){
                    can_edit_email = false;
                }
    
                resolve({
                    can_edit_email : can_edit_email,
                    can_edit_password : can_edit_password,
                })
                
            })
        }
       
    })

}

export const signInUser = (provider = "email", user_type = "customer", email = "", password = "", remember_me = false ) =>  (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : LOGIN
    })

    return new Promise( (resolve, reject) => {

        let loginProvider = null;
        const firebase = getFirebase();
        const firestore = getFirestore();
        const appState = getState().app;

        switch(provider){
            case "google":
                loginProvider = new firebase.auth.GoogleAuthProvider();
                loginProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
                loginProvider.addScope('https://www.googleapis.com/auth/user.birthday.read');
                loginProvider.addScope('https://www.googleapis.com/auth/user.addresses.read');
  
                firebase.auth().signInWithPopup(loginProvider).then(function(result) {
                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    var token = result.credential.accessToken;
                    // The signed-in user info.
                    var user = result.user;
                    
                    firestore.collection('users').doc(firebase.auth().currentUser.uid).get().then( (doc) => {

                        if(doc.data()===undefined){
                            let firstName = (result.additionalUserInfo.profile.given_name !== undefined && result.additionalUserInfo.profile.given_name !== null) ?  result.additionalUserInfo.profile.given_name : "";
                            let lastName = (result.additionalUserInfo.profile.family_name !== undefined && result.additionalUserInfo.profile.family_name !== null ) ?  result.additionalUserInfo.profile.family_name : "";
                        
                            let userData = {
                                fullName : lastName + ", " + firstName,
                                emailAddress : ( result.additionalUserInfo.profile.email !== undefined && result.additionalUserInfo.profile.email !== null) ?  result.additionalUserInfo.profile.email : "",
                                country : ( result.additionalUserInfo.profile.country !== undefined && result.additionalUserInfo.profile.country !== null ) ?  result.additionalUserInfo.profile.country : "",
                                gender : ( result.additionalUserInfo.profile.gender !== undefined && result.additionalUserInfo.profile.gender !== null ) ?  result.additionalUserInfo.profile.gender : "",
                                imageUrl : ( result.additionalUserInfo.profile.picture !== undefined && result.additionalUserInfo.profile.picture !== null ) ?  result.additionalUserInfo.profile.picture : "",
                                type : user_type,
                                status : "pending_signup",
                                loginType : "google",
                                isDeleted : false,
                                billingMethod : [],
                                dateRegistered : firestore.Timestamp.now(),
                                lang : appState.currentLanguage,
                                uid : firebase.auth().currentUser.uid,
                            }

                            firestore.collection('users').doc(firebase.auth().currentUser.uid).set({...userData});
                        }else{

                            if(permittedUsers.indexOf(doc.data().type)!=-1){
                                if(doc.data().status==="pending"){
                                    firebase.auth().signOut().then(() =>{
                                        // dispatch({
                                        //     type : LOGOUT_SUCCESS
                                        // })
                                    })
    
                                    dispatch({
                                        type : LOGIN_ERROR,
                                        payload : {
                                            error : {
                                                code : "auth/pending",
                                                message : "Your CV is sent."
                                            }
                                        }
                                    })
    
                                    resolve({
                                        code : "auth/user-pending",
                                        message : ""
                                    })
                                }
                            }else{
                                dispatch({
                                    type : LOGIN_ERROR,
                                    payload : {
                                        error : {
                                            code : "auth/unauthorized_login",
                                            message : "Your CV is sent."
                                        }
                                    }
                                })
                               
                                resolve({
                                    code : "auth/unauthorized_login",
                                    message : ""
                                })
                                firebase.auth().signOut().then(() =>{
                                })
                            }

                            

                        }

                    })

                    // ...
                  }).catch(function(error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;

                    if(errorCode==="auth/popup-closed-by-user"){
                        dispatch({
                            type : LOGIN_ERROR,
                            payload : {
                                error : {
                                    code : errorCode,
                                    message : "Google login cancelled"
                                }
                            }
                        })
                    }

                    if(errorCode==="auth/account-exists-with-different-credential"){
                        dispatch({
                            type : LOGIN_ERROR,
                            payload : {
                                error : {
                                    code : errorCode,
                                    message : "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address."
                                }
                            }
                        })
                    }
                    // ...
                });
                break;
            case "facebook":
                loginProvider = new firebase.auth.FacebookAuthProvider();
                loginProvider.addScope('email');
                loginProvider.setCustomParameters({
                    'display': 'popup'
                });
                firebase.auth().signInWithPopup(loginProvider).then(function(result) {
                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    var token = result.credential.accessToken;
                    // The signed-in user info.
                    var user = result.user;

                    firestore.collection('users').doc(firebase.auth().currentUser.uid).get().then( doc => {
                        if(doc.data()===undefined){
                            let firstName = result.additionalUserInfo.profile.first_name !== undefined ?  result.additionalUserInfo.profile.first_name : "";
                            let lastName = result.additionalUserInfo.profile.last_name !== undefined ?  result.additionalUserInfo.profile.last_name : "";
        
                            let userData = {
                                fullName : lastName + ", " + firstName,
                                emailAddress : ( result.additionalUserInfo.profile.email !== undefined && result.additionalUserInfo.profile.email !== null ) ?  result.additionalUserInfo.profile.email : "",
                                country :  ( result.additionalUserInfo.profile.country !== undefined && result.additionalUserInfo.profile.country !== null ) ?  result.additionalUserInfo.profile.country : "",
                                gender :  ( result.additionalUserInfo.profile.gender !== undefined && result.additionalUserInfo.profile.gender !== null ) ?  result.additionalUserInfo.profile.gender : "",
                                imageUrl : ( result.additionalUserInfo.profile.picture.data.url !== undefined && result.additionalUserInfo.profile.picture.data.url !== null ) ?  result.additionalUserInfo.profile.picture.data.url : "",
                                type : user_type,
                                status : "pending_signup",
                                loginType : "facebook",
                                isDeleted : false,
                                billingMethod : [],
                                dateRegistered : firestore.Timestamp.now(),
                                lang : appState.currentLanguage,
                                uid : firebase.auth().currentUser.uid,
                            }
                            
                            firestore.collection('users').doc(firebase.auth().currentUser.uid).set({...userData});
                        }else{

                            if(permittedUsers.indexOf(doc.data().type)!=-1){
                                if(doc.data().status==="pending"){
                                    firebase.auth().signOut().then(() =>{
                                        // dispatch({
                                        //     type : LOGOUT_SUCCESS
                                        // })
                                    })
    
                                    dispatch({
                                        type : LOGIN_ERROR,
                                        payload : {
                                            error : {
                                                code : "auth/pending",
                                                message : "Your CV is sent."
                                            }
                                        }
                                    })
    
                                    resolve({
                                        code : "auth/user-pending",
                                        message : ""
                                    })
                                }
                            }else{
                                dispatch({
                                    type : LOGIN_ERROR,
                                    payload : {
                                        error : {
                                            code : "auth/unauthorized_login",
                                            message : "Your CV is sent."
                                        }
                                    }
                                })
                               
                                resolve({
                                    code : "auth/unauthorized_login",
                                    message : ""
                                })
                                firebase.auth().signOut().then(() =>{
                                })
                                
                            }
                        }
                    })

                   

                    // ...
                  }).catch(function(error) {
                    
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    // The email of the user's account used.
                    var email = error.email;
                    // The firebase.auth.AuthCredential type that was used.
                    var credential = error.credential;
                    if(errorCode==="auth/popup-closed-by-user"){
                        dispatch({
                            type : LOGIN_ERROR,
                            payload : {
                                error : {
                                    code : errorCode,
                                    message : "Facebook login cancelled"
                                }
                            }
                        })
                    }

                    if(errorCode==="auth/account-exists-with-different-credential"){

                        firebase.auth().fetchSignInMethodsForEmail(email).then( (result) => {
                            
                            if(result.length!==0){
                                
                                let p ="";
                                console.log(result)
                                if(result[0]==="google.com")
                                    p = "google"

                                if(result[0]==="facebook.com")
                                    p = "facebook"

                                if(result[0]==="password")
                                    p = "password"
                            
                                dispatch({
                                    type : LOGIN_ERROR,
                                    payload : {
                                        error : {
                                            provider : p,
                                            email : email,
                                            code : errorCode,
                                            message : "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address."
                                        }
                                    }
                                })
                            }
                        })
                    }
                    // ...
                });
                break;
            default:
                const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
                
                if(!regExp.test(email)){
        
                    dispatch({
                        type : LOGIN_ERROR,
                        payload : {
                            error : {
                                code : "auth/invalid-email",
                                message : "Your CV is sent."
                            }
                        }
                    })
                    
                    resolve({
                        code : "auth/invalid-email",
                        message : ""
                    })
        
                }else{
                
                    if(!remember_me){
                        firebase.auth().setPersistence('session').then( () => {
                            // Login translator if status is not pending
                            firebase.auth().signInWithEmailAndPassword(
                                email,
                                password
                            ).then( async (authUser) => {
                                let uRef;
                                await firestore.collection('users').where('emailAddress','==',authUser.user.email).get().then( querySnapshots => {
                                    uRef = querySnapshots.docs[0].data();
                                })
        
                                if(permittedUsers.indexOf(uRef.type)!=-1){
                                    if(!authUser.user.emailVerified){
        
                                        let requestBody = {
                                            userUid : uRef.uid,
                                            email : uRef.emailAddress,
                                            lang : appState.currentLanguage
                                        }
                                       
                                        var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
            
                                        sendEmailVerification(requestBody).then( (result) => {
                                        }).catch( error => {
                                            console.log(error);
                                        })
        
                                        firebase.auth().signOut().then(() =>{
                                            dispatch({
                                                type : LOGIN_ERROR,
                                                payload : {
                                                    error : {
                                                        code : "auth/unverified-email",
                                                        message : "Please verify your email address"
                                                    }
                                                }
                                            })
                        
                                            resolve({
                                                code : "auth/unverified-email",
                                                message : ""
                                            })
                                        })
                        
                                    }else{
                                        
                    
                                        firestore.collection('users').where('emailAddress','==',email).get()
                                        .then( (querySnapShots) =>{
                                        
                                            let user = querySnapShots.docs[0].data();
                                            if(user.type==="translator"){
                                                if(user.status==="pending"){
                    
                                                    firebase.auth().signOut().then(() =>{
                                                        // dispatch({
                                                        //     type : LOGOUT_SUCCESS
                                                        // })
                                                    })
                    
                                                    dispatch({
                                                        type : LOGIN_ERROR,
                                                        payload : {
                                                            error : {
                                                                code : "auth/pending",
                                                                message : "Your CV is sent."
                                                            }
                                                        }
                                                    })
                    
                                                    resolve({
                                                        code : "auth/user-pending",
                                                        message : ""
                                                    })
                                                }else{
                                                    
                                                    dispatch({
                                                        type : LOGIN_SUCCESS
                                                    })
                                                    
                                                    localStorage.setItem('rememberMe', remember_me);
        
                                                    // firebase.auth().currentUser.getIdToken().then( idToken => {
                                                    //     axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/signin-user', { idToken : idToken }).then( (res) => console.log(res));
                                                    // })
        
                                                    resolve({
                                                        code : "auth/success",
                                                        message : "translator",
                                                        user : {...user}
                                                    })
                                                }
                                            }else{
                                            
                                                dispatch({
                                                    type : LOGIN_SUCCESS
                                                })
        
                                                localStorage.setItem('rememberMe', remember_me);
        
                                                // firebase.auth().currentUser.getIdToken().then( idToken => {
                                                //     axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/signin-user', { idToken : idToken }).then( (res) => console.log(res));
                                                // })
                                                
                                                resolve({
                                                    code : "auth/success",
                                                    message : "translator",
                                                    user : {...user}
                                                })
                                            }
                                        })
                                    }
                    
                                }else{
                                    dispatch({
                                        type : LOGIN_ERROR,
                                        payload : {
                                            error : {
                                                code : "auth/unauthorized_login",
                                                message : "Your CV is sent."
                                            }
                                        }
                                    })
                                   
                                    resolve({
                                        code : "auth/unauthorized_login",
                                        message : ""
                                    })
                                    firebase.auth().signOut().then(() =>{
                                    })
                                }
                            }).catch( error => {
                                dispatch({
                                    type : LOGIN_ERROR,
                                    payload : {
                                        error : {
                                            code : error.code,
                                            message : error.message
                                        }
                                    }
                                })
        
                                resolve({
                                    code : "auth/error",
                                    message : ""
                                })
                            })
                        })
                    }else{
                        firebase.auth().setPersistence('local').then( () => {
                            // Login translator if status is not pending
                            firebase.auth().signInWithEmailAndPassword(
                                email,
                                password
                            ).then( async (authUser) => {
                                
                                let uRef;
                                await firestore.collection('users').where('emailAddress','==',authUser.user.email).get().then( querySnapshots => {
                                    uRef = querySnapshots.docs[0].data();
                                })
        
                                if(permittedUsers.indexOf(uRef.type)!=-1){
                                    if(!authUser.user.emailVerified){
        
                                        let requestBody = {
                                            userUid : uRef.uid,
                                            email : uRef.emailAddress,
                                            lang : appState.currentLanguage
                                        }
        
                                            
                                        var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
            
                                        sendEmailVerification(requestBody).then( (result) => {
                                        }).catch( error => {
                                            console.log(error);
                                        })
                                        dispatch({
                                            type : LOGIN_ERROR,
                                            payload : {
                                                error : {
                                                    code : "auth/unverified-email",
                                                    message : "Please verify your email address"
                                                }
                                            }
                                        })
                    
                                        resolve({
                                            code : "auth/unverified-email",
                                            message : ""
                                        })
                        
                                        firebase.auth().signOut().then(() =>{
                                            dispatch({
                                                type : LOGOUT_SUCCESS
                                            })
                                        })
                        
                                    }else{
                                        
                    
                                        firestore.collection('users').where('emailAddress','==',email).get()
                                        .then( (querySnapShots) =>{
                                        
                                            let user = querySnapShots.docs[0].data();
                                            if(user.type==="translator"){
                                                if(user.status==="pending"){
                    
                                                    firebase.auth().signOut().then(() =>{
                                                        dispatch({
                                                            type : LOGOUT_SUCCESS
                                                        })
                                                    })
                    
                                                    dispatch({
                                                        type : LOGIN_ERROR,
                                                        payload : {
                                                            error : {
                                                                code : "auth/pending",
                                                                message : "Your CV is sent."
                                                            }
                                                        }
                                                    })
                    
                                                    resolve({
                                                        code : "auth/user-pending",
                                                        message : ""
                                                    })
                                                }else{
                                                    
                                                    dispatch({
                                                        type : LOGIN_SUCCESS
                                                    })
                                                    
                                                    // firebase.auth().currentUser.getIdToken().then( idToken => {
                                                    //     axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/signin-user', { idToken : idToken }).then( (res) => console.log(res));
                                                    // })
        
                                                    localStorage.setItem('rememberMe', remember_me);
        
                                                    resolve({
                                                        code : "auth/success",
                                                        message : "translator",
                                                        user : {...user}
                                                    })
                                                }
                                            }else{
                                                
                                                dispatch({
                                                    type : LOGIN_SUCCESS
                                                })
        
                                                localStorage.setItem('rememberMe', remember_me);
        
                                                // firebase.auth().currentUser.getIdToken().then( idToken => {
                                                //     axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/signin-user', { idToken : idToken }).then( (res) => console.log(res));
                                                // })
        
                                                resolve({
                                                    code : "auth/success",
                                                    message : "translator",
                                                    user : {...user}
                                                })
                                            }
                                        })
                                    }
                    
                                }else{
                                    resolve({
                                        code : "auth/error",
                                        message : ""
                                    })
                                    firebase.auth().signOut().then(() =>{
                                        dispatch({
                                            type : "LOGOUT_SUCCESS"
                                        })
                                    })
                                }
        
        
                            }).catch( error => {
                                console.log(error)
                            
                                dispatch({
                                    type : LOGIN_ERROR,
                                    payload : {
                                        error : {
                                            code : error.code,
                                            message : error.message
                                        }
                                    }
                                })
        
                                resolve({
                                    code : "auth/error",
                                    message : ""
                                })
                            })
                        })
                    }
                }
                break;
        }



 
    })
}

export const signOutUser = () => (dispatch, getState, { getFirebase }) => {
    dispatch({
        type : LOGOUT
    })

    const firebase = getFirebase();
    const oldRealTimeDb = firebase.database();
    const onlineRef = oldRealTimeDb.ref('.info/connected');

    onlineRef.on('value', snapshot => {
    
        oldRealTimeDb
        .ref(`/presence/${firebase.auth().currentUser.uid}`)
        .set('offline') // The value to be set for this key when the client disconnects
        .then(() => {
            firebase.auth().signOut().then(() =>{
                dispatch({
                    type : LOGOUT_SUCCESS
                })
                localStorage.removeItem("rememberMe");
                // axios.post('https://us-central1-tarjemle-445ea.cloudfunctions.net/widgets/signout-user').then(()=>{
                // })
            })
        }).catch(error => {
            console.log(error)
        });
    
    });
}

export const sendPasswordResetEmail = (data) => (dispatch, getState, { getFirebase, getFirestore}) => {

    dispatch({
        type : SEND_PASSWORD_RESET
    })

    return new Promise( async (resolve, reject) => {

        const firebase = getFirebase();
        const firestore = getFirestore();

        let emailValid = false;

        firebase.auth().fetchSignInMethodsForEmail(data).then( (result) => {
            if(result.length!==0){
                firebase.auth().sendPasswordResetEmail(data).then( () => {
            
                    dispatch({
                        type : SEND_PASSWORD_RESET_SUCCESS
                    })
        
                    resolve({
                        code : 200,
                        message : "Password reset email sent. Please check your email."
                    })
                }).catch( (error) => {
                    console.log(error)
        
                    dispatch({
                        type : SEND_PASSWORD_RESET_ERROR
                    })
        
                    resolve({
                        code : 500,
                        message : "Password reset email sending error."
                    })
                })
            }else{
                resolve({
                    code : 404,
                    message : "Account not found! Please try again"
                })
            }
        })
    })

}

export const updateOpenedAssignedTasks = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

    let data = {
        lastOpenedAssignedTasksDate : firestore.Timestamp.now()
    }

    firestore.runTransaction( t => {
        return t.get(userRef)
                .then( doc => {
                    t.update(userRef, data, { merge : true})
                    return Promise.resolve(data)
                })
    }).then( (result) => {
        dispatch({
            type : UPDATE_USER_SUCCESS,
            payload : {
                lastOpenedAssignedTasksDate : data.lastOpenedAssignedTasksDate
            }
        })
    }).catch( error => {
        console.log(error)
        dispatch({
            type : UPDATE_USER_ERROR
        })
    })

}

export const updateUserLastOpenedConversationDate = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "UPDATE_USER"
    })


    const firestore = getFirestore();
    const firebase = getFirebase();

    let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

    firestore.runTransaction( t => {
        return t.get(userRef)
                .then( doc => {
                    t.update(userRef, {
                        lastOpenedConversationDate : firestore.Timestamp.now()
                    })
                    return Promise.resolve(true)
                })
    }).then( (result) => {
        dispatch({
            type : "UPDATE_USER_SUCCESS"
        })
        
    }).catch( error => {
        console.log(error)
        dispatch({
            type : "UPDATE_USER_ERROR"
        })
    })
}

export const updateCurrentUser = (data) => ( dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {
                        t.update(userRef, data)
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })

} 

export const uploadUserImage = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve, reject) => {

        const firebase = getFirebase();
        const firestore = getFirestore();
        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        if(data.file!==null){
            firebase.storage().ref(`users/${userRef.id}/profilePictures/${data.file.name}`).put(data.file)
            .on('state_changed', (snapshot) => {
            },
            (error) =>{
                console.log(error);
            },
            () => {
                // complete function .... 
                firebase.storage().ref(`users/${userRef.id}/profilePictures`).child(data.file.name).getDownloadURL().then( url => {
                    
                    firestore.runTransaction( t => {
        
                        return t.get(userRef)
                            .then( doc => {
                                t.update(userRef, {imageUrl : url});
                                resolve({
                                    code : 200,
                                    url : url
                                })
                            });
                
                    }).then( result => {
                    }).catch( error => {
                        console.log(error)
                        resolve({
                            code : 500
                        })
                        throw(error);
                    });
                })
            });
        }
    })

}

export const updateUserSettings = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {

                        let userSettings = doc.data().hasOwnProperty('settings') ? doc.data().settings : {}

                        if(!userSettings.hasOwnProperty(data.group)){
                            userSettings[data.group] = {};
                        }

                        userSettings[data.group][data.settings] = data.isOn;
                    
                        t.update(userRef, { settings : userSettings})
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })





}

export const updateUserEmail = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();
        const appState = getState().app;
        
        let user = firebase.auth().currentUser;

        user.updateEmail(data.email).then( async function() {
            
            let requestBody = {
                userUid : user.uid,
                email : user.emailAddress,
                lang : appState.currentLanguage
            }

            var sendEmailVerification = firebase.functions().httpsCallable('sendEmailVerification');
    
            sendEmailVerification(requestBody).then( (result) => {
            }).catch( error => {
                console.log(error);
            })
            resolve({
                code : 200
            })

        }).catch(function(error) {
            console.log(error)
            resolve({
                code : 201
            })
        });
    })


}

export const updateUserPassword = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( resolve => {

        const firebase = getFirebase();

        let user = firebase.auth().currentUser;

        user.updatePassword(data.password).then(function() {
            resolve({
                code : 200
            })
        }).catch(function(error) {
            console.log(error)
            resolve({
                code : 201,
                message : error.message
            })
        });

    })

}

export const reAuthenticateUser = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( resolve => {
        const firebase = getFirebase();

        let user = firebase.auth().currentUser;
        let credential = firebase.auth.EmailAuthProvider.credential(data.email, data.password);
        // Prompt the user to re-provide their sign-in credentials

        user.reauthenticateWithCredential(credential).then(function() {
            resolve({
                code : 200
            })
        }).catch(function(error) {
            resolve({
                code : 500,
                message : error.message
            })
        });
    })

}

export const setUserPresence = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore =  getFirestore();
    const firebase = getFirebase();
    const oldRealTimeDb = firebase.database();
    
    const usersRef = firestore.collection('users'); // Get a reference to the Users collection;
    const onlineRef = oldRealTimeDb.ref('.info/connected'); // Get a reference to the list of connections

    onlineRef.on('value', snapshot => {
    
        oldRealTimeDb
        .ref(`/presence/${data.uid}`)
        .onDisconnect() // Set up the disconnect hook
        .set('offline') // The value to be set for this key when the client disconnects
        .then(() => {
            // Set the Firestore User's online status to true
            usersRef
            .doc(data.uid)
            .set({
                presence: "online",
            }, { merge: true});  

            // Let's also create a key in our real-time database
            // The value is set to 'online'
            oldRealTimeDb.ref(`/presence/${data.uid}`).set('online');

        }).catch(error => {
            console.log(error)
        });
    
    });

    // const firestore = getFirestore();
    // const firebase = getFirebase();

    // let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid)
    // let userFdbRef = firebase.database().ref(`presence/${firebase.auth().currentUser.uid}`)
    
    // let offlineForFirestore = {
    //     presence : "offline",
    // }

    // let onlineForFirestore = {
    //     presence : "online",
    // }

    // firebase.database().ref('.info/connected').on('value', function (snapshot){
    //     if(snapshot.val()===false){
    //         return;
    //     }
    // })

    // userFdbRef.onDisconnect().set("offline").then( function () {
    //     userFdbRef.set("online")

    //     userRef.set({
    //         ...onlineForFirestore
    //     }, { merge : true })
    // })
}

export const updateCustomerTaskCreated = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {

                        let count = doc.data().numberOfTasksCreated;
                        count = count + 1;

                        t.update(userRef, { numberOfTasksCreated : count })
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })

}

export const updateCustomerAmountSpent = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {

                        let amount = doc.data().amountSpent;
                        amount = exactMath.add(amount,data.amount);

                        t.update(userRef, { amountSpent : amount })
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })

}

export const updateTranslatorAssignedTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {

                        let count = doc.data().numberOfAssignedTasks;
                        count = count + 1;

                        t.update(userRef, { numberOfAssignedTasks : count })
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })

}

export const updateTranslatorAmountEarned = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

        firestore.runTransaction( t => {
            return t.get(userRef)
                    .then( doc => {

                        let amount = doc.data().amountEarned;
                        amount = exactMath.add(amount,data.amount);

                        t.update(userRef, { amountEarned : amount })
                        return Promise.resolve(true)
                    })
        }).then( (result) => {
            dispatch({
                type : UPDATE_USER_SUCCESS
            })
            
            resolve({
                code : 200
            })
        }).catch( error => {
            console.log(error)
            dispatch({
                type : UPDATE_USER_ERROR
            })

            resolve({
                code : 500
            })
        })


    })

}

export const updateTranslatorAvailability = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : UPDATE_USER
    })

    return new Promise ( async (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let taskCount = 0;
        let tasksRef = firestore.collection('tasks').where('assignedTranslator','==',firebase.auth().currentUser.uid)
                                                    .where('status','in',['inProgress','forReview']);

        await tasksRef.get().then( querySnapshots => {

            querySnapshots.forEach( doc => {
                taskCount = taskCount + 1
            })

        })

        if(taskCount>1){

            let userRef = firestore.collection('users').doc(firebase.auth().currentUser.uid);

            firestore.runTransaction( t => {
                return t.get(userRef)
                        .then( doc => {    
                            t.update(userRef, { status : "notAvailable" })
                            return Promise.resolve(true)
                        })
            }).then( (result) => {
                dispatch({
                    type : UPDATE_USER_SUCCESS
                })
                
                resolve({
                    code : 200
                })
            }).catch( error => {
                console.log(error)
                dispatch({
                    type : UPDATE_USER_ERROR
                })
    
                resolve({
                    code : 500
                })
            })

        }
    })

}

export const checkTranslatorAvailability = () => (dispatch, getState, { getFirestore, getFirebase }) => {


    return new Promise ( async (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let taskCount = 0;
        let tasksRef = firestore.collection('tasks').where('assignedTranslator','==',firebase.auth().currentUser.uid)
                                                    .where('status','in',['inProgress','forReview']);

        await tasksRef.get().then( querySnapshots => {
            querySnapshots.forEach( doc => {
                taskCount = taskCount + 1
            })
        })

        if(taskCount>1){
            resolve({
                code : 500
            })
        }else{
            resolve({
                code : 200
            })
        }
    })


}