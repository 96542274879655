import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import LoggedOutLinks from './LoggedOutLinks';
import LoggedInLinks from './LoggedInLinks';
import { watchNewInProgressTasks, removeNewInProgressDisplay, watchNewCompletedTasks, removeNewCompletedDisplay, } from '../../actions/taskAction'
import {
    addModal,
    popModal
} from '../../actions/appAction'

import {
    TaskInProgress
} from '../Modals/TaskInProgress';

import {
    TaskForReview
} from '../Modals/TaskForReview';

import {
    watchNotifications,
    removeNotification
} from '../../actions/notificationAction'

import {
    getTaskTransactions
} from '../../actions/taskAction'

import {
    translate
} from 'react-switch-lang';

import $ from 'jquery';

export class Header extends Component {

    componentDidMount(){
        const { auth } = this.props;

        if(auth.uid){
            // this.unsubscribeWatchNewInProgressTasks = this.props.watchNewInProgressTasks();
            // this.unsubscribeWatchNewCompletedTasks = this.props.watchNewCompletedTasks();
            this.unsubscribeWatchNotificationListener =  this.props.watchNotifications();
        }
    }

    componentDidUpdate(prevProps){

        if(this.props.auth!==prevProps.auth){
            if(this.props.auth.uid){
                this.unsubscribeWatchNotificationListener =  this.props.watchNotifications();
            }
        }

    }

    componentWillUnmount(){
        this.unsubscribeWatchNotificationListener && this.props.watchNotifications();
    }

    closeNewTaskInProgressModal = (data) => {
        this.props.removeNewInProgressDisplay(data)
    }

    closeNewTaskCompletedModal = (data) => {
        this.props.removeNewCompletedDisplay(data)
    }

    closeNotificationModal = (data) => {
        this.props.removeNotification({ uid : data })
    }

    seeResults = (task, uid) => {
        this.props.removeNotification({ uid : uid })
        this.props.history.push({
            pathname : "/tasks/published",
            state : {
                task : task
            }
        })
    }

    payLeftAmount = (tasks, uid) => {
        
        this.props.removeNotification({ uid : uid })
        this.props.history.push({
            pathname: '/task/payment',
            state: { tasks : tasks }
        })
    }

    clickToggler = () => {
        if(!$('.navbar-toggler').hasClass('collapsed')){
            $('.navbar-toggler').click();
        }
    }

    render() {

        const { auth, userProfile, authError } = this.props;
        const { newInProgressTasks, newCompletedTasks } = this.props;
        const { hasBasketTasks } = this.props;
        const { isMobileView } = this.props;
        const { currentLanguage } = this.props;
        const { notifications } = this.props;
        
        const forReviewNotifications  = notifications.filter( n => n.notificationType === 4);
        const inProgressNotifications  = notifications.filter( n => n.notificationType === 5);
        const { t } = this.props;
    
        let links;  

        if(!userProfile.hasOwnProperty('uid')){
            links = ""
        }
        

        if(auth.uid){

            if(auth.emailVerified){
                if(userProfile.hasOwnProperty('uid')){
                    if(userProfile.type==="translator" && userProfile.status==="pending"){
                        links = <LoggedOutLinks/>
                    }
                    if(userProfile.type==="customer" || (userProfile.type==="translator" && userProfile.status!=="pending")){
                        links = <LoggedInLinks/>
                    }
                    if(userProfile.status==="pending_signup"){
                        links = <LoggedOutLinks/>
                    }
                }
            }else{
                if(userProfile.hasOwnProperty('uid') && (userProfile.loginType==="facebook" || userProfile.loginType==="google")){
                    if(userProfile.type==="translator" && userProfile.status==="pending"){
                        links = <LoggedOutLinks/>
                    }
                    if(userProfile.type==="customer" || (userProfile.type==="translator" && userProfile.status!=="pending")){
                        links = <LoggedInLinks/>
                    }
                    if(userProfile.status==="pending_signup"){
                        links = <LoggedOutLinks/>
                    }
                }
            }
        }else{
            links = <LoggedOutLinks/>
        }


        return (
            <div>
                <nav style={{ padding : isMobileView ? "15px" : "16px 40px" }} className="navbar navbar-expand-lg fixed-top">
                    <img style={{ height : "44px" }} className="navbar-logo" src={ require('../../images/tarjemle-logo.png') } />
                    {
                        (isMobileView && auth.uid && userProfile.type==="customer" && userProfile.status!=="pending_signup") ? 
                        <div style={{ position : "absolute", top : "10px", right : currentLanguage === "ar" ? "unset":"80px", left : currentLanguage === "ar" ? "80px":"unset" }}>
                        { hasBasketTasks && 
                            <img style={{ width: "10px", position : "absolute", top : "15px", right : "20px" }} src={require('../../images/icon-archived.png')}/> 
                        }
                        <Link onClick={this.clickToggler} className="header-nav-link nav-link" to="/tasks/basket"><img style={{ width: "60%" }} src={ require('../../images/basket.png') }/></Link>
                        </div> : 
                        ""
                        
                    }
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="line top-bar"></span>
                        <span class="line middle-bar"></span>
                        <span class="line bottom-bar"></span>
                    </button>
                    { links }
                </nav>
                {
                    forReviewNotifications && forReviewNotifications.map( (data,i ) => (
                        <TaskForReview
                            t={t}
                            isOpened={true}
                            uid={data.uid}
                            task={JSON.parse(data.data)}
                            key={i}
                            closeNotificationModal={this.closeNotificationModal}
                            seeResults={this.seeResults}
                            addModal={this.props.addModal}
                            popModal={this.props.popModal}
                            modalInstances={this.props.modalInstances}
                            currentLanguage={currentLanguage}
                            isMobileView={isMobileView}
                        />
                    ))
                }
                {
                    inProgressNotifications && inProgressNotifications.map( (data, i) => (
                        <TaskInProgress
                            t={t}
                            isOpened={true}
                            uid={data.uid}
                            task={JSON.parse(data.data)}
                            key={i}
                            closeNotificationModal={this.closeNotificationModal}
                            payLeftAmount={this.payLeftAmount}
                            getTaskTransactions={this.props.getTaskTransactions}
                            currentLanguage={currentLanguage}
                            isMobileView={isMobileView}
                            addModal={this.props.addModal}
                            popModal={this.props.popModal}
                            modalInstances={this.props.modalInstances}
                        />
                    ))
                }
                {/* {
                    newInProgressTasks.map( (data, i) => (
                        <TaskInProgress
                            isOpened={true}
                            task={data}
                            key={i}
                            closeNewTaskInProgressModal={this.closeNewTaskInProgressModal}
                            addModal={this.props.addModal}
                            popModal={this.props.popModal}
                            modalInstances={this.props.modalInstances}
                        />
                    ))
                }
                {
                    newCompletedTasks.map( (data, i) => (
                        <TaskCompleted
                            isOpened={true}
                            task={data}
                            key={i}
                            closeNewTaskCompletedModal={this.closeNewTaskCompletedModal}
                            addModal={this.props.addModal}
                            popModal={this.props.popModal}
                            modalInstances={this.props.modalInstances}
                        />
                    ))
                } */}
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        authError : state.auth.authError,
        userProfile : state.firebase.profile,     
        hasBasketTasks: state.task.hasBasketTasks,   
        newInProgressTasks : state.task.newInProgressTasks,
        newCompletedTasks : state.task.newCompletedTasks,
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage,
        notifications : state.notification.notifications
    }
}

export default compose (
    withRouter,
    translate,
    connect(mapStateToProps, {
        watchNewInProgressTasks,
        removeNewInProgressDisplay,
        watchNewCompletedTasks,
        removeNewCompletedDisplay,
        addModal,
        popModal,
        watchNotifications,
        removeNotification,
        getTaskTransactions
    })
)(Header)
