import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import authReducer from './authReducer';
import taskReducer from './taskReducer';
import additionalServiceReducer from './additionalServiceReducer';
import conversationReducer from './conversationReducer';
import termsAndConditionsReducer from './termsAndConditionsReducer';
import appReducer from './appReducer';
import notificationReducer from './notificationReducer'

export default combineReducers({
    auth : authReducer,
    task : taskReducer,
    additionalServices : additionalServiceReducer,
    conversation : conversationReducer,
    termsAndConditions : termsAndConditionsReducer,
    app: appReducer,
    notification : notificationReducer,
    notification : notificationReducer,
    firestore : firestoreReducer,
    firebase : firebaseReducer
})