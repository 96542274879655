import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';

import {
    addModal,
    popModal
} from '../../../actions/appAction';

export class NotifyAccountManagerModal extends Component {

    constructor(){
        super();
        this.state = {
            notifyReason : "",
            isReasonEmpty : false,
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#notifyAccountManagerModal').modal({backdrop:'static', keyboard:false});
            $('#notifyAccountManagerModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#notifyAccountManagerModal').modal('show');
            this.props.addModal('notify-account-manager-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("nam-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="notify-account-manager-modal");
            if(modalInstance!==undefined){
                $('.nam-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#notifyAccountManagerModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }

    onInputChange = (e) => {

        if(e.target.value.trim().length!==0){
            this.setState({
                isReasonEmpty : false
            })
        }

        this.setState({
            notifyReason : e.target.value
        })
    }

    notifyAccountManager = () => {

        const { notifyReason } = this.state;

        if(notifyReason.trim().length!==0){
            this.props.notifyAccountManager({
                notifyReason
            })
        }else{
            this.setState({
                isReasonEmpty : true
            })
        }
        
    }

    render(){

        const { isReasonEmpty, modalZ } = this.state;

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t } = this.props;

        return (
            <div  style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} notify-account-manager-modal modal fade modal-opaque`} id="notifyAccountManagerModal" tabIndex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeNotifyAccountManagerModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="custom-modal-header modal-header">
                                <button onClick={this.props.closeNotifyAccountManagerModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        <div className="modal-body">
                            <p className="text-header">{t('task_modal_notify_header')}</p>
                            <p className="text-sub-header" style={{ fontSize : "14px !important", marginTop : "32px" }}>{t('task_modal_notify_sub_header')}</p>
                            <div className="form-group">
                                <input onChange={this.onInputChange} id="reason" name="reason" style={{ width: "100%" }} className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder={t('common_write_reason')}/>
                            </div>

                            <div className="text-center">
                                <button onClick={this.notifyAccountManager} style={{ width: "100%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('task_modal_notify_send')}</button>    
                            </div>                            
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView
    }
}

export default compose(
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(NotifyAccountManagerModal)