import React, { Component } from 'react'
import moment from 'moment'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

export class Message extends Component {

    render () {

        const { isMobileView } = this.props;
        const { lastOpenedDate, message, auth, activeConversation } = this.props;
        const { t, currentLanguage } = this.props;
        
        const other = Object.keys(activeConversation.lastOpenedDates).find( f => f!==auth.uid);

        let seen;
      
        if(message.dateAdded.seconds !== undefined){
            
            if(activeConversation.lastOpenedDates[other]!==undefined){
                if(message.dateAdded.seconds<=activeConversation.lastOpenedDates[other].seconds){
                    seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} />{t('common_seen')}</span>
                }
            }

            // if(lastOpenedDate!==null && lastOpenedDate.seconds!== undefined){
            //     if(message.dateAdded.seconds<=lastOpenedDate.seconds){
            //         seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} />{t('common_seen')}</span>
            //     }
            // }
        }

        if(message.dateAdded._seconds !== undefined){

            if(activeConversation.lastOpenedDates[other]!==undefined){
                if(message.dateAdded._seconds<=activeConversation.lastOpenedDates[other]._seconds){
                    seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} />{t('common_seen')}</span>
                }
            }

            // if(lastOpenedDate!==null && lastOpenedDate._seconds!== undefined){
            //     if(message.dateAdded._seconds<=lastOpenedDate._seconds){
            //         seen = <span style={{ fontSize : "12px", color : "#E6E4E8", marginLeft : "5px" }}><img src={require('../../images/icon-seen.png')} />{t('common_seen')}</span>
            //     }
            // }
        }
       
        let messageStyle = {
            background : "#E6E4E8",
            color : "#414042"
        }

        let senderAvatarStyle = {
            marginLeft: "10px"
        }

        let flexDirection = {
            flexDirection : "row-reverse"
        }

        let messageFlexDirection = {
            flexDirection : "row"
        }

        let chatAvatar = "";
        if(auth.uid!==message.sentBy){
            messageStyle = {
                background : "#22C9C7",
                color : "#FFF"
            }

            flexDirection = {
                flexDirection : "row"
            }

            messageFlexDirection = {
                flexDirection : "row-reverse"
            }

            senderAvatarStyle = {
                marginRight : currentLanguage === "ar" ? "unset" : "10px",
                marginLeft : currentLanguage === "ar" ? "10px" : "unset",
            }

            chatAvatar = message.senderImage
        }else{
            senderAvatarStyle = {
                marginRight : currentLanguage === "ar" ? "10px" : "unset",
                marginLeft : currentLanguage === "ar" ? "unset" : "10px",
            }

            chatAvatar = message.senderImage
        }

        return (
            <div className={ isMobileView ? "mobile-message-item animated fadeIn" : "message-item animated fadeIn "} style={flexDirection}>
                {
                    !isMobileView && <div className="message-sender-avatar">
                        <img style={senderAvatarStyle} src={ chatAvatar === "" ? require('../../images/default-user.jpg') : chatAvatar }/>
                    </div>
                }
                <div className="message-details">
                    <img style={{ opacity : isMobileView ? "1" : "0", position : "absolute", bottom : auth.uid!==message.sentBy ? "unset" : "35px" , right : auth.uid!==message.sentBy ? "unset" : "10px", left : auth.uid!==message.sentBy ? "10px" : "unset", top : auth.uid!==message.sentBy ? "-10px" : "unset" }} src={ auth.uid!==message.sentBy ? require('../../images/chat-tail-received.png') : require('../../images/chat-tail-sent.png') }/>
                    <div className="message-bubble" style={messageStyle}>
                        <p className="align-left">{message.text}</p>
                        <ul className="message-files" >
                        {
                            message.attachedFiles.length!==0  &&
                            message.attachedFiles.map( (data,i) => (
                                <li key={i}>
                                    <a style={{ color : auth.uid!==message.sentBy ? "#FFFFF" : "#414042" }} download target="_blank" href={data.downloadUrl} className="message-file"> <img style={{ width: "10px", height : "10px", marginRight : "10px" }} src={require('../../images/icon-task-file.png')}/>{data.fileName}</a>
                                </li>
                            ))
                           
                        }
                        </ul>   
                      
                    </div>
                    <div className="message-date align-right">
                        <span style={{ marginRight : "30px" }}>{ moment.unix(message.dateAdded.seconds).format("hh:mm , MMM D, YYYY") }</span>
                        { message.sentBy===auth.uid && seen }
                    </div>
                </div>
               
            </div>
           
        )

    }

}

export default Message
