import moment from 'moment'
import exactMath from 'exact-math'

import {
    GET_PUBLISHED_TASKS,
    GET_PUBLISHED_TASKS_ERROR,
    GET_PUBLISHED_TASKS_SUCCESS,
    LOAD_MORE_PUBLISHED_TASKS,
    LOAD_MORE_PUBLISHED_TASKS_ERROR,
    LOAD_MORE_PUBLISHED_TASKS_SUCCESS,
    GET_BASKET_TASKS,
    GET_BASKET_TASKS_ERROR,
    GET_BASKET_TASKS_SUCCESS,
    GET_BASKET_COUNT,
    GET_BASKET_COUNT_SUCCESS,
    GET_BASKET_COUNT_ERROR,
    LOAD_MORE_BASKET_TASKS,
    LOAD_MORE_BASKET_TASKS_ERROR,
    LOAD_MORE_BASKET_TASKS_SUCCESS,
    GET_T_INPROGRESS_TASKS,
    GET_T_INPROGRESS_TASKS_ERROR,
    GET_T_INPROGRESS_TASKS_SUCCESS,
    LOAD_MORE_T_INPROGRESS_TASKS,
    LOAD_MORE_T_INPROGRESS_TASKS_ERROR,
    LOAD_MORE_T_INPROGRESS_TASKS_SUCCESS,
    GET_ARCHIVED_TASKS,
    GET_ARCHIVED_TASKS_SUCCESS,
    GET_ARCHIVED_TASKS_ERROR,
    LOAD_MORE_ARCHIVED_TASKS,
    LOAD_MORE_ARCHIVED_TASKS_SUCCESS,
    LOAD_MORE_ARCHIVED_TASKS_ERROR,
    GET_ARCHIVED_TASKS_PAGINATE,
    GET_ARCHIVED_TASKS_PAGINATE_SUCCESS,
    GET_ARCHIVED_TASKS_PAGINATE_ERROR,
    GET_TRANSLATOR_TASKS,
    GET_TRANSLATOR_TASKS_SUCCESS,
    GET_TRANSLATOR_TASKS_ERROR,
    UPLOAD_TASK_FILE,
    UPLOADING_TASK_FILE,
    UPLOADING_TASK_FILE_ERROR,
    UPLOADING_TASK_FILE_SUCCESS,
    REMOVE_TASK_FILE,
    REMOVE_TASK_FILE_ERROR,
    REMOVE_TASK_FILE_SUCCESS,
    DISPLAY_NEW_TASK_INPROGRESS,
    REMOVE_NEW_TASK_INPROGRESS,
    DISPLAY_NEW_TASK_COMPLETED,
    REMOVE_NEW_TASK_COMPLETED,
    SAVE_TASK_CREATION_STATE,
    CLEAR_TASK_CREATION_STATE,
    SET_UPLOAD_TASK_FILES,
    RESET_UPLOAD_TASK_FILES,
    GET_PREPAID_TASKS,
    GET_PREPAID_TASKS_ERROR,
    GET_PREPAID_TASKS_SUCCESS,
    LOAD_MORE_PREPAID_TASKS,
    LOAD_MORE_PREPAID_TASKS_ERROR,
    LOAD_MORE_PREPAID_TASKS_SUCCESS,
    GET_FULLY_PAID_TASKS,
    GET_FULLY_PAID_TASKS_ERROR,
    GET_FULLY_PAID_TASKS_SUCCESS,
    LOAD_MORE_FULLY_PAID_TASKS,
    LOAD_MORE_FULLY_PAID_TASKS_ERROR,
    LOAD_MORE_FULLY_PAID_TASKS_SUCCESS,
    GET_PREPAID_TASKS_TRANSLATOR,
    GET_PREPAID_TASKS_TRANSLATOR_ERROR,
    GET_PREPAID_TASKS_TRANSLATOR_SUCCESS,
    LOAD_MORE_PREPAID_TASKS_TRANSLATOR,
    LOAD_MORE_PREPAID_TASKS_TRANSLATOR_ERROR,
    LOAD_MORE_PREPAID_TASKS_TRANSLATOR_SUCCESS,
    GET_FULLY_PAID_TASKS_TRANSLATOR,
    GET_FULLY_PAID_TASKS_TRANSLATOR_ERROR,
    GET_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS,
    LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR,
    LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_ERROR,
    LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS,
    GET_TRANSLATOR_BALANCE,
    GET_TRANSLATOR_BALANCE_SUCCESS,
    GET_TRANSLATOR_BALANCE_ERROR,
    RESET_CUSTOMER_OUTCOME_REPORTS_FILTER,
    FILTER_CUSTOMER_OUTCOME_REPORTS,
    RESET_CUSTOMER_OUTCOME_REPORTS_PAYMENT_FILTER,
    FILTER_PAYMENT_CUSTOMER_OUTCOME_REPORTS,
    RESET_TRANSLATOR_INCOME_REPORTS_FILTER,
    FILTER_TRANSLATOR_INCOME_REPORTS,
    CHECK_TRANSLATOR_BALANCE,
    PAY_TRANSLATOR,
    PAY_TRANSLATOR_SUCCESS,
    PAY_TRANSLATOR_ERROR,
    RESET_TRANSLATOR_BALANCE,
    REMOVE_UPLOAD_TASK_FILES
} from './types'

/**
 * CUSTOMER PUBLISHED TASKS
 */
export const getPublishedTasks = (firstLoad) => (dispatch, getState, { getFirebase, getFirestore }) => {

    const taskState = getState().task;
    const statePublishedTasks = taskState.publishedTasks;
   
    const firestore = getFirestore();
    const firebase = getFirebase();

    dispatch({
        type : GET_PUBLISHED_TASKS
    })

    let tasksRef = firestore.collection('tasks').where('customer','==',firebase.auth().currentUser.uid)
                             .where('isDeleted','==',false)
                             .where('isArchived','==',false)
                             .where('isUnpaid','==',false)

    if(taskState.statusFilter!=="all"){

        if(taskState.statusFilter==="inProgress" || taskState.statusFilter==="forReview"){
            tasksRef = tasksRef.where('status','in',['inProgress','forReview','new']);
        }else{
            tasksRef = tasksRef.where('status','==',taskState.statusFilter);
        }
    }else{
        tasksRef = tasksRef.where('status','in',['completed','inProgress','forReview','new'])
    }

    if(taskState.typeFilter==="unpaid"){            
        tasksRef = tasksRef.where('paymentStatus','==',"prepaid")
    }
    
    if(taskState.typeFilter==="paid"){
        tasksRef = tasksRef.where('paymentStatus','==',"fullyPaid")
    }

    tasksRef.orderBy('dateAdded','desc').limit(3).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : GET_PUBLISHED_TASKS_SUCCESS,
                payload : {
                    firstLoad : firstLoad,
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_PUBLISHED_TASKS_ERROR
            })
        })
}

export const loadMorePublishedTasks = () => (dispatch, getState, { getFirebase , getFirestore }) => {

    dispatch({
        type : LOAD_MORE_PUBLISHED_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;

    const publishedTasksRef = taskState.publishedTasksRef;
    const lastRef = publishedTasksRef.length === 0 ? null : publishedTasksRef[publishedTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks').where('customer','==',firebase.auth().currentUser.uid)
                                 .where('isDeleted','==',false)
                                 .where('isArchived','==',false)
                                 .where('isUnpaid','==',false)

        if(taskState.statusFilter!=="all"){

            if(taskState.statusFilter==="inProgress" || taskState.statusFilter==="forReview"){
                tasksRef = tasksRef.where('status','in',['inProgress','forReview','new']);
            }else{
                tasksRef = tasksRef.where('status','==',taskState.statusFilter);
            }
        }else{
            tasksRef = tasksRef.where('status','in',['completed','inProgress','forReview','new'])
        }

        if(taskState.typeFilter==="unpaid"){            
            tasksRef = tasksRef.where('paymentStatus','==',"prepaid")
        }
        
        if(taskState.typeFilter==="paid"){
            tasksRef = tasksRef.where('paymentStatus','==',"fullyPaid")
        }

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : LOAD_MORE_PUBLISHED_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_PUBLISHED_TASKS_ERROR
                })
            })

    }

}

export const watchPublishedTasks = () => ( dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const unsubscribeWatchPublishedTasks = firestore.collection('tasks')
        .where('customer','==',firebase.auth().currentUser.uid)
        .onSnapshot( querySnapshot => {
            
            querySnapshot.docChanges().forEach( change => {
                if(change.type==="modified"){
                    dispatch({
                        type : "RELOAD_PUBLISHED_TASK",
                        payload : {
                            task : change.doc.data(),
                            taskRef : change.doc
                        }
                    })
                }
            })
        })

    return unsubscribeWatchPublishedTasks

}

export const filterPublishedTasks = (status, type) => (dispatch) => {
    dispatch({
        type : "FILTER_PUBLISHED_TASKS",
        payload : {
            status,
            type
        }
    })
}

export const getBasketCount = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_BASKET_COUNT
    })
    const firestore = getFirestore();
    const firebase = getFirebase();

    let tasksRef = firestore.collection('tasks')
                            .where('customer','==',firebase.auth().currentUser.uid)
                            .where('isDeleted','==',false)
                            .where('isUnpaid','==',true);

    tasksRef.orderBy('dateAdded','desc').get()
        .then( (querySnapShots) => {
            
            dispatch({
                type : GET_BASKET_COUNT_SUCCESS,
                payload : {
                    count : querySnapShots.docs.length
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_BASKET_COUNT_ERROR
            })
        })

}

export const watchBasketTasks = () => (dispatch, getState, { getFirebase, getFirestore  }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();
    
    const unsubscribeWatchBasketTasks = firestore.collection('tasks')
        .orderBy('dateAdded','desc')
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==="modified"){

                    dispatch({
                        type : GET_BASKET_COUNT
                    })
                    const firestore = getFirestore();
                    const firebase = getFirebase();
                
                    let tasksRef = firestore.collection('tasks')
                                            .where('customer','==',firebase.auth().currentUser.uid)
                                            .where('isDeleted','==',false)
                                            .where('isUnpaid','==',true);
                
                    tasksRef.orderBy('dateAdded','desc').get()
                        .then( (querySnapShots) => {
                            
                            dispatch({
                                type : GET_BASKET_COUNT_SUCCESS,
                                payload : {
                                    count : querySnapShots.docs.length
                                }
                            })
                
                        }).catch(error=>{
                            console.log(error)
                
                            dispatch({
                                type : GET_BASKET_COUNT_ERROR
                            })
                        })
                }
            })
        })

    return unsubscribeWatchBasketTasks;

}

/**
 * CUSTOMER BASKET TASKS
 */
export const getBasketTasks = (lastRef = null) => (dispatch, getState, { getFirebase, getFirestore }) => {

    const taskState = getState().task;
    const stateBasketTasks = taskState.basketTasks;
   
    const firestore = getFirestore();
    const firebase = getFirebase();

    dispatch({
        type : GET_BASKET_TASKS
    })

    let tasksRef = firestore.collection('tasks')
                            .where('customer','==',firebase.auth().currentUser.uid)
                            .where('isDeleted','==',false)
                            .where('isUnpaid','==',true);

    tasksRef.orderBy('dateAdded','desc').limit(3).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : GET_BASKET_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_BASKET_TASKS_ERROR
            })
        })
}

export const loadMoreBasketTasks = () => (dispatch, getState, { getFirebase, getFirestore }) => {
    
    dispatch({
        type : LOAD_MORE_BASKET_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;

    const basketTasksRef = taskState.basketTasksRef;
    const lastRef = basketTasksRef.length === 0 ? null : basketTasksRef[basketTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                                .where('customer','==',firebase.auth().currentUser.uid)
                                .where('isDeleted','==',false)
                                .where('isUnpaid','==',true);

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : LOAD_MORE_BASKET_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_BASKET_TASKS_ERROR
                })
            })

    }

}

export const uploadTaskFiles = (files) => ( dispatch, getState, { getFirebase, getFirestore }) => {
    
    dispatch({
        type : UPLOAD_TASK_FILE,
        payload : {
            taskFiles : files
        }
    })

    const firebase = getFirebase();

    for(let i=0; i<files.length; i++){

        setTimeout( () => {
            let newDate = new Date();
        
            let year = newDate.getFullYear();
            let month = newDate.getMonth();
            let da = newDate.getDate();
            let h = newDate.getHours();
            let mm = newDate.getMinutes();
            let ss = newDate.getSeconds();
            let sss = newDate.getMilliseconds();

            if(typeof files[i]!=="string"){

                let file_name = `TaskFile-${year}_${month}_${da}-${h}_${mm}_${ss}_${sss}.${files[i].name.split('.').pop()}`

                firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/newTaskFiles/${file_name}`).put(files[i])
                .on('state_changed', (snapshot) => {

                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    
                    dispatch({
                        type : UPLOADING_TASK_FILE,
                        payload : {
                            up_file : files[i].name,
                            progress : progress
                        }
                    })

                },
                (error) =>{
                    console.log(error);
                    dispatch({
                        type : UPLOADING_TASK_FILE_ERROR,
                        payload : {
                            up_file : files[i].name,
                            error : error.message
                        }
                    })
                },
                async () => {
                    firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/newTaskFiles`).child(file_name).getDownloadURL().then( url => {
                        
                        dispatch({
                            type : UPLOADING_TASK_FILE_SUCCESS,
                            payload : {
                                up_file : files[i].name,
                                url : url
                            }
                        })

                    })
                
                });
            }
        }, 2000)
    }
}

/**
 * TASK CREATION
 */
export const setUploadingTaskFiles = (data) => dispatch => {

    dispatch({
        type : SET_UPLOAD_TASK_FILES,
        payload : {
            taskFiles : data
        }
    })

}

export const removeUploadingTaskFiles = (data) => dispatch => {

    dispatch({
        type : REMOVE_UPLOAD_TASK_FILES,
        payload : {
            taskFile : data
        }
    })

}

export const resetUploadingTaskFiles = () => dispatch => {

    dispatch({
        type : RESET_UPLOAD_TASK_FILES
    })
}


export const removeTaskFile = (file) => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : REMOVE_TASK_FILE
    })

    const firebase = getFirebase();

    return new Promise( (resolve, reject) => {

        if(typeof file === "object"){
            var fileRef = firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/newTaskFiles`).child(file.name)

            fileRef.delete().then( () => {
                dispatch({
                    type : REMOVE_TASK_FILE_SUCCESS,
                    payload : {
                        up_file : file.name
                    }
                })
                resolve("success")
            }).catch( error => {
                console.log(error)
                dispatch({
                    type : REMOVE_TASK_FILE_ERROR
                })
                reject(error)
            })
        }else{

            var fileRef = firebase.storage().refFromURL(file)
            fileRef.delete().then( () => {
                dispatch({
                    type : REMOVE_TASK_FILE_SUCCESS,
                    payload : {
                        up_file : file
                    }
                })
                resolve("success")
            }).catch( error => {
                console.log(error)
                dispatch({
                    type : REMOVE_TASK_FILE_ERROR
                })
                reject(error)
            })
        }

       
    })

}

export const getFileWordCount = (files) => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        var getFileWordCount = firebase.functions().httpsCallable('getFileWordCount');

        getFileWordCount({url : files}).then( (result) => {
            resolve(result)
        }).catch( error => {
            console.log(error);
        })
    })

}

export const getFilesWordCount = (files) => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        var getFilesWordCount = firebase.functions().httpsCallable('getFilesWordCount');

        getFilesWordCount({url : files}).then( (result) => {
            resolve(result)
        }).catch( error => {
            console.log(error);
        })
    })

}

export const computePriceApproximate = (numberOfWords, translationService) => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        var computePriceApproximate = firebase.functions().httpsCallable('computePriceApproximate');
        computePriceApproximate({ numberOfWords : numberOfWords, translationService : translationService })
            .then( (result) => {
                resolve(result)
            })
    })

}

export const prepareTransaction = (data) => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        var prepareTransaction = firebase.functions().httpsCallable('prepareTransaction');
        prepareTransaction(data)
            .then( (result) => {
                resolve(result)
            })
    })

}

export const createTransaction = (data) => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise ( (resolve) => {
        const firebase = getFirebase();

        var createTransaction = firebase.functions().httpsCallable('createTransaction');
        createTransaction(data)
            .then( (result) => {
                resolve(result)
            })
    })

}

export const createTask = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    let orderCountID = moment(new Date()).format('YYYY-MM-DD');

    let countRef = firestore.collection('orderCounts').doc(orderCountID);

    await countRef.get().then( async( querySnapshot) => {

        if( typeof querySnapshot.data() === "undefined"){

            let batch = firestore.batch();

            batch.set(countRef, {
                count : "0000"
            })

            await batch.commit();
        }
    })

    let newCount = await new Promise( resolve => {
        firestore.runTransaction( t => {
    
            return t.get(countRef)
                .then( doc => {

                    let newCount = parseInt(doc.data().count) + 1;
                    newCount = newCount.toString().padStart(4,'0');
                    t.update(countRef, {count: newCount});
                    resolve(newCount);

                });
    
        }).then( result => {
            //console.log(result)
        }).catch( error => {
            console.log(error);
        });
    })
    
    return new Promise ( (resolve, reject) => {

        let docRef = firestore.collection('tasks').doc();

        data.uid = docRef.id;
        data.orderID = newCount + "-" +orderCountID
        data.dateAdded = firestore.Timestamp.now()

        docRef.set({
            ...data,
            accountManager : ""
        }, { merge : true})
        .then( ()=> {

            firebase.analytics().logEvent('new_task', {
                taskUid : data.uid,
                taskType : data.taskType,
                dateAdded : data.dateAdded
            });

            resolve({
                task : data,
                code : "success"
            })
            dispatch({
                type : "CREATE_TASK_SUCCESS"
            })

        }).catch(error => {
            console.log(error);
            reject({
                task : null,
                code : "error"
            })

            dispatch({
                type : "CREATE_TASK_ERROR"
            })
        })
    })
}

export const saveTaskCreationState = (data) => async (dispatch) => {
    dispatch({
        type : SAVE_TASK_CREATION_STATE,
        payload : {
            data : data
        }
    })
}

export const resetTaskCreationState = (data) => async (dispatch) => {
    dispatch({
        type : CLEAR_TASK_CREATION_STATE
    })
}

export const checkTaskStatus = (data) => async (dispatch, getState, { getFirestore, getFirebase}) => {

    return new Promise ( resolve => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let taskRef = firestore.collection('tasks').doc(data.uid)

        taskRef.get().then( doc => {
            if(doc.data()!==undefined){
                resolve(doc.data())
            }
        })

    })

}

export const updateTask = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "UPDATE_TASK"
    })
    
    return new Promise ( (resolve) => {

        const firestore = getFirestore();

        let taskRef = firestore.collection('tasks').doc(data.uid);

        firestore.runTransaction( t => {
            return t.get(taskRef)
                    .then( doc => {
                        t.update(taskRef, data)                
                    })
        }).then( (result) => {
            
            dispatch({
                type : "UPDATE_TASK_SUCCESS"
            })

            taskRef = firestore.collection('tasks').doc(data.uid);

            taskRef.get().then( querySnapshot => {
                resolve({
                    data : querySnapshot.data(),
                    code : 200
                })
               
            })

           
        }).catch( error => {
            console.log(error)
            dispatch({
                type : "UPDATE_TASK_ERROR"
            })
            
            resolve({
                code : 500
            })
        })

    })
}

export const declineAssignedTask = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {
    
    dispatch({
        type : "UPDATE_TASK"
    })
    
    return new Promise ( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let taskRef = firestore.collection('tasks').doc(data.uid);

        firestore.runTransaction( t => {
            return t.get(taskRef)
                    .then( doc => {

                        let newInvitedTranslators = [...doc.data().invitedTranslators];
                        let userIndex = newInvitedTranslators.indexOf(firebase.auth().currentUser.uid)

                        newInvitedTranslators.splice(userIndex, 1);

                        let declineReason = {};

                        if(doc.data().hasOwnProperty('declineReason')){
                            declineReason = {
                                ...doc.data().declineReason,
                                [firebase.auth().currentUser.uid] : data.declineReason
                            }
                        }else{
                            declineReason = {
                                [firebase.auth().currentUser.uid] : data.declineReason
                            }
                        }

                        t.update(taskRef, {
                            ...data,
                            declineReason : declineReason,
                            invitedTranslators : newInvitedTranslators
                        })                
                    })
        }).then( (result) => {
            
            dispatch({
                type : "UPDATE_TASK_SUCCESS"
            })

            taskRef = firestore.collection('tasks').doc(data.uid);

            taskRef.get().then( querySnapshot => {
                resolve({
                    data : querySnapshot.data(),
                    code : 200
                })
               
            })

           
        }).catch( error => {
            console.log(error)
            dispatch({
                type : "UPDATE_TASK_ERROR"
            })
            
            resolve({
                code : 500
            })
        })

    })
}

// export const getCustomerArchiveTasks = (lastRef = null) => async (dispatch, getState, { getFirebase, getFirestore }) => {

//     dispatch({
//         type : "GET_ARCHIVED_TASKS"
//     })

//     return new Promise ( async (resolve, reject) => {

//         const firestore = getFirestore();
//         const firebase = getFirebase();

//         let archivedTasks = firestore.collection('tasks')
//                                 .where('customer','==',firebase.auth().currentUser.uid)
//                                 .where('isDeleted','==',false)
//                                 .where('isArchived','==',true)

//         archivedTasks = archivedTasks.orderBy('dateAdded','desc')

//         archivedTasks.get()
//             .then( (querySnapShots) => {

//                 let taskList = [];

//                 querySnapShots.forEach( doc => {
//                     taskList.push(doc.data())
//                 })

//                 resolve(true);

//                 dispatch({
//                     type : "GET_ARCHIVED_TASKS_SUCCESS",
//                     payload : {
//                         data : [...taskList]
//                     }
//                 })
//             })

//     }).catch( (error) => {
//         dispatch({
//             type : "GET_ARCHIVED_TASKS_ERROR"
//         })
//     })

// }

/**
 * CUSTOMER ARCHIVED TASKS
 */
export const getArchivedTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {
    
    dispatch({
        type : GET_ARCHIVED_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;

    let tasksRef = firestore.collection('tasks')
                .where('customer','==',firebase.auth().currentUser.uid)
                .where('status','in',['stopped','paused','declined'])
                .where('isArchived','==',true)

    tasksRef.orderBy('dateAdded','desc').limit(3).get()
    .then( (querySnapShots) => {

        let taskList = [];
        let taskRefList = [];

        querySnapShots.forEach( doc => {
            taskList.push(doc.data())
            taskRefList.push(doc);
        })

        dispatch({
            type : GET_ARCHIVED_TASKS_SUCCESS,
            payload : {
                tasks : taskList,
                tasksRef : taskRefList
            }
        })

    })
}

export const loadMoreArchivedTasks = () => (dispatch, getState, { getFirebase , getFirestore }) => {

    dispatch({
        type : LOAD_MORE_ARCHIVED_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;

    const archivedTasksRef = taskState.archivedTasksRef;
    const lastRef = archivedTasksRef.length === 0 ? null : archivedTasksRef[archivedTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                            .where('customer','==',firebase.auth().currentUser.uid)
                            .where('status','in',['stopped','paused','declined'])
                            .where('isArchived','==',true)

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : LOAD_MORE_ARCHIVED_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_ARCHIVED_TASKS_ERROR
                })
            })

    }


}


export const getTaskTransactions = (taskUid) => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : "GET_TASK_TRANSACTIONS"
    })

    return new Promise( (resolve, reject) => {

        const firestore = getFirestore();

        let taskTransactions = [];

        let taskTransactionsRef = firestore.collection('transactions')
                                        .where('task','==',taskUid)
                                        .orderBy('dateAdded','asc')
        
        taskTransactionsRef.get().then( (querySnapshots ) => {

            querySnapshots.forEach( (doc) => {
                taskTransactions.push(doc.data())
            })

            resolve({
                code : 200,
                data : {
                    transactions : [...taskTransactions]
                }
            })

            dispatch({
                type : "GET_TASK_TRANSACTIONS_SUCCESS"
            })

        }).catch( error => {
            console.log(error)
            dispatch({
                type : "GET_TASK_TRANSACTIONS_ERROR"
            })
        })

    })

}

export const setEscalation = (data) => async (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : "CHECK_ESCALATION"
    })

    return new Promise( async (resolve, reject) => {

        const firestore = getFirestore();
        const firebase = getFirebase();
    
        let escalationRef = firestore.collection('escalations').where('task','==',data.taskUid);
        let escalation = null;
        let escalationId = null;

        await escalationRef.get().then( (querySnapshots) => {

            querySnapshots.forEach( (doc) => {
                escalation = doc.data()
            })

        }).catch( error => {
            console.log(error)
            dispatch({
                type : "CHECK_ESCALATION_ERROR"
            })
            resolve({
                code : 500,
                message : error
            })
        })

        dispatch({
            type : "CHECK_ESCALATION_SUCCESS"
        })
        
        if(escalation===null){

            escalationRef = firestore.collection('escalations').doc();
            escalationId = escalationRef.id;

        }else{

            escalationId = escalation.uid
            escalationRef = firestore.collection('escalations').doc(escalation.uid);

        }

        let key = firebase.auth().currentUser.uid;
        let escalationLetter = {
            [key] : data.escalationLetter
        }
        let escalationReason = {
            [key] : data.escalationReason
        }
        let escalatedBy = {
            [key] : true
        }
        
        escalationRef.set({
            isResolved : false,
            dateAdded : firestore.Timestamp.now(),
            uid : escalationId,
            task : data.taskUid,
            operator : data.operator,
            escalationReason : escalation === null ? {...escalationReason} : {...escalation.escalationReason, ...escalationReason},
            escalationLetter : escalation === null ? {...escalationLetter} : {...escalation.escalationLetter, ...escalationLetter},
            escalatedBy : escalation === null ?  {...escalatedBy} : {...escalation.escalatedBy, ...escalatedBy}
        }, { merge : true }).then( () => {

            dispatch({
                type : "SET_ESCALATION_SUCCESS"
            })

            resolve({
                code : 200,
                message : "success"
            })
        })




    })

}

export const createRating = (ratings) => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : "CREATE_RATING"
    })

    return new Promise( async (resolve, reject) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let result = [];

        for(let i=0; i<ratings.length; i++){

            let ratingRef = firestore.collection('ratings').where('recipient','==',ratings[i].recipient)
                                                            .where('task','==',ratings[i].taskUid)

            let userRating = null;

            await ratingRef.get().then( (querySnapshot) => {
                querySnapshot.forEach( (doc) => {
                    userRating = doc.data();
                })
            })

            if(userRating!==null){
                dispatch({
                    type : "CREATE_RATING_ERROR"
                })
                result.push({
                    code : 500,
                    message : "User already rated"
                })

            }else{

                ratingRef = firestore.collection('ratings').doc();

                await ratingRef.set({
                    uid : ratingRef.id,
                    recipient : ratings[i].recipient,
                    task : ratings[i].taskUid,
                    role : ratings[i].userRole,
                    dateAdded : firestore.Timestamp.now(),
                    rating : ratings[i].rating,
                    feedback : ratings[i].feedback
                }, { merge : true }).then ( () => {

                    result.push({
                        code : 200,
                        uid : ratingRef.id,
                        message : "Rating saved."
                    })

                    dispatch({
                        type : "CREATE_RATING_SUCCESS"
                    })
                }).catch( error => {
                    console.log(error)
                    dispatch({
                        type : "CREATE_RATING_ERROR"
                    })
                    result.push({
                        code : 500,
                        message : error
                    })
                })

            }

        }
        
        resolve(result)
    })

}

export const getTaskRatings = (data) => ( dispatch, getState, { getFirebase, getFirestore}) => {

    dispatch({
        type : "GET_TASK_RATINGS"
    })

    return new Promise( (resolve, reject) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let ratingsRef = firestore.collection('ratings').where('task','==',data);

        let ratings = [];

        ratingsRef.get().then( (querySnapshot) => {

            querySnapshot.forEach( (doc) => {
                ratings.push(doc.data())
            })

            dispatch({
                type : "GET_TASK_RATINGS_SUCCESS"
            })

            resolve({
                code : 200,
                data : ratings,
                message : "success"
            })
        }).catch( error => {
            dispatch({
                type : "GET_TASK_RATINGS_ERROR"
            })

            resolve({
                code : 500,
                message : error
            })
        })

    })

}

/**
 * TRANSLATOR TASKS
 */

export const getAssignedTasks = (lastRef = null) => (dispatch, getState , { getFirebase, getFirestore }) => {

    dispatch({
        type : "GET_ASSIGNED_TASKS"
    })

    const taskState = getState().task;
    const lastRef = taskState.assignedTasksLastRef;
   
    const firestore = getFirestore();
    const firebase = getFirebase();

    let tasksRef = firestore.collection('tasks')
                                    .where('invitedTranslators','array-contains',firebase.auth().currentUser.uid)
                                    .where('isDeleted','==',false)
                                    .where('isArchived','==',false)
                                    .where('status','==','new')

    if(lastRef!==null){
        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()     
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : "GET_ASSIGNED_TASKS_PAGINATE_SUCCESS",
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })
        })

    }else{

        tasksRef.orderBy('dateAdded','desc').limit(3).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : "GET_ASSIGNED_TASKS_SUCCESS",
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        })
    }

}

export const watchAssignedTasks = (data) => (dispatch, getState, { getFirebase, getFirestore  }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();
    
    const unsubscribeWatchAssignedTasks = firestore.collection('tasks')
        .orderBy('dateAdded','desc')
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==="modified"){
                    dispatch({
                        type : "RELOAD_ASSIGNED_TASK",
                        payload : {
                            task : change.doc.data(),
                            taskRef : change.doc,
                            userUid : data.authUid
                        }
                    })
                }
            })
        })

    return unsubscribeWatchAssignedTasks;

}

export const clearAssignedTasksCount = () => (dispatch) => {
    dispatch({
        type : "CLEAR_ASSIGNED_TASKS_COUNT"
    })
}

export const getInProgressTasks = (firstLoad) => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_T_INPROGRESS_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    let tasksRef = firestore.collection('tasks')
                                     .where('assignedTranslator','==',firebase.auth().currentUser.uid)
                                     .where('isDeleted','==',false)
                                     .where('isArchived','==',false)
                                     .where('status','in',['inProgress','forReview'])

    tasksRef.orderBy('dateAdded','desc').limit(3).get()
    .then( (querySnapShots) => {

        let taskList = [];
        let taskRefList = [];

        querySnapShots.forEach( doc => {
            taskList.push(doc.data())
            taskRefList.push(doc);
        })

        dispatch({
            type : GET_T_INPROGRESS_TASKS_SUCCESS,
            payload : {
                tasks : taskList,
                tasksRef : taskRefList,
                firstLoad : firstLoad
            }
        })

    })
}

export const loadMoreInprogressTasks = () => ( dispatch, getState, { getFirebase, getFirestore }) => {
 
    dispatch({
        type : LOAD_MORE_T_INPROGRESS_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;

    const tInProgressTasksRef = taskState.tInProgressTasksRef;
    const lastRef = tInProgressTasksRef.length === 0 ? null : tInProgressTasksRef[tInProgressTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                            .where('assignedTranslator','==',firebase.auth().currentUser.uid)
                            .where('isDeleted','==',false)
                            .where('isArchived','==',false)
                            .where('status','in',['inProgress','forReview'])

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : LOAD_MORE_T_INPROGRESS_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_T_INPROGRESS_TASKS_ERROR
                })
            })

    }



}

export const watchTinProgressTasks = (data) => (dispatch, getState, { getFirebase, getFirestore  }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const unsubscribeWatchTinProgressTasks = firestore.collection('tasks')
        .orderBy('dateAdded','desc')
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==="modified"){
                    dispatch({
                        type : "RELOAD_TINPROGRESS_TASK",
                        payload : {
                            task : change.doc.data(),
                            taskRef : change.doc,
                            userUid : data.authUid
                        }
                    })
                }
            })
        })

    return unsubscribeWatchTinProgressTasks;

}

export const getTranslatorArchiveTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {
    
    dispatch({
        type : GET_ARCHIVED_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const lastRef = taskState.archivedTasksLastRef;

    let tasksRef = firestore.collection('tasks')
                .where('assignedTranslator','==',firebase.auth().currentUser.uid)
                .where('status','in',['stopped','paused','declined'])
                .where('isArchived','==',true)

    if(lastRef!==null){
        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : GET_ARCHIVED_TASKS_PAGINATE_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            })

    }else{

        tasksRef.orderBy('dateAdded','desc').limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : GET_ARCHIVED_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            })
    }

}

export const uploadResultFiles = (taskUid, files) => ( dispatch, getState, { getFirebase, getFirestore }) => {
    
    const firebase = getFirebase();

    return new Promise( async (resolve, reject) => {

        let resultFileURL = [];
        for(let i=0; i<files.length; i++){

            let newDate = new Date();
          
            let year = newDate.getFullYear();
            let month = newDate.getMonth();
            let da = newDate.getDate();
            let h = newDate.getHours();
            let mm = newDate.getMinutes();
            let ss = newDate.getSeconds();


            if(typeof files[i]!=="string"){

                let file_name = `TaskFile-${year}_${month}_${da}-${h}_${mm}_${ss}.${files[i].name.split('.').pop()}`

                let x = await new Promise ( (resolve) => {
                    firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/tasks/${taskUid}/resultFiles/${file_name}`).put(files[i])
                    .on('state_changed', (snapshot) => {
                    },
                    (error) =>{
                        resolve({
                            code : 500,
                            data : null
                        })
                    },
                    async () => {
                        firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/tasks/${taskUid}/resultFiles`).child(file_name).getDownloadURL().then( url => {
                            resolve({
                                code: 200,
                                data : url
                            })
                        })
                    
                    });
                })
                resultFileURL.push(x);    
            }
        }

      
        resolve(
            resultFileURL
        )

    })

   

}

export const removeResultFile = (taskUid, file) => (dispatch, getState, { getFirebase, getFirestore }) => {

    const firebase = getFirebase();

    return new Promise( (resolve, reject) => {

        if(typeof file === "object"){
            var fileRef = firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/tasks/${taskUid}/resultFiles`).child(file.name)

            fileRef.delete().then( () => {
                resolve("success")
            }).catch( error => {
                console.log(error)
                reject(error)
            })
        }else{
            var fileRef = firebase.storage().refFromURL(file)

            fileRef.delete().then( () => {
                resolve("success")
            }).catch( error => {
                console.log(error)
                reject(error)
            })
        }

       
    })

}

export const getTaskDetails = (taskUid) => (dispatch, getState, { getFirebase, getFirestore }) => {

    return new Promise( (resolve, reject) => {

        const firestore = getFirestore();

        firestore.collection('tasks').doc(taskUid)
            .get().then( querySnapshot => {

                resolve(querySnapshot.data())

            })
            .catch( error => {
                console.log(error)
                throw(error)
            })
    })

}

export const saveEarnings = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise( (resolve) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let earningsRef = firestore.collection('earnings').doc();

        earningsRef.set({
            uid : earningsRef.id,
            amount : data.amount,
            tasks : data.tasks,
            translator : firebase.auth().currentUser.uid,
            datePaid : firestore.Timestamp.now(),
            status : "PENDING"
        }, { merge : true}).then( () => {
            dispatch({
                type : RESET_TRANSLATOR_BALANCE
            })
            resolve({
                code : 200,
                message : "Email sent."
            })
           
        }).catch( error => {
            resolve({
                code : 500,
                message : "Something went wrong",
                error : error
            })
        })
    })

}

export const setIncomeReportsFilter = (data) => dispatch => {
    dispatch({
        type : FILTER_TRANSLATOR_INCOME_REPORTS,
        payload : {
            dateFrom : data.dateFrom,
            dateTo : data.dateTo
        }
    })
}

export const resetIncomeReportsFilter = (data) => dispatch =>{
    dispatch({
        type : RESET_TRANSLATOR_INCOME_REPORTS_FILTER
    })
}


export const getTranslatorBalance = () => async (dispatch, getState, { getFirestore, getFirebase }) => {
 
    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    
    dispatch({
        type : GET_TRANSLATOR_BALANCE
    })
        
    let tasksRef = firestore.collection('tasks')
        .where('assignedTranslator','==',firebase.auth().currentUser.uid)
        .where('status','in',['completed'])

    let totalBalance = 0;

    await tasksRef.get().then( (querySnapshots) => {

        querySnapshots.forEach( doc => {
            if(doc.data().isPaidToTranslator===false){
                totalBalance = exactMath.add( totalBalance, doc.data().translatorEarnings)
            }
            
        })

    }).catch( error => {

        dispatch({
            type : GET_TRANSLATOR_BALANCE_ERROR,
            payload : {
                balance : totalBalance
            }
        })

        return;

    })

    dispatch({
        type : GET_TRANSLATOR_BALANCE_SUCCESS,
        payload : {
            balance : totalBalance
        }
    })
}

export const getPrepaidTasksTranslator = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const incomeReportFilters = taskState.incomeReportFilters;
    
    dispatch({
        type : GET_PREPAID_TASKS_TRANSLATOR
    })
        
    let tasksRef = firestore.collection('tasks')
        .where('assignedTranslator','==',firebase.auth().currentUser.uid)
        .where('status','in',['inProgress','forReview'])
    
    tasksRef = tasksRef.orderBy('dateAdded','desc')

    if(incomeReportFilters!==null){
        tasksRef = tasksRef.where('dateAdded','>=',moment(incomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(incomeReportFilters.dateTo).endOf('day').toDate())
    }

    tasksRef.limit(10).get()
    .then( (querySnapShots) => {

        let taskList = [];
        let taskRefList = [];

        querySnapShots.forEach( doc => {
            taskList.push(doc.data())
            taskRefList.push(doc);
        })

        dispatch({
            type : GET_PREPAID_TASKS_TRANSLATOR_SUCCESS,
            payload : {
                tasks : taskList,
                tasksRef : taskRefList
            }
        })

    }).catch(error=>{
        console.log(error)

        dispatch({
            type : GET_PREPAID_TASKS_TRANSLATOR_ERROR
        })
    })
}

export const loadMorePrepaidTasksTranslator = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_PREPAID_TASKS_TRANSLATOR
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;

    const translatorPrepaidTasksRef = taskState.translatorPrepaidTasksRef;
    const incomeReportFilters = taskState.incomeReportFilters;
    const lastRef = translatorPrepaidTasksRef.length === 0 ? null : translatorPrepaidTasksRef[translatorPrepaidTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
            .where('assignedTranslator','==',firebase.auth().currentUser.uid)
            .where('status','in',['inProgress','forReview'])

        if(incomeReportFilters!==null){
            tasksRef = tasksRef.where('dateAdded','>=',moment(incomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(incomeReportFilters.dateTo).endOf('day').toDate())
        }

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : LOAD_MORE_PREPAID_TASKS_TRANSLATOR_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_PREPAID_TASKS_TRANSLATOR_ERROR
                })
            })
    }

}

export const getFullyPaidTasksTranslator = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const incomeReportFilters = taskState.incomeReportFilters;
    
    dispatch({
        type : GET_FULLY_PAID_TASKS_TRANSLATOR
    })
    
    let tasksRef = firestore.collection('tasks')
        .where('assignedTranslator','==',firebase.auth().currentUser.uid)
        .where('status','==','completed')
    
    tasksRef = tasksRef.orderBy('dateAdded','desc')

    if(incomeReportFilters!==null){
        tasksRef = tasksRef.where('dateAdded','>=',moment(incomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(incomeReportFilters.dateTo).endOf('day').toDate())
    }

    tasksRef.limit(10).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : GET_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_FULLY_PAID_TASKS_TRANSLATOR_ERROR
            })
        })
}

export const loadMoreFullyPaidTasksTranslator = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;
    const incomeReportFilters = taskState.incomeReportFilters;
    const translatorFullyPaidTasksRef = taskState.translatorFullyPaidTasksRef;
    const lastRef = translatorFullyPaidTasksRef.length === 0 ? null : translatorFullyPaidTasksRef[translatorFullyPaidTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                                .where('assignedTranslator','==',firebase.auth().currentUser.uid)
                                .where('status','==','completed')

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        if(incomeReportFilters!==null){
            tasksRef = tasksRef.where('dateAdded','>=',moment(incomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(incomeReportFilters.dateTo).endOf('day').toDate())
        }

        tasksRef.startAfter(lastRef).limit(3).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_ERROR
            })
        })
    }
}

export const getAllTranslatorTasks = () => (dispatch, getState, { getFirebase, getFirestore }) => {

    dispatch({
        type : GET_TRANSLATOR_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const incomeReportFilters = taskState.incomeReportFilters;

    let tasksRef = firestore.collection('tasks')
        .where('assignedTranslator','==',firebase.auth().currentUser.uid)
        .where('status','in',['new','inProgress','forReview','completed'])

    tasksRef = tasksRef.orderBy('dateAdded','desc')

    if(incomeReportFilters!==null){
        tasksRef = tasksRef.where('dateAdded','>=',moment(incomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(incomeReportFilters.dateTo).endOf('day').toDate())
    }

    tasksRef.get()
        .then( querySnapshots => {

            let taskList = [];
            let taskRefList = [];

            querySnapshots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })  

            dispatch({
                type : GET_TRANSLATOR_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch( error => {
            console.log(error)

            dispatch({
                type : GET_TRANSLATOR_TASKS_ERROR
            })
        })
}






export const watchAllTranslatorTasks = () => (dispatch, getState, { getFirebase, getFirestore }) => {
    
}

export const watchNewInProgressTasks = () => async (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const unsubscribeWatchNewInProgressTasks = firestore.collection('tasks')
        .where('customer','==',firebase.auth().currentUser.uid)
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( async (change) => {
                
                if((change).type==="modified"){
                    if(change.doc.data().status==="inProgress" &&
                        change.doc.data().assignedTranslator!=="" &&
                        (change.doc.data().hasOwnProperty('resultFiles') && change.doc.data().resultFiles.length===0)){

                            let transactionsRef = firestore.collection('transactions').where('task','==',change.doc.data().uid)
                        
                            let taskData = change.doc.data();
                            taskData.transactions = [];

                            await transactionsRef.get().then( querySnapShots => {
                                querySnapShots.forEach( doc => {
                                    taskData.transactions.push(doc.data())
                                })
                            })

                        dispatch({
                            type : DISPLAY_NEW_TASK_INPROGRESS,
                            payload : {
                                task : taskData,
                                taskRef : change.doc
                            }
                        })
                    }
                }
            })
        })

    return unsubscribeWatchNewInProgressTasks

}

export const removeNewInProgressDisplay = (data) => dispatch => {

    dispatch({
        type : REMOVE_NEW_TASK_INPROGRESS,
        payload : {
            task : data
        }
    })

}

export const watchNewCompletedTasks = () => async (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const unsubscribeWatchNewCompletedTasks = firestore.collection('tasks')
        .where('customer','==',firebase.auth().currentUser.uid)
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( async (change) => {
                
                if((change).type==="modified"){
                    if(change.doc.data().status==="forReview"){
                        
                        let taskData = change.doc.data();
                        dispatch({
                            type : DISPLAY_NEW_TASK_COMPLETED,
                            payload : {
                                task : taskData,
                                taskRef : change.doc
                            }
                        })
                    }
                }
            })
        })

    return unsubscribeWatchNewCompletedTasks

}

export const removeNewCompletedDisplay = (data) => dispatch => {

    dispatch({
        type : REMOVE_NEW_TASK_COMPLETED,
        payload : {
            task : data
        }
    })

}

export const setOutcomeReportsFilter = (data) => dispatch => {
    dispatch({
        type : FILTER_CUSTOMER_OUTCOME_REPORTS,
        payload : {
            dateFrom : data.dateFrom,
            dateTo : data.dateTo
        }
    })
}

export const resetOutcomeReportsFilter = (data) => dispatch =>{
    dispatch({
        type : RESET_CUSTOMER_OUTCOME_REPORTS_FILTER
    })
}

export const setOutcomeReportsPaymentFilter = (data) => dispatch => {
    dispatch({
        type : FILTER_PAYMENT_CUSTOMER_OUTCOME_REPORTS,
        payload : {
            paymentFilter : data.paymentFilter
        }
    })
}

export const resetOutcomeReportsPaymentFilter = (data) => dispatch =>{
    dispatch({
        type : RESET_CUSTOMER_OUTCOME_REPORTS_PAYMENT_FILTER
    })
}


export const getPrepaidTasks = (data) => async (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const statePrepaidTasks = taskState.prepaidTasks;
    const outcomeReportFilters = taskState.outcomeReportFilters;
    const paymentFilter = taskState.paymentFilter;
    
    dispatch({
        type : GET_PREPAID_TASKS
    })
        
    let tasksRef = firestore.collection('tasks')
                                    .where('isDeleted','==',false)
                                    .where('isArchived','==',false)
                                    .where('customer','==',firebase.auth().currentUser.uid)
                                    .where('paymentStatus','==','prepaid')
    
    tasksRef = tasksRef.orderBy('dateAdded','desc')

    if(outcomeReportFilters!==null){
        tasksRef = tasksRef.where('dateAdded','>=',moment(outcomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(outcomeReportFilters.dateTo).endOf('day').toDate())
    }

    if(paymentFilter!==null){
        tasksRef.limit(10).get().then( async (querySnapshots) => {

            let taskList = [];
            let taskRefList = [];

            for(let q=0; q<querySnapshots.docs.length; q++){
                let transactionsRef = firestore.collection('transactions').doc(querySnapshots.docs[q].data().transactions[0])
                await transactionsRef.get().then( transactionQuerySnapshot => {
                    if(transactionQuerySnapshot.data().paymentType===paymentFilter){
                        taskList.push(querySnapshots.docs[q].data())
                        taskRefList.push(querySnapshots.docs[q]);
                    }
                })
            }

            dispatch({
                type : GET_PREPAID_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })
        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_PREPAID_TASKS_ERROR
            })
        })

    }else{

        tasksRef.limit(10).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })
        
            dispatch({
                type : GET_PREPAID_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_PREPAID_TASKS_ERROR
            })
        })
    }
    
    

}

export const loadMorePrepaidTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_PREPAID_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;

    const prepaidTasksRef = taskState.prepaidTasksRef;
    const outcomeReportFilters = taskState.outcomeReportFilters;
    const paymentFilter = taskState.paymentFilter;
    const lastRef = prepaidTasksRef.length === 0 ? null : prepaidTasksRef[prepaidTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                                .where('isDeleted','==',false)
                                .where('isArchived','==',false)
                                .where('customer','==',firebase.auth().currentUser.uid)
                                .where('paymentStatus','==','prepaid')

        if(outcomeReportFilters!==null){
            tasksRef = tasksRef.where('dateAdded','>=',moment(outcomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(outcomeReportFilters.dateTo).endOf('day').toDate())
        }

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        if(paymentFilter!==null){

            tasksRef.startAfter(lastRef).limit(3).get().then( async (querySnapshots) => {

                let taskList = [];
                let taskRefList = [];
    
                for(let q=0; q<querySnapshots.docs.length; q++){
                    let transactionsRef = firestore.collection('transactions').doc(querySnapshots.docs[q].data().transactions[0])
                    await transactionsRef.get().then( transactionQuerySnapshot => {
                        if(transactionQuerySnapshot.data().paymentType===paymentFilter){
                            taskList.push(querySnapshots.docs[q].data())
                            taskRefList.push(querySnapshots.docs[q]);
                        }
                    })
                }

                dispatch({
                    type : LOAD_MORE_PREPAID_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })
            }).catch(error=>{
                console.log(error)
    
                dispatch({
                    type : LOAD_MORE_PREPAID_TASKS_ERROR
                })
            })

        }else{

            tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : LOAD_MORE_PREPAID_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_PREPAID_TASKS_ERROR
                })
            })

        }

    }

}

export const getFullyPaidTasks = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const taskState = getState().task;
    const paymentFilter = taskState.paymentFilter;
    const outcomeReportFilters = taskState.outcomeReportFilters;
    
    
    dispatch({
        type : GET_FULLY_PAID_TASKS
    })
    
    let tasksRef = firestore.collection('tasks')
                                 .where('isDeleted','==',false)
                                 .where('isArchived','==',false)
                                 .where('customer','==',firebase.auth().currentUser.uid)
                                 .where('paymentStatus','==','fullyPaid')
                                 .where('status','==','completed')

    tasksRef = tasksRef.orderBy('dateAdded','desc')

    if(outcomeReportFilters!==null){
        tasksRef = tasksRef.where('dateAdded','>=',moment(outcomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(outcomeReportFilters.dateTo).endOf('day').toDate())
    }

    if(paymentFilter!==null){

        tasksRef.limit(10).get().then( async (querySnapshots) => {

            let taskList = [];
            let taskRefList = [];

            for(let q=0; q<querySnapshots.docs.length; q++){

                let transID;

                if(querySnapshots.docs[q].data().transactions.length==1){
                    transID = querySnapshots.docs[q].data().transactions[0]
                }else{
                    transID = querySnapshots.docs[q].data().transactions[1]
                }

                let transactionsRef = firestore.collection('transactions').doc(transID)
                await transactionsRef.get().then( transactionQuerySnapshot => {
                    if(transactionQuerySnapshot.data().paymentType===paymentFilter){
                        taskList.push(querySnapshots.docs[q].data())
                        taskRefList.push(querySnapshots.docs[q]);
                    }
                })
            }

            dispatch({
                type : GET_FULLY_PAID_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })
        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_FULLY_PAID_TASKS_ERROR
            })
        })


    }else{

        tasksRef.limit(10).get()
        .then( (querySnapShots) => {

            let taskList = [];
            let taskRefList = [];

            querySnapShots.forEach( doc => {
                taskList.push(doc.data())
                taskRefList.push(doc);
            })

            dispatch({
                type : GET_FULLY_PAID_TASKS_SUCCESS,
                payload : {
                    tasks : taskList,
                    tasksRef : taskRefList
                }
            })

        }).catch(error=>{
            console.log(error)

            dispatch({
                type : GET_FULLY_PAID_TASKS_ERROR
            })
        })
    }
    
    

}

export const loadMoreFullyPaidTasks = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_FULLY_PAID_TASKS
    })

    const firestore = getFirestore();
    const firebase = getFirebase();
    const taskState = getState().task;
    const outcomeReportFilters = taskState.outcomeReportFilters;
    const paymentFilter = taskState.paymentFilter;
    const fullyPaidTasksRef = taskState.fullyPaidTasksRef;
    const lastRef = fullyPaidTasksRef.length === 0 ? null : fullyPaidTasksRef[fullyPaidTasksRef.length-1];

    if(lastRef!==null){

        let tasksRef = firestore.collection('tasks')
                                .where('isDeleted','==',false)
                                .where('isArchived','==',false)
                                .where('customer','==',firebase.auth().currentUser.uid)
                                .where('paymentStatus','==','fullyPaid')
                                .where('status','==','completed')

        tasksRef = tasksRef.orderBy('dateAdded','desc')

        if(outcomeReportFilters!==null){
            tasksRef = tasksRef.where('dateAdded','>=',moment(outcomeReportFilters.dateFrom).startOf('day').toDate()).where('dateAdded','<=',moment(outcomeReportFilters.dateTo).endOf('day').toDate())
        }

        if(paymentFilter!==null){

            tasksRef.startAfter(lastRef).limit(3).get().then( async (querySnapshots) => {

                let taskList = [];
                let taskRefList = [];
    
                for(let q=0; q<querySnapshots.docs.length; q++){
                    let transID;

                    if(querySnapshots.docs[q].data().transactions.length==1){
                        transID = querySnapshots.docs[q].data().transactions[0]
                    }else{
                        transID = querySnapshots.docs[q].data().transactions[1]
                    }

                    let transactionsRef = firestore.collection('transactions').doc(transID)
                    await transactionsRef.get().then( transactionQuerySnapshot => {
                        if(transactionQuerySnapshot.data().paymentType===paymentFilter){
                            taskList.push(querySnapshots.docs[q].data())
                            taskRefList.push(querySnapshots.docs[q]);
                        }
                    })
                }
    
                dispatch({
                    type : LOAD_MORE_FULLY_PAID_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })
            }).catch(error=>{
                console.log(error)
    
                dispatch({
                    type : LOAD_MORE_FULLY_PAID_TASKS_ERROR
                })
            })

        }else{

            tasksRef.startAfter(lastRef).limit(3).get()
            .then( (querySnapShots) => {

                let taskList = [];
                let taskRefList = [];

                querySnapShots.forEach( doc => {
                    taskList.push(doc.data())
                    taskRefList.push(doc);
                })

                dispatch({
                    type : LOAD_MORE_FULLY_PAID_TASKS_SUCCESS,
                    payload : {
                        tasks : taskList,
                        tasksRef : taskRefList
                    }
                })

            }).catch(error=>{
                console.log(error)

                dispatch({
                    type : LOAD_MORE_FULLY_PAID_TASKS_ERROR
                })
            })
        }
    }
}

export const createPaymentEvent = ( data ) => ( dispatch, getState, { getFirestore, getFirebase }) => {

    const firebase = getFirebase();

    data.tasks.map( d => {
        firebase.analytics().logEvent('paid_transaction_event', {
           taskUid : d.uid,
           orderID : d.orderID,
           taskTitle : d.title,
           taskType : d.taskType,
           customerStudy : d.customerStudy,
           amountPaid : d.remainingAmount,
           paymentMethod : data.paymentMethod
        });
    })
   

}

export const checkTranslatorBalance = (data) => async ( dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    let tasksRef = firestore.collection('tasks').where('assignedTranslator','==',firebase.auth().currentUser.uid)

    let transactions = [];

    await tasksRef.get().then( async(querySnapshots) => {
        for(let q=0; q<querySnapshots.docs.length; q++){
            let transactionsRef = firestore.collection('transactions').where('task','==',querySnapshots.docs[q].data().uid)
            await transactionsRef.get().then( transactionQuerySnapshots => {
                transactionQuerySnapshots.forEach( doc => {
                    transactions.push({
                        ...doc.data(),
                        taskStatus : querySnapshots.docs[q].data().status
                    })
                })
            })
        }
    })

    dispatch({
        type : CHECK_TRANSLATOR_BALANCE,
        payload : {
            data : transactions
        }
    })

}

export const updateTasksPaid = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    data.map( d => {
        let t = firestore.collection('tasks').doc(d.uid);
        t.set({
            isPaidToTranslator : true
        }, { merge : true })
    })

}
