import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

export class PaymentConfirmationModal extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#paymentConfirmationModal').modal({backdrop:'static', keyboard:false});
            $('#paymentConfirmationModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#paymentConfirmationModal').modal('show');
            this.props.addModal('payment-confirmation-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("pc-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="payment-confirmation-modal");
            if(modalInstance !== undefined){
                $('.pc-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#paymentConfirmationModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }

    render(){
        
        const { modalZ } = this.state

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} payment-confirmation-modal modal fade modal-opaque`} id="paymentConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="paymentConfirmationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.goToTaskPage} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="modal-header">
                            </div>
                        }    
                        <div className="modal-body">
                            {
                                isMobileView ? 
                                <div>
                                    <div style={{ flex : "1", textAlign : "center" }}>
                                        <p style={{ fontWeight : "bold", color : "#414042", fontSize : "32px" }}>{t('task_modal_paid_header')}</p>
                                        <p style={{ fontWeight : "500", color : "#414042", fontSize : "18px" }}>{t('task_modal_paid_content')}</p>
                                        <button onClick={this.props.goToTaskPage} style={{ width: "100%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "14px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FCFCFC", marginTop : "30px" }}>{t('task_modal_paid_button')}</button>    
                                    </div>
                                    <div style={{ flex: "1", marginTop : "50px" }}>
                                        <img src={require('../../../images/payment-confirmation.png')} style={{ width : "280px", height : "212px" }} />
                                    </div>
                                </div>   
                                :
                                <div style={{ display : "flex" }}>
                                    <div style={{ flex: "1" }}>
                                        <img src={require('../../../images/payment-confirmation.png')} style={{ width : "280px", height : "212px" }} />
                                    </div>
                                    <div style={{ flex : "1", textAlign : "left" }}>
                                        <p style={{ fontWeight : "bold", color : "#414042", fontSize : "32px" }}>{t('task_modal_paid_header')}</p>
                                        <p style={{ fontWeight : "500", color : "#414042", fontSize : "18px" }}>{t('task_modal_paid_content')}</p>

                                        <button onClick={this.props.goToTaskPage} style={{ width: "192px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "14px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FCFCFC", marginTop : "30px" }}>{t('task_modal_paid_button')}</button>    

                                    </div>
                                </div>   
                            }                       
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(PaymentConfirmationModal)