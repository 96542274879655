import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

import {
    translate
} from 'react-switch-lang'

export class CouponCode extends Component {

    constructor(){
        super();
        this.state = {
            couponCode : "",
            isCouponCodeEmpty : false,
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#couponCodeModal').modal({backdrop:'static', keyboard:false});
            $('#couponCodeModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#couponCodeModal').modal('show');
            this.props.addModal('coupon-code-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("cc-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="coupon-code-modal");
            if(modalInstance!==undefined){
                $('.cc-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#couponCodeModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }

    onInputChange = (e) => {

        if(e.target.value.trim().length!==0){
            this.setState({
                isCouponCodeEmpty : false
            })
        }

        this.setState({
            couponCode : e.target.value
        })
    }

    addCouponCode = () => {

        const { couponCode } = this.state;

        if(couponCode.trim().length!==0){
            this.props.addCouponCode(
                couponCode
            )
        }else{
            this.setState({
                isCouponCodeEmpty : true
            })
        }
        
    }

    render(){

        const { modalZ, isCouponCodeEmpty } = this.state;

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : ""

        const { t, currentLanguage } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} coupon-code-modal modal fade modal-opaque`} id="couponCodeModal" tabIndex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeCouponCodeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="custom-modal-header modal-header">
                                <button onClick={this.props.closeCouponCodeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        
                        <div className="modal-body">
                            <p className="text-header">{t('task_modal_coupon_header')}</p>
                            <div className="form-group">
                                <input onChange={this.onInputChange} id="coupon_code" name="coupon_code" style={{ width: "100%" }} className={`custom-input ${ isCouponCodeEmpty ? "error-input" : ""}`} type="text" placeholder={t('common_enter_coupon')}/>
                            </div>

                            <div className="text-center">
                                <button onClick={this.addCouponCode} style={{ width: "100%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('common_confirm')}</button>    
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(CouponCode)