import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Item from './Item';

export class List extends Component {

    componentDidUpdate(prevProps, prevState){
    }

    acceptTask = (task) => {
        this.props.acceptTask(task);
    }

    openDeclineTaskModal = (task) => {
        this.props.openDeclineTaskModal(task)
    }

    render(){

        const { tasks, isMobileView, t, currentLanguage } = this.props;
        return (
            
            <div className="" ref="assignedList">
                {
                    tasks.map( (data, i) => <Item displayDelay={500} t={t} currentLanguage={currentLanguage} isMobileView={isMobileView} openDeclineTaskModal={ () => this.openDeclineTaskModal(data) } acceptTask={ () => this.acceptTask(data)} key={data.uid} task={data}/> )
                }

                <div className="text-center">
                    <button onClick={this.props.loadAssignedTasks} className="load-more-button">{t('common_load_more')}</button>
                </div>
            </div>

           

        )

    }

}

export default List;
