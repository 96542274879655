import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export class DateFilter extends Component {

    render(){

        const { dateFrom, dateTo, isMobileView } = this.props;
        const { t } = this.props;
        
        return(
            <div className={`date-range-filter ic-r ${isMobileView ? "rf-mobile" :"rf-desktop"}`} style={{ width : isMobileView ? "100%" : "300px"}}>
                <span><img style={{ height : "20px" }} src={require('../../../../images/icon-calendar.png')}/></span>
                <DatePicker dateFormat="MMM dd, yyyy" locale="ar" style={{ width: "100%" }} showYearDropdown showMonthDropdown name="date_from" id="date_from" onChange={this.props.onDateFromChange} selected={dateFrom} placeholderText="-" />
                <span>—</span>
                <DatePicker dateFormat="MMM dd, yyyy" minDate={dateFrom} style={{ width: "100%" }} showYearDropdown showMonthDropdown name="date_to" id="date_to" onChange={this.props.onDateToChange} selected={dateTo} placeholderText="-" />
            </div>
        )

    }

}

export default DateFilter