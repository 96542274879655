import React, { Component } from 'react'
import ConversationHeader from './ConversationHeader'
import ConversationBody from './ConversationBody'
import ConversationFooter from './ConversationFooter'

export class Messages extends Component {

    droppableRef = React.createRef();

    constructor(){
        super();
        this.state = {
            dragging : false,
            droppedFiles : []
        }
    }

    componentDidMount() {
        this.dragCounter = 0
        let div = this.droppableRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
    }
    
    componentWillUnmount() {
        let div = this.droppableRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }

    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter > 0) return
        this.setState({dragging: false})
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            // this.props.handleDrop(e.dataTransfer.files)

            let files = [];
            for(let x=0; x < e.dataTransfer.files.length; x++){
                let file = e.dataTransfer.files[x]
                file.from = "drop"
                files.push(file)
            }

            this.setState({

                droppedFiles : [...this.state.droppedFiles, ...files]
            }, () => {
                e.dataTransfer.clearData()
                this.dragCounter = 0    
            })
        }
    }

    clearDroppedFiles = () => {
        this.setState({
            droppedFiles : []
        })
    }

    removeToDroppedFileList = (key) => {
        const { droppedFiles } = this.state;
        let newFiles = droppedFiles;

        newFiles.splice(key,1);
        this.setState({
            droppedFiles : [...newFiles]
        })
    }

    updateUserOpenedDate = () => {

        const { activeConversation, userProfile } = this.props;
        this.props.updateConversationLastOpenedDate({ uid : activeConversation.uid });

    }

    render(){

        const { activeConversation, userProfile, isGettingMessages, lastOpenedDate, isMobileView } = this.props;
        const { droppedFiles } = this.state;
        const { t , currentLanguage } = this.props;

        let imageUrl;
        let userName;
        let recipient;

        let headerStyle = {
            position : "absolute",
            right : "15px",
            top: "15px"
        }

        let actionBtnStyle = {
            right : "auto",
            left : "10px",
            top : "40px"
        }

        if( activeConversation!== null ){
            Object.keys(activeConversation.memberData).map( i=> {
                if(i!==userProfile.uid){
                    recipient = activeConversation.memberData[i]
                }
            })
    
            imageUrl = recipient.imageUrl === "" ? require('../../images/default-user.jpg') : recipient.imageUrl
            userName = recipient.fullName.split(",");  
        }

        return (
            <div ref={this.droppableRef} style={{ marginLeft : isMobileView ? "0" : "32px" }} className="conversation-box">
                { 
                    !isMobileView && 
                    <ConversationHeader 
                        t={t}
                        currentLanguage={currentLanguage}
                        isMobileView={isMobileView}
                        userProfile={userProfile}
                        activeConversation={activeConversation}
                        openPauseTaskModal={this.props.openPauseTaskModal}
                        openStopTaskModal={this.props.openStopTaskModal}
                        openEscalateTaskModal={this.props.openEscalateTaskModal}
                    />
                }
                {
                    isMobileView &&
                    <div className="mobile-conversation-header" style={{ position : "absolute", top : "0" }}>
                        <nav className="navbar navbar-expand-lg fixed-top">
                            <div className="recipient-details">
                                <div  style={ currentLanguage === "ar" ? headerStyle : {}}>
                                    <img onClick={ () => this.props.setActiveConversation(null) } style={{ cursor: "pointer", width: "15px", height : "20px", marginTop : currentLanguage==="ar" ? "10px" : "unset", marginRight: currentLanguage === "ar" ? "unset" : "15px", marginLeft : currentLanguage === "ar" ? "15px" : "unset"}} src={require('../../images/back.png')}/><img className="display-photo" src={imageUrl}/> 
                                </div>
                                <div className="details" style={{ marginTop : currentLanguage === "ar" ? "5px" : "-25px", marginLeft : "80px", marginRight : currentLanguage === "ar" ? "80px" : "unset" }}>
                                    <span className="username">{activeConversation !== null ? userName[1]+' '+userName[0] : ""}</span>
                                    <span className="task-title">{activeConversation !== null ? activeConversation.taskTitle : "" }</span>
                                </div>
                            </div>
                            <div className="recipient-actions">
                                {
                                    activeConversation && <div class="btn-group">
                                        <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        . . .
                                        </button>
                                        <div style={ currentLanguage==='ar' ? actionBtnStyle :{} } class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                        { (userProfile.type ==="customer" && activeConversation.hasOwnProperty('taskObject') && activeConversation.taskObject.status!=="completed") && <button onClick={ () => this.props.openPauseTaskModal({ uid : activeConversation.task})} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_pause')}</button> }
                                        { (userProfile.type ==="customer" && activeConversation.hasOwnProperty('taskObject') && activeConversation.taskObject.status!=="completed") &&  <button onClick={ () => this.props.openStopTaskModal({ uid : activeConversation.task})} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_stop')}</button> }
                                        <button onClick={ () => this.props.openEscalateTaskModal({ uid : activeConversation.task})} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </nav>
                    </div>
                }
                <ConversationBody   t={t}
                        currentLanguage={currentLanguage} isMobileView={isMobileView} lastOpenedDate={lastOpenedDate} loadConversationMessages={this.props.loadConversationMessages} activeConversation={activeConversation} />
                <ConversationFooter t={t} updateUserOpenedDate={this.updateUserOpenedDate} t={t}
                        currentLanguage={currentLanguage}  isMobileView={isMobileView} removeToDroppedFileList={this.removeToDroppedFileList} clearDroppedFiles={this.clearDroppedFiles} droppedFiles={droppedFiles} activeConversation={activeConversation} sendMessage={this.props.sendMessage} />
            </div>
        )

    }

}

export default Messages