import React, { Component } from 'react'
import ConversationItem from './ConversationItem';
import SearchInput from './SearchInput';
import SearchedContact from './SearchedContact';
import SearchedMessage from './SearchedMessage';

export class ConversationList extends Component {

    constructor(){
        super();
        this.state = {
            search : ``,
            facetFilters : []
        }
    }

    componentDidMount(){
    }

    searchConversations = (keyword) => {
        this.setState({
            search : keyword
        }, () => {
            if(keyword.trim()!==""){
                this.props.searchConversations(keyword);
            }
        })
    }

    render(){

        const { isLoadingMoreConversations, isSearchingContacts, isSearchingMessages, searchedContacts, searchedMessages, userProfile, activeConversation, isGettingConversations, conversations } = this.props;
        const { t, currentLanguage } = this.props;
        const { search } = this.state;

        return (
            <div className="user-list">
                <div style={{ marginLeft : "20px", marginRight : "20px", borderBottom : "1px solid #E6E4E8" }}>
                    <p style={{ fontSize : "12px", textAlign: "center", color : "#414042" }}>{t('messenger_recent')}</p>
                </div>
                <SearchInput t={t} disabled={isGettingConversations} searchConversations={this.searchConversations}/>
                {
                    isGettingConversations ?
                    <div className="custom-spinner-border spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    :
                    search === "" ?
                    conversations.length!==0 ?
                    conversations.map( ( data, i) => (
                        <ConversationItem displayDelay={500} key={i} setActiveConversation={this.props.setActiveConversation} userProfile={userProfile} activeConversation={activeConversation} conversation={data} />
                    ))
                    :
                    <p style={{ margin : "5px 20px", fontSize : "12px", textAlign: "center", color : "#7D7C7F" }}>{t('messenger_no_messages')}</p>
                    :  
                    isSearchingContacts && isSearchingMessages ?
                    <div className="custom-spinner-border spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : 
                    <div>
                        <div style={{ marginLeft : "20px", marginRight : "20px", borderBottom : "1px solid #E6E4E8" }}>
                            <p style={{ marginBottom: "5px", fontSize : "12px", textAlign: "left", color : "#7D7C7F" }}>Contacts:</p>
                        </div>
                        {
                            searchedContacts.length===0 ?
                            <p style={{ margin : "5px 20px", fontSize : "12px", textAlign: "center", color : "#7D7C7F" }}>No result(s) found.</p>
                            :
                            searchedContacts.map( ( data, i) => (
                                <SearchedContact displayDelay={500} userProfile={userProfile} getAndSetActiveConversation={this.props.getAndSetActiveConversation} key={i} searched={data} />
                            ))
                        }
                        <div style={{ marginLeft : "20px", marginRight : "20px", borderBottom : "1px solid #E6E4E8" }}>
                            <p style={{ marginBottom: "5px", fontSize : "12px", textAlign: "left", color : "#7D7C7F" }}>Messages:</p>
                        </div>
                        {
                            searchedMessages.length===0 ?
                            <p style={{ margin : "5px 20px", fontSize : "12px", textAlign: "center", color : "#7D7C7F" }}>No result(s) found.</p> :
                            searchedMessages.map( ( data, i) => (
                                <SearchedMessage displayDelay={500} getAndSetActiveConversation={this.props.getAndSetActiveConversation} key={i} searched={data} />
                            ))
                        }
                    </div>
                }
                <div className="text-center">
                {
                    isLoadingMoreConversations ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> :(!isGettingConversations && !isSearchingContacts  && conversations.length!==0) && <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreConversations} className="load-more-button">{t('common_load_more')}</button>
                    </div>
                }
                </div>                  
            </div>
        )

    }

}


export default ConversationList