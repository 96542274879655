import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';

import {
    addModal,
    popModal
} from '../../../actions/appAction';

export class DeleteTaskModal extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#deleteTaskModal').modal({backdrop:'static', keyboard:false});
            $('#deleteTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#deleteTaskModal').modal('show');
            this.props.addModal('delete-task-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("det-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="delete-task-modal");
            if(modalInstance!==undefined){
                $('.det-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#deleteTaskModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }


    render(){

        const { modalZ } = this.state;
        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 
        const { t, currentLanguage } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} delete-task-modal modal fade modal-opaque`} id="deleteTaskModal" tabIndex="-1" role="dialog" aria-labelledby="deleteTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeDeleteTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="modal-header">
                                <button onClick={this.props.closeDeleteTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        
                        <div className="modal-body">
                            <p style={{ marginTop : isMobileView ? "200px" : "0" }} className="text-header">{t('task_modal_delete_header')}</p>
                            <p className="text-sub-header">{t('task_modal_delete_sub_header')}</p>
                            <div>
                                <button onClick={this.props.closeDeleteTaskModal} style={{ marginLeft : currentLanguage === "ar" ? "80px" : "unset", marginRight : currentLanguage === "ar" ? "unset" : "80px", width: "108px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "14px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color: "#FCFCFC" }}>{t('common_no')}</button>   
                                <button onClick={this.props.deleteTask} style={{  width: "108px" , border :"none", background : "#F5F4F6", borderRadius : "8px", padding: "16px 20px", fontSize : "14px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#22C9C7" }}>{t('common_delete')}</button>    
                            </div>                            
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView
    }
}

export default compose(
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(DeleteTaskModal)