import {
    GET_PUBLISHED_TASKS,
    GET_PUBLISHED_TASKS_ERROR,
    GET_PUBLISHED_TASKS_SUCCESS,
    LOAD_MORE_PUBLISHED_TASKS,
    LOAD_MORE_PUBLISHED_TASKS_ERROR,
    LOAD_MORE_PUBLISHED_TASKS_SUCCESS,
    GET_T_INPROGRESS_TASKS,
    GET_T_INPROGRESS_TASKS_ERROR,
    GET_T_INPROGRESS_TASKS_SUCCESS,
    LOAD_MORE_T_INPROGRESS_TASKS,
    LOAD_MORE_T_INPROGRESS_TASKS_ERROR,
    LOAD_MORE_T_INPROGRESS_TASKS_SUCCESS,
    GET_BASKET_TASKS,
    GET_BASKET_TASKS_ERROR,
    GET_BASKET_TASKS_SUCCESS,
    GET_BASKET_COUNT,
    GET_BASKET_COUNT_SUCCESS,
    GET_BASKET_COUNT_ERROR,
    LOAD_MORE_BASKET_TASKS,
    LOAD_MORE_BASKET_TASKS_ERROR,
    LOAD_MORE_BASKET_TASKS_SUCCESS,
    GET_ARCHIVED_TASKS,
    GET_ARCHIVED_TASKS_SUCCESS,
    GET_ARCHIVED_TASKS_ERROR,
    LOAD_MORE_ARCHIVED_TASKS,
    LOAD_MORE_ARCHIVED_TASKS_SUCCESS,
    LOAD_MORE_ARCHIVED_TASKS_ERROR,
    GET_ARCHIVED_TASKS_PAGINATE,
    GET_ARCHIVED_TASKS_PAGINATE_SUCCESS,
    GET_ARCHIVED_TASKS_PAGINATE_ERROR,
    GET_TRANSLATOR_TASKS,
    GET_TRANSLATOR_TASKS_SUCCESS,
    GET_TRANSLATOR_TASKS_ERROR,
    UPLOADING_TASK_FILE,
    UPLOADING_TASK_FILE_SUCCESS,
    UPLOADING_TASK_FILE_ERROR,
    UPLOAD_TASK_FILE,
    REMOVE_TASK_FILE,
    REMOVE_TASK_FILE_SUCCESS,
    REMOVE_TASK_FILE_ERROR,
    SAVE_TASK_CREATION_STATE,
    CLEAR_TASK_CREATION_STATE,
    SET_UPLOAD_TASK_FILES,
    RESET_UPLOAD_TASK_FILES,
    DISPLAY_NEW_TASK_INPROGRESS,
    REMOVE_NEW_TASK_INPROGRESS,
    DISPLAY_NEW_TASK_COMPLETED,
    REMOVE_NEW_TASK_COMPLETED,
    GET_PREPAID_TASKS,
    GET_PREPAID_TASKS_ERROR,
    GET_PREPAID_TASKS_SUCCESS,
    LOAD_MORE_PREPAID_TASKS,
    LOAD_MORE_PREPAID_TASKS_ERROR,
    LOAD_MORE_PREPAID_TASKS_SUCCESS,
    GET_FULLY_PAID_TASKS,
    GET_FULLY_PAID_TASKS_ERROR,
    GET_FULLY_PAID_TASKS_SUCCESS,
    LOAD_MORE_FULLY_PAID_TASKS,
    LOAD_MORE_FULLY_PAID_TASKS_ERROR,
    LOAD_MORE_FULLY_PAID_TASKS_SUCCESS,
    GET_PREPAID_TASKS_TRANSLATOR,
    GET_PREPAID_TASKS_TRANSLATOR_ERROR,
    GET_PREPAID_TASKS_TRANSLATOR_SUCCESS,
    LOAD_MORE_PREPAID_TASKS_TRANSLATOR,
    LOAD_MORE_PREPAID_TASKS_TRANSLATOR_ERROR,
    LOAD_MORE_PREPAID_TASKS_TRANSLATOR_SUCCESS,
    GET_FULLY_PAID_TASKS_TRANSLATOR,
    GET_FULLY_PAID_TASKS_TRANSLATOR_ERROR,
    GET_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS,
    LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR,
    LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_ERROR,
    LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS,
    GET_TRANSLATOR_BALANCE,
    GET_TRANSLATOR_BALANCE_SUCCESS,
    GET_TRANSLATOR_BALANCE_ERROR,
    FILTER_CUSTOMER_OUTCOME_REPORTS,
    RESET_CUSTOMER_OUTCOME_REPORTS_FILTER,
    FILTER_PAYMENT_CUSTOMER_OUTCOME_REPORTS,
    RESET_CUSTOMER_OUTCOME_REPORTS_PAYMENT_FILTER,
    RESET_TRANSLATOR_INCOME_REPORTS_FILTER,
    FILTER_TRANSLATOR_INCOME_REPORTS,
    CHECK_TRANSLATOR_BALANCE,
    RESET_TRANSLATOR_BALANCE,
    REMOVE_UPLOAD_TASK_FILES
} from '../actions/types'

const initState = {
    taskCreationState : null,
    isGettingTasks : false,
    isLoadingMoreTasks : false,
    statusFilter : "all",
    typeFilter : "all",
    tasks : [],
    tasksRef : [],
    tasksLastRef : null,
    publishedTasks : [],
    publishedTasksRef : [],
    publishedTasksLastRef : null,
    isPublishedTasksEmpty : false,
    isLoadingMorePublishedTasks : false,
    basketTasksCount : 0,
    hasBasketTasks : false,
    basketTasks : [],
    basketTasksRef : [],
    basketTasksLastRef : null,
    isLoadingMoreBasketTasks : false,
    assignedTasks : [],
    assignedTasksRef : [],
    assignedTasksLastRef : null,
    assignedTasksCount : 0,
    isLoadingMoreTInprogressTasks : false,
    tInProgressTasks : [],
    tInProgressTasksRef : [],
    tInProgressTasksLastRef : null,
    isTinProgressTasksEmpty : false,
    archivedTasks : [],
    archivedTasksRef : [],
    archivedTasksLastRef : null,
    translatorTasks : [],
    translatorTasksRef : [],
    uploadingTaskFiles : [],
    isUploadingFiles : false,
    newInProgressTasks : [],
    newCompletedTasks : [],
    isGettingPrepaidTasks : false,
    isLoadingMorePrepaidTasks : false,
    prepaidTasks : [],
    prepaidTasksRef : [],
    prepaidTasksLastRef : null,
    isGettingFullyPaidTasks : false,
    isLoadingMoreFullyPaidTasks : false,
    fullyPaidTasks : [],
    fullyPaidTasksRef : [],
    fullyPaidTasksLastRef : null,
    outcomeReportFilters : null,
    paymentFilter : null,
    incomeReportFilters : null,
    isGettingTranslatorPrepaidTasks : false,
    isLoadingMoreTranslatorPrepaidTasks : false,
    translatorPrepaidTasks : [],
    translatorPrepaidTasksRef : [],
    translatorPrepaidTasksLastRef : null,
    isGettingTranslatorFullyPaidTasks : false,
    isLoadingMoreTranslatorFullyPaidTasks : false,
    translatorFullyPaidTasks : [],
    translatorFullyPaidTasksRef : [],
    translatorFullyPaidTasksLastRef : null,
    translatorTransactionBalance : 0
}

const publishedStat = [
    "inProgress",
    "forReview",
    "completed",
    "new"
]

const assignedStat = [
    "new"
]

const translatorInProgressStat = [
    "inProgress",
    "forReview"
]

const taskReducer = ( state = initState , action ) => {
    
    let fileIndex;
    let newFiles = [];
    switch(action.type){
        case GET_BASKET_COUNT_SUCCESS:
            return {
                ...state,
                basketTasksCount : action.payload.count,
                hasBasketTasks : action.payload.count===0? false : true
            }
        case CHECK_TRANSLATOR_BALANCE: 
            return {
                ...state,
                translatorTransactionBalance : action.payload.data
            }
        case GET_TRANSLATOR_BALANCE_SUCCESS: 
            return {
                ...state,
                translatorTransactionBalance : action.payload.balance
            }
        case RESET_TRANSLATOR_BALANCE:
            return {
                ...state,
                translatorTransactionBalance : 0
            }
        case RESET_CUSTOMER_OUTCOME_REPORTS_FILTER:
            return {
                ...state,
                outcomeReportFilters : null
            }
        case FILTER_CUSTOMER_OUTCOME_REPORTS:
            return{
                ...state,
                outcomeReportFilters : {
                    dateFrom : action.payload.dateFrom,
                    dateTo : action.payload.dateTo,
                }
            }
        case RESET_CUSTOMER_OUTCOME_REPORTS_PAYMENT_FILTER:
            return {
                ...state,
                paymentFilter : null
            }
        case FILTER_PAYMENT_CUSTOMER_OUTCOME_REPORTS:
            return{
                ...state,
                paymentFilter : action.payload.paymentFilter
            }
        case RESET_TRANSLATOR_INCOME_REPORTS_FILTER:
            return {
                ...state,
                incomeReportFilters : null
            }
        case FILTER_TRANSLATOR_INCOME_REPORTS:
            return{
                ...state,
                incomeReportFilters : {
                    dateFrom : action.payload.dateFrom,
                    dateTo : action.payload.dateTo
                }
            }
        case GET_FULLY_PAID_TASKS:
            return {
                ...state,
                isGettingFullyPaidTasks : true,
            }
        case GET_FULLY_PAID_TASKS_SUCCESS:
            return {
                ...state,
                fullyPaidTasks :  [...action.payload.tasks],
                fullyPaidTasksRef : [...action.payload.tasksRef],
                fullyPaidTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
                isGettingFullyPaidTasks : false
            }
        case GET_FULLY_PAID_TASKS_ERROR:
            return{
                ...state,
                isGettingFullyPaidTasks : false
            }
        case LOAD_MORE_FULLY_PAID_TASKS:
            return{
                ...state,
                isLoadingMoreFullyPaidTasks : true
            }
        case LOAD_MORE_FULLY_PAID_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMoreFullyPaidTasks : false,
                fullyPaidTasks :  [...state.fullyPaidTasks, ...action.payload.tasks],
                fullyPaidTasksRef : [...state.fullyPaidTasksRef, ...action.payload.tasksRef],
                fullyPaidTasksLastRef : action.payload.tasksRef.length===0 ? state.fullyPaidTasksRef[state.fullyPaidTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_FULLY_PAID_TASKS_ERROR:
            return{
                ...state,
                isLoadingMoreFullyPaidTasks : false
            }
        case GET_PREPAID_TASKS:
            return {
                ...state,
                isGettingPrepaidTasks : true,
            }
        case GET_PREPAID_TASKS_SUCCESS:
            return {
                ...state,
                prepaidTasks :  [...action.payload.tasks],
                prepaidTasksRef : [...action.payload.tasksRef],
                prepaidTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
                isGettingPrepaidTasks : false
            }
        case GET_PREPAID_TASKS_ERROR:
            return{
                ...state,
                isGettingPrepaidTasks : false
            }
        case LOAD_MORE_PREPAID_TASKS:
            return{
                ...state,
                isLoadingMorePrepaidTasks : true
            }
        case LOAD_MORE_PREPAID_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMorePrepaidTasks : false,
                prepaidTasks :  [...state.prepaidTasks, ...action.payload.tasks],
                prepaidTasksRef : [...state.prepaidTasksRef, ...action.payload.tasksRef],
                prepaidTasksLastRef : action.payload.tasksRef.length===0 ? state.prepaidTasksRef[state.prepaidTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_PREPAID_TASKS_ERROR:
            return{
                ...state,
                isLoadingMorePrepaidTasks : false
            }
        // NEW
        case GET_FULLY_PAID_TASKS_TRANSLATOR:
            return {
                ...state,
                isGettingTranslatorFullyPaidTasks : true,
            }
        case GET_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS:
            return {
                ...state,
                translatorFullyPaidTasks :  [...action.payload.tasks],
                translatorFullyPaidTasksRef : [...action.payload.tasksRef],
                translatorFullyPaidTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
                isGettingTranslatorFullyPaidTasks : false
            }
        case GET_FULLY_PAID_TASKS_TRANSLATOR_ERROR:
            return{
                ...state,
                isGettingTranslatorFullyPaidTasks : false
            }
        case LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR:
            return{
                ...state,
                isLoadingMoreTranslatorFullyPaidTasks : true
            }
        case LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_SUCCESS:
            return{
                ...state,
                isLoadingMoreTranslatorFullyPaidTasks : false,
                translatorFullyPaidTasks :  [...state.translatorFullyPaidTasks, ...action.payload.tasks],
                translatorFullyPaidTasksRef : [...state.translatorFullyPaidTasksRef, ...action.payload.tasksRef],
                translatorFullyPaidTasksLastRef : action.payload.tasksRef.length===0 ? state.translatorFullyPaidTasksRef[state.translatorFullyPaidTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_FULLY_PAID_TASKS_TRANSLATOR_ERROR:
            return{
                ...state,
                isLoadingMoreTranslatorFullyPaidTasks : false
            }
        case GET_PREPAID_TASKS_TRANSLATOR:
            return {
                ...state,
                isGettingTranslatorPrepaidTasks : true,
            }
        case GET_PREPAID_TASKS_TRANSLATOR_SUCCESS:
            return {
                ...state,
                translatorPrepaidTasks :  [...action.payload.tasks],
                translatorPrepaidTasksRef : [...action.payload.tasksRef],
                translatorPrepaidTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
                isGettingTranslatorPrepaidTasks : false
            }
        case GET_PREPAID_TASKS_ERROR:
            return{
                ...state,
                isGettingTranslatorPrepaidTasks : false
            }
        case LOAD_MORE_PREPAID_TASKS_TRANSLATOR:
            return{
                ...state,
                isLoadingMoreTranslatorPrepaidTasks : true
            }
        case LOAD_MORE_PREPAID_TASKS_TRANSLATOR_SUCCESS:
            return{
                ...state,
                isLoadingMoreTranslatorPrepaidTasks : false,
                translatorPrepaidTasks :  [...state.translatorPrepaidTasks, ...action.payload.tasks],
                translatorPrepaidTasksRef : [...state.translatorPrepaidTasksRef, ...action.payload.tasksRef],
                translatorPrepaidTasksLastRef : action.payload.tasksRef.length===0 ? state.translatorPrepaidTasksRef[state.translatorPrepaidTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_PREPAID_TASKS_TRANSLATOR_ERROR:
            return{
                ...state,
                isLoadingMoreTranslatorPrepaidTasks : false
            }
        // END
        case DISPLAY_NEW_TASK_COMPLETED : {

            return {
                ...state,
                newCompletedTasks : [...state.newCompletedTasks, action.payload.task]
            }
        }
        case REMOVE_NEW_TASK_COMPLETED: {

            state.newCompletedTasks.pop();

            return {
                ...state,
                newCompletedTasks : [...state.newCompletedTasks]
            }
        }
        case DISPLAY_NEW_TASK_INPROGRESS : {
            return {
                ...state,
                newInProgressTasks : [...state.newInProgressTasks, action.payload.task]
            }
        }
        case REMOVE_NEW_TASK_INPROGRESS: {

            state.newInProgressTasks.pop();

            return {
                ...state,
                newInProgressTasks : [...state.newInProgressTasks]
            }
        }
        case SET_UPLOAD_TASK_FILES:
            
            action.payload.taskFiles.map( (data) => {
                newFiles.push({
                    up_file : data.name,
                    upload_progress : 100,
                    uploading : false,
                    url : data
                })
            })

            return {
                ...state,
                uploadingTaskFiles : [...state.uploadingTaskFiles, ...newFiles],
            }
        case REMOVE_UPLOAD_TASK_FILES:

            let newUploadTaskFileIndex = state.uploadingTaskFiles.findIndex( data => data.url===action.payload.taskFile.url);
            
            state.uploadingTaskFiles.splice(newUploadTaskFileIndex, 1)

            return{
                ...state,
                uploadingTaskFiles : [...state.uploadingTaskFiles]
            }

        case RESET_UPLOAD_TASK_FILES:
            return {
                ...state,
                uploadingTaskFiles : []
            }
        case UPLOAD_TASK_FILE:

            newFiles = [];
            
            action.payload.taskFiles.map( (data) => {
                if(typeof data !== "string"){
                    newFiles.push({
                        up_file : data.name,
                        upload_progress : 0,
                        uploading : true,
                        url : ""
                    })
                }
            })

            return {
                ...state,
                uploadingTaskFiles : [...state.uploadingTaskFiles, ...newFiles],
            }
        case UPLOADING_TASK_FILE:
            fileIndex = state.uploadingTaskFiles.findIndex( data => data.up_file===action.payload.up_file);

            if(fileIndex!==-1){
                state.uploadingTaskFiles[fileIndex].upload_progress = action.payload.progress
                state.uploadingTaskFiles[fileIndex].uploading = true
            }
            return {
                ...state,
                uploadingTaskFiles : [...state.uploadingTaskFiles]
            }
        case UPLOADING_TASK_FILE_SUCCESS:
                
            fileIndex = state.uploadingTaskFiles.findIndex( data => data.up_file===action.payload.up_file);

            if(fileIndex!==-1){
                state.uploadingTaskFiles[fileIndex].url = action.payload.url
                state.uploadingTaskFiles[fileIndex].uploading = false
            }
            return {
                ...state,
                uploadingTaskFiles : [...state.uploadingTaskFiles]
            }
        case REMOVE_TASK_FILE:
            return{
                ...state
            }
        case REMOVE_TASK_FILE_ERROR:
            return{
                ...state
            }
        case REMOVE_TASK_FILE_SUCCESS:
            fileIndex = state.uploadingTaskFiles.findIndex( data => data.url===action.payload.up_file);

            state.uploadingTaskFiles.splice(fileIndex, 1)

            return{
                ...state,
                uploadingTaskFiles : [...state.uploadingTaskFiles]
            }
        case SAVE_TASK_CREATION_STATE:
            return{
                ...state,
                taskCreationState : {...action.payload.data}
            }
        case CLEAR_TASK_CREATION_STATE:
            return{
                ...state,
                taskCreationState : null
            }
        case "GET_TASKS":
            return {
                ...state,
                //tasks : [],
                isGettingTasks : true
            }
        case "GET_TASKS_SUCCESS":
            return {
                ...state,
                isGettingTasks : false,
                tasks :  [...action.payload.tasks]
            }
        case GET_BASKET_TASKS:
            return {
                ...state,
                uploadingTaskFiles : [],
                taskCreationState : null,
                isGettingTasks : true
            }
        case GET_BASKET_TASKS_SUCCESS:
            return {
                ...state,
                isGettingTasks : false,
                basketTasks : [...action.payload.tasks],
                basketTasksRef : [...action.payload.tasksRef],
                basketTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case GET_BASKET_TASKS_ERROR:
            return{
                ...state,
                isGettingTasks : false
            }
        case LOAD_MORE_BASKET_TASKS:
            return{
                ...state,
                isLoadingMoreBasketTasks : true
            }
        case LOAD_MORE_BASKET_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMoreBasketTasks : false,
                basketTasks :  [...state.basketTasks, ...action.payload.tasks],
                basketTasksRef : [...state.basketTasksRef, ...action.payload.tasksRef],
                basketTasksLastRef : action.payload.tasksRef.length===0 ? state.basketTasksRef[state.basketTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_BASKET_TASKS_ERROR:
            return{
                ...state,
                isLoadingMoreBasketTasks : false
            }
        case GET_PUBLISHED_TASKS:
            return {
                ...state,
                isGettingTasks : true
            }
        case GET_PUBLISHED_TASKS_SUCCESS:
   
            return {
                ...state,
                isGettingTasks : false,
                isPublishedTasksEmpty : (action.payload.firstLoad===true && action.payload.tasks.length===0) ? true : false,
                publishedTasks : [...action.payload.tasks],
                publishedTasksRef : [...action.payload.tasksRef],
                publishedTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case GET_PUBLISHED_TASKS_ERROR:
            return{
                ...state,
                isGettingTasks : false
            }
        case LOAD_MORE_PUBLISHED_TASKS:
            return{
                ...state,
                isLoadingMorePublishedTasks : true
            }
        case LOAD_MORE_PUBLISHED_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMorePublishedTasks : false,
                publishedTasks :  [...state.publishedTasks, ...action.payload.tasks],
                publishedTasksRef : [...state.publishedTasksRef, ...action.payload.tasksRef],
                publishedTasksLastRef : action.payload.tasksRef.length===0 ? state.publishedTasksRef[state.publishedTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_PUBLISHED_TASKS_ERROR:
            return{
                ...state,
                isLoadingMorePublishedTasks : false
            }

        case GET_T_INPROGRESS_TASKS:
            return {
                ...state,
                isGettingTasks : true
            }
        case GET_T_INPROGRESS_TASKS_SUCCESS:
            return {
                ...state,
                isGettingTasks : false,
                isTinProgressTasksEmpty : (action.payload.firstLoad && action.payload.tasks.length===0) ? true : false,
                tInProgressTasks : [...action.payload.tasks],
                tInProgressTasksRef : [...action.payload.tasksRef],
                tInProgressTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case GET_T_INPROGRESS_TASKS_ERROR:
            return{
                ...state,
                isGettingTasks : false
            }
        case LOAD_MORE_T_INPROGRESS_TASKS:
            return{
                ...state,
                isLoadingMoreTInprogressTasks : true
            }
        case LOAD_MORE_T_INPROGRESS_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMoreTInprogressTasks : false,
                tInProgressTasks :  [...state.tInProgressTasks, ...action.payload.tasks],
                tInProgressTasksRef : [...state.tInProgressTasksRef, ...action.payload.tasksRef],
                tInProgressTasksLastRef : action.payload.tasksRef.length===0 ? state.tInProgressTasksRef[state.tInProgressTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_T_INPROGRESS_TASKS_ERROR:
            return{
                ...state,
                isLoadingMoreTInprogressTasks : false
            }

        case "RELOAD_PUBLISHED_TASK":
            /**
             * This case is for checking changes in published tasks
             */
            
            let uPublishedData = action.payload.task;
            let uPublishedIndex = state.publishedTasks.findIndex( (data) => data.uid===uPublishedData.uid )
            
            if(uPublishedIndex===-1){
                if(uPublishedData.isUnpaid===false && publishedStat.indexOf(uPublishedData.status)!==-1){
                    state.publishedTasks.unshift(action.payload.task)
                    if(state.publishedTasks.length>3){
                        state.publishedTasks.pop();
                    }
                    state.publishedTasksRef.unshift(action.payload.taskRef)
                    if(state.publishedTasks.length>3){
                        state.publishedTasksRef.pop();
                    }
                }
            }else{
                if(publishedStat.indexOf(uPublishedData.status)!==-1){
                    if(uPublishedData.isDeleted || uPublishedData.isArchived){
                        state.publishedTasks.splice(uPublishedIndex, 1);
                        state.publishedTasksRef.splice(uPublishedIndex, 1);
                    }else{
                        state.publishedTasks[uPublishedIndex] = uPublishedData   
                    }
                }else{
                    state.publishedTasks.splice(uPublishedIndex, 1);
                    state.publishedTasksRef.splice(uPublishedIndex, 1);
                }
            }
            
        
            return{
                ...state,
                publishedTasks : [...state.publishedTasks],
                publishedTasksRef : [...state.publishedTasksRef],
                taskLastRef : state.publishedTasksRef.length === 0 ? null : state.publishedTasksRef[state.publishedTasksRef.length-1],
                isGettingTasks : false
            }

        case "GET_ASSIGNED_TASKS":
            return {
                ...state,
                isGettingTasks : true
            }
        case "GET_ASSIGNED_TASKS_SUCCESS":
            return {
                ...state,
                isGettingTasks : false,
                assignedTasks : [...action.payload.tasks],
                assignedTasksRef : [...action.payload.tasksRef],
                assignedTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case "CLEAR_ASSIGNED_TASKS_COUNT":
            return{
                ...state,
                assignedTasksCount : 0
            }
        case "RELOAD_ASSIGNED_TASK":
            /**
             * This case is for checking newly assigned tasks
             * to the logged in translator
             */
            let newData = action.payload.task;
            let currentUserUid = action.payload.userUid;
            let newCount = state.assignedTasksCount;

            let taskIndex = state.assignedTasks.findIndex( task => task.uid===newData.uid )

            // Check if the task in on the current page
            if(taskIndex===-1){
                if(assignedStat.indexOf(newData.status)!==-1){
                    if(newData.hasOwnProperty('invitedTranslators')){
                        if(newData.invitedTranslators.indexOf(currentUserUid)!==-1){
                            state.assignedTasks.unshift(action.payload.task)
                            if(state.assignedTasks.length>3){
                                state.assignedTasks.pop();
                            }
                            state.assignedTasksRef.unshift(action.payload.taskRef)
                            if(state.assignedTasks.length>3){
                                state.assignedTasksRef.pop();
                            }
                            newCount = newCount + 1
                        }else{
                            newCount = newCount - 1
                        }
                    }
                }
            }else{
                // Check if the tasks is removed/archived by the customer
                if(newData.isDeleted || newData.isArchived || newData.status=="inProgress"){
                    state.assignedTasks.splice(taskIndex, 1);
                    state.assignedTasksRef.splice(taskIndex, 1);
                    newCount = newCount - 1
                }else{

                    if(newData.hasOwnProperty('invitedTranslators')){
                        if(newData.invitedTranslators.indexOf(currentUserUid)!==-1){
                            state.assignedTasks[taskIndex] = newData
                        }else{
                            state.assignedTasks.splice(taskIndex, 1);
                            state.assignedTasksRef.splice(taskIndex, 1);
                            newCount = newCount - 1
                        }
                    }
                }
            }

            return {
                ...state,
                assignedTasks : [...state.assignedTasks],
                assignedTasksRef : [...state.assignedTasksRef],
                assignedTasksCount : newCount,
                assignedTasksLastRef : state.assignedTasksRef.length===0 ? null : state.assignedTasksRef[state.assignedTasksRef.length-1]
            }
        case "GET_TASKS_SUCCESS_PAGINATE":
            return {
                ...state,
                isGettingTasks : false,
                tasks :  [...state.tasks, ...action.payload.tasks]
            }
        case "GET_ASSIGNED_TASKS_PAGINATE":
            return{
                ...state,
                isGettingTasks : true
            }
        case "GET_ASSIGNED_TASKS_PAGINATE_SUCCESS":
            return {
                ...state,
                isGettingTasks : false,
                assignedTasks :  [...state.assignedTasks, ...action.payload.tasks],
                assignedTasksRef : [...state.assignedTasksRef, ...action.payload.tasksRef],
                assignedTasksLastRef : action.payload.tasksRef.length===0 ? state.assignedTasksRef[state.assignedTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
    
        case "RELOAD_TINPROGRESS_TASK":
                /**
                 * This case is for checking in progress tasks
                 * to the logged in translator
                 */
                let updatedIpData = action.payload.task;
                let ipCurrentUserUid = action.payload.userUid;

                let ipTaskIndex = state.tInProgressTasks.findIndex( task => task.uid===updatedIpData.uid )
                // Check if the task is for logged in translator
                if(updatedIpData.assignedTranslator===ipCurrentUserUid && updatedIpData.accountManager!==""){
                    // Check if the task in on the current page
                    if(ipTaskIndex===-1){
                        state.tInProgressTasks.unshift(action.payload.task)
                        if(state.tInProgressTasks.length>3){
                            state.tInProgressTasks.pop();
                        }
                        state.tInProgressTasksRef.unshift(action.payload.taskRef)
                        if(state.tInProgressTasks.length>3){
                            state.tInProgressTasksRef.pop();
                        }
                    }else{
                        // Check if the tasks is removed/archived by the customer
                        if(updatedIpData.isDeleted || updatedIpData.isArchived || translatorInProgressStat.indexOf(updatedIpData.status)==-1){
                            state.tInProgressTasks.splice(ipTaskIndex, 1);
                            state.tInProgressTasksRef.splice(ipTaskIndex, 1);
                        }else{
                            state.tInProgressTasks[ipTaskIndex] = updatedIpData
                        }
                    }
                }else{
    
                    // Assume that the task was declined by the account manager
                    
                    if(ipTaskIndex!==-1){
                        state.tInProgressTasks.splice(ipTaskIndex, 1);
                        state.tInProgressTasksRef.splice(ipTaskIndex, 1);
                    }
                }
    
                return {
                    ...state,
                    tInProgressTasks : [...state.tInProgressTasks],
                    tInProgressTasksRef : [...state.tInProgressTasksRef],
                    tInProgressTasksLastRef : state.tInProgressTasksRef.length===0 ? null : state.tInProgressTasksRef[state.assignedTasksRef.length-1]
                }                
        case GET_ARCHIVED_TASKS:
            return {
                ...state,
                isGettingTasks : true
            }
        case GET_ARCHIVED_TASKS_SUCCESS:
            return {
                ...state,
                isGettingTasks : false,
                archivedTasks : [...action.payload.tasks],
                archivedTasksRef : [...action.payload.tasksRef],
                archivedTasksLastRef : action.payload.tasksRef.length===0 ? null : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case GET_ARCHIVED_TASKS:
            return{
                ...state,
                isGettingTasks : false
            }
        case LOAD_MORE_ARCHIVED_TASKS:
            return{
                ...state,
                isLoadingMoreTasks : true
            }
        case LOAD_MORE_ARCHIVED_TASKS_SUCCESS:
            return{
                ...state,
                isLoadingMoreTasks : false,
                archivedTasks :  [...state.archivedTasks, ...action.payload.tasks],
                archivedTasksRef : [...state.archivedTasksRef, ...action.payload.tasksRef],
                archivedTasksLastRef : action.payload.tasksRef.length===0 ? state.archivedTasksRef[state.archivedTasksRef.length-1] : action.payload.tasksRef[action.payload.tasksRef.length-1],
            }
        case LOAD_MORE_ARCHIVED_TASKS_ERROR:
            return{
                ...state,
                isLoadingMoreTasks : false
            }
            
        case "FILTER_PUBLISHED_TASKS":
            return {
                ...state,
                publishedTasks : [],
                publishedTasksLastRef : null,
                statusFilter : action.payload.status,
                typeFilter : action.payload.type
            }
        case "CREATE_TASK_SUCCESS":
            return {
                ...state,
                //uploadingTaskFiles : []
            }
        case "CREATE_TASK_ERROR":
            return {
                ...state
            }
        case "UPDATE_TASK_SUCCESS":
            return {
                ...state,
                assignedTasksLastRef : null
            }
        case "UPDATE_TASK_ERROR":
            return {
                ...state
            }
        case "DELETE_TASK_SUCCESS":
            return {
                ...state
            }
        case "DELETE_TASK_ERROR":
            return {
                ...state
            }
        case "LOGOUT_SUCCESS":
            return initState;
        case GET_TRANSLATOR_TASKS:
            return {
                ...state,
                isGettingTasks : true
            }
        case GET_TRANSLATOR_TASKS_SUCCESS:
            return {
                ...state,
                isGettingTasks : false,
                translatorTasks : action.payload.tasks,
                translatorTasksRef : action.payload.tasksRef
            }
        case GET_TRANSLATOR_TASKS_ERROR:
            return {
                ...state,
                isGettingTasks : false
            }
        default:
            return state;
    }
}

export default taskReducer;