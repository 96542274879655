import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { signOutUser, setUserPresence, updateCurrentUser } from '../../actions/authAction';
import { clearAssignedTasksCount, watchAssignedTasks, watchNewInProgressTasks, removeNewInProgressDisplay, getBasketCount, watchBasketTasks } from '../../actions/taskAction'
import { watchNewMessages, resetMessageBadge } from '../../actions/conversationAction';

import {
    addModal,
    popModal,
    setCurrentLanguage
} from '../../actions/appAction'

import {
    TaskInProgress
} from '../Modals/TaskInProgress';

import $ from 'jquery';

import {
    translate
} from 'react-switch-lang';

export class LoggedInLinks extends Component {

    constructor(){
        super();
        this.state = {
            assignedTaskCount : 0
        }
    }

    componentDidMount(){
        const { auth } = this.props;

        if(auth.uid){
            this.props.watchAssignedTasks({
                authUid : auth.uid
            });
            this.props.getBasketCount();
            this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
            this.unsubscribeWatchBasketTasks = this.props.watchBasketTasks();
        }
    }

    componentDidUpdate(prevProps){
        
        if(this.props.userProfile!==prevProps.userProfile){
            this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
            if(this.props.location.pathname!=="/chat"){
                setTimeout( () => {
                    this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
                }, 3000 )
            }
        }

        if(this.props.location!==prevProps.location){
            if(this.props.location.pathname==="/chat"){
                this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
            }else{
                setTimeout( () => {
                    this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
                }, 3000 )
            }
        }
    }

    signOutUser = async () => {
        setTimeout( () => {
            if($('.modal-backdrop')[0]!==undefined){
                $('.modal-backdrop')[0].remove();
            }
            if($('.spinner-modal')[0]!==undefined){
                $('.spinner-modal')[0].remove();
            }
        }, 800)
        this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
        const { auth } = this.props;
        if(!$('.navbar-toggler').hasClass('collapsed')){
            $('.navbar-toggler').click();
        }
        this.props.signOutUser();

        
    }

    clickToggler = () => {
        $('.navbar-toggler').click();
    }

    setLanguage = (lang) => {
        const { isMobileView } = this.props;

        this.props.setCurrentLanguage(lang);
        this.props.updateCurrentUser({
            lang : lang
        });
        
        if(isMobileView){
            this.clickToggler();
        }
    }

    createTask = () => {
        this.props.history.replace({});
    }
    
    render(){

        const { auth, userProfile, location, assignedTasksCount, hasNewMessage, hasBasketTasks } = this.props;
        const { isMobileView } = this.props;
        const { t, currentLanguage } = this.props;

        let path = location.pathname;

        if(userProfile.type==="customer"){
            if(isMobileView){
                return (
                    <div className="collapse navbar-collapse navbar-dark" id="navbarNav" style={{ paddingTop: "30px"}}>
                        <ul className="navbar-nav">
                            <li className="header-nav-item nav-item">
                <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/profile">{t('navbar_profile')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                <Link onClick={this.clickToggler} to="/tasks/archived">{t('navbar_archived')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/outcome_reports">{t('navbar_outcome_report')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                <a role="menuitem" tabIndex="-1" href="javascript:void(0)" onClick={this.signOutUser}>{t('navbar_logout')}</a>
                            </li>
                            <li style={{ width : "100%", textAlign : "center" }} className="header-nav-item nav-item">
                                <div style={{ flex : "1", marginTop : "10px" }}>
                                    <img onClick={ () => this.setLanguage('ar')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                    <img onClick={ () => this.setLanguage('en')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            }else{
                return (
                    <div className="collapse navbar-collapse navbar-dark" id="navbarNav" style={{ paddingTop: "30px"}}>
                        <ul className={`navbar-nav ${currentLanguage==="ar" ? "mr-auto" : "ml-auto"}`}>
                            <li className="header-nav-item nav-item">
                                <Link className={`header-nav-link nav-link ${path==="/task/published" ? "active-link" : ""}`} to="/tasks/published">{t('navbar_published')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <Link onClick={this.props.resetMessageBadge} className={`header-nav-link nav-link ${path==="/chat" ? "active-link" : ""}`} to="/chat">
                                    <div style={{ position : "relative" }}>{t('navbar_messenger')} { hasNewMessage && <img style={{ width: "10px", position : "absolute", top : "2px", right : "-10px" }} src={require('../../images/icon-archived.png')}/> }</div>
                                </Link>
                            </li>
                            {/* <li className="header-nav-item nav-item">
                                <a className="header-nav-link nav-link" href="javascript:void(0)"><img style={{ width: "60%" }} src={ require('../../images/notification-bell.png') }/></a>
                            </li> */}
                            <li className="header-nav-item nav-item">
                                <div style={{ position : "relative"}}>
                                    { hasBasketTasks && <img style={{ width: "10px", position : "absolute", top : "15px", right : "10px" }} src={require('../../images/icon-archived.png')}/> }
                                    <Link className="header-nav-link nav-link" to="/tasks/basket"><img style={{ width: "60%" }} src={ require('../../images/basket.png') }/></Link>
                                </div>
                            </li>
                            <li className="header-nav-item nav-item"  style={{ paddingTop: "5px" }}>
                                <Link onClick={this.createTask} className="btn-link btn-create-task-nav nav-link" to="/task/create">{t('navbar_create_task')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="imageDropdown" data-toggle="dropdown"><img className="avatar" src={ userProfile.imageUrl ? userProfile.imageUrl : require('../../images/default-user.jpg') }/></a>
                                    <ul className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                        <li role="presentation"><Link role="menuitem" tabIndex="-1" to="/profile">{t('navbar_profile')}</Link></li>
                                        <li role="presentation"><Link role="menuitem" tabIndex="-1" to="/outcome_reports">{t('navbar_outcome_report')}</Link></li>
                                        <li role="presentation"><Link to="/tasks/archived">{t('navbar_archived')}</Link></li>
                                        <li role="presentation" className="divider"></li>
                                        <li role="presentation"><a role="menuitem" tabIndex="-1" href="javascript:void(0)" onClick={this.signOutUser}>{t('navbar_logout')}</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li style={{ textAlign : "center" }} className="header-nav-item nav-item">
                                <div style={{ flex : "1", marginTop : "5px" }}>
                                    <img onClick={ () => this.setLanguage('ar')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                    <img onClick={ () => this.setLanguage('en')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                )
            }
        }

        if(userProfile.type==="translator"){
            if(isMobileView){
                return (
                    <div className="collapse navbar-collapse navbar-dark" id="navbarNav" style={{ paddingTop: "30px"}}>
                        <ul className="navbar-nav">
                            <li className="header-nav-item nav-item">
                                <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/profile">{t('navbar_profile')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <Link onClick={this.clickToggler} to="/tasks/archived">{t('navbar_archived')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <Link onClick={this.clickToggler} to="/tasks/assigned">{t('navbar_assigned')} { (assignedTasksCount>0) ? <span className="assigned-task-count">{assignedTasksCount}</span> : "" }</Link>
                            </li>
                            <li onClick={this.clickToggler} className="header-nav-item nav-item">
                                <Link to="/tasks/in_progress">{t('navbar_in_progress')}</Link>
                            </li>
                            <li onClick={this.clickToggler} className="header-nav-item nav-item">
                                <Link to="/income_reports" role="menuitem" tabIndex="-1" href="javascript:void(0)">{t('navbar_income_report')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <a role="menuitem" tabIndex="-1" href="javascript:void(0)" onClick={this.signOutUser}>{t('navbar_logout')}</a>
                            </li>
                            <li style={{ width : "100%", textAlign : "center" }} className="header-nav-item nav-item">
                                <div style={{ flex : "1", marginTop : "10px" }}>
                                    <img onClick={ () => this.setLanguage('ar')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                    <img onClick={ () => this.setLanguage('en')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            }else{
                return (
                    <div className="collapse navbar-collapse navbar-dark" id="navbarNav" style={{ paddingTop: "30px"}}>
                        <ul className={`navbar-nav ${currentLanguage==="ar" ? "mr-auto" : "ml-auto"}`}>
                            <li className="header-nav-item nav-item">
                                <Link onClick={this.props.clearAssignedTasksCount} className={`header-nav-link nav-link ${path==="/tasks/assigned" ? "active-link" : ""}`} to="/tasks/assigned">{t('navbar_assigned')} { (assignedTasksCount>0) ? <span className="assigned-task-count">{assignedTasksCount}</span> : "" }</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <Link className={`header-nav-link nav-link ${path==="/tasks/in_progress" ? "active-link" : ""}`} to="/tasks/in_progress">{t('navbar_in_progress')}</Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <Link onClick={this.props.resetMessageBadge} className={`header-nav-link nav-link ${path==="/chat" ? "active-link" : ""}`} to="/chat">
                                    <div style={{ position : "relative" }}>{t('navbar_messenger')} { hasNewMessage && <img style={{ width: "10px", position : "absolute", top : "2px", right : "-10px" }} src={require('../../images/icon-archived.png')}/> }</div>
                                </Link>
                            </li>
                            <li className="header-nav-item nav-item">
                                <div className="dropdown">
                                    <a href="javascript:void(0)" id="imageDropdown" data-toggle="dropdown"><img className="avatar" src={ userProfile.imageUrl ? userProfile.imageUrl : require('../../images/default-user.jpg') }/></a>
                                    <ul className="dropdown-menu custom-dropdown-menu" role="menu" aria-labelledby="imageDropdown">
                                        <li role="presentation"><Link role="menuitem" tabIndex="-1" to="/profile">{t('navbar_profile')}</Link></li>
                                        <li role="presentation"><Link to="/income_reports" role="menuitem" tabIndex="-1" href="javascript:void(0)">{t('navbar_income_report')}</Link></li>
                                        <li role="presentation"><Link to="/tasks/archived">{t('navbar_archived')}</Link></li>
                                        <li role="presentation" className="divider"></li>
                                        <li role="presentation"><a role="menuitem" tabIndex="-1" href="javascript:void(0)" onClick={this.signOutUser}>{t('navbar_logout')}</a></li>
                                    </ul>
                                </div>
                                
                            </li>
                            <li style={{ textAlign : "center" }} className="header-nav-item nav-item">
                                <div style={{ flex : "1", marginTop : "5px" }}>
                                    <img onClick={ () => this.setLanguage('ar')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                    <img onClick={ () => this.setLanguage('en')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        assignedTasks : state.task.assignedTasks,
        hasBasketTasks: state.task.hasBasketTasks,
        assignedTasksCount : state.task.assignedTasksCount,
        hasNewMessage : state.conversation.hasNewMessage,
        newInProgressTasks : state.task.newInProgressTasks,
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        signOutUser,
        setUserPresence,
        clearAssignedTasksCount,
        watchAssignedTasks,
        watchNewMessages,
        resetMessageBadge,
        watchNewInProgressTasks,
        removeNewInProgressDisplay,
        addModal,
        popModal,
        getBasketCount,
        setCurrentLanguage,
        updateCurrentUser,
        watchBasketTasks
    })
)(LoggedInLinks)
