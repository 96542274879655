import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';
import $ from 'jquery';

const renderStatus = (status, t) => {

    if(status==="inProgress"){
        return <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={require('../../../../images/icon-inprogress.png')}/></span>{t('common_stat_in_progress')}</p>
    }

    if(status==="forReview"){
        return <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={require('../../../../images/icon-review.png')}/></span>{t('common_stat_for_review')}</p>
    }

    if(status==="completed"){
        return <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={require('../../../../images/icon-completed.png')}/></span>{t('common_stat_completed')}</p> 
    }

}

const renderPaymentStatus = (status, t) => {
    switch(status){
        case "prepaid" :
            return t('common_pay_stat_unpaid')
        case "fullyPaid" :
            return t('common_pay_stat_paid')
        default:
            return t('common_pay_stat_unpaid')
    }
}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class Item extends Component {

    state = {
        shouldRender : false
    }

    openTaskDetailsModal = (task, activeTab) => {
        this.props.openTaskDetailsModal(task, activeTab);
    }

    openEscalateTaskModal = (task) => {
        this.props.openEscalateTaskModal(task)
    }

    componentDidMount(){
        const { displayDelay } = this.props;
        this.timer = setTimeout(() => {
            this.setState({ shouldRender: true }, () => {
                const scrollHeight = $('html')[0].scrollHeight;
                const height = $('html')[0].clientHeight;
                const maxScrollTop = scrollHeight - height;
                // $("html, body").animate({ scrollTop : maxScrollTop > 0 ? maxScrollTop : 0 }, 200);
            });
        }, displayDelay);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){
        const { shouldRender } = this.state;
        const { task, isMobileView , t, currentLanguage } = this.props;
        
        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
        
        let d = new Date();
        let dateAddedBar = new Date(moment.unix(dateAdded))
        let deadlineBar = new Date(moment.unix(deadline))
        let timelineBar = moment(deadlineBar).diff(dateAddedBar, 'days')
        let daysDiffBar = moment(deadlineBar).diff(d,'days')

        let sendFilesBtnStyle = {
            color : "#22C9C7",
            fontSize  : "14px",
            fontWeight : "bold",
            borderRadius : "8px",
            boxSizing : "border-box",
            border : "2px solid #22C9C7",
            width: isMobileView ? "100%" : "90%",
            padding: "12px 16px",
            marginTop : "15px",
            background : "transparent",
            position : isMobileView ? "unset" : "absolute",
            bottom : "0",
            right : "15px"
        }

        let passedDueStyle = {};
        let progressBarClasses = "progress-bar custom-progress-bar-safe";

        let progressBarStyle = {}

        if(moment.unix(deadline).diff(moment(new Date()), 'days')<=0){
            sendFilesBtnStyle.color = "#FF6666"
            sendFilesBtnStyle.border = "2px solid #FF6666"

            passedDueStyle = {
                color : "#FF6666"
            }

            progressBarClasses = "progress-bar custom-progress-bar-danger"
        }

        if(daysDiffBar<=0){
            progressBarStyle.width = "100%"
        }else{
            progressBarStyle.width = 100 - ((daysDiffBar/timelineBar) * 100) +"%"
        }

        let earnings = task.price - task.remainingAmount;

        if(isMobileView){
            return (
                <div>
                    {
                        shouldRender &&
                            <div className="task-item animated fadeInUp">
                                <div onClick={ () => this.openTaskDetailsModal(task,'task-files')}  className="row" style={{ marginRight : "5px", marginLeft : "5px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                    <div className="col" style={{ position : "relative", padding : "0" }}>
                                        <span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span>
                                        <p style={{ marginBottom : "15px", marginTop : "15px" }} className="task-title">{task.title}</p>
                                        <span className="order-number" style={{ marginBottom : "15px", display: "block", fontSize : "13px", color: "#676A6C" }}><span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                        <div className="row" style={{ width: "100%" }}>
                                            <div className="col">
                                                {renderStatus(task.status, t)}
                                            </div>
                                            <div className="col">
                                                <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={ task.paymentStatus==="fullyPaid" ? require('../../../../images/icon-paid.png') : require('../../../../images/icon-unpaid.png')}/></span>{renderPaymentStatus(task.paymentStatus,t)}</p>
                                            </div>
                                        </div>
                                        <div class="btn-group" style={{ position : "absolute", top : "-7px", right : currentLanguage == "ar" ? "unset" : "15px", left : currentLanguage == "ar" ? "15px" : "unset"  }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                                <button onClick={ () => this.props.openEscalateTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" onClick={ () => this.openTaskDetailsModal(task,'task-files')} style={{  marginRight : "5px", marginLeft : "5px", paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_task_created')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_deadline')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).format("Y/MM/DD")}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_word_count')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{task.numberOfWords}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_days_to_deadline')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span></div>
                                    </div>
                                </div>
                                <div className="row" onClick={ () => this.openTaskDetailsModal(task,'task-files')} style={{  marginRight : "5px", marginLeft : "5px", paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA" }}>
                                    <div className="col" style={{ padding : 0 }}>
                                        <p className="task-description align-left"><span style={{ color : "#7e7e7e" }}>&nbsp;</span>{task.detailedInstructions}</p>
                                        <p className="align-left" style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>Time to deadline</p>
                                        <div style={{ display : "flex" }}>
                                            <div style={{ flex : "1" }}>
                                                {
                                                    task.status!=="new" && 
                                                    <div className="progress mt-1 custom-progress">
                                                        <div className={progressBarClasses} aria-valuenow={daysDiffBar} aria-valuemin={0} aria-valuemax={timelineBar} style={progressBarStyle}></div>
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    (task.status!=="new" && daysDiffBar<= 0) && <img src={require('../../../../images/danger.png')} style={{ marginLeft : "13px", width: "24px", height : "24px" }}/>
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row" className="row" style={{ marginRight : "5px", marginLeft : "5px", paddingBottom : "15px" }}>
                                    <div className="col" style={{ paddingLeft : "0" }}>
                                        <span className="align-left" style={{ display : "block", fontSize:"20px", color : "#464646" }}>{t('in_progress_task_your_earnings')}</span>
                                        <p className="task-remaining-amount">${ Number.isInteger(task.translatorEarnings) ? task.translatorEarnings.toFixed(2) : task.translatorEarnings }</p>
                                        <button onClick={ () => this.openTaskDetailsModal(task,'task-files')} style={sendFilesBtnStyle}>{t('in_progress_task_send_file_result')}</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        }else{
            return (
                <div>
                    {
                        shouldRender &&
                            <div className="task-item animated fadeInUp">
                                <div className="row">
                                    <div onClick={ () => this.openTaskDetailsModal(task,'task-files')} style={{ cursor : "pointer", borderRight: currentLanguage==="ar" ? "none" : "1px solid #E9E9EA", borderLeft: currentLanguage==="ar" ? "1px solid #E9E9EA" : "none" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <div className="row pl-2">
                                            <div className="col">
                                                {renderStatus(task.status, t)}
                                            </div>
                                            <div className="col">
                                                <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={ task.paymentStatus==="fullyPaid" ? require('../../../../images/icon-paid.png') : require('../../../../images/icon-unpaid.png')}/></span>{renderPaymentStatus(task.paymentStatus, t)}</p>
                                            </div>
                                        </div>
                                        <div className="row pl-2">
                                            <div className="col">
                                                <span className="field-label">{t('common_task_created')}</span>
                                                <span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span>
                                            </div>
                                            <div className="col">
                                                <span className="field-label">{t('common_deadline')}</span>
                                                <span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).format("Y/MM/DD")}</span>
                                            </div>
                                        </div>
                                        <div className="row pl-2 mt-4">
                                            <div className="col">
                                                <span className="field-label">{t('common_word_count')}</span>
                                                <span className="field-value">{task.numberOfWords}</span>
                                            </div>
                                            <div className="col">
                                                <span className="field-label">{t('common_days_to_deadline')}</span>
                                                <span className="field-value" style={passedDueStyle}>{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                                            </div>
                                        </div>
                
                                    </div>
                                    <div onClick={ () => this.openTaskDetailsModal(task,'task-files')} style={{ cursor : "pointer", borderRight: currentLanguage==="ar" ? "none" : "1px solid #E9E9EA", borderLeft: currentLanguage==="ar" ? "1px solid #E9E9EA" : "none" }}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="row pl-2">
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <p className="task-title">{task.title}</p>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                                            <span className="order-number" style={{ fontSize : "14px", color: "#676A6C" }}><span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                            </div>
                                        </div>
                                        <div className="row pl-2">
                                            <div className="col">
                                                <p className="task-description align-left"><span style={{ color : "#7e7e7e" }}>&nbsp;</span>{task.detailedInstructions}</p>
                                            </div>
                                        </div>
                                        <div style={{ display : "flex", marginTop : "20px" }}>
                                            <div>
                                                <p className="align-left" style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>Time to deadline</p>
                                            </div>
                                            <div style={{ flex : "1", marginLeft : "13px" }}>
                                                <div className="progress mt-1 custom-progress">
                                                    <div className={progressBarClasses} aria-valuenow={daysDiffBar} aria-valuemin={0} aria-valuemax={timelineBar} style={progressBarStyle}></div>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    daysDiffBar<= 0 && <img src={require('../../../../images/danger.png')} style={{ marginLeft : "13px", width: "24px", height : "24px" }}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <div class="btn-group" style={{ position : "absolute", top : "-7px", right : currentLanguage === "ar" ? "unset" : "15px",  left : currentLanguage === "ar" ? "15px" : "unset" }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                                <button onClick={ () => this.props.openEscalateTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                            </div>
                                        </div>
                                        <span className="block align-left" style={{ fontSize:"20px", color : "#464646" }}>{t('in_progress_task_your_earnings')}</span>
                                        <p className="task-remaining-amount">${ Number.isInteger(task.translatorEarnings) ? task.translatorEarnings.toFixed(2) : task.translatorEarnings }</p>
                                        <button onClick={ () => this.openTaskDetailsModal(task,'task-files')} style={sendFilesBtnStyle}>{t('in_progress_task_send_file_result')}</button>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        }

        

    }

}

export default Item;
