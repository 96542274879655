import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import BeautyStars from 'beauty-stars';

import { 
    createConversationMessageAM, 
    clearConversationAndMessages, 
    createConversation, 
    createConversationMessage, 
    checkConversation,
    setActiveConversation,
    uploadMessageFiles,
    updateConversationLastOpenedDate
} from '../../../actions/conversationAction'

import {
    getTaskTransactions,
    uploadResultFiles,
    removeResultFile,
    updateTask,
    createRating,
    getTaskRatings
} from '../../../actions/taskAction'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

import SpinnerModal from '../../Spinner/SpinnerModal'
import Header from '../Conversation/Header';
import Footer from '../Conversation/Footer';
import MessageList from '../Conversation/MessageList';
import ConfirmationModal from './Confirmation'
import MessageModal from '../../Modals/Message'

import { translate } from 'react-switch-lang'

export class TaskDetailsModal extends Component {

    constructor(){
        super();
        this.state = {
            selectedTask : {},
            taskTrans : [],
            taskRatings : [],
            translatorResultFiles : [],
            translatorResultFilesURL : [],
            transResFilesUploaded : false,
            sentForReview : false,
            isProcessing : false,
            activeTab : "task-files",
            managerRating : 0,
            managerRated : false,
            translatorRating : 0,
            translatorRated : false,
            managerFeedback : "",
            translatorFeedback : "",
            isConfirmationModalActive : false,
            isMessageModalActive : false,
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened, task, activeTab, currentLanguage } = this.props;
        
        if(isOpened){
     
            $('#taskDetails').modal({backdrop:'static', keyboard:false});
            $('#taskDetails .modal-dialog').attr('class',`modal-dialog modal-dialog-centered ${currentLanguage==="ar" ? 'slideInLeft' : 'slideInRight'} animated`)
            $('#taskDetails').modal('show');
            this.props.addModal('right-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("td-bd");

            if(task.status==="forReview"){  
                this.setState({
                    sentForReview : true
                })
            }

            if(task.resultFiles.length===0){
                
                this.setState({
                    selectedTask : {...task}
                }, () => {
                    if(activeTab==="billing-details"){
                        $(`#li-${activeTab}`).trigger('click')
                    }
                })
            }else{
               
                this.setState({
                    translatorResultFilesURL : [...task.resultFiles],
                    translatorResultFiles : [...task.resultFiles],
                    selectedTask : {...task},
                    transResFilesUploaded : true
                }, () => {
                    if(activeTab==="billing-details"){
                        $(`#li-${activeTab}`).trigger('click')
                    }
                })
            }  
        }

    }

    componentWillUnmount(){
        $('#taskDetails').modal('hide');
        $('body').removeClass('modal-open')
        this.props.clearConversationAndMessages()
        this.props.popModal()
    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="right-modal");
            if(modalInstance!==undefined){
                $('.td-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }

        }
    }

    checkConversation = async () => {

        const { selectedTask } = this.state;
        const { userProfile, auth, activeConversation } = this.props;

        if(selectedTask.accountManager!==""){
            let checkData = {
                userType : userProfile.type,
                taskUid : selectedTask.uid,
                recipient : "accountManager",
                accountManager : selectedTask.accountManager,
            }
    
            let result = await this.props.checkConversation(checkData);
            
            if(result.code===404){

                let memberData = []
                let memberTypes = {}
                let members = []

                memberData = {
                    [auth.uid] : {
                        fullName : userProfile.fullName,
                        imageUrl : userProfile.imageUrl !== undefined ? userProfile.imageUrl : "",
                        userType : userProfile.type
                    },
                    [result.accountManager.uid] : {
                        fullName : result.accountManager.fullName,
                        imageUrl : result.accountManager.imageUrl !== undefined ? result.accountManager.imageUrl : "",
                        userType : "accountManager"
                    }
                }

                members = [
                    auth.uid,
                    result.accountManager.uid
                ]
    
                memberTypes = {
                    accountManager : true,
                    [userProfile.type] : true
                }

                await this.props.createConversation({
                    members : members,
                    memberData : memberData,
                    memberTypes : memberTypes,
                    lastMessageSentText : "",
                    lastMessageSentBy : "",
                    lastMessageSentByUserType : "",
                    task : selectedTask.uid,
                    taskTitle : selectedTask.title,
                    initiatedBy : auth.uid
                })
            }else{
                this.props.setActiveConversation(result.data)
            }
        }else{

        }        
    }

    updateUserOpenedDate = () => {

        const { activeConversation, userProfile } = this.props;
        this.props.updateConversationLastOpenedDate({ uid : activeConversation.uid });

    }

    sendMessage = async (data) => {
        
        const { activeConversation, auth, userProfile } = this.props;
        const { selectedTask } = this.state;

        if(data.message || data.files.length!==0) {

            let recipientType = Object.keys(activeConversation.memberTypes).find( data => data!== userProfile.type)
            let recipientUid = activeConversation.members.find( data => data!== userProfile.uid);
            let recipientName = activeConversation.memberData[recipientUid].fullName;
            let recipientImage = activeConversation.memberData[recipientUid].imageUrl;


            if(data.files.length!==0){

                let result = await this.props.uploadMessageFiles(data.files, activeConversation.uid);

                let messageData = {
                    conversation : activeConversation.uid,
                    text : data.message,
                    attachedFiles : [...result],
                    sentBy : auth.uid,
                    senderType : userProfile.type,
                    senderName : userProfile.fullName,
                    senderImage : userProfile.imageUrl,
                    recipient : recipientUid,
                    recipientType : recipientType,
                    recipientName : recipientName,
                    recipientImage : recipientImage,
                    members : activeConversation.members
                }
                
                this.props.createConversationMessage(messageData)
            }else{
                let messageData = {
                    conversation : activeConversation.uid,
                    text : data.message,
                    attachedFiles : [],
                    sentBy : auth.uid,
                    senderType : userProfile.type,
                    senderName : userProfile.fullName,
                    senderImage : userProfile.imageUrl,
                    recipient : recipientUid,
                    recipientType : recipientType,
                    recipientName : recipientName,
                    recipientImage : recipientImage,
                    members : activeConversation.members
                }
                
                this.props.createConversationMessage(messageData)
            }
        }
    }

    checkTaskTransactions = async (data) => {

        const { selectedTask } = this.state;

        this.props.getTaskTransactions(selectedTask.uid).then( (result) => {
            if(result.code==200){
                this.setState({
                    taskTrans : [...result.data.transactions]
                })
            }

        })

    }

    openFileUpload = async (e) => {
        e.preventDefault();
        $('#result_files_attachment').click();
        // const { translatorResultFiles, selectedTask } = this.state;

        // if(translatorResultFiles.length!==0){

        //     this.props.toggleProcessing(true)

        //     let result = await this.props.uploadResultFiles(selectedTask.uid, translatorResultFiles);

        //     let updatedTask = await this.props.updateTask({
        //         uid : selectedTask.uid,
        //         resultFiles : result
        //     })

        //     setTimeout( () => {

        //         this.setState({
        //             translatorResultFilesURL : [...result],
        //             transResFilesUploaded : true,
        //             selectedTask : {...updatedTask.data}
        //         }, () => {
        //             this.props.toggleProcessing(false)
        //         })

        //     }, 1500)

        // }else{
        //     $('#result_files_attachment').click();
        // }
    }

    sendForReview = async () => {
        const { selectedTask, transResFilesUploaded, sentForReview } = this.state;
        const { t } = this.props;

        if(transResFilesUploaded && sentForReview){
            $('.error-input-message').text(t('task_modal_details_upload_new_results_error'))
        }else{
            $('.error-input-message').text('')

            this.props.toggleProcessing(true)

            let updatedTask = await this.props.updateTask({
                uid : selectedTask.uid,
                status : "forReview"
            })

            this.setState({
                isProcessing : false,
                selectedTask : {...updatedTask.data},
                sentForReview : true
            }, () => {
                this.props.toggleProcessing(false)
                this.props.sentForReview();
            })
            
        }
    }

    openConfirmationModal = (data) => {
        const { t } = this.props;

        this.setState({
            isConfirmationModalActive : true,
            confirmationHeader : t('task_modal_details_message_remove_all')
        })
    }

    closeConfirmationModal = () => {
        this.setState({
            isConfirmationModalActive : false,
            confirmationHeader : "",
        }, () => {
            
            setTimeout( () => {
                $('body').addClass('modal-open')
            }, 1000)

        })
    }

    confirm = () => {
        this.closeConfirmationModal();
        this.removeAllFiles();
    }

    onFileChange = async (e) => {

        let allowedMimeTypes = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"]
        e.preventDefault();

        if(e.target.files!==null && e.target.files!==undefined){
        
            let invalidFiles = 0;
            Object.keys(e.target.files).map( (data,i) => {
                if(allowedMimeTypes.indexOf(e.target.files[i].type)==-1){
                    invalidFiles++;
                }
            })

            const { t } = this.props;

            if(invalidFiles!=0){
                this.props.displayErrorUpload(t('common_task_result_upload'));
                return;
            }else{
                const { selectedTask } = this.state;
    
                if(e.target.files.length>1){
                    $('#result-files-message').text('')
                    this.props.toggleProcessing(true)
                    
                    this.setState({
                        translatorResultFiles : [...e.target.files]
                    })
                    let result = await this.props.uploadResultFiles(selectedTask.uid, e.target.files);
    
                    let errorIndex = result.findIndex( c => c.code == 500);
    
                    if(errorIndex!==-1){
                        this.props.displayErrorUpload(t('common_something_went_wrong'));
                        return;
                    }

                    let resultF = [];

                    result.map( (data) => {
                        resultF.push(data.data)
                    })

    
                    let updatedTask = await this.props.updateTask({
                        uid : selectedTask.uid,
                        resultFiles : resultF
                    })
    
                    let arr = [];
                    result.map( (d) =>{
                        if(d.code==200)
                            arr.push(d.data);
                    })
    
                    if(arr.length<=1){
                        $('#result-files-message').text(t('task_modal_details_send_for_review_single'))
                    }else{
                        this.setState({
                            translatorResultFilesURL : [...arr],
                            transResFilesUploaded : true,
                            selectedTask : {...updatedTask.data}
                        }, () => {
                            this.props.toggleProcessing(false)
                            setTimeout( () => {
                                $('body').addClass('modal-open')
                            }, 1000)
                        })
                        $('#result-files-message').text('');
                        
                    }
                }else{
                    $('#result-files-message').text(t('task_modal_details_send_for_review_single'))
                }
            }
        }
    }

    removeToFileList = (key) => {
        const { translatorResultFiles } = this.state;
        let newFiles = translatorResultFiles;

        newFiles.splice(key,1);

        this.setState({
            translatorResultFiles : [...newFiles]
        })

    }

    removeAllFiles = async () => {

        this.props.toggleProcessing(true);
        
        const { translatorResultFilesURL, selectedTask } = this.state;

        translatorResultFilesURL.forEach( async data => {
            await this.props.removeResultFile(null,data);
        })  

        $('#result_files_attachment').val('');
        let updatedTask = await this.props.updateTask({
            uid : selectedTask.uid,
            resultFiles : [],
            status : "inProgress"
        })

        this.setState({
            translatorResultFilesURL : [],
            translatorResultFiles : [],
            transResFilesUploaded : false,
            selectedTask : {...updatedTask.data},
            sentForReview : false
        }, () => {
            $('.error-input-message').text('')
            this.props.toggleProcessing(false)
            setTimeout( () => {
                $('body').addClass('modal-open')
            }, 1000)
        })
    }

    onInputChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    saveFeedback = async () => {

        const { managerFeedback, translatorFeedback, selectedTask, managerRating, translatorRating } = this.state;
        let ratingsData = [];

        ratingsData.push({
            recipient : selectedTask.accountManager,
            taskUid : selectedTask.uid,
            userRole : "accountManager",
            rating : managerRating,
            feedback : managerFeedback
        })

        ratingsData.push({
            recipient : selectedTask.assignedTranslator,
            taskUid : selectedTask.uid,
            userRole : "translator",
            rating : translatorRating,
            feedback : translatorFeedback
        })

        this.props.toggleProcessing(true)
        this.props.createRating(ratingsData).then( (result) => {
            this.setState({
                isProcessing : false,
                managerRated : true,
                translatorRated : true
            })
            this.props.toggleProcessing(false)
            setTimeout( () => {
                $('body').addClass('modal-open')
            }, 1000)
            this.setState({
                isMessageModalActive : true
            })
        })
      
    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        })
    }

    getTaskRatings = async () => {
        const { selectedTask } = this.state;

        this.props.getTaskRatings(selectedTask.uid).then( (result) => {
            if(result.code==200){

                result.data.map( (data, i) => {

                    if(data.role==="accountManager"){
                        this.setState({
                            managerRating : data.rating,
                            managerRated : true
                        })
                    }

                    if(data.role==="translator"){
                        this.setState({
                            translatorRating : data.rating,
                            translatorRated : true
                        })
                    }

                })

                this.setState({
                    taskRatings : [...result.data],
                    managerFeedback : result.data.length !== 0 ? result.data[0].feedback : "",
                    translatorFeedback : result.data.length !== 0 ? result.data[1].feedback : ""
                })
            }

        })

    }

    openBillingTab = () => {
        this.checkTaskTransactions();
        this.getTaskRatings();
    }

    render(){

        const { couponCode, task, activeConversation, userProfile, activeTab, isCheckingConversation } = this.props;
        const { isMessageModalActive, modalZ, confirmationHeader, isConfirmationModalActive, isCouponCodeModalActive, isProcessing, managerFeedback, translatorFeedback, taskTrans, translatorResultFiles, translatorResultFilesURL, transResFilesUploaded } = this.state;
        
        const { isMobileView } = this.props;
        const { t , currentLanguage } = this.props;

        let tasks = [];
        tasks.push(task)

        const taskDetailsHeader = {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "64px",
            color: "#464646",
            marginBottom : "10px"
        }

        const resultFileItem = {
            border: "2px solid #29B6F6",
            boxSizing: "border-box",
            borderRadius: "8px",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "16px",
            textTransform: "uppercase",
            color: "#29B6F6",
            background : "#FFF",
            padding : "14px 16px",
            marginRight : currentLanguage == "ar" ? "unset" : "10px",
            marginLeft : currentLanguage == "ar" ? "10px" : "unset",
            textDecoration : "none"
        }

        const taskFileItem = {
            border: "2px solid #29B6F6",
            boxSizing: "border-box",
            borderRadius: "8px",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "16px",
            textTransform: "uppercase",
            color: "#29B6F6",
            background : "#FFF",
            padding : "14px 16px",
            marginRight : currentLanguage == "ar" ? "unset" : "16px",
            marginLeft : currentLanguage == "ar" ? "16px" : "unset",
            textDecoration : "none",
            width: "204px"
        }

        const disabledResultFileItem = {
            border: "2px solid #E6E4E8",
            boxSizing: "border-box",
            borderRadius: "8px",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "16px",
            textTransform: "uppercase",
            color: "#E6E4E8",
            background : "#FFF",
            padding : "14px 16px",
            marginRight : currentLanguage == "ar" ? "unset" : "10px",
            marginLeft : currentLanguage == "ar" ? "10px" : "unset",
            textDecoration : "none"
        }

        const filesFromTranslatorStyle = {
            color : "#414042",
            fontSize : "12px"
        }

        const paymentHeader = {
            fontSize : "16px",
            fontWeight : "bold",
            color : "#414042"
        }

        let d = new Date();
        let dateAdded = new Date(moment.unix(task.dateAdded.seconds))
        let deadline = new Date(moment.unix(task.deadline.seconds))
        let timeline = moment(deadline).diff(dateAdded, 'days')

        let daysDiff = moment(deadline).diff(d,'days')

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        return (
            <div>
                <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} modal right-modal fade`}  id="taskDetails" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {
                                isMobileView ?
                                <div className="mobile-custom-header modal-header" style={{ position: "relative" }}>
                                    <div class="btn-group" style={{ position : "absolute", top : "20px", right : currentLanguage == "ar" ? "unset" : "15px", left : currentLanguage == "ar" ? "15px" : "unset", }}>
                                        <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        . . .
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                        { (userProfile.type ==="customer" && task.status!=="completed") && <button onClick={ () => this.props.openPauseTaskModal(task,"task-details")} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_pause')}</button> }
                                        { (userProfile.type ==="customer" && task.status!=="completed") && <button onClick={ () => this.props.openStopTaskModal(task,"task-details")} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_stop')}</button> }
                                        <button onClick={ () => this.props.openEscalateTaskModal(task,"task-details")} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                        </div>
                                    </div>
                                    <p style={taskDetailsHeader}> <img onClick={this.props.closeTaskDetailsModal} style={{ cursor: "pointer", width: "10px", height : "15px", marginRight: currentLanguage==="ar" ? "unset" : "15px",  marginLeft: currentLanguage==="ar" ? "15px" : "unset" }} src={require('../../../images/back.png')}/>{t('task_modal_details_header')}</p>
                                </div>
                                :
                                <div className="modal-header" style={{ position: "relative" }}>
                                    <div class="btn-group" style={{ position : "absolute", top : "20px", right : currentLanguage == "ar" ? "unset" : "15px", left : currentLanguage == "ar" ? "15px" : "unset" }}>
                                        <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        . . .
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                        { (userProfile.type ==="customer" && task.status!=="completed") && <button onClick={ () => this.props.openPauseTaskModal(task,"task-details")} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_pause')}</button> }
                                        { (userProfile.type ==="customer" && task.status!=="completed") && <button onClick={ () => this.props.openStopTaskModal(task,"task-details")} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_stop')}</button> }
                                        <button onClick={ () => this.props.openEscalateTaskModal(task,"task-details")} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                        </div>
                                    </div>
                                    <p style={taskDetailsHeader}> <img id="td-close"  onClick={this.props.closeTaskDetailsModal} style={{ cursor: "pointer", width: "10px", height : "15px",marginRight: currentLanguage==="ar" ? "unset" : "15px",  marginLeft: currentLanguage==="ar" ? "15px" : "unset" }} src={require('../../../images/back.png')}/>{t('task_modal_details_header')}</p>
                                </div>
                            }

                            <div  className="modal-body" style={{ overflowY : "auto" }}>
                                <div >
                                    <ul className="nowrap-nav nav nav-tabs nav-fill nav-header" role="tablist" style={{ width : isMobileView ? window.innerWidth : "unset", marginTop : "5px !important", marginRight : "40px", marginLeft : "40px", marginTop : "32px" }}>
                                        <li className="nav-item" >
                                            <a className={`nav-link ${activeTab==="task-files" ? "active" : ""}`} id="task-files-tab" data-toggle="tab" href="#task-files" role="tab" aria-controls="task-files" aria-selected={ activeTab === "task-files" }>{t('task_modal_details_task_files')}</a>
                                        </li>
                                        <li onClick={this.checkConversation} className="nav-item">
                                            <a className={`nav-link ${activeTab==="chat-manager" ? "active" : ""}`} id="chat-manager-tab" data-toggle="tab" href="#chat-manager" role="tab" aria-controls="chat-manager" aria-selected={ activeTab === "chat-manager" }>{t('task_modal_details_chat_mngr')}</a>
                                        </li>
                                        {
                                            userProfile.type === "customer" && <li id="li-billing-details" onClick={this.openBillingTab} className="nav-item">
                                                <a className={`nav-link ${activeTab==="billing-details" ? "active" : ""}`} id="billing-details-tab" data-toggle="tab" href="#billing-details" role="tab" aria-controls="billing-details" aria-selected={ activeTab === "billing-details" }>{t('task_modal_details_billing_details')}</a>
                                            </li>
                                        }
                                        
                                    </ul>
                                </div>
                                <div className="task-details tab-content" id="myTabContent" style={{ position :"relative" }}>
                                    <div style={{ paddingTop: "30px", paddingBottom: "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="task-files" ? "active" : ""}`} id="task-files" role="tabpanel" aria-labelledby="task-files-tab">
                                        {/* TASK FILES CUSTOMER */}
                                        {
                                            userProfile.type==="customer" &&
                                            <div className="task-files">
                                                <p className="align-left" style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('common_description')}</p>
                                                <p className="task-description align-left"><span style={{ color : "#7e7e7e" }}>{t('common_description')}: &nbsp;</span>{ task.detailedInstructions}</p>
                                                <p className="align-left" style={{ marginBottom: "0", marginTop : "10px", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('task_modal_details_files_from_cust')}</p>
                                                <ul className="task-file-list">
                                                    { task.taskFiles.map( (data,i) => (
                                                        <li key={i} style={{ borderBottom : "none !important" }}>
                                                            <span style={{ fontSize : "16px !important" }} className="task-file-item"> <img className="task-file-item-icon" src={require('../../../images/icon-task-file.png')}/>{t('task_modal_details_for_translation', { number : (i+1)})}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                {
                                                    ( task.resultFiles.length===0 ) && <div>
                                                        <p  className="align-left" style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('task_modal_details_work_in_progress')}</p>
                                                        <div className="progress mt-1" style={{ height : "10px" }}>
                                                            <div className="progress-bar custom-progress-bar" aria-valuenow={daysDiff} aria-valuemin={0} aria-valuemax={timeline} style={{ width : 100 - ((daysDiff/timeline) * 100) +"%" }}></div>
                                                        </div>
                                                    
                                                        <div className="mt-2" style={{ display : "flex"}}>
                                                            <div style={{ flex : "1" }}>
                                                                <span className="block align-left" style={{ fontSize : "12px", fontWeight : "light", color : "#676A6C" }}>{t('common_task_created')}<br/></span>
                                                                <span className="block align-left" style={{ fontSize : "16px", fontWeight : "bold", color : '#293846' }}>{ moment.unix(task.dateAdded.seconds).format("Y/M/DD")}</span>
                                                            </div>
                                                            <div className="align-right" style={{ flex : "1"}}>
                                                                <span className="block align-right" style={{ fontSize : "12px", fontWeight : "light", color : "#676A6C"}}>{t('common_deadline')}<br/></span>
                                                                <span className="block align-right" style={{ fontSize : "16px", fontWeight : "bold", color : '#293846' }}>{ moment.unix(task.deadline.seconds).format("Y/M/DD")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (task.paymentStatus!=="fullyPaid" && task.resultFiles.length===0) &&
                                                    <div>
                                                        <p className="align-left" style={{  marginTop : "5px", color: "#676A6C", fontSize : "16px", fontWeight : "light"}}>{t('task_modal_details_notice')}</p> 
                                                        <p className="align-left" style={{ marginBottom: "0", fontSize : "14px", fontWeight : "bold", color : "#414042" }}>{t('common_need_to_pay')}</p>
                                                        <p  className="align-left"  style={{ color : "#464646", fontSize : "32px", fontWeight : "bold" }}>$ {Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount}</p>

                                                        <Link to={{
                                                            pathname: '/task/payment',
                                                            state: { tasks }
                                                        }} style={{ width: "100% !important"}} className="task-pay-left-details">{t('common_left_to_pay')}</Link>
                                                    </div>
                                                }
                                                {
                                                    (task.paymentStatus!=="fullyPaid" && task.resultFiles.length!==0) && <div className="mt-3">
                                                        <p className="align-left" style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('task_modal_details_files_from_translator')}</p>
                                                    
                                                        <ul style={{ listStyle : "none", paddingLeft : "0", marginTop : "20px"}}>
                                                            {
                                                                task.resultFiles.map ( (data, i) => (
                                                                    <li  key={i} style={{ borderBottom : "none !important", marginBottom : "40px" }}>
                                                                        {
                                                                            i===0 ? <a href={data} download style={resultFileItem}> <img style={{ width: "13px", height : "13px", marginRight : "10px" }} src={require('../../../images/icon-download.png')}/>{t('task_modal_details_file_parts', { number : (i+1)})}</a> : <a href="#" style={disabledResultFileItem}> <img style={{ width: "13px", height : "13px", marginRight : "10px" }} src={require('../../../images/icon-download-disabled.png')}/>{t('task_modal_details_file_parts', { number : (i+1)})}</a>
                                                                        }
                                                                        
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    
                                                        <p className="align-left" style={{  marginTop : "5px", color: "#676A6C", fontSize : "16px", fontWeight : "light"}}>{t('task_modal_details_notice')}</p> 

                                                        {
                                                            task.paymentStatus!=="fullyPaid" &&
                                                            <div>
                                                                <p style={{ marginBottom: "0", fontSize : "14px", fontWeight : "bold", color : "#414042" }}>{t('common_need_to_pay')}</p>
                                                                <p style={{ color : "#464646", fontSize : "32px", fontWeight : "bold" }}>$ {Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount}</p>
                                                            
                                                                <Link to={{
                                                                    pathname: '/task/payment',
                                                                    state: { tasks }
                                                                }} style={{ width: "100% !important"}} className="task-pay-left-details">{t('common_left_to_pay')}</Link>
                                                            </div>
                                                        }

                                                    </div>
                                                }
                                                {
                                                    (task.paymentStatus==="fullyPaid" && task.resultFiles.length!==0 && (task.status==="forReview" ||  task.status==="completed")) && <div className="mt-3">
                                                        <p className="align-left" style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('task_modal_details_files_from_translator')}</p>
                                                    
                                                        <ul style={{ listStyle : "none", paddingLeft : "0", marginTop : "20px"}}>
                                                            {
                                                                task.resultFiles.map ( (data, i) => (
                                                                    <li key={i} style={{ borderBottom : "none !important", marginBottom : "40px" }}>
                                                                        {
                                                                            <a href={data} download style={resultFileItem}> <img style={{ width: "13px", height : "13px", marginRight : currentLanguage == "ar" ? "unset" : "10px", marginLeft : currentLanguage == "ar" ? "10px" : "unset" }} src={require('../../../images/icon-download.png')}/>{t('task_modal_details_for_translation', { number : (i+1)})}</a>
                                                                        }
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                    
                                                
                                            </div>
                                        }
                                        {/* TASK FILES TRANSLATOR */}
                                        {
                                            userProfile.type==="translator" &&
                                            <div className="task-files">
                                                <div className="description-div">
                                                    <p className="align-left" style={{ marginBottom: "0", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('common_description')}</p>
                                                    <p className="task-description align-left"><span style={{ color : "#7e7e7e" }}>{t('common_description')}: &nbsp;</span>{ task.detailedInstructions}</p>
                                                </div>
                                                <ul style={{ display : "flex", listStyle : "none", paddingLeft : "0", marginTop : "20px"}}>
                                                    {
                                                        task.taskFiles.map ( (data, i) => (
                                                            <li key={i} style={{ borderBottom : "none !important" }}>
                                                                {
                                                                <a href={data} download style={taskFileItem}> <img style={{ width: "13px", height : "13px", marginRight : "10px" }} src={require('../../../images/icon-download.png')}/>{t('task_modal_details_to_translate')}</a>
                                                                }
                                                                
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                <p style={{ marginTop: "32px", marginBottom: "12px", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('task_modal_details_files_from_translator')}</p>
                                                {/* {
                                                    !transResFilesUploaded && 
                                                    <ul style={{ display : "flex", listStyle : "none", paddingLeft : "0", marginTop : "20px"}}>
                                                        {
                                                            translatorResultFiles.map ( (data, i) => (
                                                                <li key={i} style={{ borderBottom : "none !important" }}>
                                                                    {
                                                                        !transResFilesUploaded && <span style={taskFileItem}>{ <img onClick={ () => this.removeToFileList(i)} style={{ cursor : "pointer", width: "13px", height : "13px", marginRight : "10px" }} src={require('../../../images/icon-remove-result-file.png')}/>} { (i+1) } PART</span>
                                                                    }
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                } */}
                                                {
                                                    transResFilesUploaded && 
                                                    <ul style={{ listStyle : "none", paddingLeft : "0", marginTop : "20px"}}>
                                                        {
                                                            translatorResultFiles.map ( (data, i) => (
                                                                <li key={i} style={{ borderBottom : "none !important" }}>
                                                                    {
                                                                        transResFilesUploaded && <span style={filesFromTranslatorStyle}><img style={{ width: "13px", height : "13px", marginRight : currentLanguage == "ar" ? "unset" : "10px", marginLeft : currentLanguage == "ar" ? "10px" : "unset" }} src={require('../../../images/icon-task-file.png')}/>{t('task_modal_details_for_translation', { number : (i+1)})}</span>
                                                                    }
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                }
                                                {
                                                    transResFilesUploaded &&
                                                    <button onClick={this.openConfirmationModal} style={{ border : "none", background : "transparent", color : "#939097", fontSize : "14px", fontWeight : "bold" }}><img style={{ width: "13px", height : "13px", marginRight : currentLanguage == "ar" ? "unset" : "10px", marginLeft : currentLanguage == "ar" ? "10px" : "unset" }} src={require('../../../images/icon-remove-task-file.png')}/>{t('task_modal_details_delete_all')}</button>
                                                }
                                                <div style={{ display: "flex", marginTop : "28px" }}>
                                                    <input multiple onChange={this.onFileChange} type="file" name="result_files_attachment" id="result_files_attachment" style={{ display : "none" }} accept=".doc, .docx, .pdf"  />
                                                    {
                                                        !transResFilesUploaded &&
                                                        <button onClick={this.openFileUpload} style={{ flex : "1", border : "2px solid #22C9C7", borderRadius : "8px", background : "transparent", color : "#22C9C7", fontSize : "14px", fontWeight : "bold",  textTransform : "uppercase", padding : "16px 20px" }}>{t('task_modal_details_upload_file_result')}</button>
                                                    }
                                                    
                                                    {
                                                        (translatorResultFiles.length!==0 && transResFilesUploaded) &&
                                                        <button onClick={this.sendForReview} style={{ flex : "1", border : "none", borderRadius : "8px", background : "#22C9C7", color : "#FCFCFC", fontSize : "14px", fontWeight : "bold", padding : "16px 20px", textTransform : "uppercase" }}>{t('task_modal_details_send_for_review')}</button>

                                                    }
                                                </div>
                                                <span id="result-files-message" className="align-left block error-input-message"></span>
                                            </div>
                                        }
                                    </div>
                                    <div style={{ paddingTop: "10px", paddingBottom: "30px", textAlign : "left" }} className={`tab-pane fade show ${activeTab==="chat-manager" ? "active" : ""}`} id="chat-manager" role="tabpanel" aria-labelledby="chat-manager-tab">
                                        {
                                            task.accountManager!=="" ?
                                            (isCheckingConversation ? 
                                            <div class="custom-spinner-border spinner-border" role="status">

                                                <span class="sr-only">Loading...</span>
                                            </div> 
                                            :
                                            <div>
                                                {
                                                    activeConversation!==null && 
                                                    <div>
                                                        <Header t={t} currentLanguage={currentLanguage} conversation={activeConversation} task={task} />
                                                        <MessageList t={t} currentLanguage={currentLanguage} />
                                                        <Footer t={t} updateUserOpenedDate={this.updateUserOpenedDate} sendMessage={this.sendMessage}/>
                                                    </div>
                                                }
                                            </div>
                                            )
                                            :
                                            <div style={{ paddingTop : "20%" }}>
                                                <p style={{ textAlign : "center", fontSize : "1.5rem", width: "50%", margin : "0 auto", lineHeight : "1.5"}}>{t('task_modal_details_no_manager')}</p>
                                            </div>
                                        }
                                    </div>
                                    <div style={{ padding : "30px" , textAlign : "left" }} className={`tab-pane fade show ${activeTab==="billing-details" ? "active" : ""}`} id="billing-details" role="tabpanel" aria-labelledby="billing-details-tab">
                                        {/* BILLING DETAILS */}
                                        {
                                            task.paymentStatus==="prepaid" ? 
                                            taskTrans.map( (data, i) => 
                                            <div style={{ paddingBottom : "10px", paddingTop : "10px", borderBottom : "1px solid #DFDFE0" }}>
                                                <p className="align-left" style={paymentHeader}>{ i===0 ? t('task_modal_details_first_payment') : t('task_modal_details_last_payment') }{moment.unix(data.dateAdded.seconds).format("M.DD.YYYY")}</p>
                                                <div style={{ display : "flex" }}>
                                                    <p className="align-left" style={{ fontSize : "16px", marginTop : "15px", color : "#676A6C", fontWeight : "500", flex : "1" }}>{ i===0 ? t('task_modal_details_prepay') : t('task_modal_details_paid_amount') }</p>
                                                    <p className="align-right" style={{ marginTop : "15px", color : "#464646", fontSize : "18px", fontWeight : "600"  }}>${ Number.isInteger(data.amount) ? data.amount.toFixed(2) : data.amount}</p>
                                                </div>
                                            </div>
                                            ):
                                            ""
                                        }
                                        {
                                            (task.paymentStatus==="fullyPaid" && taskTrans.length>1) && 
                                                taskTrans.map( (data, i) => 
                                                    <div style={{ paddingBottom : "10px", paddingTop : "10px", borderBottom : "1px solid #DFDFE0" }}>
                                                        <p className="align-left"  style={paymentHeader}>{ i===0 ? t('task_modal_details_first_payment') : t('task_modal_details_last_payment') }{moment.unix(data.dateAdded.seconds).format("M.DD.YYYY")}</p>
                                                        <div style={{ display : "flex" }}>
                                                            <p className="align-left" style={{ fontSize : "16px", marginTop : "15px", color : "#676A6C", fontWeight : "500", flex : "1" }}>{ i===0 ? t('task_modal_details_prepay') : t('task_modal_details_paid_amount') }</p>
                                                            <p className="align-right" style={{ marginTop : "15px", color : "#464646", fontSize : "18px", fontWeight : "600"  }}>${ Number.isInteger(data.amount) ? data.amount.toFixed(2) : data.amount}</p>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                        {
                                            (task.paymentStatus==="fullyPaid" && taskTrans.length===1) && 
                                                taskTrans.map( (data, i) => 
                                                    <div style={{ paddingBottom : "10px", paddingTop : "10px", borderBottom : "1px solid #DFDFE0" }}>
                                                        <p className="align-left" style={paymentHeader}>{t('task_modal_details_last_payment')}: {moment.unix(data.dateAdded.seconds).format("M.DD.YYYY")}</p>
                                                        <div style={{ display : "flex" }}>
                                                            <p className="align-left" style={{ fontSize : "16px", marginTop : "15px", color : "#676A6C", fontWeight : "500", flex : "1" }}>{t('task_modal_details_paid_amount')}</p>
                                                            <p className="align-right" style={{ marginTop : "15px", color : "#464646", fontSize : "18px", fontWeight : "600"  }}>${ Number.isInteger(data.amount) ? data.amount.toFixed(2) : data.amount}</p>
                                                        </div>
                                                    </div>
                                                )
                                            
                                        }
                                        {
                                            task.remainingAmount!==0 &&
                                            <div>
                                                <p className="align-left" style={{ marginBottom: "8px", marginTop : "8px", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('task_modal_quick_progress_to_pay')}</p>
                                                <p className="align-left" style={{ color : "#464646", fontSize : "32px", fontWeight : "bold", marginBottom : "5px" }}>$ {Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount}</p>
                                                {
                                                    couponCode==="" && <span className="align-left block"><img src={require('../../../images/icon-coupon.png')}/><a style={{ marginLeft : currentLanguage === "ar" ? "unset" : "10px",  marginRight : currentLanguage === "ar" ? "10px" : "unset", color : '#7AB7B6', textDecoration :"underline", fontSize : '16px', cursor : "pointer" }} onClick={this.props.openCouponCodeModal}>{t('common_enter_coupon')}</a></span>
                                                }
                                                {
                                                    couponCode!=="" && <span className="align-left block" style={{ color : "#939097", fontSize : "14px", fontWeight : "bold" }}><img style={{ width: "13px", height : "13px", marginRight : "10px", cursor : "pointer" }} onClick={this.props.removeCouponCode} src={require('../../../images/icon-remove-task-file.png')}/>{ couponCode }</span>
                                                }
                                                <Link to={{
                                                    pathname: '/task/payment',
                                                    state: { tasks }
                                                }} style={{ width: "100% !important", marginTop : "10px" }} className="task-pay-left-details">{t('common_left_to_pay')}</Link>
                                            
                                            </div>
                                        }
                                        {
                                            task.status==="completed" &&
                                            <div>
                                                <p className="align-left" style={{ marginBottom: "8px", marginTop : "8px", fontSize : "16px", fontWeight : "bold", color : "#414042" }}>{t('task_modal_details_feedback')}</p>
                                                <p className="align-left" style={{ fontSize : "16px", marginTop : "15px", color : "#676A6C", fontWeight : "500" }}>{t('task_modal_details_rate_manager')}</p>
                                                <BeautyStars
                                                    editable={!this.state.managerRated}
                                                    value={this.state.managerRating}
                                                    inactiveColor="#f3f3f3"
                                                    activeColor="#22C9C7"
                                                    onChange={managerRating => this.setState({ managerRating })}
                                                />
                                                <div className="form-group" style={{ marginTop : "15px" }}>
                                                    <label className="custom-create-task-label block align-left" style={{ fontSize : "16px", fontWeight : "500",  color : "#414042" }}>{t('task_modal_details_text_feedback')}</label>
                                                    <textarea disabled={this.state.translatorRated && this.state.managerRated} value={managerFeedback} onChange={this.onInputChange} style={{ resize : "none", width: "100%" }} name="managerFeedback" id="managerFeedback" rows={3} placeholder={t('task_modal_details_enter_feedback')} className="custom-input"></textarea>
                                                </div>
                                                <p className="align-left" style={{ fontSize : "16px", marginTop : "15px", color : "#676A6C", fontWeight : "500" }}>{t('task_modal_details_rate_translator')}</p>
                                                <BeautyStars
                                                    editable={!this.state.translatorRated}
                                                    value={this.state.translatorRating}
                                                    inactiveColor="#f3f3f3"
                                                    activeColor="#22C9C7"
                                                    onChange={translatorRating => this.setState({ translatorRating })}
                                                />
                                                <div className="form-group" style={{ marginTop : "15px" }}>
                                                    <label className="custom-create-task-label block align-left" style={{ fontSize : "16px", fontWeight : "500",  color : "#414042" }}>{t('task_modal_details_text_feedback')}</label>
                                                    <textarea disabled={this.state.translatorRated && this.state.managerRated} value={translatorFeedback} onChange={this.onInputChange} style={{ resize : "none", width: "100%" }} name="translatorFeedback" id="translatorFeedback" rows={3} placeholder={t('task_modal_details_enter_feedback')} className="custom-input"></textarea>
                                                </div>
                                                <button disabled={this.state.translatorRated && this.state.managerRated} onClick={this.saveFeedback} style={{ width: "100% !important", marginTop : "10px" }} className="task-pay-left-details">{t('task_modal_details_save_feedback')}</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            {
                isConfirmationModalActive &&
                <ConfirmationModal
                    t={t}
                    isOpened={isConfirmationModalActive}
                    closeConfirmationModal={this.closeConfirmationModal}
                    confirm={this.confirm}
                    header={confirmationHeader}
                    btnYes={t('common_yes')}
                    btnCancel={t('common_no')}
                />
            }
            {
                isMessageModalActive && 
                <MessageModal 
                    t={t}
                    isOpened={isMessageModalActive}
                    header={""}
                    subHeader={t('task_modal_details_feedback_message')}
                    closeMessageModal={this.closeMessageModal}
                />
            }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        activeConversation : state.conversation.activeConversation,
        isCheckingConversation : state.conversation.isCheckingConversation,
        userProfile : state.firebase.profile,
        modalInstances : state.app.modalInstances,
        isMobile : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        checkConversation, 
        getTaskRatings, 
        updateTask, 
        createRating, 
        getTaskTransactions, 
        uploadResultFiles, 
        removeResultFile, 
        updateTask, 
        createConversationMessageAM, 
        clearConversationAndMessages,  
        createConversation, 
        createConversationMessage, 
        uploadMessageFiles,
        addModal,
        popModal,
        setActiveConversation,
        updateConversationLastOpenedDate
    })
)(TaskDetailsModal)
