import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

import {
    translate
} from 'react-switch-lang'

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class AcceptedTaskModal extends Component {

    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
    
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#acceptedTaskModal').modal({backdrop:'static', keyboard:false});
            $('#acceptedTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#acceptedTaskModal').modal('show');
            this.props.addModal('accepted-task-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("at-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="accepted-task-modal");
            if(modalInstance!==undefined){
                $('.at-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#acceptedTaskModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }
  
    render(){
        const { task } = this.props;
        const { modalZ } = this.state;

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t, currentLanguage } = this.props;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
       
        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} accepted-task-modal modal fade modal-opaque`} id="acceptedTaskModal" tabIndex="-1" role="dialog" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col align-left" style={{ paddingRight : "0" }}>
                                        <button onClick={this.props.closeAcceptedTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="custom-modal-header modal-header">
                                <button onClick={this.props.closeAcceptedTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        
                        <div className="modal-body">
                            <p className="text-header">{t('task_modal_accepted_header')}</p>
                            {
                                isMobileView ?
                                <div>
                                    <div style={{ padding : "0" }} className="accepted-task-details task-item">
                                        <div className="row" style={{ marginRight : "5px", marginLeft : "5px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                            <div className="col" style={{ position : "relative", padding : "0" }}>
                                                <span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span>
                                                <p style={{ marginBottom : "15px", marginTop : "15px" }} className="task-title">{task.title}</p>
                                                <span style={{ marginBottom : "15px", display: "block", fontSize : "13px", color: "#676A6C" }}>{t('common_order_number')}:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                            </div>
                                        </div>
                                        <div className="row" style={{  marginRight : "5px", marginLeft : "5px", paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                            <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                                <div className="col"><span className="field-label">{t('common_task_created')}</span></div>
                                                <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span></div>
                                            </div>
                                            <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                                <div className="col"><span className="field-label">{t('common_deadline')}</span></div>
                                                <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(deadline).format("Y/MM/DD")}</span></div>
                                            </div>
                                            <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                                <div className="col"><span className="field-label">{t('common_word_count')}</span></div>
                                                <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{task.numberOfWords}</span></div>
                                            </div>
                                            <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                                <div className="col"><span className="field-label">{t('common_days_to_deadline')}</span></div>
                                                <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span></div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div className="text-center" style={{ marginTop : "15px" }}>
                                        <button onClick={this.props.goToTaskDetails} style={{ width: "100%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('task_modal_accepted_go_to_details')}</button>    
                                    </div>  
                                </div>
                                :
                                <div className="accepted-task-details task-item">
                                    <div style={{ display : "flex" }}>
                                        <div style={{ flex : "1" }}><span style={taskTypeColor(task.taskType)} className="task-type">{task.taskType}</span></div>
                                        <div style={{ textAlign: "right" }}><span style={{ fontSize : "14px", color: "#676A6C" }}>{t('common_order_number')}<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span></div>
                                    </div>
                                    <p style={{ marginTop: "16px" }} className="task-title">{task.title}</p>
                                    <div style={{ display : "flex", marginTop : "24px" }}>
                                        <div style={{ marginRight : "24px"}}>
                                            <span className="field-label">{t('common_task_created')}</span>
                                            <span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span>
                                        </div>
                                        <div style={{ flex : "1"}}>
                                            <span className="field-label">{t('common_deadline')}</span>
                                            <span className="field-value">{ moment.unix(deadline).format("Y/MM/DD")}</span>
                                        </div>
                                    </div>
                                    <div style={{ display : "flex", marginTop : "24px" }}>
                                        <div style={{ marginRight : "24px"}}>
                                            <span className="field-label">{t('common_word_count')}</span>
                                            <span className="field-value">{task.numberOfWords}</span>
                                        </div>
                                        <div style={{ flex : "1"}}>
                                            <span className="field-label">{t('common_days_to_deadline')}</span>
                                            <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                                        </div>
                                    </div>
                                    <div className="text-center" style={{ marginTop : "56px" }}>
                                        <button onClick={this.props.goToTaskDetails} style={{ width: "100%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('task_modal_accepted_go_to_details')}</button>    
                                    </div>     
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(AcceptedTaskModal)