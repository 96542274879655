import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import $ from 'jquery'

import { getAssignedTasks, updateTask, declineAssignedTask } from '../../../../actions/taskAction'
import { notifyAccountManager } from '../../../../actions/notificationAction'
import { updateCurrentUser, updateTranslatorAssignedTasks, updateTranslatorAvailability, checkTranslatorAvailability, setUserPresence } from '../../../../actions/authAction'
import List from './List';
import Item from './Item';
import SpinnerModal from '../../../Spinner/SpinnerModal'
import  NotifyAccountManagerModal from '../../Modals/NotifyAccountManagerModal'
import  AcceptedTaskModal from '../../Modals/AcceptedTaskModal'
import  DeclineTaskModal from '../../Modals/DeclineTaskModal'
import MessageModal from '../../../Modals/Message'

import { translate } from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            loadMore : false,
            isEmpty : false,
            selectedTask : {},
            isProcessing : false,
            lastRef : null,
            notifyAccountManagerModalActive : false,
            acceptedTaskModalActive : false,
            declineTaskModalActive : false,
            isAuthenticated : false,
            isMessageModalActive : false
        }
    }
    
    componentDidMount(){

        const { auth, userProfile } = this.props;

        if(auth.uid){
            this.props.setUserPresence({
                uid : auth.uid
            })
            this.loadAssignedTasks();
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    loadAssignedTasks = async () => {
        await this.props.getAssignedTasks();
    }

    openNotifyAccountManagerModal = () => {
        this.setState({
            notifyAccountManagerModalActive : true
        })
    }

    closeNotifyAccountManagerModal = () => {
        $('#notifyAccountManagerModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                notifyAccountManagerModalActive : false
            })
        }, 1500)
       
    }

    notifyAccountManager = async (data) => {

        this.setState({
            isProcessing : true
        }, async () => {

            let result = await this.props.notifyAccountManager({ message : data.notifyReason });
            $('#notifyAccountManagerModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    notifyAccountManagerModalActive : false
                })
            }, 1500)

        })
    }

    acceptTask = (task) => {
        
        const { auth } = this.props;

        this.setState({
            isProcessing : true,
            selectedTask : {...task}
        }, async () => {
            
            let resultAvailability = await this.props.checkTranslatorAvailability();

            if(resultAvailability.code===500){
               this.setState({
                   isMessageModalActive : true,
                   isProcessing : false,
                   selectedTask : {}
               })
            }else{
               
                let result = await this.props.updateTask({
                    ...task,
                    status : "inProgress",
                    assignedTranslator : auth.uid
                })
    
                if(result.code===200){
                    // let res = await this.props.updateCurrentUser({
                    //     status : "notAvailable"
                    // })
    
                    // if(res.code==200){
                    //     this.setState({
                    //         isProcessing : false,
                    //         acceptedTaskModalActive : true
                    //     })
                    // }
                    $('#acceptedTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
    
                    setTimeout( () => {
                        this.setState({
                            isProcessing : false,
                            acceptedTaskModalActive : true
                        })
                    }, 1500)
    
                    this.props.updateTranslatorAssignedTasks();
                    this.props.updateTranslatorAvailability();
                    
                }else{
                    $('#acceptedTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
    
                    setTimeout( () => {
                        this.setState({
                            isProcessing : false,
                            selectedTask : {}
                        })
                    }, 1500)
                }
            }
        })
    }

    closeAcceptedTaskModal = () => {
        $('#acceptedTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

        setTimeout( () => {
            this.setState({
                acceptedTaskModalActive : false,
                selectedTask : {}
            }, () => {
                this.loadAssignedTasks();
            })
        }, 1500)
    }

    goToTaskDetails = () => {
        const { selectedTask } = this.state;

        this.props.history.push({
            pathname : '/tasks/in_progress',
            state : {
                task : selectedTask
            }
        })

    }

    openDeclineTaskModal = (task) => {

        this.setState({
            declineTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeDeclineTaskModal = () => {
        $('#declineTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

        setTimeout( () => {
            this.setState({
                declineTaskModalActive : false,
                selectedTask : {}
            })
        }, 1500)
    }

    declineTask = async (declineData) => {
        const { selectedTask } = this.state;
        const { auth } = this.props;

        let data = {
            uid : selectedTask.uid,
            declineReason : declineData.declineReason,
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.declineAssignedTask(data);

            if(result.code===200){
                $('#declineTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

                setTimeout( () => {
                    this.setState({
                        declineTaskModalActive : false,
                        isProcessing : false
                    }, () => {
                        this.loadAssignedTasks();
                    })
                }, 1500)
            }
        })
    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false
        })
    }

    render() {

        const { isMobileView } = this.props;

        const { isLoggingOut, auth, tasks, isGettingTasks, userProfile } = this.props;
        const { isMessageModalActive, isAuthenticated, selectedTask, lastRef, loadMore, isEmpty, isProcessing, notifyAccountManagerModalActive, acceptedTaskModalActive, declineTaskModalActive } = this.state;

        const { t, currentLanguage } = this.props;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="translator"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    isAuthenticated && 
                    <div className="div-holder">
                        {
                            (tasks.length!==0) && <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <p className="module-header">{t('assigned_header')}</p>
                                </div>
                            </div>
                        }
                        {
                            isGettingTasks ?
                            <div className="d-flex justify-content-center">
                                <div style={{ width: "5rem", height : "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            tasks.length===0 ?
                            <div className="landing-empty-task">
                                <div className="empty-task-heading">
                                    <p style={{ width : isMobileView ? "100%" : "50%" }} className="empty-task-heading-text">{t('assigned_empty_header')}</p>
                                    <span style={{ width : isMobileView ? "100%" : "30%" }}  className="empty-task-heading-sub">{t('assigned_empty_sub_header')}</span>
                                    <button onClick={this.openNotifyAccountManagerModal} className="empty-assigned-task-heading-button">{t('assigned_empty_button')}</button>
                                </div>
                            </div> :   
                            <div>
                               
                                <div className="">
                                    <List  
                                        t={t}
                                        currentLanguage={currentLanguage}
                                        isMobileView={isMobileView}
                                        loadAssignedTasks={this.loadAssignedTasks} 
                                        acceptTask={this.acceptTask}
                                        openDeclineTaskModal={this.openDeclineTaskModal}
                                        tasks={tasks}
                                        />
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    
                }
                {
                    notifyAccountManagerModalActive && <NotifyAccountManagerModal t={t} closeNotifyAccountManagerModal={this.closeNotifyAccountManagerModal} notifyAccountManager={this.notifyAccountManager} isOpened={notifyAccountManagerModalActive} />
                }
                {
                    acceptedTaskModalActive && <AcceptedTaskModal goToTaskDetails={this.goToTaskDetails} task={selectedTask} closeAcceptedTaskModal={this.closeAcceptedTaskModal} isOpened={acceptedTaskModalActive} />
                }
                {
                    declineTaskModalActive && <DeclineTaskModal declineTask={this.declineTask} closeDeclineTaskModal={this.closeDeclineTaskModal} isOpened={declineTaskModalActive}/>
                }
                {
                    isProcessing && 
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                {
                    isLoggingOut && 
                    <SpinnerModal
                        isOpened={isLoggingOut}
                    />
                }
                {
                    isMessageModalActive && 
                    <MessageModal 
                        isOpened={isMessageModalActive}
                        header={""}
                        t={t}
                        subHeader={t('task_modal_assigned')}
                        closeMessageModal={this.closeMessageModal}
                    />
                }
            </div>
        )
        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        tasks : state.task.assignedTasks,
        userProfile : state.firebase.profile,
        isGettingTasks : state.task.isGettingTasks,
        statusFilter : state.task.statusFilter,
        typeFilter : state.task.typeFilter,
        conversation : state.conversation.conversation,
        isLoggingOut : state.auth.isLoggingOut,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        getAssignedTasks, 
        updateTask,
        updateCurrentUser,
        declineAssignedTask,
        updateTranslatorAssignedTasks,
        updateTranslatorAvailability,
        notifyAccountManager,
        checkTranslatorAvailability,
        setUserPresence
    })
)(Index)

