import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import $ from 'jquery'

import moment from 'moment';
import exactMath from 'exact-math'

const renderStatus = (status, t) => {
    if(status==="new"){
        return <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={require('../../../../images/icon-new.png')}/></span>{t('common_stat_new')}</p>
    }

    if(status==="inProgress"){
        return <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={require('../../../../images/icon-inprogress.png')}/></span>{t('common_stat_in_progress')}</p>
    }

    if(status==="forReview"){
        return <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={require('../../../../images/icon-review.png')}/></span>{t('common_stat_for_review')}</p>
    }

    if(status==="completed"){
        return <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={require('../../../../images/icon-completed.png')}/></span>{t('common_stat_completed')}</p> 
    }
}

const renderPaymentStatus = (status, t) => {
    switch(status){
        case "prepaid" :
            return t('common_pay_stat_unpaid')
        case "fullyPaid" :
            return t('common_pay_stat_paid')
        default:
            return t('common_pay_stat_unpaid')
    }
}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

const renderAdditionalServices = (services, t) => {

    let servicesList = [];

    if(services !== undefined){
        services.map( (data) => {

            if(data==="diamond"){
                servicesList.push(<span className="service-tag" style={{ fontSize : "12px"}}><img style={{ width: "30px", height: "30px" }} src={require('../../../../images/icon-diamond.png')}/>&nbsp;&nbsp;&nbsp;{ services.length===1 ? t('common_high_prio') : '' }</span>)
            }
    
            if(data==="express"){
                servicesList.push(<span className="service-tag" style={{ fontSize : "12px"}}><img style={{ width: "30px", height: "30px" }} src={require('../../../../images/icon-express.png')}/>&nbsp;&nbsp;&nbsp;{ services.length===1 ? t('common_express') : '' }</span>)
            }

            if(data==="certified"){
                servicesList.push(<span className="service-tag" style={{ fontSize : "12px"}}><img style={{ width: "30px", height: "30px" }} src={require('../../../../images/icon-certified.png')}/>&nbsp;&nbsp;&nbsp;{ services.length===1 ? t('common_express') : '' }</span>)
            }
        })
    }

    return servicesList;

}

export class Item extends Component {

    state = {
        shouldRender : false
    }

    openPauseTaskModal = (task) => {
        this.props.openPauseTaskModal(task);
    }

    openStopTaskModal = (task) => {
        this.props.openStopTaskModal(task);
    }

    openEscalateTaskModal = (task) => {
        this.props.openEscalateTaskModal(task);
    }

    openTaskDetailsModal = (task, activeTab) => {
        this.props.openTaskDetailsModal(task, activeTab);
    }

    giveFeedback = (task) => {
        this.props.giveFeedback(task)
    }

    componentDidMount(){
        const { displayDelay } = this.props;
       
        this.timer = setTimeout(() => {
            this.setState({ shouldRender: true }, () => {
                const scrollHeight = $('html')[0].scrollHeight;
                const height = $('html')[0].clientHeight;
                const maxScrollTop = scrollHeight - height;
                // $("html, body").animate({ scrollTop : maxScrollTop > 0 ? maxScrollTop : 0 }, 200);
            });
        }, displayDelay);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){
        const { shouldRender } = this.state;
        const { task, isMobileView, currentLanguage, t } = this.props;
        let tasks = []
        tasks.push(task)

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
        
        let d = new Date();
        let dateAddedBar = new Date(moment.unix(dateAdded))
        let deadlineBar = new Date(moment.unix(deadline))
        let timelineBar = moment(deadlineBar).diff(dateAddedBar, 'days')
        let daysDiffBar = moment(deadlineBar).diff(d,'days')

        let sendFilesBtnStyle = {
            color : "#22C9C7",
            fontSize  : "14px",
            fontWeight : "bold",
            borderRadius : "8px",
            boxSizing : "border-box",
            border : "2px solid #22C9C7",
            width: "100%",
            padding: "12px 16px",
            marginTop : "15px",
            background : "transparent",
            position : "absolute",
            bottom : "0",
            right : currentLanguage === "ar" ? "15px" : "0"
        }

        let passedDueStyle = {};
        let progressBarClasses = "progress-bar custom-progress-bar-safe";

        let progressBarStyle = {}

        if(moment.unix(deadline).diff(moment(new Date()), 'days')<=0){
            sendFilesBtnStyle.color = "#FF6666"
            sendFilesBtnStyle.border = "2px solid #FF6666"

            passedDueStyle = {
                color : "#FF6666"
            }

            progressBarClasses = "progress-bar custom-progress-bar-danger"
        }

        if(daysDiffBar<=0){
            progressBarStyle.width = "100%"
        }else{
            progressBarStyle.width = 100 - ((daysDiffBar/timelineBar) * 100) +"%"
        }
        
        if(isMobileView){
            return (
                <div>
                    {
                        shouldRender &&
                            <div className="mobile-view task-item fadeInUp animated">
                                <div className="row" style={{ paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                    <div className="col" style={{ position : "relative", padding : "0" }}>
                                        <span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span>
                                        <p style={{ marginBottom : "15px", marginTop : "15px" }} className="task-title">{task.title}</p>
                                        <span className="order-number" style={{ marginBottom : "15px", display: "block", fontSize : "13px", color: "#676A6C" }}><span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                        <div className="row" style={{ width: "100%" }}>
                                            <div className="col">
                                                {renderStatus(task.status,t)}
                                            </div>
                                            <div className="col">
                                                <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={ task.paymentStatus==="fullyPaid" ? require('../../../../images/icon-paid.png') : require('../../../../images/icon-unpaid.png')}/></span>{renderPaymentStatus(task.paymentStatus, t)}</p>
                                            </div>
                                        </div>
                                        <div class="btn-group" style={{ position : "absolute", top : "-7px", right : currentLanguage == "ar" ? "unset" : "15px", left : currentLanguage == "ar" ? "15px" : "unset" }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                            { task.status!== "completed" && <button onClick={ () => this.props.openPauseTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_pause')}</button> }
                                            { task.status!== "completed" && <button onClick={ () => this.props.openStopTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_stop')}</button> }
                                            <button onClick={ () => this.props.openEscalateTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" onClick={ () => this.openTaskDetailsModal(task)} style={{  paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_task_created')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_deadline')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value" style={ task.status!=="completed" ? passedDueStyle : {}}>{ moment.unix(deadline).format("Y/MM/DD")}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_word_count')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{task.numberOfWords}</span></div>
                                    </div>
                                    <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                                        <div className="col"><span className="field-label">{t('common_days_to_deadline')}</span></div>
                                        <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value" style={ task.status!=="completed" ? passedDueStyle : {}}>{ task.status!=="completed" ? moment.unix(deadline).diff(moment(new Date()), 'days') : "" }</span></div>
                                    </div>
                                    {
                                        task.status!=="completed" &&
                                        <div className="row" style={{ width: "100%"}}>
                                            <div className="col" style={{ paddingRight : "0" }}>
                                                <div style={{ display : "flex" }}>
                                                    <div style={{ flex : "1" }}>
                                                        {
                                                            <div className="progress mt-1 custom-progress">
                                                                <div className={progressBarClasses} aria-valuenow={daysDiffBar} aria-valuemin={0} aria-valuemax={timelineBar} style={progressBarStyle}></div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            (daysDiffBar<= 0) && <img src={require('../../../../images/danger.png')} style={{ marginLeft : "13px", width: "24px", height : "24px" }}/>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row" style={{ paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA" }}>
                                    <div className="col" style={{ padding : 0 }}>
                                        <p className="task-description"><span style={{ color : "#7e7e7e" }}>&nbsp;</span>{task.detailedInstructions}</p>
                                        {
                                            task.additionalServices.length!== 0 &&
                                            <div className="align-left" style={{ marginBottom : "15px" }}>
                                                { renderAdditionalServices(task.additionalServices, t) }
                                            </div>
                                        }
                                        <span className="align-left" style={{ fontSize:"20px", color : "#464646", display: "block" }}>{ task.remainingAmount === 0 ? t('common_task_all_amount'): t('common_need_to_pay')} ${ task.remainingAmount===0? Number.isInteger(task.price) ? task.price.toFixed(2) : task.price : Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount }</span>
                                    </div>
                                </div>
                                <div className="row" style={{ paddingTop : "15px" }}>
                                    <div className="col" style={{ padding : 0 }}>
                                        <p className="task-payed">{ task.paymentStatus!=="unpaid" ? `${t('common_paid')}: $ ${Number.isInteger(exactMath.sub(task.price,task.remainingAmount)) ? exactMath.sub(task.price,task.remainingAmount).toFixed(2) : (exactMath.sub(task.price,task.remainingAmount))}` :""}</p>
                                        { task.remainingAmount!==0 ? <Link to={{
                                            pathname: '/task/payment',
                                            state: { tasks }
                                        }}><button style={{ marginTop : "0", width : "100%", right : currentLanguage == "ar" ? "0" : "15px" }} className="task-pay-left">{t('common_left_to_pay')}</button></Link> : <button onClick={ () => this.giveFeedback(task)} style={{ marginTop : "0" }} className="task-give-feedback">{t('published_task_feedback')}</button> }
                                    </div>
                                </div>
            
                                <div className="row">
                                    <div onClick={ () => this.openTaskDetailsModal(task)} style={{ cursor: "pointer", borderRight: currentLanguage==="ar" ? "none" : "1px solid #E9E9EA", borderLeft: currentLanguage==="ar" ? "1px solid #E9E9EA" : "none"}}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    </div>
                                    <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <p className="task-remaining-amount"></p>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        }else{
            return (
                <div>
                    {
                        shouldRender &&
                            <div className="task-item fadeInUp animated">
                                <div className="row">
                                    <div onClick={ () => this.openTaskDetailsModal(task)} style={{ cursor : "pointer", borderRight: currentLanguage==="ar" ? "none" : "1px solid #E9E9EA", borderLeft: currentLanguage==="ar" ? "1px solid #E9E9EA" : "none"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <div className="row pl-2">
                                            <div className="col">
                                                {renderStatus(task.status, t)}
                                            </div>
                                            <div className="col">
                                                <p className="task-status"><span><img style={{ width: "10px", height: "10px" }} src={ task.paymentStatus==="fullyPaid" ? require('../../../../images/icon-paid.png') : require('../../../../images/icon-unpaid.png')}/></span>{renderPaymentStatus(task.paymentStatus, t)}</p>
                                            </div>
                                        </div>
                                        <div className="row pl-2">
                                            <div className="col">
                                                <span className="field-label">{t('common_task_created')}</span>
                                                <span className="field-value">{ moment.unix(dateAdded).format("Y/MM/DD")}</span>
                                            </div>
                                            <div className="col">
                                                <span className="field-label">{t('common_deadline')}</span>
                                                <span className="field-value" style={ task.status!=="completed" ? passedDueStyle : {}}>{ moment.unix(deadline).format("Y/MM/DD")}</span>
                                            </div>
                                        </div>
                                        <div className="row pl-2 mt-4">
                                            <div className="col">
                                                <span className="field-label">{t('common_word_count')}</span>
                                                <span className="field-value">{task.numberOfWords}</span>
                                            </div>
                                            <div className="col">
                                                <span className="field-label">{t('common_days_to_deadline')}</span>
                                                <span className="field-value" style={ task.status!=="completed" ? passedDueStyle : {}}>{ task.status!=="completed" ? moment.unix(deadline).diff(moment(new Date()), 'days') : "" }</span>
                                            </div>
                                        </div>
                
                                    </div>
                                    <div onClick={ () => this.openTaskDetailsModal(task)} style={{ cursor: "pointer", borderRight: currentLanguage==="ar" ? "none" : "1px solid #E9E9EA", borderLeft: currentLanguage==="ar" ? "1px solid #E9E9EA" : "none" }}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="row pl-2">
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <p className="task-title" style={{ marginLeft : isMobileView ? "15px" : "0" }}>{task.title}</p>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-right">
                                                <span style={{ fontSize : "13px", color: "#676A6C" }} className="order-number"><span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                            </div>
                                        </div>
                                        <div className="row pl-2">
                                            <div className="col">
                                                <p className="task-description"><span style={{ color : "#7e7e7e" }}>&nbsp;</span>{task.detailedInstructions}</p>
                                            </div>
                                        </div>
                                        <div style={{ display : "flex" }}>
                                            <div>
                                                <p className="task-payed">{ task.paymentStatus!=="unpaid" ? `${t('common_paid')}: $ ${Number.isInteger(exactMath.sub(task.price,task.remainingAmount)) ? exactMath.sub(task.price,task.remainingAmount).toFixed(2) : (exactMath.sub(task.price,task.remainingAmount))}` :""}</p>
                                            </div>
                                            <div style={{ flex : "1", marginLeft : "13px" }}>
                                                {
                                                    task.status!=="completed" &&
                                                    <div className="progress mt-1 custom-progress">
                                                        <div className={progressBarClasses} aria-valuenow={daysDiffBar} aria-valuemin={0} aria-valuemax={timelineBar} style={progressBarStyle}></div>
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    (daysDiffBar<= 0 && task.status!=="completed") && <img src={require('../../../../images/danger.png')} style={{ marginLeft : "13px", width: "24px", height : "24px" }}/>
                                                }
                                            </div>
                                            {
                                                task.additionalServices.length!== 0 &&
                                                <div className="align-right add-services-div" style={{ flex : "1" }}>
                                                    { renderAdditionalServices(task.additionalServices, t) }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <div class="btn-group" style={{ position : "absolute", top : "-7px", right : currentLanguage == "ar" ? "unset" : "15px", left : currentLanguage == "ar" ? "15px" : "unset" }}>
                                            <button type="button" class="btn btn-option" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            . . .
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right custom-dropdown-right">
                                            { task.status!== "completed" && <button onClick={ () => this.props.openPauseTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_pause')}</button> }
                                            { task.status!== "completed" && <button onClick={ () => this.props.openStopTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_stop')}</button> }
                                            <button onClick={ () => this.props.openEscalateTaskModal(task)} class="dropdown-item custom-dropdown-item" type="button">{t('task_option_escalate')}</button>
                                            </div>
                                        </div>
                                        <span className="align-left" style={{ display: "block", fontSize:"20px", color : "#464646" }}>{ task.remainingAmount === 0 ? t('common_task_all_amount'): t('common_need_to_pay')}</span>
                                        <p className="task-remaining-amount align-left">${ task.remainingAmount===0? Number.isInteger(task.price) ? task.price.toFixed(2) : task.price : Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount }</p>
                                        { task.remainingAmount!==0 ? <Link to={{
                                            pathname: '/task/payment',
                                            state: { tasks }
                                        }}><button style={{ right : currentLanguage && !isMobileView == "ar" ? "0" : "15px" }} className="task-pay-left">{t('common_left_to_pay')}</button></Link> : <button onClick={ () => this.giveFeedback(task)} style={{ marginTop : "0" }} className="task-give-feedback">{t('published_task_feedback')}</button> }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            )
        }
    }

}

export default Item;
