import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import $ from 'jquery'

import { getInProgressTasks, loadMoreInprogressTasks, watchTinProgressTasks, updateTask, setEscalation } from '../../../../actions/taskAction';
import { notifyAccountManager } from '../../../../actions/notificationAction'
import List from './List';
import SpinnerModal from '../../../Spinner/SpinnerModal'
import Message from '../../../Modals/Message';
import EscalateTaskModal from '../../Modals/EscalateTaskModal';
import TaskDetailsModal from '../../Modals/TaskDetailsModal';
import  NotifyAccountManagerModal from '../../Modals/NotifyAccountManagerModal'
import {
    setUserPresence
} from '../../../../actions/authAction'
import {
    addModal,
    popModal
} from '../../../../actions/appAction';
import {
    translate
} from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            loadMore : false,
            isEmpty : false,
            selectedTask : {},
            isProcessing : false,
            lastRef : null,
            escalateTaskModalActive : false,
            taskDetailsModalActive : false,
            notifyAccountManagerModalActive : false,
            activeTab : "task-files",
            isAuthenticated : false,
            isMessageModalActive : false,
            messageModalHeader : "",
            messageModalSubHeader : ""
        }
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            if(this.props.userProfile.type==="customer"){
                this.props.history.push("/tasks")
            }
        }
    }

    componentDidMount(){

        const { auth, userProfile } = this.props;

        if(auth.uid){
            this.props.setUserPresence({
                uid : auth.uid
            })
            this.loadTasks(true)
            this.unsubscribeTinProgressTasks = this.props.watchTinProgressTasks({
                authUid : auth.uid
            });

            let task = this.props.history.location.state=== undefined ? null : this.props.history.location.state.task;

            if(task!==null){
                this.openTaskDetailsModal(task)
            }

        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentWillUnmount(){
        this.unsubscribeTinProgressTasks && this.unsubscribeTinProgressTasks();
    }

    loadTasks = async (firstLoad = false) => {
        await this.props.getInProgressTasks(firstLoad);
    }

    loadMoreTasks = () => {
        this.props.loadMoreInprogressTasks();
    }


    openNotifyAccountManagerModal = () => {
        this.setState({
            notifyAccountManagerModalActive : true
        })
    }

    closeNotifyAccountManagerModal = () => {
        $('#notifyAccountManagerModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                notifyAccountManagerModalActive : false
            })
        }, 1500)
    }

    notifyAccountManager = (data) => {

        this.setState({
            isProcessing : true
        }, async () => {

            let result = await this.props.notifyAccountManager({ message : data.notifyReason });
            $('#notifyAccountManagerModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    notifyAccountManagerModalActive : false
                })
            }, 1500)

        })
    }

    openTaskDetailsModal = (task, activeTab = "task-files") => {
        this.setState({
            taskDetailsModalActive : true,
            selectedTask : {...task},
            activeTab : activeTab
        })
    }

    closeTaskDetailsModal = () => {
        const { currentLanguage } = this.props;
        $('#taskDetails .modal-dialog').attr('class',`modal-dialog modal-dialog-centered ${currentLanguage==="ar"? 'slideOutLeft' : 'slideOutRight'} animated`)

        setTimeout( () => {
            this.setState({
                taskDetailsModalActive : false,
                selectedTask : {}
            })
        }, 1500)
    }
    
    openEscalateTaskModal = (task) => {
        this.setState({
            escalateTaskModalActive : true,
            selectedTask : {...task}
        })
    }

    closeEscalateTaskModal = () => {
        $('#escalateTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

        setTimeout( () => {
            this.setState({
                escalateTaskModalActive : false,
                selectedTask : this.state.taskDetailsModalActive ? this.state.selectedTask : {}
            })
        }, 1500)
    }

    escalateTask = async (escalateData) => {
        const { selectedTask } = this.state;
        const { auth } = this.props;

        let data = {
            taskUid : selectedTask.uid,
            escalationLetter : escalateData.escalationLetter,
            escalationReason : escalateData.escalationReason,
            operator : ""
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.setEscalation(data);

            this.loadTasks();
            $('#escalateTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')

            setTimeout( () => {
                this.setState({
                    isProcessing : false,
                    escalateTaskModalActive : false
                })
            }, 1500)
           
        })
    }

    goToAssignedTasksPage = () => {
        this.props.history.push('/tasks/assigned')
    }
    
    toggleProcessing = (signal) => {
        this.setState({
            isProcessing : signal
        })    
    }

    sentForReview = () => {
        const { t } = this.props;
        this.setState({
            isMessageModalActive : true,
            messageModalHeader : "",
            messageModalSubHeader : t('task_sent_for_review')
        })
    }

    displayErrorUpload = (message) => {
        const { t } = this.props;
        this.setState({
            isMessageModalActive : true,
            messageModalHeader : "",
            messageModalSubHeader : message
        })
    }

    closeMessageModal = () => {
        $('#messageModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        
        setTimeout( () => {
            this.setState({
                isMessageModalActive : false
            })
        }, 1500)
    }

    render() {

        const { isMobileView } = this.props;

        const { isLoadingMoreTInprogressTasks, isLoggingOut, auth, tasks, isTinProgressTasksEmpty, isGettingTasks, statusFilter, typeFilter, conversation, userProfile } = this.props;
        const { notifyAccountManagerModalActive, isAuthenticated, activeTab, taskDetailsModalActive, lastRef, loadMore, isEmpty, escalateTaskModalActive, isProcessing, selectedTask } = this.state;
        const { isMessageModalActive, messageModalHeader, messageModalSubHeader } = this.state;

        const { currentLanguage, t } = this.props;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="translator"){
                    return "NOT ALLOWED";
                }
            }
        }
        
        return (
            <div className="div-holder">
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        {
                            (isTinProgressTasksEmpty) ? 
                            <div className="landing-empty-task">
                                <div className="empty-task-heading">
                                    <p className="empty-task-heading-text">{t('in_progress_header')}</p>
                                    <span className="empty-task-heading-sub">{t('in_progress_sub_header')}</span>
                                    {
                                        isMobileView ?
                                        <div style={{ width: "100%"}}>
                                            <button style={{ textTransform : "uppercase", display : "block", marginBottom : "15px", width : "100%", background : "#22c9c7", border : "none", borderRadius : "8px", padding : "16px 20px", height : "52px", fontSize : "14px", color : "#fcfcfc"  }} onClick={this.goToAssignedTasksPage} >{t('in_progress_go_to_assigned')}</button>
                                            <button style={{ width: "100%", textTransform : "uppercase" }} onClick={this.openNotifyAccountManagerModal} className="empty-assigned-task-heading-button">{t('task_modal_notify_header')}</button>
                                        </div>
                                        :
                                        <div style={{ display : 'flex', width: "55%"}}>
                                            <button style={{ textTransform : "uppercase", background : "#22c9c7", marginRight : "15px", border : "none", borderRadius : "8px", padding : "16px 20px", height : "52px", fontSize : "14px", color : "#fcfcfc"  }} onClick={this.goToAssignedTasksPage} >{t('in_progress_go_to_assigned')}</button>
                                            <button style={{ textTransform : "uppercase", marginRight : "15px" }}  onClick={this.openNotifyAccountManagerModal} className="empty-assigned-task-heading-button">{t('task_modal_notify_header')}</button>
                                        </div>
                                    }
                                </div>
                                {
                                    notifyAccountManagerModalActive && <NotifyAccountManagerModal t={t} closeNotifyAccountManagerModal={this.closeNotifyAccountManagerModal} notifyAccountManager={this.notifyAccountManager} isOpened={notifyAccountManagerModalActive} />
                                }
                            </div>
                            :
                            <div className="div-holder">
                                <div style={{ display : "flex" }}>
                                    <div style={{ flex : "1", textAlign : "left" }}>
                                        <p className="module-header">{t('in_progress_header')}</p>
                                    </div>
                                    
                                </div>
                                <div className="div-holder">
                                {
                                    isGettingTasks ? 
                                    <div className="d-flex justify-content-center">
                                        <div style={{ width: "5rem", height : "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    tasks.length!==0 ?  
                                    <List 
                                        t={t}
                                        currentLanguage={currentLanguage}
                                        isMobileView={isMobileView}
                                        isLoadingMoreTInprogressTasks={isLoadingMoreTInprogressTasks}
                                        loadMoreTasks={this.loadMoreTasks}
                                        loadTasks={this.loadTasks} 
                                        openTaskDetailsModal={this.openTaskDetailsModal}
                                        openEscalateTaskModal={this.openEscalateTaskModal}
                                        tasks={tasks}
                                        /> : ""
                                }
                            </div>
                            {
                                isProcessing && 
                                <SpinnerModal
                                    isOpened={isProcessing}
                                />
                            }
                            {
                                escalateTaskModalActive && <EscalateTaskModal userProfile={userProfile} escalateTask={this.escalateTask} closeEscalateTaskModal={this.closeEscalateTaskModal} isOpened={escalateTaskModalActive}/>
                            }
                            {
                                taskDetailsModalActive && 
                                <TaskDetailsModal 
                                    displayErrorUpload={this.displayErrorUpload}
                                    currentLanguage={currentLanguage}
                                    sentForReview={this.sentForReview}
                                    toggleProcessing={this.toggleProcessing}
                                    activeTab={activeTab} 
                                    closeTaskDetailsModal={this.closeTaskDetailsModal} 
                                    isOpened={taskDetailsModalActive} 
                                    openEscalateTaskModal={this.openEscalateTaskModal}
                                    task={selectedTask} />
                            }
                            
                            {
                                isLoggingOut && 
                                <SpinnerModal
                                    isOpened={isLoggingOut}
                                />
                            }
                            {
                                isMessageModalActive && 
                                <Message 
                                    t={t}
                                    isMobileView={isMobileView}
                                    closeMessageModal={this.closeMessageModal} 
                                    isOpened={isMessageModalActive} 
                                    header={messageModalHeader} 
                                    subHeader={messageModalSubHeader}
                                    addModal={this.props.addModal}
                                    popModal={this.props.popModal}
                                    modalInstances={this.props.modalInstances}
                                />
                            }
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        tasks : state.task.tInProgressTasks,
        isGettingTasks : state.task.isGettingTasks,
        isLoadingMoreTInprogressTasks : state.task.isLoadingMoreTInprogressTasks, 
        isTinProgressTasksEmpty : state.task.isTinProgressTasksEmpty,
        conversation : state.conversation.conversation,
        isLoggingOut : state.auth.isLoggingOut,
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        loadMoreInprogressTasks,
        setUserPresence, 
        getInProgressTasks, 
        watchTinProgressTasks, 
        setEscalation, addModal, popModal, notifyAccountManager })
)(Index)

