import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';
import exactMath from 'exact-math'

const renderStatus = (status,t) => {
    switch(status){
        case "inProgress":
            return t('published_stat_in_prog');
        case "completed":
            return t('published_stat_completed');
        default:
            return t('published_stat_completed');
    }
}

const renderPaymentStatus = (status,t) => {
    switch(status){
        case "prepaid" :
        case "fullyPaid" :
            return t('published_payment_stat_paid')
        default:
            return t('published_payment_stat_unpaid')
    }
}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class Item extends Component {

    render(){

        const { task, isMobileView, t , currentLanguage } = this.props;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
       
        if(isMobileView){
            return (
                <div className="task-item">
                    <div className="row" style={{ marginRight : "5px", marginLeft : "5px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                        <div className="col" style={{ position : "relative", padding : "0" }}>
                            <span style={taskTypeColor(task.taskType)} className="task-type">{t(`archive_task_${task.taskType}`)}</span>
                            <p style={{ marginBottom : "15px", marginTop : "15px" }} className="task-title">{task.title}</p>
                            <span style={{ marginBottom : "15px", display: "block", fontSize : "13px", color: "#676A6C" }}>{t('archive_task_order_number')}:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                            <div className="row" style={{ width: "100%" }}>
                                <div className="col">
                                    {renderStatus(task.status,t)}
                                </div>
                                <div className="col">
                                    <p className="task-status"><span style={{ marginRight: "10px" }}><img style={{ width: "10px", height: "10px" }} src={ task.paymentStatus==="fullyPaid" ? require('../../../../images/icon-paid.png') : require('../../../../images/icon-unpaid.png')}/></span>{renderPaymentStatus(task.paymentStatus,t)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{  marginRight : "5px", marginLeft : "5px", paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA"}}>
                        <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                            <div className="col"><span className="field-label">{t('archive_task_created')}</span></div>
                            <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span></div>
                        </div>
                        <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                            <div className="col"><span className="field-label">{t('archive_task_deadline')}</span></div>
                            <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(deadline).format("Y/M/DD")}</span></div>
                        </div>
                        <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                            <div className="col"><span className="field-label">{t('archive_task_word_count')}</span></div>
                            <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{task.numberOfWords}</span></div>
                        </div>
                        <div className="row" style={{ width: "100%", marginBottom : "15px" }}>
                            <div className="col"><span className="field-label">{t('archive_task_days_to_deadline')}</span></div>
                            <div className="col" style={{ paddingLeft : "25px" }}><span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span></div>
                        </div>
                    </div>
                    <div className="row" style={{  marginRight : "5px", marginLeft : "5px", paddingTop : "15px", paddingBottom : "15px", borderBottom: "1px solid #E9E9EA" }}>
                        <div className="col" style={{ padding : 0 }}>
                            <p className="task-description"><span style={{ color : "#7e7e7e" }}>&nbsp;</span>{task.detailedInstructions}</p>
                            
                            <span style={{ fontSize:"20px", color : "#464646", display: "block" }}>{t('archive_task_paid')}: ${ task.remainingAmount===0? Number.isInterger(task.price) ? task.price.toFixed(2) : task.price : Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount }</span>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="task-item">
                    <div className="row">
                        <div style={{ borderRight: currentLanguage==="ar" ? "none" : "1px solid #E9E9EA", borderLeft: currentLanguage==="ar" ? "1px solid #E9E9EA" : "none"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="row pl-2">
                                <div className="col">
                                    <span className="field-label">{t('archive_task_created')}</span>
                                    <span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span>
                                </div>
                                <div className="col">
                                    <span className="field-label">{t('archive_task_deadline')}</span>
                                    <span className="field-value">{ moment.unix(deadline).format("Y/M/DD")}</span>
                                </div>
                            </div>
                            <div className="row pl-2 mt-4">
                                <div className="col">
                                    <span className="field-label">{t('archive_task_word_count')}</span>
                                    <span className="field-value">{task.numberOfWords}</span>
                                </div>
                                <div className="col">
                                    <span className="field-label">{t('archive_task_days_to_deadline')}</span>
                                    <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                                </div>
                            </div>
    
                        </div>
                        <div style={{ borderRight: currentLanguage==="ar" ? "none" : "1px solid #E9E9EA", borderLeft: currentLanguage==="ar" ? "1px solid #E9E9EA" : "none"}}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="row pl-2">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                    <span style={taskTypeColor(task.taskType)} className="task-type">{t(`archive_task_${task.taskType}`)}</span>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                    <p className="task-title">{task.title}</p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                                <span style={{ fontSize : "13px", color: "#676A6C" }}>{t('archive_task_order_number')}:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                                </div>
                            </div>
                            <div className="row pl-2">
                                <div className="col">
                                    <p className="task-description"><span style={{ color : "#7e7e7e" }}>&nbsp;</span>{task.detailedInstructions}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            
                            <span style={{ fontSize:"20px", color : "#464646" }}>{t('archive_task_paid')}</span>
                            <p className="task-remaining-amount">${ task.remainingAmount===0? Number.isInterger(task.price) ? task.price.toFixed(2) : task.price : Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount }</p>
                        </div>
                    </div>
    
    
                </div>
            )
        }

    }

}

export default Item;
