import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect , withRouter } from 'react-router-dom';
import { signInUser, sendPasswordResetEmail, setUserPresence, signOutUser } from '../../actions/authAction';
import MessageModal from '../Modals/Message';
import SpinnerModal from '../Spinner/SpinnerModal'
import { ForgotPasswordModal } from './Modals/ForgotPasswordModal'
import { UserChooser } from './Modals/UserChooser'

import {
    addModal,
    popModal
} from '../../actions/appAction'

import {
    translate
} from 'react-switch-lang'

import $ from 'jquery';

export class Login extends Component {

    state = {
        email : "",
        password : "",
        errorMessage : "",
        isLoginModalActive : false,
        loginModalHeader : "",
        loginModalSubHeader : "",
        loginModalLink : "",
        emailWrongFormat : "",
        emptyPassword : false,
        emptyEmail : false,
        forgotPasswordModalActive : false,
        isProcessing : false,
        forgotPasswordModalError : "",
        rememberMe : false,
        isMessageModalActive : false,
        underReviewMessage : "",
        isUserChooserModalActive : false,
        provider : "emailAddress"
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onChecked = (e) => {
        this.setState({
            [e.target.name] : e.target.checked
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state
        const { t } = this.props;

         if(email==="" || password===""){
            if(email===""){
                this.setState({
                    emptyEmail : true
                })
            }
            if(password===""){
                this.setState({
                    emptyPassword : true
                })
            }
            this.setState({
                errorMessage : t('login_message_incomplete_details')
            })
        }else{
            this.setState({
                isProcessing : true,
                errorMessage : "",
                emailWrongFormat : "",
                emptyEmail : false,
                emptyPassword : false
            }, () => {

                const { rememberMe } = this.state;
                
                this.props.signInUser("email", "", email,password,rememberMe)
                    .then( (result) => {
                        if(result.code==="auth/user-pending"){
                            this.setState({
                                isProcessing : false,
                                // errorMessage : t('login_message_under_review'),
                            })
                        }else if(result.code==="auth/success"){

                            const { auth } = this.props;
                            
                            this.setState({
                                isProcessing : false
                            }, () => {
                                if(result.user.type==="customer"){
                                    this.props.history.push('/tasks/published')
                                }
    
                                if(result.user.type==="translator"){
                                    this.props.history.push('/tasks/assigned')
                                }
                            })

                        }else{
                            this.setState({
                                isProcessing : false
                            })
                        }
                        setTimeout( () => {
                            if($('.modal-backdrop')[0]!==undefined){
                                $('.modal-backdrop')[0].remove();
                            }
                            if($('.spinner-modal')[0]!==undefined){
                                $('.spinner-modal')[0].remove();
                            }
                        }, 500)

                    }).catch( error => {
                        this.setState({
                            isProcessing : false
                        })
                    })
            })
        }
    }

    selectUserType = (userType) => {
        const { provider } = this.state;
        this.props.signInUser(provider, userType)
        this.setState({
            isUserChooserModalActive : false
        })
    }

    onSubmitSocialMedia = async (e) => {
        
        let provider = e.target.id==="google-btn" ? "google" : "facebook"
        
        this.setState({
            isUserChooserModalActive : true,
            provider : provider
        })
        
    }

    openForgotPassword = () => {
        this.setState({
            forgotPasswordModalActive : true
        })
    }

    closeforgotPasswordModal = () => {
        $('#forgotPasswordModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                forgotPasswordModalActive : false
            })
        }, 1500)
    }

    sendForgotPasswordLink = (data) => {
       
        this.setState({
            isProcessing : true
        }, () => {

            this.props.sendPasswordResetEmail(data.emailAddress)
                .then ( (result) => {
                    if(result.code==200){
                        $('#forgotPasswordModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
                        setTimeout( () => {
                            this.setState({
                                isProcessing : false,
                                forgotPasswordModalActive : false,
                                forgotPasswordModalError : "",
                                isMessageModalActive : true
                            })
                        }, 1500)
                    }else{
                        this.setState({
                            isProcessing : false,
                            forgotPasswordModalError : result.message
                        })
                    }
                })
        })
    }

    componentDidMount(){

        setTimeout( () => {
            if($('.modal-backdrop')[0]!==undefined){
                $('.modal-backdrop')[0].remove();
            }
            if($('.spinner-modal')[0]!==undefined){
                $('.spinner-modal')[0].remove();
            }
        }, 500)
    }

    closeMessageModal = () => {
        $('#messageModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                isMessageModalActive : false
            })
        }, 1500)
    }

    closeUserChooserModal = () => {
        $('#userChooserModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                isUserChooserModalActive : false
            })
        }, 1500)
    }

    render () {

        const { isMobileView } = this.props;
        const { forgotPasswordModalError, isProcessing, forgotPasswordModalActive,  errorMessage, isMessageModalActive, emptyEmail, emptyPassword, isUserChooserModalActive } = this.state;
        const { authError, auth, isLoggingIn, userProfile, modalInstances } = this.props;
        const { currentLanguage, t } = this.props;

        let emailErrorMessage = "";
        let emailErrorClass = "";
        let passwordErrorClass = "";
        let passwordErrorMessage = "";
        let underReviewMessage = "";
        
        if(auth.uid && userProfile.hasOwnProperty('uid')){
            if(userProfile.status==="pending_signup"){
                return <Redirect to={`/signup`}/>
            }
        }

        if(auth.uid && auth.emailVerified && userProfile.hasOwnProperty('uid') && userProfile.status!=="pending") {
            if(userProfile.type==="customer"){
                return <Redirect to="/tasks/published"/>
            }

            if(userProfile.type==="translator"){
                if(userProfile.status==="available" || userProfile.status==="notAvailable"){
                    return <Redirect to="/tasks/assigned"/>
                }
            }

            return ""

        }else{
            if(authError!==null){
                if(errorMessage===""){
                    const { code } = authError;
                    const { provider } = this.state;

                    if(code==="auth/invalid-email"){
                        emailErrorMessage = t('signup_message_incorrect_email');
                        emailErrorClass = "error-input"
                    }
    
                    if(code==="auth/user-not-found"){
                        emailErrorMessage = t('login_message_no_account');
                        emailErrorClass = "error-input"
                    }
    
                    if(code==="auth/wrong-password"){
                        passwordErrorMessage = t('login_message_incorrect_password')
                        passwordErrorClass = "error-input"
                    }
    
                    if(code==="auth/unverified-email"){
                        emailErrorMessage = t('login_message_unverified_email')
                        emailErrorClass = "error-input"
                    }

                    if(code==="auth/user-disabled"){
                        emailErrorMessage = t("login_message_account_disabled")
                        emailErrorClass = "error-input"
                    }

                    if(code==="auth/pending"){
                        underReviewMessage = t('login_message_under_review')
                    }

                    if(code==="auth/popup-closed-by-user"){
                        underReviewMessage = provider === "google" ?  t('login_message_google_closed') : t('login_message_fb_closed')
                    }

                    if(code==="auth/account-exists-with-different-credential"){

                        let errProvider = "";
                        if( authError.provider==="password"){
                            errProvider = t('common_email_and_pass')
                        }

                        if( authError.provider==="google"){
                            errProvider = t('common_google')
                        }

                        if( authError.provider==="facebook"){
                            errProvider = t('common_facebook')
                        }

                        underReviewMessage = t('login_message_dup_assoc',{ email : authError.email, provider : errProvider })
                    }

                    if(code==="auth/unauthorized_login"){
                        underReviewMessage = t('login_message_unathorized_login', { userType : t('login_message_unathorized_login_customer')+"/"+ t('login_message_unathorized_login_translator')})
                    }
                }
            }

            if(errorMessage!==""){
                passwordErrorMessage = "";
                emailErrorMessage = ""
            }
    
            const loginHeader = {
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "48px",
                lineHeight: "64px",
                color: "#414042",
                marginBottom : "40px"
            }
            
            return (
                <div className="main-page main-page-customer">
                    <div className="row">
                        <div className={`col-md-6 ${currentLanguage==="ar" ? "ml-auto" : "mr-auto"}`}>
                            <p className="align-left" style={loginHeader}>{t('login_header')}</p>
                            <div className="row mt-3 mb-3">
                                <div className="col">
                                    <div style={{ display : "flex" }}>
                                        <div style={{ flex : "1", padding : "5px" }}>
                                            <button onClick={this.onSubmitSocialMedia} id="google-btn" className="soc-media-btn google"><img className={`soc-media-img ${isMobileView ? "mob-soc-media-img" : ""}`} src={require('../../images/google-logo.png')}/>{ isMobileView ? t('login_header') : t('common_login_google')}</button>
                                        </div>
                                        <div style={{ flex : "1", padding : "5px" }}>
                                            <button onClick={this.onSubmitSocialMedia} id="fb-btn" className="soc-media-btn fb"><img className={`soc-media-img ${isMobileView ? "mob-soc-media-img" : ""}`} src={require('../../images/fb-logo.png')}/>{ isMobileView ? t('login_header') : t('common_login_fb')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form>
                                <div className="form-group">
                                    <label className="align-left custom-label block">{t('login_header')}</label>
                                    <input onChange={this.onChange} name="email" style={{ width: "100%" }} className={`custom-input ${emailErrorClass} ${emptyEmail ? "error-input" : ""}`} type="text" placeholder={t('common_enter_here')}/>
                                    <span className="block align-left error-input-message">{ emailErrorMessage }</span>
                                </div>
                                <div className="form-group">
                                    <label className="align-left custom-label block">{t('common_password')}</label>
                                    <input onChange={this.onChange} name="password" style={{ width: "100%" }} className={`custom-input ${passwordErrorClass} ${emptyPassword ? "error-input" : ""}`} type="password" placeholder={t('common_enter_here')}/>
                                    <span className="block align-left error-input-message">{ passwordErrorMessage }</span>
                                </div>
                               
                                <div style={{ display : "flex" }}>
                                    <div style={{ flex : "1" }}>       
                                    <button disabled={isLoggingIn} onClick={this.onSubmit} className="custom-btn">{t('login_button')}</button>
                                    </div>
                                    
                                </div>
                                <span className="align-left block error-input-message block mt-3">{ errorMessage }</span>
                                <span className="align-left block error-input-message block mt-3">{ underReviewMessage }</span>
                                <div style={{ marginTop : "15px" }}>
                                        <label className="align-left checkbox-container">{t('login_remember_me')}
                                        <input onClick={this.onChecked} name="rememberMe" type="checkbox"/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                               
                            </form>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="login-sub-text no-account">{t('login_no_account')}<Link className="custom-link" to="/signup">&nbsp;{t('login_signup')}</Link></div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6">
                                <div className="login-sub-text forgot-pass"><a className="custom-link" href="javascript:void(0)" onClick={this.openForgotPassword}>{t('login_forgot')}</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        forgotPasswordModalActive && <ForgotPasswordModal t={t} isMobileView={isMobileView} modalInstances={modalInstances} addModal={this.props.addModal} popModal={this.props.popModal} isOpened={forgotPasswordModalActive} sendForgotPasswordLink={this.sendForgotPasswordLink} closeforgotPasswordModal={this.closeforgotPasswordModal} forgotPasswordModalError={forgotPasswordModalError}/>
                    }
                    {
                        isProcessing && <SpinnerModal isOpened={isProcessing}/>
                    }
                    {
                        isMessageModalActive &&
                        <MessageModal
                            t={t}
                            isMobileView={isMobileView}
                            isOpened={isMessageModalActive}
                            header={t('login_password_reset')}
                            subHeader={t('login_password_reset_text')}
                            closeMessageModal={this.closeMessageModal}
                        />
                    }
                    {
                        isUserChooserModalActive &&
                        <UserChooser  
                        closeUserChooserModal={this.closeUserChooserModal}
                        selectUserType={this.selectUserType}
                        addModal={this.props.addModal} 
                        popModal={this.props.popModal} 
                        isOpened={isUserChooserModalActive}
                        t={t}
                        isMobileView={isMobileView}
                        />
                    }
                </div>
            )
        }

        
        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        authError : state.auth.authError,
        userProfile : state.firebase.profile,
        isLoggingIn : state.auth.isLoggingIn,
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { signInUser, sendPasswordResetEmail, setUserPresence, signOutUser, addModal,
        popModal })
)(Login)