import {
    ADD_MODAL,
    POP_MODAL,
    SET_HEADER,
    SET_DEVICE,
    LOGOUT_SUCCESS,
    SET_CURR_LANG
} from '../actions/types'

const initState = {
    currentZindex : 1050,
    modalInstances : [],
    textHeader : "",
    isMobileHeader : false,
    headerBackRedirect : "back",
    isMobileView : false,
    currentLanguage : "en"
}

const appReducer = (state = initState, action) => {

    let newZindex;

    switch(action.type){
        case ADD_MODAL: 
            newZindex = state.currentZindex + 20;
            state.modalInstances.push({
                modal_class : action.payload.modalClass,
                z_index : newZindex
            });
            return{
                ...state,
                currentZindex : newZindex,
                modalInstances: [...state.modalInstances]
            }
        case POP_MODAL:
            newZindex = state.currentZindex - 20;
            state.modalInstances.pop();
            return{
                ...state,
                currentZindex : newZindex,
                modalInstances : [...state.modalInstances]
            }
        case SET_HEADER:
            return{
                ...state,
                isMobileHeader : action.payload.isMobileHeader,
                textHeader : action.payload.textHeader,
                headerBackRedirect : action.payload.backRedirect
            }
        case SET_DEVICE:
            return{
                ...state,
                isMobileView : action.payload.isMobileView
            }
        case SET_CURR_LANG:{
            return {
                ...state,
                currentLanguage : action.payload.currentLanguage
            }
        }
        case LOGOUT_SUCCESS:
            return {
                currentZindex : 1050,
                modalInstances : [],
                textHeader : "",
                isMobileHeader : false,
                headerBackRedirect : "back",
                isMobileView : state.isMobileView,
                currentLanguage : state.currentLanguage
            }
        default:
            return state;
    }

}

export default appReducer