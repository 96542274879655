import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    setCurrentLanguage
} from '../../actions/appAction'

import $ from 'jquery';

import {
    translate
} from 'react-switch-lang';

export class LoggedOutLinks extends Component {
    
    toggleHeader = () => {
        $('.navbar-toggler').click();
    }

    setLanguage = (lang) => {
        const { isMobileView } = this.props;

        this.props.setCurrentLanguage(lang);
        if(isMobileView){
            this.toggleHeader(); 
        }
         
    }
    

    render () {

        const { isMobileView } = this.props;
        const { t, currentLanguage } = this.props;

        if(isMobileView){
            return (
                <div className="collapse navbar-collapse navbar-dark" id="navbarNav">
                    <ul className="navbar-nav">
                        <li style={{ marginRight : "0"}} className="header-nav-item nav-item active">
                            <a className="align-left header-nav-link nav-link" href="http://www.tarjemle.com#corporate">{t('navbar_corporate')}</a>
                        </li>
                        <li style={{ marginRight : "0"}} className="header-nav-item nav-item">
                            <a className="align-left header-nav-link nav-link" href="http://www.tarjemle.com#about-us">{t('navbar_about_us')}</a>
                        </li>
                        <li style={{ marginRight : "0"}} className="header-nav-item nav-item">
                            <a className="align-left header-nav-link nav-link" href="http://www.tarjemle.com#reviews">{t('navbar_reviews')}</a>
                        </li>
                        <li className="header-nav-item nav-item" style={{ paddingTop: "5px", marginTop : "85px", marginRight : "0" }}>
                            <div className="row">
                                <div className="col">
                                    <Link onClick={this.toggleHeader} className="btn-link btn-signup nav-link" to="/signup">{t('navbar_signup')}</Link>
                                </div>
                                <div className="col">
                                    <Link onClick={this.toggleHeader} className="btn-link btn-login nav-link" to="/login">{t('navbar_login')}</Link>
                                </div>      
                            </div>
                        </li>
                        
                        <li style={{ textAlign : "center" }} className="header-nav-item nav-item">
                            <div style={{ flex : "1", marginTop : "5px" }}>
                                <img onClick={ () => this.props.setCurrentLanguage('ar')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                <img onClick={ () => this.props.setCurrentLanguage('en')} style={{ height : "30px", width : "60px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                            </div>
                        </li>
                    </ul>
                </div>
            )
        }else{
            return (
                <div className="collapse navbar-collapse navbar-dark" id="navbarNav">
                    <ul className={`navbar-nav ${currentLanguage==="ar" ? "mr-auto" : "ml-auto"}`}>
                        <li className="header-nav-item nav-item active">
                            <a className="header-nav-link nav-link" href="http://www.tarjemle.com#corporate">{t('navbar_corporate')}</a>
                        </li>
                        <li className="header-nav-item nav-item">
                            <a className="header-nav-link nav-link" href="http://www.tarjemle.com#about-us">{t('navbar_about_us')}</a>
                        </li>
                        <li className="header-nav-item nav-item">
                            <a className="header-nav-link nav-link" href="http://www.tarjemle.com#reviews">{t('navbar_reviews')}</a>
                        </li>
                        <li className="header-nav-item nav-item" style={{ paddingTop: "5px" }}>
                            <Link className="btn-link btn-login nav-link" to="/login">{t('navbar_login')}</Link>
                        </li>
                        <li className="header-nav-item nav-item"  style={{ paddingTop: "5px" }}>
                            <Link className="btn-link btn-signup nav-link" to="/signup">{t('navbar_signup')}</Link>
                        </li>
                        <li style={{ textAlign : "center" }} className="header-nav-item nav-item">
                            <div style={{ flex : "1", marginTop : "5px" }}>
                                <img onClick={ () => this.setLanguage('ar')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "ar" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/ar-flag.png')}/>
                                <img onClick={ () => this.setLanguage('en')} style={{ height : "20px", width : "35px", borderRadius : "2px", border : currentLanguage === "en" ? "2px solid #23c9c7" : "none", marginRight : "5px", marginLeft : "5px" }} src={require('../../images/en-flag.png')}/>
                            </div>
                        </li>
                    </ul>
                </div>
            )
        }
        
    }
}

const mapStateToProps = (state) => {
    return {
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { setCurrentLanguage } )
)(LoggedOutLinks)
