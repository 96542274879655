import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';

import SpinnerModal from '../../../Spinner/SpinnerModal'

import {
    setCurrentLanguage
} from '../../../../actions/appAction'

  
  import {
    translate
  } from 'react-switch-lang';


export class SuccessPage extends Component {

    constructor(){
        super();
        this.state = {
            isProcessing : false,
        }
    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps){

    }

   
    render() {

        const { isProcessing } = this.state;
        const { t, currentLanguage } = this.props;

        const APP_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_APP_URL : process.env.REACT_APP_DEV_APP_URL
        const ADMIN_URL = process.env.REACT_APP_ENV=="production " ? process.env.REACT_APP_PROD_ADMIN_URL : process.env.REACT_APP_DEV_ADMIN_URL

        return (
            <div className="div-holder">
                <div style={{ textAlign : "center" }}>
                    <img style={{ width : "20%", marginTop : "50px" }} src={ require('../../../../images/tarjemle-logo.png') }/>
                    <p>SUCCESS</p>
                </div>
                {
                    isProcessing &&
                    <SpinnerModal
                        isOpened={isProcessing}
                    />
                }
                
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {
        setCurrentLanguage
    })
)(SuccessPage)