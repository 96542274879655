import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'

import {
    translate
} from 'react-switch-lang'

import exactMath from 'exact-math'

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class TaskInProgress extends Component {
    
    constructor(){
        super();
        this.state = {
            modalZ : 1050,
            transactions : []
        }
    }

    async componentDidMount(){
       
        const { isOpened, uid, task } = this.props;

        if(isOpened){

            let tr = await this.props.getTaskTransactions(task.uid);
            if(tr.code==200){
                this.setState({
                    transactions : [...tr.data.transactions]
                })
            }

            $(`#taskInProgressModal-${uid}`).modal({backdrop:'static', keyboard:false});
            $(`#taskInProgressModal-${uid} .modal-dialog`).attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $(`#taskInProgressModal-${uid}`).modal('show');
            if(this.props.addModal){
                this.props.addModal(`task-inprogress-modal-${uid}`)
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass(`task-inprogress-modal-${uid}-bd`);
        }

    }

    componentDidUpdate(prevProps){
        const { uid } = this.props;

        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class===`task-inprogress-modal-${uid}`);
            if(modalInstance!==undefined){
                $(`.task-inprogress-modal-${uid}-bd`).css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        const { uid } = this.props;

        $(`#taskInProgressModal-${uid}`).modal('hide');
        if(this.props.popModal){
            this.props.popModal()
        }
        $('body').removeClass('modal-open');
    }

    render(){

        const { task, uid } = this.props;
        const { modalZ, transactions } = this.state;
        let d = new Date();
        let dateAdded = task.dateAdded.seconds == undefined ? new Date(moment.unix(task.dateAdded._seconds)) : new Date(moment.unix(task.dateAdded.seconds))
        let deadline = task.deadline.seconds == undefined ? new Date(moment.unix(task.deadline._seconds)) : new Date(moment.unix(task.deadline.seconds))
        let timeline = moment(deadline).diff(dateAdded, 'days')

        let daysDiff = moment(deadline).diff(d,'days')
        
        let percentage = exactMath.sub(100, exactMath.mul(exactMath.div(daysDiff,timeline), 100)).toFixed(2)
        let tasks = [];
        tasks.push(task);

        let firstPaymentDate;
        if(transactions.length!==0){
            firstPaymentDate = transactions[0].dateAdded.seconds == undefined ? new Date(moment.unix(transactions[0].dateAdded._seconds)) : new Date(moment.unix(transactions[0].dateAdded.seconds))
        }
       
        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t, currentLanguage } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} task-inprogress-modal modal fade`} id={`taskInProgressModal-${uid}`} tabIndex="-1" role="dialog" aria-labelledby="taskInProgressModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="custom-modal-header modal-header">
                            <button id="modal-closer" onClick={ () => this.props.closeNotificationModal(uid)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <img style={{ width: "50%", marginBottom : "30px"}} src={require('../../images/tarjemle-logo.png')}/>
                            <p className="text-header">{t('task_modal_quick_progress_header')}</p>
                            { task.paymentStatus === "prepaid" && <p style={{ fontWeight : "400", textAlign : "center", marginTop : "12px" }}>{t('task_modal_quick_progress_sub_header')}</p> }
                            
                            {
                                task &&
                                <div className="task-in-progress task-details mt-3">
                                    <div style={{ display : "flex" }}>
                                        <div style={{ flex : "1" }}><span style={taskTypeColor(task.taskType)} className="task-type">{t(`common_${task.taskType}`)}</span></div>
                                        <div style={{ textAlign: "right" }}><span style={{ fontSize : "14px", color: "#676A6C" }}>{t('common_order_num')}:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span></div>
                                    </div>
                                    <div style={{ display : "flex" }}>
                                        <div className="progress mt-3" style={{ height : "10px", width : "100%" }}>
                                            <div className="progress-bar custom-progress-bar custom-progress-bar-danger" aria-valuenow={percentage} aria-valuemin={0} aria-valuemax={timeline} style={{ width : percentage > 100 ? "100%" : percentage +"%" }}></div>
                                        </div>
                                    </div>
                                    <div style={{ display : "flex" }}>
                                        <div style={{ flex : "1", textAlign : "left" }}><span style={{ fontWeight : "300", fontSize : "12px", color :"#939097"}}>{t('task_modal_quick_progress_completed')}</span></div>
                                        <div style={{ textAlign : "right" }}><span style={{ fontWeight : "300", fontSize : "12px", color :"#939097"}}>{t('task_modal_quick_progress_deadline')}</span></div>
                                    </div>
                                    <div style={{ display : "flex", marginBottom : "24px" }}>
                                        <div style={{ flex : "1", textAlign : "left"  }}><span style={{ fontWeight : "300", fontSize : "12px", color :"#939097"}}>{percentage > 100 ? "100%" : percentage +"%"}</span></div>
                                        <div style={{ textAlign : "right" }}><span style={{ fontWeight : "300", fontSize : "12px", color :"#939097"}}>{moment(deadline).format("Y/M/D")}</span></div>
                                    </div>
                                    <div style={{ display : "flex" }}>
                                        <span style={{ fontSize : "16px", fontWeight : "bold", color :"#414042"}}>{t('common_first_payment')}:{moment(firstPaymentDate).format("M.DD.YYYY")}</span>
                                    </div>
                                    {
                                        task.paymentStatus==="fullyPaid" &&
                                        <div>
                                            <div style={{ display : "flex", marginTop : "24px" }}>
                                                <div style={{ flex : "1", textAlign : "left" }}><span style={{ fontWeight : "300", fontSize : "18px", color :"#676A6C"}}>{t('common_task_all_amount')}</span></div>
                                                <div style={{ textAlign : "right" }}><span style={{ fontWeight : "600", fontSize : "20px", color :"#464646"}}>${ Number.isInteger(task.price) ? task.price.toFixed(2) : task.price}</span></div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (task.paymentStatus==="prepaid" && transactions.length!==0) &&
                                        <div>
                                            <div style={{ display : "flex", marginBottom : "24px" }}>
                                                <div style={{ flex : "1", textAlign : "left" }}><span style={{ fontWeight : "300", fontSize : "18px", color :"#676A6C"}}>{t('task_modal_quick_progress_prepay')}</span></div>
                                                <div stmyle={{ textAlign : "right" }}><span style={{ fontWeight : "600", fontSize : "20px", color :"#464646"}}>${ Number.isInteger(transactions[0].amount) ? transactions[0].amount.toFixed(2) : transactions[0].amount}</span></div>
                                            </div>
                                            <div style={{ display : "flex" }}>
                                                <div style={{ flex : "1", textAlign : "left" }}><span style={{ fontWeight : "300", fontSize : "18px", color :"#414042"}}>{t('task_modal_quick_progress_to_pay')}</span></div>
                                            </div>
                                            <div style={{ display : "flex", marginBottom : "56px" }}>
                                                <div style={{ flex : "1", textAlign : "left" }}><span style={{ fontWeight : "600", fontSize : "32px", color :"#676A6C"}}>${ Number.isInteger(task.remainingAmount) ? task.remainingAmount.toFixed(2) : task.remainingAmount }</span></div>
                                                <div style={{ textAlign : "right", paddingTop : "15px" }}><span><img src={require('../../images/icon-coupon.png')}/><a style={{ marginLeft : "10px", color : '#7AB7B6', textDecoration :"underline", fontSize : '16px', cursor : "pointer" }} onClick={this.props.openCouponCodeModal}>{t('common_enter_coupon')}</a></span></div>
                                            </div>
                                            <div className="text-center">
                                                <button onClick={ () => this.props.payLeftAmount(tasks, uid) } style={{ width: "210px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('common_left_to_pay')}</button>
                                            </div> 
                                        </div>
                                    }   
                                    
                                </div>
                            }

                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps)
)(TaskInProgress)