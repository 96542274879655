const initState = {
    sList : []
}

const additionalServiceReducer = ( state = initState, action ) => {

    switch(action.type){
        case "GET_ADDITIONAL_SERVICES_SUCCESS":
            return {
                ...state,
                sList : [...action.payload.data]
            }
        case "GET_ADDITIONAL_SERVICES_ERROR":
            return {
                ...state,
                sList : []
            }
        default:
            return state;
    }

}

export default additionalServiceReducer;