import React, { Component } from 'react'
import $ from 'jquery'

export class Header extends Component {


    render () {

        const { task, conversation, currentLanguage, t } = this.props;

        const managerName = conversation.memberData[task.accountManager].fullName.split(',')

        return (
            <div className="chat-header">
                <span style={{ color : '#414042', fontSize : "16px" }}>{t('task_modal_details_chat')}</span>
                <div className="mt-2" style={{ display : "flex", position : "relative"}}>
                    <div style={{ flex : "1" }} className="align-left">
                        <img style={{ position : "absolute", width: "40px", height : "40px" }} src={ conversation.memberData[task.accountManager].imageUrl==="" ? require('../../../images/default-user.jpg') : conversation.memberData[task.accountManager].imageUrl} />
                        <span style={{ fontSize : "12px", fontWeight : "bold", color : "#676A6C", marginLeft : currentLanguage ==="en" ?  "50px" : "unset", marginRight : currentLanguage ==="ar" ?  "50px" : "unset" }}>{managerName[1]+ " " + managerName[0]}<br/></span>
                        <span style={{ fontSize : "12px", fontWeight : "light", color : '#464646', marginLeft : currentLanguage ==="en" ?  "50px" : "unset", marginRight : currentLanguage ==="ar" ?  "50px" : "unset" }}>{task.title}</span>
                    </div>
                    <div style={{ flex : "1" }} className="align-right">
                        <span><br/></span>
                        <span style={{ fontSize : "14px", fontWeight : "light", color : "#22C9C7", textTransform : "uupercase"}}>{t('task_modal_details_chat')}</span>
                    </div>
                </div>
            </div>
        )
    }

}

export default Header