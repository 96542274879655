import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

import { translate } from 'react-switch-lang'


export class StopTaskModal extends Component {

    constructor(){
        super();
        this.state = {
            stopReason : "",
            showOtherInput : false,
            isReasonEmpty : false,
            modalZ : 1050
        }
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#stopTaskModal').modal({backdrop:'static', keyboard:false});
            $('#stopTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#stopTaskModal').modal('show');
            this.props.addModal('stop-task-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("sto-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="stop-task-modal");
            if(modalInstance!==undefined){
                $('.sto-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#stopTaskModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }


    selectReason = (e) => {
        let text = $(e.target).parent('.checkbox-container').text();

        if(text==="Other"){
            this.setState({
                showOtherInput : true,
                stopReason : ""
            })
        }else{
            this.setState({
                stopReason : text,
                showOtherInput : false
            })
        }

        
    }

    onInputChange = (e) => {

        if(e.target.value!==""){
            this.setState({
                isReasonEmpty : false
            })
        }

        this.setState({
            stopReason : e.target.value
        })

    }

    stopTask = () => {

        const { stopReason } = this.state;

        if(stopReason===""){

            this.setState({
                isReasonEmpty : true
            })

        }else{
            this.props.stopTask({
                stopReason : stopReason
            })
        }

    }

    render(){
        const { stopReason, showOtherInput, isReasonEmpty, modalZ } = this.state;

        let modalDialogClass = window.innerWidth < 1024 ? "mobile-modal" : "" 

        const { isMobileView, t, currentLanguage } = this.props;
        
        return (
            <div style={{ zIndex : `${modalZ}` }} className={` ${modalDialogClass} stop-task-modal modal fade modal-opaque`} id="stopTaskModal" tabIndex="-1" role="dialog" aria-labelledby="stopTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        
                        {
                            window.innerWidth<1024 ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeStopTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="custom-modal-header modal-header">
                                <button onClick={this.props.closeStopTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        <div className="modal-body">
                            {
                                window.innerWidth>=1024 && <img style={{ width: "50%", marginBottom : "30px"}} src={require('../../../images/tarjemle-logo.png')}/>
                            }
                            <p className="text-header">Stop Task</p>
                            <p className="align-left" style={{ fontWeight : "bold", marginTop : "50px" }}>{t('task_modal_stop_reason_header')}</p>
                            
                    
                            <div className="form-group">
                                <ul className="reason-list align-left">
                                    <li>
                                        <label className="checkbox-container">{t('task_modal_stop_no_money')}
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">The task is no longer relevant
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('task_modal_stop_too_long')}
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('task_modal_stop_broken')}
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('common_other')}
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div className="form-group align-left" style ={{ paddingLeft : "30px" }}>
                            { showOtherInput && 
                                <div className="div-holder">
                                    <input style={{ width: "90%" }} onChange={this.onInputChange} id="other_reason" name="other_reason" style={{ width: "100%" }} className={`custom-input ${ isReasonEmpty ? "error-input" : ""}`} type="text" placeholder={t('common_write_reason')}/>
                                </div>
                            }  
                            </div>
                            <div className="text-center">
                                <button onClick={this.stopTask} style={{ width: isMobileView ? "100%" : "192px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>STOP TASK</button>    
                            </div>                            
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    translate,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(StopTaskModal)