import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { watchNewMessages, resetMessageBadge } from '../../actions/conversationAction';

import './footer.css'

import $ from 'jquery';


export class Footer extends Component {

    componentDidMount(){
        const { auth } = this.props;

        if(auth.uid){
            this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
        }
    }

    componentDidUpdate(prevProps){
        
        if(this.props.userProfile!==prevProps.userProfile){
            this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
            if(this.props.location.pathname!=="/chat"){
                setTimeout( () => {
                    this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
                }, 3000 )
            }
        }

        if(this.props.location!==prevProps.location){
            if(this.props.location.pathname==="/chat"){
                this.unsubscribeWatchNewMessages &&  this.unsubscribeWatchNewMessages()
            }else{
                setTimeout( () => {
                    this.unsubscribeWatchNewMessages = this.props.watchNewMessages();
                }, 3000 )
            }
        }
    }

    clickToggler = () => {
        if(!$('.navbar-toggler').hasClass('collapsed')){
            $('.navbar-toggler').click();
        }
    }

    render(){

        const { location, userProfile, hasNewMessage } = this.props;

        let path = location.pathname;

        return (
            <div className="footer">
                <nav className="navbar navbar-expand-lg fixed-bottom" style={{ flexWrap : "no-wrap"}}>
                    {
                        userProfile.type==="customer" ?
                        <div className="footer-icon-div">
                            <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/tasks/published">
                                <img className="footer-icon" src={ 
                                    path==="/tasks/published" ? 
                                    require('../../images/icon-footer-tasks-active.png'):
                                    require('../../images/icon-footer-tasks.png')
                                }/>
                            </Link>
                        </div> :
                        <div className="footer-icon-div">
                            <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/tasks/in_progress">
                                <img className="footer-icon" src={ 
                                    path==="/tasks/in_progress" ? 
                                    require('../../images/icon-footer-tasks-active.png'):
                                    require('../../images/icon-footer-tasks.png')
                                }/>
                            </Link>
                        </div>
                    }
                    <div className="footer-icon-div">
                        {
                            userProfile.type==="customer" ?
                            <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/task/create">
                                <img className="footer-icon" src={require('../../images/icon-footer-task.png')}/>
                            </Link>
                            :
                            <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/">
                                <img style={{ width : "30px", height : "30px"}} className="footer-icon" src={require('../../images/notification-bell.png')}/>
                            </Link>
                        }
                    </div>
                    <div className="footer-icon-div">
                        <Link onClick={this.props.resetMessageBadge} role="menuitem" tabIndex="-1" to="/chat">
                            <div style={{ position : "relative" }}><img style={path==="/chat" ? { width : "30px", height : "25px" } : {}} className="footer-icon" src={ 
                                    path==="/chat" ? 
                                    require('../../images/icon-footer-messages-active.png'):
                                    require('../../images/icon-footer-messages.png')
                                }/> { hasNewMessage && <img style={{ width: "12px", position : "absolute", top : "10px", left : "30px" }} src={require('../../images/icon-archived.png')}/> }</div>
                        </Link>
                    </div>
                    <div className="footer-icon-div">
                        <Link onClick={this.clickToggler} role="menuitem" tabIndex="-1" to="/profile">
                            <img style={{ width: "30px", height : "30px", borderRadius : "100%" }} className="footer-icon" src={ userProfile && userProfile.imageUrl === "" ? require('../../images/icon-footer-profile.png') : userProfile.imageUrl }/>
                        </Link>
                    </div>
                </nav>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        hasNewMessage : state.conversation.hasNewMessage,
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {
        resetMessageBadge,
        watchNewMessages
    })
)
(Footer)