import {
    CHECK_CONVERSATION,
    CHECK_CONVERSATION_SUCCESS,
    CHECK_CONVERSATION_ERROR,
    CREATE_CONVERSATION,
    CREATE_CONVERSATION_SUCCESS,
    CREATE_CONVERSATION_ERROR,
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_SUCCESS,
    GET_CONVERSATIONS_ERROR,
    LOAD_MORE_CONVERSATIONS,
    LOAD_MORE_CONVERSATIONS_SUCCESS,
    LOAD_MORE_CONVERSATIONS_ERROR,
    CLEAR_ACTIVE_CONVERSATION,
    CREATE_CONVERSATION_MESSAGE,
    CREATE_CONVERSATION_MESSAGE_SUCCESS,
    CREATE_CONVERSATION_MESSAGE_ERROR,
    RELOAD_CONVERSATION_MESSAGES,
    SET_ACTIVE_CONVERSATION,
    RELOAD_ACTIVE_CONVERSATION,
    REMOVE_ACTIVE_CONVERSATION,
    GET_CONVERSATION_MESSAGES,
    GET_CONVERSATION_MESSAGES_SUCCESS,
    GET_CONVERSATION_MESSAGES_ERROR,
    GET_CONVERSATION_MESSAGES_PAGINATE,
    GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS,
    GET_CONVERSATION_MESSAGES_PAGINATE_ERROR,
    SEARCH_CONTACTS,
    SEARCH_CONTACTS_SUCCESS,
    SEARCH_CONTACTS_ERROR,
    SEARCH_MESSAGES,
    SEARCH_MESSAGES_SUCCESS,
    SEARCH_MESSAGES_ERROR,
    DISPLAY_MESSAGE_BADGE,
    RESET_MESSAGE_BADGE,
    RELOAD_CONVERSATIONS
} from '../actions/types'

import moment from 'moment'

const initState = {
    activeConversation : null,
    isCheckingConversation : false,
    isCreatingConversation : false,
    isGettingConversations : false,
    isLoadingMoreConversations : false,
    conversations : [],
    lastOpenedDate : null,
    conversationsRef : [],
    conversationsLastRef : null,
    isGettingMessages : false,
    isGettingMoreMessages : false,
    messages : [],
    groupedMessages : {},
    messagesRef : [],
    messagesLastRef : null,
    messageLastSent : null,
    isSearchingContacts : false,
    searchedContacts : [],
    isSearchingMessages : false,
    searchedMessages : [],
    hasNewMessage : false
}

const conversationReducer = ( state = initState , action ) => {

    switch(action.type){
        case DISPLAY_MESSAGE_BADGE:
            return {
                ...state,
                hasNewMessage : true
            }
        case RESET_MESSAGE_BADGE:
            return {
                ...state,
                hasNewMessage : false
            }
        case CHECK_CONVERSATION:
            return {
                ...state,
                isCheckingConversation : true
            }
        case CHECK_CONVERSATION_SUCCESS:
            return {
                ...state,
                // activeConversation : {...action.payload.conversation},
                isCheckingConversation : false
            }
        case CHECK_CONVERSATION_ERROR:
            return {
                ...state,
                activeConversation : null,
                isCheckingConversation : false
            }
        case CREATE_CONVERSATION_SUCCESS:
            return {
                ...state,
                activeConversation : action.payload.conversation
            }
        case CREATE_CONVERSATION_ERROR:
            return {
                ...state,
                activeConversation : null
            }
        case CREATE_CONVERSATION_MESSAGE :
            return {
                ...state
            }
        case CREATE_CONVERSATION_MESSAGE_SUCCESS:
            return {
                ...state,
                activeConversation : action.payload.conversation
            }
        case GET_CONVERSATIONS:
            return {
                ...state,
                isGettingConversations : true,
            }
        case GET_CONVERSATIONS_ERROR:
            return{
                ...state,
                isGettingConversations : false
            }
        case LOAD_MORE_CONVERSATIONS:
            return {
                ...state,
                isLoadingMoreConversations : true
            }
        case GET_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                isGettingConversations : false,
                conversations : [...action.payload.conversations],
                conversationsRef : [...action.payload.conversationsRef],
                conversationsLastRef : action.payload.conversationsRef.length===0 ? null : action.payload.conversationsRef[action.payload.conversationsRef.length-1],
            }
        case LOAD_MORE_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                isLoadingMoreConversations : false,
                conversations :  [...state.conversations, ...action.payload.conversations],
                conversationsRef : [...state.conversationsRef, ...action.payload.conversationsRef],
                conversationsLastRef : action.payload.conversationsRef.length===0 ? state.conversationsRef[state.conversationsRef.length-1] : action.payload.conversationsRef[action.payload.conversationsRef.length-1],
            }
        case LOAD_MORE_CONVERSATIONS_ERROR:
            return{
                ...state,
                isLoadingMoreConversations : false
            }
        case RELOAD_CONVERSATIONS:
            
            let conversationIndex = state.conversations.findIndex( c => c.uid === action.payload.data.uid);

            if(conversationIndex===-1){
                if(state.conversations.length>10){
                    state.conversations.splice(conversationIndex, 1);
                    state.conversationsRef.splice(conversationIndex, 1);
                    state.conversations.unshift(action.payload.data)
                    state.conversationsRef.unshift(action.payload.dataRef)
                }else{
                    state.conversations.unshift(action.payload.data)
                    state.conversationsRef.unshift(action.payload.dataRef)
                }

                return {
                    ...state,
                    conversations : [...state.conversations],
                    conversationsRef : [...state.conversationsRef],
                    conversationsLastRef : state.conversationsRef[state.conversationsRef.length-1]
                }
            }else{
                state.conversations[conversationIndex] = action.payload.data

                return {
                    ...state,
                    conversations : [...state.conversations],
                    conversationsRef : [...state.conversationsRef],
                    conversationsLastRef : state.conversationsRef[state.conversationsRef.length-1]
                }
            }

            return{
                ...state
            }
            
        case SET_ACTIVE_CONVERSATION:

            return {
                ...state,
                activeConversation : action.payload.conversation,
                messageLastSent : action.payload.lastDateSent,
                messages : [],
                messagesRef : [],
                messagesLastRef : null,
                groupedMessages : {}
            }
        case RELOAD_ACTIVE_CONVERSATION:
            
            return {
                ...state,
                activeConversation : action.payload.data,
            }

        case REMOVE_ACTIVE_CONVERSATION:

            let removeData = action.payload.conversation
            let removeDataIndex = state.conversations.findIndex( (data) => data.uid===removeData.uid )

            if(removeDataIndex!==-1){
                state.conversations.splice(removeDataIndex, 1);
                state.conversationsRef.splice(removeDataIndex, 1);
            }

            return{
                ...state,
                conversations : [...state.conversations],
                conversationsRef : [...state.conversationsRef],
                conversationsLastRef : state.conversationsRef[state.conversationsRef.length-1],
                activeConversation : null
            }

        case CLEAR_ACTIVE_CONVERSATION:
            return {
                ...state,
                activeConversation : null,
                lastOpenedDate : null,
                messages : [],
                messagesLastRef : null,
                messagesRef : [],
                groupedMessages : {},
                conversations: [],
                conversationsRef : [],
                conversationsLastRef : null
            }
        case RELOAD_CONVERSATION_MESSAGES:
            
            let nm = action.payload.data;
            let nmDateKey = moment.unix(nm.dateAdded.seconds).format("dddd, MMMM Do YYYY");

            if(!state.groupedMessages.hasOwnProperty(nmDateKey)){
                state.groupedMessages[nmDateKey] = [];
            }

            state.groupedMessages[nmDateKey].push(nm)

            let rsorted = Object.keys(state.groupedMessages).sort( (a,b) => moment(b,"dddd, MMMM Do YYYY") - moment(a,"dddd, MMMM Do YYYY") );
            let rsortedMessages = {};

            rsorted.map( data =>{
                rsortedMessages[data] = [...state.groupedMessages[data]]
            })

            return {
                ...state,
                messages : [...state.messages, action.payload.data],
                groupedMessages : {...rsortedMessages}
            }
        case GET_CONVERSATION_MESSAGES:
            return {
                ...state,
                isGettingMessages : state.messages.length == 0 ? false : true
            }
        case GET_CONVERSATION_MESSAGES_SUCCESS:

            let gMessages = {};

            for(let i=action.payload.messages.length-1; i>=0; i--){

                let dm = action.payload.messages[i];
                let dateKey = moment.unix(dm.dateAdded.seconds).format("dddd, MMMM Do YYYY");

                if(!gMessages.hasOwnProperty(dateKey)){
                    gMessages[dateKey] = [];
                }

                gMessages[dateKey].unshift(dm)

            }
            
            let sorted = Object.keys(gMessages).sort( (a,b) => moment(b,"dddd, MMMM Do YYYY") - moment(a,"dddd, MMMM Do YYYY") );
            let sortedMessages = {};

            sorted.map( data =>{
                sortedMessages[data] = [...gMessages[data]]
            })

            return{
                ...state,
                isGettingMessages : false,
                groupedMessages : {...sortedMessages},
                messages : [...action.payload.messages],
                messagesRef : [...action.payload.messagesRef],
                messagesLastRef : action.payload.messagesRef.length===0 ? null : action.payload.messagesRef[action.payload.messagesRef.length-1],
            }
        case GET_CONVERSATION_MESSAGES_PAGINATE:
            return {
                ...state,
                isGettingMoreMessages : true,
            }
        case GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS:
            
            let wsortedMessages;

            if(action.payload.messages.length!==0){
                for(let i=action.payload.messages.length-1; i>=0; i--){

                    let pm = action.payload.messages[i];
                    let pmDateKey = moment.unix(pm.dateAdded.seconds).format("dddd, MMMM Do YYYY");

                    if(!state.groupedMessages.hasOwnProperty(pmDateKey)){
                        state.groupedMessages[pmDateKey] = [];
                    }
        
                    state.groupedMessages[pmDateKey].unshift(pm)
                
                }
            }

            let wsorted = Object.keys(state.groupedMessages).sort( (a,b) => moment(b,"dddd, MMMM Do YYYY") - moment(a,"dddd, MMMM Do YYYY") );
            wsortedMessages = {};

            wsorted.map( data =>{

                let unique = state.groupedMessages[data]
                        .map(e => e['uid'])
                    
                        // store the keys of the unique objects
                        .map((e, i, final) => final.indexOf(e) === i && i)
                    
                        // eliminate the dead keys & store unique objects
                        .filter(e => state.groupedMessages[data][e]).map(e => state.groupedMessages[data][e]);

                wsortedMessages[data] = [...unique]
            })

            return{
                ...state,
                isGettingMoreMessages : false,
                groupedMessages : {...wsortedMessages},
                messages : [...state.messages, ...action.payload.messages],
                messagesRef : [...state.messagesRef, ...action.payload.messagesRef],
                messagesLastRef : action.payload.messagesRef.length===0 ? state.messagesRef[state.messagesRef.length-1] : action.payload.messagesRef[action.payload.messagesRef.length-1]
            }
        case SEARCH_CONTACTS:
            return{
                ...state,
                isSearchingContacts : true,
                searchedContacts : []
            }
        case SEARCH_CONTACTS_SUCCESS:
            return {
                ...state,
                isSearchingContacts : false,
                searchedContacts : [...action.payload.searchedContacts]
            }
        case SEARCH_MESSAGES:
            return{
                ...state,
                isSearchingMessages : true,
                searchedMessages : []
            }
        case SEARCH_MESSAGES_SUCCESS:
            return{
                ...state,
                isSearchingMessages: false,
                searchedMessages : [...action.payload.searchedMessages]
            }
        default :
            return state
    }

}

export default conversationReducer;