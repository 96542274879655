import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import MobileItem from './MobileItem';

export class MobileList extends Component {

    render(){

        const { tasks, taskSet, isMobileView, t } = this.props;
        const { isLoadingMoreTranslatorPrepaidTasks ,  isLoadingMoreTranslatorFullyPaidTasks } = this.props;

        if(taskSet==="inProgress"){
            return (
                <div className="" ref="incomeReportList">
                    <div>
                        <p style={{ fontSize : "14px", fontWeight : "bold" }}>{t('in_progress_header')}</p>
                    </div>
                    {
                        tasks.map( (data, i) => <MobileItem t={t} displayDelay={500} key={data.uid} task={data}/> )
                    }
                    <div className="text-center">
                    {
                        isLoadingMoreTranslatorPrepaidTasks ? <div style={{ paddingTop : "15px" }}>
                            <div class="custom-get-more-spinner spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                            <button onClick={this.props.loadMoreTranslatorPrepaidTasks} className="load-more-button">{t('common_load_more')}</button>
                        </div>
                    }
                    </div>  
                </div>
            )
        }else{
            return (
                <div className="" ref="incomeReportList">
                    <div>
                        <p style={{ fontSize : "14px", fontWeight : "bold" }}>{t('income_ready_projects')}</p>
                    </div>
                    {
                        tasks.map( (data, i) => <MobileItem t={t} displayDelay={500} key={data.uid} task={data}/> )
                    }
                    <div className="text-center">
                    {
                        isLoadingMoreTranslatorFullyPaidTasks ? <div style={{ paddingTop : "15px" }}>
                            <div class="custom-get-more-spinner spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                            <button onClick={this.props.loadMoreTranslatorFullyPaidTasks} className="load-more-button">{t('common_load_more')}</button>
                        </div>
                    }
                    </div>  
                </div>
            )
        }

    }

}

export default MobileList;
