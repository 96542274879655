import React, { Component } from 'react'
import $ from 'jquery';

export class NewPassword extends Component {
    
    constructor(){
        super();
        this.state = {
            modalZ : 1050
        }
    }

    componentDidMount(){
        const { isOpened } = this.props;

        if(isOpened){
            $('#passwordChangeModal').modal({backdrop:'static', keyboard:false});
            $('#passwordChangeModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#passwordChangeModal').modal('show');
            if(this.props.addModal){
                this.props.addModal('password-change-modal')
            }
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("pwc-bd");
        }
    }
    

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="password-change-modal");
            if(modalInstance!==undefined){
                $('.pwc-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#passwordChangeModal').modal('hide');
        if(this.props.popModal){
            this.props.popModal()
        }
        $('body').removeClass('modal-open');
    }

    render(){

        const { modalZ } = this.state;

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : ""

        const { t } = this.props;

        return (
            <div style={{ zIndex : `${modalZ}` }} className={` ${modalDialogClass} password-change-modal modal fade`} id="passwordChangeModal" tabIndex="-1" role="dialog" aria-labelledby="passwordChangeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="custom-modal-header modal-header">
                            <button onClick={this.props.closeNewPasswordModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text-header">{t('profile_modal_change_pass_header')}</p>
                            <div className="form-group align-left">
                                <label className="custom-label block">{t('profile_modal_new_pass')}</label>
                                <input onChange={this.props.onPasswordChange} id="newAuthPassword" name="newAuthPassword" style={{ width: "100%" }} className={`custom-input`} type="password" placeholder={t('common_enter_here')}/>
                                <span class="error-input-message"></span>
                            </div>
                            <div className="form-group align-left">
                                <label className="custom-label block">{t('profile_modal_new_pass_confirm')}</label>
                                <input onChange={this.props.onPasswordConfirmChange} id="authPasswordConfirm" name="authConfirmPassword" style={{ width: "100%" }} className={`custom-input`} type="password" placeholder={t('common_enter_here')}/>
                                <span class="error-input-message"></span>
                            </div>
                            <div className="text-center">
                                <button onClick={this.props.onSavePasswordChange} style={{ width: "100%" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('common_save')}</button>    
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewPassword