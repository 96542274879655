import React, { Component } from 'react'
import $ from 'jquery';

export class TermsAndConditionsModal extends Component {

    constructor(){
        super();
    }

    componentDidMount(){
       
        const { isOpened } = this.props;
        
        if(isOpened){
            $('.modal-backdrop').addClass('.modal-opaque');
            $('#termsAndConditionsModal').modal({backdrop:'static', keyboard:false});
            $('#termsAndConditionsModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#termsAndConditionsModal').modal('show');
        }

    }

    componentWillUnmount(){
        $('#termsAndConditionsModal').modal('hide');
    }

    convertNtoNewLine = (body) => {
        return body.replace(/\\n/g,"\n\n")
    }

    compareValues = (key, order='asc') => {

        return function(a, b) {
            if(!a.hasOwnProperty(key) || 
               !b.hasOwnProperty(key)) {
                return 0; 
            }
            
            const varA = (typeof a[key] === 'string') ? 
              a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ? 
              b[key].toUpperCase() : b[key];
              
            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return (
              (order == 'desc') ? 
              (comparison * -1) : comparison
            );
          };

    }

    render(){
        const { termsAndConditionsList, t } = this.props;

        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : ""

        let sortedTerms = termsAndConditionsList.sort(this.compareValues('order','asc'))

        return (
            <div className={`${modalDialogClass} terms-and-conditions-modal modal fade modal-opaque`} id="termsAndConditionsModal" tabIndex="-1" role="dialog" aria-labelledby="termsAndConditionsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col align-left">
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col align-right" style={{ paddingRight : "0" }}>
                                        <button onClick={this.props.setTermsAndConditionsModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="modal-header">
                                <button onClick={this.props.setTermsAndConditionsModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "35px" }} aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        
                        <div className="modal-body">
                            { !isMobileView && <img style={{ width: "25%", marginBottom : "30px"}} src={require('../../../images/tarjemle-logo.png')}/> }
                            <p className="text-header">{t('signup_modal_terms_header')}</p>
                            <div className="terms-div">
                            {
                                sortedTerms.map( ( data, i) => (

                                    <div key={i}>
                                        <p className="align-left" style={{ marginBottom : "8px", fontSize :"20px", color : "#000000", fontWeight : "600" }}>{data.title}</p>
                                        <p className="align-left" style={{ whiteSpace : "pre-line" ,lineHeight : "28px", fontSize :"16px", color : "#464646", fontWeight : "500" }}>{this.convertNtoNewLine(data.body)}</p>
                                    </div>

                                ))
                            }
                            <div id="bottom-fade"></div>
                            </div>

                            <button onClick={this.props.setAcceptTerms} style={{ width: "192px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('signup_modal_terms_accept')}</button>    

                        </div>
                    </div>
                </div>
            </div>
            
        )

    }

}

export default TermsAndConditionsModal