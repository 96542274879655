import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import moment from 'moment';

const renderStatus = (status) => {
    return status==="inProgress" ? "In progress" : "Completed"
}

const taskTypeColor = (type) => {
    switch(type){
        case "presentation":
            return { background: "#EA5895" }
        case "essay":
            return { background : "#7E57C2" }
        default:
            return { background : "#29B6F6" }
    }
}

export class Item extends Component {

    render(){

        const { task } = this.props;

        let dateAdded = task.dateAdded.seconds === undefined ? task.dateAdded._seconds : task.dateAdded.seconds;
        let deadline = task.deadline.seconds === undefined ? task.deadline._seconds : task.deadline.seconds;
       
        return (
            <div className="task-item">
                <div className="row">
                    <div style={{ borderRight: "1px solid #E9E9EA"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div className="row pl-2">
                            <div className="col">
                                <span className="field-label">Task created</span>
                                <span className="field-value">{ moment.unix(dateAdded).format("Y/M/DD")}</span>
                            </div>
                            <div className="col">
                                <span className="field-label">Deadline</span>
                                <span className="field-value">{ moment.unix(deadline).format("Y/M/DD")}</span>
                            </div>
                        </div>
                        <div className="row pl-2 mt-4">
                            <div className="col">
                                <span className="field-label">Words number</span>
                                <span className="field-value">{task.numberOfWords}</span>
                            </div>
                            <div className="col">
                                <span className="field-label">Days to deadline</span>
                                <span className="field-value">{ moment.unix(deadline).diff(moment(new Date()), 'days')}</span>
                            </div>
                        </div>

                    </div>
                    <div style={{ borderRight: "1px solid #E9E9EA"}}  className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="row pl-2">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                <span style={taskTypeColor(task.taskType)} className="task-type">{task.taskType}</span>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                <p className="task-title">{task.title}</p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                            <span style={{ fontSize : "13px", color: "#676A6C" }}>Order#:<span style={{ fontSize: ".80rem", color: "black" }}>{task.orderID}</span></span>
                            </div>
                        </div>
                        <div className="row pl-2">
                            <div className="col">
                                <p className="task-description"><span style={{ color : "#7e7e7e" }}>Description: &nbsp;</span>{task.detailedInstructions}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ position :"relative" }} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        
                        <span style={{ fontSize:"20px", color : "#464646" }}>Your earnings</span>
                        <p className="task-remaining-amount">${ parseFloat(task.price - task.remainingAmount).toFixed(2) }</p>
                    </div>
                </div>


            </div>
        )

    }

}

export default Item;
