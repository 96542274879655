import React, { Component } from 'react'
import Item from './Item';

export class List extends Component {

    openDeleteTaskModal = (uid) => {
        this.props.openDeleteTaskModal(uid);
    }

    render(){

        const { tasks, isLoadingMoreBasketTasks, t, currentLanguage, isMobileView } = this.props;

        return (

            <div className="div-holder">

                {
                    tasks.map( (data, i) => <Item displayDelay={500} t={t} currentLanguage={currentLanguage} isMobileView={isMobileView} openDeleteTaskModal={ () => this.openDeleteTaskModal(data)} key={data.uid} task={data}/> )
                }

                <div className="text-center">
                {
                    isLoadingMoreBasketTasks ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreBasketTasks} className="load-more-button">{t('common_load_more')}</button>
                    </div>
                }
    
                </div>
            </div>
        )

    }

}

export default List;
