import {
    CHECK_CONVERSATION,
    CHECK_CONVERSATION_SUCCESS,
    CHECK_CONVERSATION_ERROR,
    CREATE_CONVERSATION,
    CREATE_CONVERSATION_SUCCESS,
    CREATE_CONVERSATION_ERROR,
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_SUCCESS,
    GET_CONVERSATIONS_ERROR,
    LOAD_MORE_CONVERSATIONS,
    LOAD_MORE_CONVERSATIONS_SUCCESS,
    LOAD_MORE_CONVERSATIONS_ERROR,
    CLEAR_ACTIVE_CONVERSATION,
    CREATE_CONVERSATION_MESSAGE,
    CREATE_CONVERSATION_MESSAGE_SUCCESS,
    CREATE_CONVERSATION_MESSAGE_ERROR,
    RELOAD_CONVERSATION_MESSAGES,
    SET_ACTIVE_CONVERSATION,
    RELOAD_ACTIVE_CONVERSATION,
    REMOVE_ACTIVE_CONVERSATION,
    GET_CONVERSATION_MESSAGES,
    GET_CONVERSATION_MESSAGES_SUCCESS,
    GET_CONVERSATION_MESSAGES_ERROR,
    GET_CONVERSATION_MESSAGES_PAGINATE,
    GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS,
    GET_CONVERSATION_MESSAGES_PAGINATE_ERROR,
    SEND_MESSAGE,
    SEND_MESSAGE_ERROR,
    SEND_MESSAGE_SUCCESS,
    SEARCH_CONTACTS,
    SEARCH_CONTACTS_SUCCESS,
    SEARCH_CONTACTS_ERROR,
    SEARCH_MESSAGES,
    SEARCH_MESSAGES_SUCCESS,
    SEARCH_MESSAGES_ERROR,
    DISPLAY_MESSAGE_BADGE,
    RESET_MESSAGE_BADGE,
    RELOAD_CONVERSATIONS
} from './types'

import moment from 'moment'
import algoliasearch from 'algoliasearch'


const client = algoliasearch('QZDWQC5AMN','c8973aeead0de12842cdf80db955436a',{
    timeouts: {
      connect: 1000,
      read: 2 * 1000,
      write: 30 * 1000
    }
})


export const checkConversation = (data) => async (dispatch, getState, {getFirestore, getFirebase}) => {

    dispatch({
        type : CHECK_CONVERSATION
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    return new Promise ( async (resolve, reject) => {

        let conversationRef = firestore.collection('conversations')
                                        .where('members','array-contains',firebase.auth().currentUser.uid)
        
        conversationRef = conversationRef.where(`memberTypes.${data.recipient}`,'==',true)
                                        
        conversationRef = conversationRef.where('task','==',data.taskUid)

        await conversationRef.get().then( async querySnapshot => {

            let code = 404
            let type = CHECK_CONVERSATION_ERROR
            let conversation = null;
            let userData = null;

            if(querySnapshot.docs.length!==0){
                code = 200
                type = CHECK_CONVERSATION_SUCCESS
                conversation = querySnapshot.docs[0].data()

            }else{
                let userRef = null;
                
                if(data.recipient==="accountManager"){
                    userRef = firestore.collection('users').doc(data.accountManager)

                    await userRef.get().then( querySnapshot => {
                        if(!querySnapshot.empty){
                            userData = {...querySnapshot.data()}
                        }
                    })
                }

                if(data.recipient==="operator"){
                    userRef = firestore.collection('users').doc(data.operator)
                    await userRef.get().then( querySnapshot => {
                        if(!querySnapshot.empty){
                            userData = {...querySnapshot.data()}
                        }
                    })
                }
            }

            dispatch({
                type : type,
                payload : {
                    conversation : conversation
                }
            })

            resolve({
                code : code,
                [data.recipient] : {...userData},
                data : conversation
            })

        });
    })
}

export const createConversation = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : CREATE_CONVERSATION
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    const userProfile = getState().firebase.profile;

    return new Promise ( (resolve, reject) => {

        let conversationRef = firestore.collection('conversations').doc();
        let uid = conversationRef.id;

        conversationRef.set({
            uid : uid,
            ...data,
            dateAdded : firestore.Timestamp.now(),
            lastMessageSentAt : firestore.Timestamp.now(),
            lastOpenedDates : {
                [firebase.auth().currentUser.uid] : firestore.Timestamp.now()
            }
        }, { merge: true }).then( (result) => {
           
            let conversation = firestore.collection('conversations').doc(uid)
                            .get().then( querySnapshot => {

                            resolve({
                                code : "conversation/created",
                                message : "Conversation created successfully.",
                                data : querySnapshot.data()
                            })

                            dispatch({
                                type : CREATE_CONVERSATION_SUCCESS,
                                payload : {
                                    conversation : querySnapshot.data()
                                }
                            })

                            firebase.analytics().logEvent('new_message', {
                                created_by : userProfile.uid,
                                creator_type : userProfile.type
                            });
                            
                    })

        }).catch( error => {
            reject({
                code : "conversation/create-failed",
                message : "Conversation creation failed."
            })

            dispatch({
                type : CREATE_CONVERSATION_ERROR
            })
        })

    })


}

export const createConversationMessage = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : CREATE_CONVERSATION_MESSAGE
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    const userProfile = getState().firebase.profile;

    return new Promise ( (resolve, reject) => {

        let newMessageRef = firestore.collection('conversations')
                                        .doc(data.conversation)
                                        .collection('messages').doc()

        let messageData = {
            ...data,
            uid : newMessageRef.id,
            dateAdded : firestore.Timestamp.now()
        }

        newMessageRef.set(messageData).then(() => {

            let conversationRef = firestore.collection('conversations').doc(data.conversation)

            firestore.runTransaction( t => {
    
                return t.get(conversationRef)
                    .then( doc => {
                    
                        let toUpdate ={
                            lastMessageSentText : data.text,
                            lastMessageSentBy : data.sentBy,
                            lastMessageSentAt : firestore.Timestamp.now(),
                            lastMessageSentByUserType : data.senderType
                        }
                        t.update(conversationRef, toUpdate)
    
                    });
        
            }).then( result => {
               
                conversationRef.get().then( querySnapshot => {
                
                    let taskRef = firestore.collection('tasks').doc(querySnapshot.data().task)

                    taskRef.get().then( tQsnapshot => {

                        let newConversation = querySnapshot.data()
                    
                        dispatch({
                            type : CREATE_CONVERSATION_MESSAGE_SUCCESS,
                            payload : {
                                message : messageData,
                                conversation : newConversation
                            }
                        })

                        firebase.analytics().logEvent('new_message', {
                            sent_by : data.sentBy,
                            sent_to : data.recipient,
                            sent_by_type : data.senderType,
                            sent_to_type : data.recipientType,
                            task_id : tQsnapshot.data().uid,
                            task_type : tQsnapshot.data().taskType,
                            task_category : tQsnapshot.data().taskCategory,
                            task_status : tQsnapshot.data().status
                        });

                        resolve({
                            code : "message/sent",
                            message : "Message sent successfully."
                        })
        

                    })
                })

                
            }).catch( error => {
                console.log(error);
                dispatch({
                    type : CREATE_CONVERSATION_MESSAGE_ERROR
                })
            });

           
        }).catch( error => {
            console.log(error)
            reject({
                code : "message/failed",
                message : "Message sending failed."
            })
        })

    })

}

export const loadConversationMessages = (conversation) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();

    const conversationState = getState().conversation;
    const lastRef = conversationState.messagesLastRef;

    let messagesRef = firestore.collection('conversations').doc(conversation.uid).collection('messages')

    if(lastRef!==null){

        dispatch({
            type : GET_CONVERSATION_MESSAGES_PAGINATE
        })

        messagesRef = messagesRef.orderBy('dateAdded','desc')

        messagesRef.startAfter(lastRef).limit(10).get()
            .then( (querySnapShots) => {

                let messageList = [];   
                let messageRefList = [];

                querySnapShots.forEach( doc => {
                    messageList.push(doc.data())
                    messageRefList.push(doc);
                })

                dispatch({
                    type : GET_CONVERSATION_MESSAGES_PAGINATE_SUCCESS,
                    payload : {
                        messages : messageList.reverse(),
                        messagesRef : messageRefList
                    }
                })

            })

    }else{

        dispatch({
            type : GET_CONVERSATION_MESSAGES
        })

        messagesRef.orderBy('dateAdded','desc').limit(10).get()
            .then( (querySnapShots) => {

                let messageList = [];
                let messageRefList = [];

                querySnapShots.forEach( doc => {
                    messageList.push(doc.data())
                    messageRefList.push(doc);
                })

                dispatch({
                    type : GET_CONVERSATION_MESSAGES_SUCCESS,
                    payload : {
                        messages : messageList.reverse(),
                        messagesRef : messageRefList
                    }
                })

            })
    }
}

export const watchConversationMessages = (conversationUid) => (dispatch, getState, { getFirestore, getFirebase}) => {

    const firestore = getFirestore();
    const conversationState = getState().conversation;

    let lastDateSent = conversationState.messageLastSent;
    const unsubscribeWatchMessages = firestore.collection('conversations').doc(conversationUid).collection('messages')
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                
                if(change.type==='added'){
                    if(change.doc.data().dateAdded.seconds>=lastDateSent){
                        dispatch({
                            type : RELOAD_CONVERSATION_MESSAGES,
                            payload : {
                                data : change.doc.data()
                            }
                        })
                    }
                    
                }
            })
        })

    return unsubscribeWatchMessages;

}


export const uploadMessageFiles = (files, conversationUid) => ( dispatch, getState, { getFirebase, getFirestore }) => {
    
    const firebase = getFirebase();

    return new Promise( async (resolve, reject) => {

        let taskFileUrl = [];
        for(let i=0; i<files.length; i++){

            let x = await new Promise ( (resolve) => {
                firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/conversations/${conversationUid}/files/${files[i].name}`).put(files[i])
                .on('state_changed', (snapshot) => {
                },
                (error) =>{
                    console.log(error);
                },
                async () => {
                    firebase.storage().ref(`users/${firebase.auth().currentUser.uid}/conversations/${conversationUid}/files`).child(files[i].name).getDownloadURL().then( url => {
                        resolve({
                            downloadUrl : url,
                            fileName : files[i].name,
                            fileSize : files[i].size/1000000,
                            fileType : files[i].type
                        })
                    })
                
                });
            })
            taskFileUrl.push(x);

        }

      
        resolve(
            taskFileUrl
        )

    })
}

export const clearConversationAndMessages = () => (dispatch) => {
    dispatch({
        type : CLEAR_ACTIVE_CONVERSATION
    })
}

export const getConversations = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const conversationState = getState().conversation;
    const stateConversations = conversationState.conversations;
    
    dispatch({
        type : GET_CONVERSATIONS
    })
    
    let conversationsRef = firestore.collection('conversations')
            .where('members','array-contains',firebase.auth().currentUser.uid)

    conversationsRef.orderBy('lastMessageSentAt','desc').limit(5).get()
        .then( async (querySnapshots) => {

        let conversationList = [];
        let conversationRefList = [];

        for(let index=0; index<querySnapshots.docs.length; index++){
            const taskRef = firestore.collection('tasks').doc(querySnapshots.docs[index].data().task)
            const taskObj =  await taskRef.get().then( (querySnapshot) => {
                return querySnapshot.data()
            })

            if(taskObj!== undefined){
                let convoData = querySnapshots.docs[index].data()
                convoData.taskObject = taskObj
                conversationList.push(convoData)
                conversationRefList.push(querySnapshots.docs[index])
            }
        }

        dispatch({
            type : GET_CONVERSATIONS_SUCCESS,
            payload : {
                conversations : conversationList,
                conversationsRef : conversationRefList
            }
        })

    }).catch( error => {
        console.log(error)
        dispatch({
            type : GET_CONVERSATIONS_ERROR
        })
    })
}

export const watchConversations = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();

    const unsubscribeWatchConversations = firestore.collection('conversations')
        .where('members','array-contains',firebase.auth().currentUser.uid)
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( async (change) => {
                if(change.type==='modified' || change.type==="added"){
                    dispatch({
                        type : RELOAD_CONVERSATIONS,
                        payload : {
                            data : change.doc.data(),
                            dataRef : change.doc
                        }
                    })
                }
            })
        })
                
    return unsubscribeWatchConversations;

}

export const loadMoreConversations = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : LOAD_MORE_CONVERSATIONS
    })
    const firestore = getFirestore();
    const firebase = getFirebase();

    const conversationState = getState().conversation;
    const conversationsRef = conversationState.conversationsRef;
    const lastRef =  conversationsRef.length === 0 ? null : conversationsRef[conversationsRef.length-1];

    if(lastRef!==null){
        let conversationsRef = firestore.collection('conversations')
            .where('members','array-contains',firebase.auth().currentUser.uid)

        conversationsRef = conversationsRef.orderBy('dateAdded','desc')

        conversationsRef.startAfter(lastRef).limit(5).get()
            .then( async (querySnapshots) => {

                let conversationList = [];
                let conversationRefList = [];
               
                for(let index=0; index<querySnapshots.docs.length; index++){
                   
                    const taskRef = firestore.collection('tasks').doc(querySnapshots.docs[index].data().task)
                    const taskObj =  await taskRef.get().then( (querySnapshot) => {
                        return querySnapshot.data()
                    })

                    if(taskObj!==undefined){
                        let convoData = querySnapshots.docs[index].data()
                        convoData.taskObject = taskObj
                        conversationList.push(convoData)
                        conversationRefList.push(querySnapshots.docs[index])
                    }

                }

                dispatch({
                    type : LOAD_MORE_CONVERSATIONS_SUCCESS,
                    payload : {
                        conversations : conversationList,
                        conversationsRef : conversationRefList
                    }
                })

            }).catch( error => {
                console.log(error)
                dispatch({
                    type : LOAD_MORE_CONVERSATIONS_ERROR
                })
            })
    }else{
        dispatch({
            type : LOAD_MORE_CONVERSATIONS_SUCCESS,
            payload : {
                conversations : [],
                conversationsRef : []
            }
        })
    }

}

export const setActiveConversation = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve, reject) => {

        if(data!==null){
            let lastDateSent = data.hasOwnProperty('lastMessageSentAt') ? data.lastMessageSentAt.seconds : moment().unix();

            const firestore = getFirestore();
            const firebase = getFirebase();

            let conversationRef = firestore.collection('conversations').doc(data.uid)

            firestore.runTransaction( t => {

                return t.get(conversationRef)
                    .then( doc => {
                        
                        let key = firebase.auth().currentUser.uid;
                        let opened = doc.data().lastOpenedDates;
                        
                        let newDate = firestore.Timestamp.now();

                        let toUpdate ={
                            lastOpenedDates : {...opened, ...{
                                [key] : newDate
                            }},
                        }

                        t.update(conversationRef, toUpdate)
                        return newDate;
                    });

            }).then( result => {
                                
                conversationRef.get().then( async(querySnapshot) => {
                
                    let taskRef = firestore.collection('tasks').doc(querySnapshot.data().task)

                    taskRef.get().then( tQsnapshot => {

                        let newConversation = querySnapshot.data()
                        newConversation.taskObject = tQsnapshot.data()
                       
                        dispatch({
                            type : SET_ACTIVE_CONVERSATION,
                            payload : {
                                conversation : newConversation,
                                lastDateSent : lastDateSent,
                                lastOpenedDate : result,
                                currentUser : firebase.auth().currentUser.uid
                            }
                        })
    
                        resolve({
                            code : 200,
                            data : newConversation
                        })

                    })
                })
            }).catch( error => {
                console.log(error);
            });
        }else{
            dispatch({
                type : SET_ACTIVE_CONVERSATION,
                payload : {
                    conversation : null,
                    lastDateSent : null,
                    lastOpenedDate : null
                }
            })

            resolve({
                code : 200,
                data : null
            })
        }
    })
}

export const updateConversationLastOpenedDate = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve, reject) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let conversationRef = firestore.collection('conversations').doc(data.uid)

        firestore.runTransaction( t => {

            return t.get(conversationRef)
                .then( doc => {
                    
                    let key = firebase.auth().currentUser.uid;
                    let opened = doc.data().lastOpenedDates;
                    
                    let newDate = firestore.Timestamp.now();

                    let toUpdate ={
                        lastOpenedDates : {...opened, ...{
                            [key] : newDate
                        }},
                    }

                    t.update(conversationRef, toUpdate)
                    return newDate;
                });

        }).then( result => {
        
            conversationRef.get().then( async(querySnapshot) => {
            
                let newConversation = querySnapshot.data()

                dispatch({
                    type : RELOAD_ACTIVE_CONVERSATION,
                    payload : {
                        data : newConversation,
                        currentUser : firebase.auth().currentUser.uid
                    }
                })
            })

        }).catch( error => {
            console.log(error);
        });
    })

}

export const watchConversation = (conversationUid) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();
    const conversationState = getState().conversation;
    const activeConversation = conversationState.activeConversation;
    
    const unsubscribeWatchConversation = firestore.collection('conversations')
        .onSnapshot( querySnapshot => {            
            querySnapshot.docChanges().forEach( change => {
                if(change.type==='modified'){
                    if(activeConversation.uid===change.doc.data().uid){
                        dispatch({
                            type : RELOAD_ACTIVE_CONVERSATION,
                            payload : {
                                data : change.doc.data(),
                                currentUser : firebase.auth().currentUser.uid
                            }
                        })
                    }
                }
            })
        })

    return unsubscribeWatchConversation;

}

export const getAndSetActiveConversation = (conversationUid) => (dispatch, getState, { getFirestore, getFirebase }) => {

    return new Promise ( (resolve, reject) => {

        const firestore = getFirestore();
        const firebase = getFirebase();

        let conversationRef = firestore.collection('conversations').doc(conversationUid)

        firestore.runTransaction( t => {
            return t.get(conversationRef)
                .then( doc => {
                    
                    let key = firebase.auth().currentUser.uid;
                    let opened = doc.data().lastOpenedDates;
                    
                    let newDate = firestore.Timestamp.now();

                    let toUpdate ={
                        lastOpenedDates : {...opened, ...{
                            [key] : newDate
                        }},
                    }

                    t.update(conversationRef, toUpdate)
                    return newDate;

                });

        }).then( result => {
        
            conversationRef.get().then( async(querySnapshot) => {
            
                let newConversation = querySnapshot.data()

                let lastDateSent = querySnapshot.data().hasOwnProperty('lastMessageSentAt') ? querySnapshot.data().lastMessageSentAt.seconds : moment().unix();

                dispatch({
                    type : SET_ACTIVE_CONVERSATION,
                    payload : {
                        conversation : newConversation,
                        lastDateSent : lastDateSent,
                        lastOpenedDate : result
                    }
                })

                resolve({
                    code : 200,
                    data : newConversation
                })
            })

            
        }).catch( error => {
            console.log(error);
        });
    })
}

export const clearActiveConversation = () => (dispatch) => {
    dispatch({
        type : CLEAR_ACTIVE_CONVERSATION
    })
}

export const removeConversation = (conversation) => (dispatch) => {

    dispatch({
        type : REMOVE_ACTIVE_CONVERSATION,
        payload : {
            conversation : conversation
        }
    })
}

export const searchConversations = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : SEARCH_CONTACTS
    })

    dispatch({
        type : SEARCH_MESSAGES
    })

    const firestore = getFirestore();
    const firebase = getFirebase();

    const conversationsIndex = process.env.REACT_APP_ENV==="production " ? client.initIndex("prod_CONVERSATIONS") :  client.initIndex("dev_CONVERSATIONS")
    const messagesIndex = process.env.REACT_APP_ENV==="production " ? client.initIndex("prod_MESSAGES") :  client.initIndex("dev_MESSAGES")
    const contactsIndex = process.env.REACT_APP_ENV==="production " ? client.initIndex("prod_CONTACTS") :  client.initIndex("dev_CONTACTS")

    contactsIndex.search({
        query : data.search,
        facetFilters : [
            `owner:${firebase.auth().currentUser.uid}`
        ]
        // facetFilters : [
        //     `members:${firebase.auth().currentUser.uid}`,
        //     `initiatedBy:-${firebase.auth().currentUser.uid}`
           
        // ]
    }, async (error, res) => {
        if( error ){
            console.log(error)
            dispatch({
                type : SEARCH_CONTACTS_ERROR
            })
            throw(error)
        }

        const { hits } = res;
        let contactHits = hits;

        for(let h = 0; h<contactHits.length; h++){

            // let recipientUid = contactHits[h].members.find(data => data!== contactHits[h].initiatedBy)
            // let recipientName = contactHits[h].memberData[recipientUid].fullName.split(",");

            await firestore.collection('users').doc(contactHits[h].contactUid)
                .get().then( querySnapshot => {
                    contactHits[h].presence = querySnapshot.data().presence;
                    // contactHits[h].recipient = recipientName[1] !== undefined ? `${recipientName[1].trim()} ${recipientName[0]}` : recipientName[0];
                    // contactHits[h].recipientImage = contactHits[h].memberData[recipientUid].imageUrl;
                })
        }

        dispatch({
            type : SEARCH_CONTACTS_SUCCESS,
            payload : {
                searchedContacts : contactHits
            }
        })

    })

    messagesIndex.search({
        query : data.search,
        facetFilters : [
            `members:${firebase.auth().currentUser.uid}`,
        ]
    }, (error, res) => {
        if( error ){
            console.log(error)
            dispatch({
                type : SEARCH_MESSAGES_ERROR
            })
            throw(error)
        }

        dispatch({
            type : SEARCH_MESSAGES_SUCCESS,
            payload : {
                searchedMessages : res.hits
            }
        })
    })
}

/**
 * FOR TESTING PURPOSES
 */

export const createConversationMessageAM = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : CREATE_CONVERSATION_MESSAGE
    })
    
    const firestore = getFirestore();
    const firebase = getFirebase();

    return new Promise ( (resolve, reject) => {

        let newMessageRef = firestore.collection('conversations')
                                        .doc(data.conversation)
                                        .collection('messages').doc()

        let messageData = {
            ...data,
            uid : newMessageRef.id,
            dateAdded : firestore.Timestamp.now()
        }

        newMessageRef.set(messageData).then(() => {

            let conversationRef = firestore.collection('conversations').doc(data.conversation)

            firestore.runTransaction( t => {
    
                return t.get(conversationRef)
                    .then( doc => {
                        
                        let newOpened = {};
                        let key = data.sentBy;
                        newOpened = {
                            [key] : firestore.Timestamp.now()
                        }
                        let opened = doc.data().lastOpenedDates;
                        opened.push(newOpened);

                        let mData = {
                            ...doc.data(),
                            lastOpenedDates : [...opened]
                        }

                        let toUpdate ={
                            lastMessageSentText : data.text,
                            lastMessageSentBy : data.sentBy,
                            lastMessageSentAt : firestore.FieldValue.serverTimestamp(),
                            lastOpenedDates : opened,
                            lastMessageSentByUserType : data.senderType

                        }
                        t.update(conversationRef, toUpdate)
                        return Promise.resolve(mData)
                        
                    });
        
            }).then( result => {

                conversationRef.get().then( querySnapshot => {
                
                    let taskRef = firestore.collection('tasks').doc(querySnapshot.data().task)

                    taskRef.get().then( tQsnapshot => {

                        let newConversation = querySnapshot.data()
                        newConversation.taskObject = tQsnapshot.data()
                       
                        dispatch({
                            type : CREATE_CONVERSATION_MESSAGE_SUCCESS,
                            payload : {
                                message : messageData,
                                conversation : newConversation
                            }
                        })

                        firebase.analytics().logEvent('new_message', {
                            sent_by : data.sentBy,
                            sent_to : data.recipient,
                            sent_by_type : data.senderType,
                            sent_to_type : data.recipientType,
                            task_id : tQsnapshot.data().uid,
                            task_type : tQsnapshot.data().taskType,
                            task_category : tQsnapshot.data().taskCategory,
                            task_status : tQsnapshot.data().status
                        });

                        resolve({
                            code : "message/sent",
                            message : "Message sent successfully."
                        })
                    })
                })              
            }).catch( error => {
                console.log(error);
                dispatch({
                    type : CREATE_CONVERSATION_MESSAGE_ERROR
                })
            });

           
        }).catch( error => {
            console.log(error)
            reject({
                code : "message/failed",
                message : "Message sending failed."
            })
        })

    })

}

export const watchNewMessages = (data) => (dispatch, getState, { getFirestore, getFirebase }) => {

    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = getState().firebase.profile;
    let openedConversationDate = user.hasOwnProperty('lastOpenedConversationDate') ? user.lastOpenedConversationDate : firestore.Timestamp.now()
    
    if(firebase.auth().currentUser!==null){
        const unsubscribeWatchNewMessages = firestore.collectionGroup('messages')
        .where('dateAdded','>=', openedConversationDate)
        .where('recipient','==',firebase.auth().currentUser.uid)
        .onSnapshot( querySnapshot => {
            querySnapshot.docChanges().forEach( change => {
                if(change.type==='added'){
                    dispatch({
                        type : DISPLAY_MESSAGE_BADGE
                    })
                }
            })
        })

        return unsubscribeWatchNewMessages;
    }
                
}

export const resetMessageBadge = () => (dispatch, getState) => {
    dispatch({
        type : RESET_MESSAGE_BADGE
    })
}