import React, { Component } from 'react'
import ReactDOM from 'react-dom';

import Item from './Item';

export class List extends Component {

    componentDidUpdate(prevProps, prevState){
    }

    openEscalateTaskModal = (uid) => {
        this.props.openEscalateTaskModal(uid);
    }

    openTaskDetailsModal = (task) => {
        this.props.openTaskDetailsModal(task);
    }

    render(){

        const { tasks, isLoadingMoreTInprogressTasks, isMobileView, currentLanguage, t } = this.props;
        return (
            
            <div className="" ref="inProgressList">
                {
                    tasks.map( (data, i) => <Item displayDelay={500} t={t} currentLanguage={currentLanguage} isMobileView={isMobileView} openTaskDetailsModal={ () => this.openTaskDetailsModal(data) } openEscalateTaskModal={() => this.openEscalateTaskModal(data)} key={data.uid} task={data}/> )
                }

                <div className="text-center">
                {
                    isLoadingMoreTInprogressTasks ? <div style={{ paddingTop : "15px" }}>
                        <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> : <div className="text-center" style={{ marginTop : "15px" }}>
                        <button onClick={this.props.loadMoreTasks} className="load-more-button">LOAD MORE</button>
                    </div>
                }
                </div>
            </div>
        )

    }

}

export default List;
