import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';

export class MessageModal extends Component {
    
    componentDidMount(){
        const { opened } = this.props;

        if(opened){
            $('#messageModal').modal({backdrop:'static', keyboard:false});
            $('#messageModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#messageModal').modal('show');
        }
    }

    componentWillUnmount(){
        $('#messageModal').modal('hide');
    }

    render(){

        const { opened, header, subHeader, link } = this.props;

        const { t, isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : ""

        return (
            <div className={`${modalDialogClass} signup-modal modal fade`} id="messageModal" tabIndex="-1" role="dialog" aria-labelledby="messageModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeMessageModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="modal-header">
                                <button onClick={this.props.closeMessageModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span style={{ fontWeight: "300", fontSize: "35px" }} aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                       
                        <div className="modal-body">
                            <p className="text-header">{ header }</p>
                            <p className="text-sub-header">{ subHeader }</p>
                            <a onClick={this.props.closeMessageModal} className="btn-link ok">{t('common_ok')}</a>
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }
}

export default MessageModal