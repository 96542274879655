import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import $ from 'jquery'

import {
    getConversations,
    loadMoreConversations,
    clearActiveConversation,
    setActiveConversation,
    removeConversation,
    loadConversationMessages,
    createConversationMessage,
    createConversationMessageAM,
    watchConversationMessages,
    watchConversation,
    uploadMessageFiles,
    searchConversations,
    getAndSetActiveConversation,
    watchConversations,
    updateConversationLastOpenedDate
} from '../../actions/conversationAction'

import { 
    updateTask, 
    setEscalation,
    getTaskDetails,
    checkTaskStatus
} from '../../actions/taskAction'

import {
    setUserPresence,
    updateUserLastOpenedConversationDate
} from '../../actions/authAction'

import ConversationWindow from './Conversation';
import ConversationList from './ConversationList';
import SpinnerModal from "../Spinner/SpinnerModal";
import PauseTaskModal from '../Tasks/Modals/PauseTaskModal';
import StopTaskModal from '../Tasks/Modals/StopTaskModal';
import EscalateTaskModal from '../Tasks/Modals/EscalateTaskModal';

import {
    translate
} from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state = {
            isProcessing : false,
            selectedConversation : null,
            isPauseTaskModalActive : false,
            isStopTaskModalActive : false,
            isEscalateTaskModalActive : false,
            selectedTask : null,
            search : ``,
            isAuthenticated : false
        }
    }

    componentWillUnmount(){
        this.props.clearActiveConversation();
        if(this.props.auth.uid){
            this.props.updateUserLastOpenedConversationDate();
        }
        this.watchConversation && this.watchConversation()
        this.watchConversations && this.watchConversations()
        this.watchConversationMessages && this.watchConversationMessages()
    }

    componentDidMount () {
        const { auth, userProfile } = this.props;

        if(auth.uid){
            this.props.setUserPresence({
                uid : auth.uid
            });
        }

        if(auth.uid){
            this.loadConversations();
            this.props.updateUserLastOpenedConversationDate();
            this.watchConversations = this.props.watchConversations()
        }

        if(userProfile.hasOwnProperty('fullName')){

            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps){
       
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

    }

    loadConversations = async (userType) => {
        await this.props.getConversations();
    }

    loadMoreConversations = () => {
        this.props.loadMoreConversations();
    }

    setActiveConversation = async (conversation) => {
        if(conversation!==null){
            this.setState({
                selectedTask : { uid : conversation.task }
            }, async () =>{
                
                await this.props.setActiveConversation(conversation);
                await this.props.loadConversationMessages(this.props.activeConversation);
                this.watchConversation && this.watchConversation()
                this.watchConversation = this.props.watchConversation(conversation.uid)
                this.watchConversationMessages && this.watchConversationMessages()
                this.watchConversationMessages = this.props.watchConversationMessages(conversation.uid)
            })
        }else{
            await this.props.setActiveConversation(null);
        }
    }

    getAndSetActiveConversation = async (conversationUid) => {
        let result = await this.props.getAndSetActiveConversation(conversationUid);

        this.setState({
            selectedTask : { uid : result.data.task }
        }, async () => {
            await this.props.loadConversationMessages(result.data);
            this.watchConversation && this.watchConversation()
            this.watchConversation = this.props.watchConversation(conversationUid)
            this.watchConversationMessages && this.watchConversationMessages()
            this.watchConversationMessages = this.props.watchConversationMessages(conversationUid)
            
        })

    }

    loadConversationMessages = async () => {
        const { activeConversation } = this.props;

        await this.props.loadConversationMessages(activeConversation);
        this.watchConversationMessages && this.watchConversationMessages()
        this.watchConversationMessages = this.props.watchConversationMessages(activeConversation.uid)
    }

    openPauseTaskModal = async (task) => {
        let result = await this.props.checkTaskStatus(task);
        if(result!==undefined){
            if(result.status!=="completed"){
                this.setState({
                    isPauseTaskModalActive : true,
                    selectedTask : task
                })
            }
        }
    }

    closePauseTaskModal = () => {
        this.setState({
            isPauseTaskModalActive : false,
            selectedTask : null
        })
    }

    pauseTask = async (pauseData) => {
        const { selectedTask } = this.state;
        const { activeConversation } = this.props;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            pauseReason : pauseData.pauseReason,
            dateToContinue : pauseData.dateToContinue,
            status : "paused"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.updateTask(data);

            this.props.removeConversation(activeConversation)
            this.props.clearActiveConversation()
            
            this.setState({
                isPauseTaskModalActive : false,
                isProcessing : false
            })

        })  
    }

    openEscalateTaskModal = (task) => {
        this.setState({
            isEscalateTaskModalActive : true,
            selectedTask : task
        })
    }

    closeEscalateTaskModal = () => {
        this.setState({
            isEscalateTaskModalActive : false,
            selectedTask : null
        })
    }

    escalateTask = async (escalateData) => {
        const { selectedTask } = this.state;
        const { activeConversation } = this.props;

        let data = {
            taskUid : selectedTask.uid,
            escalationLetter : escalateData.escalationLetter,
            escalationReason : escalateData.escalationReason,
            operator : ""
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.setEscalation(data);

            this.setState({
                isEscalateTaskModalActive : false,
                isProcessing : false
            })
        })
    }

    openStopTaskModal = (task) => {
        this.setState({
            isStopTaskModalActive : true,
            selectedTask : task
        })
    }

    closeStopTaskModal = () => {
        this.setState({
            isStopTaskModalActive : false,
            selectedTask : null
        })
    }

    stopTask = async (stopData) => {
        const { selectedTask } = this.state;
        const { activeConversation } = this.props;

        let data = {
            uid : selectedTask.uid,
            isArchived : true,
            stopReason : stopData.stopReason,
            status : "stopped"
        }

        this.setState( {
            isProcessing : true
        }, async () => {
            let result = await this.props.updateTask(data);

            this.props.removeConversation(activeConversation)
            this.props.clearActiveConversation()

            this.setState({
                isStopTaskModalActive : false,
                isProcessing : false
            })
        })
    }

    sendMessage = async (data) => {
        
        const { activeConversation, auth, userProfile } = this.props;
        const { selectedTask } = this.state;

        if(data.message || data.files.length!==0) {

            let recipientType = Object.keys(activeConversation.memberTypes).find( data => data!== userProfile.type)
            let recipientUid = activeConversation.members.find( data => data!== userProfile.uid);
            let recipientName = activeConversation.memberData[recipientUid].fullName;
            let recipientImage = activeConversation.memberData[recipientUid].imageUrl;

            if(data.files.length!==0){

                let result = await this.props.uploadMessageFiles(data.files, activeConversation.uid);

                let messageData = {
                    conversation : activeConversation.uid,
                    text : data.message,
                    attachedFiles : [...result],
                    sentBy : auth.uid,
                    senderType : userProfile.type,
                    senderName : userProfile.fullName,
                    senderImage : userProfile.imageUrl,
                    recipient : recipientUid,
                    recipientType : recipientType,
                    recipientName : recipientName,
                    recipientImage : recipientImage,
                    members : activeConversation.members
                }
                
                this.props.createConversationMessage(messageData)
            }else{
                let messageData = {
                    conversation : activeConversation.uid,
                    text : data.message,
                    attachedFiles : [],
                    sentBy : auth.uid,
                    senderType : userProfile.type,
                    senderName : userProfile.fullName,
                    senderImage : userProfile.imageUrl,
                    recipient : recipientUid,
                    recipientType : recipientType,
                    recipientName : recipientName,
                    recipientImage : recipientImage,
                    members : activeConversation.members
                }
                
                this.props.createConversationMessage(messageData)
            }
        }
    }

    searchConversations = (data) => {
        
        this.setState({
            search : data
        }, () => {

            const { search } = this.state;

            this.props.searchConversations({
                search : search
            })
           
        })

        
    }

    render(){

        const { isMobileView } = this.props;
        const { t, currentLanguage } = this.props;

        const { isLoadingMoreConversations, isSearchingContacts, isSearchingMessages, searchedMessages, searchedContacts,
            isGettingMessages, isGettingConversations, conversations, 
            activeConversation, userProfile, auth, isLoggingOut, lastOpenedDate } = this.props;

        const {
            isAuthenticated,
            isProcessing,
            isPauseTaskModalActive, 
            isStopTaskModalActive, 
            isEscalateTaskModalActive, 
            selectedTask,
        } = this.state;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid){
            return <Redirect to="/"/>
        }

        if(isMobileView){
            return (
                <div className="page">
                    <div className="chat-main">
                        <div style={{ width: "100%" }}>
                        {
                            !isGettingConversations && conversations.length===0 ?
                            <div style={{ textAlign : "center" }}>
                                <p className="messenger-no-chat">{t('messenger_no_active_chat')}</p>
                                <img style={{ width : "80%"}} src={ require('../../images/empty-messages.png')}/>
                            </div>
                            :
                            activeConversation!==null ? 
                                <ConversationWindow 
                                    t={t}
                                    currentLanguage={currentLanguage}
                                    setActiveConversation={this.setActiveConversation} 
                                    isMobileView={isMobileView}
                                    lastOpenedDate={lastOpenedDate}
                                    loadConversationMessages={this.loadConversationMessages}
                                    isGettingMessages={isGettingMessages}
                                    userProfile={userProfile} 
                                    activeConversation={activeConversation}
                                    openPauseTaskModal={this.openPauseTaskModal}
                                    openStopTaskModal={this.openStopTaskModal}
                                    openEscalateTaskModal={this.openEscalateTaskModal}
                                    sendMessage={this.sendMessage}
                                    updateUserLastOpenedConversationDate={this.props.updateUserLastOpenedConversationDate}
                                    updateConversationLastOpenedDate={this.props.updateConversationLastOpenedDate}
                                    />
                                :
                                <ConversationList 
                                    t={t}
                                    currentLanguage={currentLanguage}
                                    isLoadingMoreConversations={isLoadingMoreConversations}
                                    loadMoreConversations={this.loadMoreConversations}
                                    searchConversations={this.searchConversations} 
                                    setActiveConversation={this.setActiveConversation} 
                                    getAndSetActiveConversation={this.getAndSetActiveConversation}
                                    userProfile={userProfile} 
                                    loadConversations={this.loadConversations} 
                                    activeConversation={activeConversation} 
                                    conversations={conversations} 
                                    isGettingConversations={isGettingConversations}
                                    isSearchingMessages={isSearchingMessages}
                                    searchedContacts={searchedContacts}
                                    isSearchingContacts={isSearchingContacts}
                                    searchedMessages={searchedMessages}
                                    />
                        }
                        </div>
                    </div>
                    {
                        isProcessing &&
                        <SpinnerModal 
                            isOpened={isProcessing}
                        />
                    }
                    {
                        isLoggingOut && 
                        <SpinnerModal
                            isOpened={isLoggingOut}
                        />
                    }
                    {
                        isPauseTaskModalActive && <PauseTaskModal pauseTask={this.pauseTask} closePauseTaskModal={this.closePauseTaskModal} isOpened={isPauseTaskModalActive}/>
                    }
                    {
                        isStopTaskModalActive && <StopTaskModal stopTask={this.stopTask} closeStopTaskModal={this.closeStopTaskModal} isOpened={isStopTaskModalActive}/>
                    }
                    {
                        isEscalateTaskModalActive && <EscalateTaskModal userProfile={userProfile} escalateTask={this.escalateTask} closeEscalateTaskModal={this.closeEscalateTaskModal} isOpened={isEscalateTaskModalActive}/>
                    }
                </div>
            )
        }else{

            return (
                <div className="page">
                    {
                        !isGettingConversations && conversations.length===0 ?
                        <div style={{ textAlign : "center", margin: "0 auto",  marginTop : "50px" }}>
                            <p className="messenger-no-chat" style={{ width : "100%" }}>{t('messenger_no_active_chat')}</p>
                            <img style={{ width : "25%", height : "50%" }} src={ require('../../images/empty-messages.png')}/>
                        </div> :
                        <div className="chat-main">
                        <ConversationList 
                            t={t}
                            currentLanguage={currentLanguage}
                            isLoadingMoreConversations={isLoadingMoreConversations}
                            loadMoreConversations={this.loadMoreConversations}
                            searchConversations={this.searchConversations} 
                            setActiveConversation={this.setActiveConversation} 
                            getAndSetActiveConversation={this.getAndSetActiveConversation}
                            userProfile={userProfile} 
                            loadConversations={this.loadConversations} 
                            activeConversation={activeConversation} 
                            conversations={conversations} 
                            isGettingConversations={isGettingConversations}
                            isSearchingMessages={isSearchingMessages}
                            searchedContacts={searchedContacts}
                            isSearchingContacts={isSearchingContacts}
                            searchedMessages={searchedMessages}
                            
                            />
                        <ConversationWindow 
                            t={t}
                            currentLanguage={currentLanguage}
                            lastOpenedDate={lastOpenedDate}
                            loadConversationMessages={this.loadConversationMessages}
                            isGettingMessages={isGettingMessages}
                            userProfile={userProfile} 
                            activeConversation={activeConversation}
                            openPauseTaskModal={this.openPauseTaskModal}
                            openStopTaskModal={this.openStopTaskModal}
                            openEscalateTaskModal={this.openEscalateTaskModal}
                            sendMessage={this.sendMessage}
                            updateUserLastOpenedConversationDate={this.props.updateUserLastOpenedConversationDate}
                            updateConversationLastOpenedDate={this.props.updateConversationLastOpenedDate}
                            />
                            </div>
                    }
                    {
                        isProcessing &&
                        <SpinnerModal 
                            isOpened={isProcessing}
                        />
                    }
                    {
                        isLoggingOut && 
                        <SpinnerModal
                            isOpened={isLoggingOut}
                        />
                    }
                    {
                        isPauseTaskModalActive && <PauseTaskModal pauseTask={this.pauseTask} closePauseTaskModal={this.closePauseTaskModal} isOpened={isPauseTaskModalActive}/>
                    }
                    {
                        isStopTaskModalActive && <StopTaskModal stopTask={this.stopTask} closeStopTaskModal={this.closeStopTaskModal} isOpened={isStopTaskModalActive}/>
                    }
                    {
                        isEscalateTaskModalActive && <EscalateTaskModal userProfile={userProfile} escalateTask={this.escalateTask} closeEscalateTaskModal={this.closeEscalateTaskModal} isOpened={isEscalateTaskModalActive}/>
                    }
                </div>
            )


        }


        
    }

}


const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        activeConversation : state.conversation.activeConversation,
        lastOpenedDate : state.conversation.lastOpenedDate,
        isGettingConversations : state.conversation.isGettingConversations,
        isLoadingMoreConversations : state.conversation.isLoadingMoreConversations,
        conversations : state.conversation.conversations,
        groupedMessages : state.conversation.groupedMessages,
        isSearchingContacts : state.conversation.isSearchingContacts,
        searchedContacts : state.conversation.searchedContacts,
        isSearchingMessages : state.conversation.isSearchingMessages,
        searchedMessages : state.conversation.searchedMessages,
        isLoggingOut : state.auth.isLoggingOut,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}


export default compose(
    withRouter,
    translate,
    connect( mapStateToProps, {
        setUserPresence,
        getConversations,
        loadMoreConversations,
        clearActiveConversation,
        setActiveConversation,
        updateTask,
        setEscalation,
        removeConversation,
        loadConversationMessages,
        createConversationMessage,
        createConversationMessageAM,
        watchConversationMessages,
        watchConversation,
        uploadMessageFiles,
        searchConversations,
        getTaskDetails,
        getAndSetActiveConversation,
        updateUserLastOpenedConversationDate,
        updateConversationLastOpenedDate,
        watchConversations,
        checkTaskStatus
    })
)(Index)