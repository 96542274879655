import React, { Component } from 'react'

const specialtyOpts = [
    { en : "Accounting & Consulting", ar : "اسشارات ومحاسبة" },
    { en : "Admin Support", ar : "دعم إداري" },
    { en : "Customer Service", ar : "خدمة عملاء"},
    { en : "Data Science and Analytics", ar : "علم البيانات والتحليل"},
    { en : "Design & Creative", ar : "ابتكار وتصميم" },
    { en : "Engineering and Architecture", ar : "هندسة وتصميم"},
    { en : "IT & Engineering", ar : "هندسة وتكنولوجيا المعلومات" },
    { en : "Legal", ar : "علوم القانون" },
    { en : "Sales & Marketing", ar : "مبيعات وتسويق" },
    { en : "Translation", ar : "ترجمة" },
    { en : "Web, Mobile, & Software Development", ar : "مول والبرمجيات" },
    { en : "Writing", ar : "الكتابة"},
    { en : "Medical", ar : "المجال الطبي"}
]

const yearsOfExperienceOpts = [
    { en : "Less than 1 year", ar : "أقل من سنة"},
    { en : "1-3 years", ar : "1-3 سنوات" },
    { en : "3-5 years", ar : "3-5 سنوات" },
    { en : "More than 5 years", ar : "أكتر من 5 سنوات"}
]

export class TranslatorPage2 extends Component {

    render () {
        const { values, t, currentLanguage } = this.props;

        return (
            <div>
                <div className="form-group">
                    <label className="align-left custom-label block">{t('signup_label_specialty')}</label>
                    <select defaultValue={values.specialty} onChange={this.props.onChange('specialty')} placeholder={t('common_select_here')} class="custom-select form-control" id="specialty" name="specialty">
                        <option value="">{t('common_select_here')}</option>
                        {
                            specialtyOpts.map( (data, i) => 
                                <option key={i} value={data.en}>{ currentLanguage === "ar" ? data.ar : data.en}</option>
                            )
                        }
                    </select>
                    <span class="block align-left error-input-message"></span>
                </div>

                <div className="form-group">
                    <label className="align-left custom-label block">{t('signup_label_years')}</label>
                    <select defaultValue={values.yearsOfExperience} onChange={this.props.onChange('yearsOfExperience')} placeholder={t('common_select_here')} class="custom-select form-control" id="yearsOfExperience" name="yearsOfExperience">
                        <option value="">{t('common_select_here')}</option>
                        {
                            yearsOfExperienceOpts.map( (data, i) => 
                                <option key={i} value={data.en}>{ currentLanguage === "ar" ? data.ar : data.en}</option>
                            )
                        }
                    </select>                    
                    <span class="block align-left error-input-message"></span>
                </div>
                <div className="form-group">
                    <label className="align-left custom-label block">{t('signup_label_words')}</label>
                    <textarea onChange={this.props.onChange('aboutYourSelf')} defaultValue={values.aboutYourSelf} style={{ resize : "none", width: "100%" }} name="aboutYourSelf" id="aboutYourSelf" rows={3} placeholder={t('common_enter_here')} className="custom-input"></textarea>
                </div>
                
            </div>
        )
    }

}

export default TranslatorPage2;