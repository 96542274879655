import React, { Component } from 'react';
import { compose } from 'redux';
import { Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {
    signUpUser,
    checkForDuplicateEmail,
    updateCurrentUser,
    signOutUser
} from '../../actions/authAction';
import {
    getTermsAndConditions
} from '../../actions/termsAndConditionsAction'
import CustomerPage1 from './Forms/CustomerPage1';
import CustomerPage2 from './Forms/CustomerPage2';
import TranslatorPage1 from './Forms/TranslatorPage1';
import TranslatorPage2 from './Forms/TranslatorPage2';
import TranslatorPage3 from './Forms/TranslatorPage3';
import MessageModal from './Modals/MessageModal';
import TermsAndConditionsModal from './Modals/TermsAndConditionsModal';
import $ from 'jquery';
import _ from 'lodash/'

import SpinnerModal from '../Spinner/SpinnerModal'
import {
    translate
} from 'react-switch-lang'

import moment from 'moment'

Date.prototype.isValid = function () { 
    // If the date object is invalid it 
    // will return 'NaN' on getTime()  
    // and NaN is never equal to itself. 
    return this.getTime() === this.getTime(); 
}; 

export class Signup extends Component {

    constructor(){
        super();
        this.state = {
            isSigningUp : false,
            isSignUpModalActive : false,
            signUpModalHeader :"",
            signUpModalSubHeader :"",
            signUpModalLink : "",
            userType : "customer",
            signUpStep : 1,
            stepOneDone : true,
            stepTwoDone : false,
            stepThreeDone : false,
            customerStep : 1,
            translatorStep : 1,
            fullName : "",
            firstName : "",
            lastName : "",
            emailAddress : "",
            password : "",
            dateOfBirth : null,
            gender : "",
            interests : "",
            imageFile : null,
            resumeFile : null,
            phoneNumber :  "",
            specialty : "",
            status : "",
            aboutYourSelf : "",
            yearsOfExperience : "",
            acceptTerms : false,
            termsAndConditionsModalActive : false,
            activeTab : "customer",
            firstNameValidation : "",
            lastNameValidation : "",
            emailValidation : "",
            emailValidationCode : 0,
            passValidation : "",
            passValidationCode : 0,
            uploadValidation : "",
            termsValidation : "",
            provider : "emailAddress",
            isEmailDisabled : false,
            isPasswordDisabled : false
        }

        this.checkForEmailDuplication = _.debounce(this.checkForEmailDuplication,500);
    }

    componentDidMount(){
        this.props.getTermsAndConditions();

        let activeTab = "customer"
        const { t, currentLanguage, userProfile } = this.props;
       
        this.setState({
            signUpModalHeader : t('signup_message_translator_success_header'),
            signUpModalSubHeader : t('signup_message_translator_success'),
        })

        if(this.props.location.hash.indexOf("translator")!==-1){
            activeTab = "translator"
        }

        if(userProfile.hasOwnProperty('uid')){
            this.setState({
                activeTab : userProfile.type,
                userType : userProfile.type
            })
        }

        if(activeTab!==null){
           
            if(activeTab==="customer"){
                this.customerTab.click();
            }

            if(activeTab==="translator"){
                this.translatorTab.click();
            }
        }
        
        if(userProfile.hasOwnProperty('uid')){
            if(userProfile.status==="pending_signup"){

                let fullName = userProfile.fullName.split(",");
                let firstName = fullName[1] !== undefined ? fullName[1].trim() : "";
                let lastName = fullName[0] !== undefined ? fullName[0].trim() : "";

                this.setState({
                    firstName : firstName,
                    lastName : lastName,
                    emailAddress : userProfile.emailAddress !== "" ? userProfile.emailAddress : "",
                    imageFile : userProfile.imageUrl !== "" ? userProfile.imageUrl : null,
                    provider : userProfile.loginType
                })

                if(userProfile.loginType!=="emailAddress"){
                    this.setState({
                        isEmailDisabled : true,
                        isPasswordDisabled : true
                    })
                }
            }
        }
    }

    componentDidUpdate(prevProps){

        if(this.props.userProfile!==prevProps.userProfile){
            if(this.props.userProfile.hasOwnProperty('uid')){
                
                this.setState({
                    activeTab : this.props.userProfile.type,
                    userType :  this.props.userProfile.type
                })

                if(this.props.userProfile.status==="pending_signup"){

                    let fullName = this.props.userProfile.fullName.split(",");
                    let firstName = fullName[1] !== undefined ? fullName[1].trim() : "";
                    let lastName = fullName[0] !== undefined ? fullName[0].trim() : "";
                 
                    this.setState({
                        firstName : firstName,
                        lastName : lastName,
                        emailAddress : this.props.userProfile.emailAddress !== "" ? this.props.userProfile.emailAddress : "",
                        imageFile : this.props.userProfile.imageUrl !== "" ? this.props.userProfile.imageUrl : null,
                        provider : this.props.userProfile.loginType
                    })

                    if(this.props.userProfile.type==="customer"){
                        this.customerTab.click();
                    }
        
                    if(this.props.userProfile.type==="translator"){
                        this.translatorTab.click();
                    }
                    

                    if(this.props.userProfile.loginType!=="emailAddress"){
                        this.setState({
                            isEmailDisabled : true,
                            isPasswordDisabled : true
                        })
                    }
                }
            }else{
                if(!this.props.auth.uid){
                    this.props.history.push("/")
                }
            }
        }

        if(this.props.currentLanguage!==prevProps.currentLanguage){
            this.props.getTermsAndConditions();

            const { t } = this.props;

            if(this.state.firstNameValidation!==""){
                this.setState({
                    firstNameValidation : t('signup_message_incorrect_name')
                }, () => {
                    this.setState({
                        firstNameValidation : t('signup_message_incorrect_name')
                    })
                })
            }

            if(this.state.lastNameValidation!==""){
                this.setState({
                    lastNameValidation : t('signup_message_incorrect_name')
                }, () => {
                    this.setState({
                        lastNameValidation : t('signup_message_incorrect_name')
                    })
                })
            }

            if(this.state.uploadValidation!==""){
                this.setState({
                    uploadValidation : t('signup_message_upload_resume')
                }, () => {
                    this.setState({
                        uploadValidation : t('signup_message_upload_resume')
                    })
                })
            }

            if(this.state.termsValidation!==""){
                this.setState({
                    termsValidation : t('signup_message_accept_terms')
                }, () => {
                    this.setState({
                        termsValidation : t('signup_message_accept_terms')
                    })
                })
            }

            if(this.state.passValidation!==""){
                switch(this.state.passValidationCode){

                    case 1 :
                        this.setState({
                            passValidation : t('signup_message_password_valid')
                        }, () => {
                            this.setState({
                                passValidation : t('signup_message_password_valid')
                            })
                        })
                        break;

                       
                    case 2 :
                        this.setState({
                            passValidation : t('signup_message_password_too_short')
                        }, () => {
                            this.setState({
                                passValidation : t('signup_message_password_too_short')
                            })
                        })
                        break;
                    case 3 :
                        this.setState({
                            passValidation : t('signup_message_password_format')
                        }, () => {
                            this.setState({
                                passValidation : t('signup_message_password_format')
                            })
                        })
                        break;
                    default:
                        this.setState({
                            passValidation : ""
                        })
                        break;
                }
            }   


            if(this.state.emailValidation!==""){

                switch(this.state.emailValidationCode){
                    case 1 :
                        this.setState({
                            emailValidation : t('signup_message_incorrect_email')
                        }, () => {
                            this.setState({
                                emailValidation : t('signup_message_incorrect_email')
                            })
                        })
                        break;
                    case 2 :
                        this.setState({
                            emailValidation : t('signup_message_used_email')
                        }, () => {
                            this.setState({
                                emailValidation : t('signup_message_used_email')
                            })
                        })
                        break;
                    case 3 :

                        this.setState({
                            emailValidation : t('signup_message_available_email')
                        }, () => {
                            this.setState({
                                emailValidation : t('signup_message_available_email')
                            })
                        })
                        break;
                    default:
                        this.setState({
                            emailValidation : ""
                        })
                        break;
                }
                

            }


        }
    }

    setUserType = (e) => {

        const { userProfile } = this.props;

        let userType;
        userType = userProfile.hasOwnProperty('type') ? userProfile.type : $(e.target).data('usertype');
        let userData = {}
       
        this.setState( {
            userType : userType,
            signUpStep : 1,
            stepTwoDone : false,
            passValidation : "",
            passValidationCode : 0,
            emailValidation : "",
            emailValidationCode : 0,
            firstNameValidation : "",
            lastNameValidation : "",
            termsValidation : "",
            uploadValidation : ""
        });
    }

    inputFocus = (e) => {
        e.target.select();
    }

    onChange = input => async(e) => {
        const { t, currentLanguage, userProfile } = this.props;
        const { userType, provider } = this.state;

        if(e.target.name==="firstName"){

            $('#firstName').removeClass('error-input');
            $('#firstName').removeClass('success-input');

            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+/g;
            
            if(e.target.value.trim().length===0){
                this.setState({
                    firstName : "",
                })
                return
            }

            if(e.target.value.match(regExp) && e.target.value!==""){
                $('#firstName').addClass('error-input');
                // $('#firstName').next('.error-input-message').text(t('signup_message_incorrect_name'));
                this.setState({
                    firstName : "",
                    firstNameValidation : t('signup_message_incorrect_name')
                })
            }else{

                $('#firstName').removeClass('error-input');
                $('#firstName').next('.error-input-message').text('');
                this.setState({
                    firstName : e.target.value,
                    firstNameValidation : ""
                })
            }
        }


        if(e.target.name==="lastName"){

            $('#lastName').removeClass('error-input');
            $('#lastName').removeClass('success-input');

            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+/g;
           
            if(e.target.value.trim().length===0){
                this.setState({
                    lastName : "",
                })
                return
            }

            if(regExp.test(e.target.value) && e.target.value!==""){
                $('#lastName').addClass('error-input');
                // $('#lastName').next('.error-input-message').text(t('signup_message_incorrect_name'));
                this.setState({
                    lastName : "",
                    lastNameValidation : t('signup_message_incorrect_name')
                })
            }else{

                $('#lastName').removeClass('error-input');
                $('#lastName').next('.error-input-message').text('');
                this.setState({
                    lastName : e.target.value,
                    lastNameValidation : ""
                })
            }
        }

        // if(e.target.name==="fullName"){

        //     $('#fullName').removeClass('error-input');
        //     $('#fullName').removeClass('success-input');
        //     $('#fullName').next('.error-input-message').text('');

        //     let regExp = /^[\w]*,\s[\w\s]*$/i;
        //     if(e.target.value.length===0){
        //         return
        //     }

        //     if(!regExp.test(e.target.value) && e.target.value!==""){
        //         $('#fullName').addClass('error-input');
        //         $('#fullName').next('.error-input-message').text( `Name should be on the following format, Last Name, First Name` );
        //         this.setState({
        //             fullName : ""
        //         })
        //     }else{

        //         $('#fullName').removeClass('error-input');
        //         $('#fullName').next('.error-input-message').text('');
        //         this.setState({
        //             fullName : e.target.value
        //         })
        //     }
        // }

       

        if(e.target.name==="emailAddress"){

            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').removeClass('success-input');
            $('#emailAddress').next('span').removeClass('error-input-message');
            $('#emailAddress').next('span').removeClass('success-input-message');
            this.setState({
                emailAddress : "",
                emailValidation : "",
                emailValidationCode : 0
            })
         
            if(provider==="emailAddress" || userProfile.emailAddress==="" || (e.target.value.trim()!==userProfile.emailAddress)){
                let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
                let emailVal = e.target.value;
                
                if(e.target.value.trim().length===0){
                    return
                }
    
                if(!regExp.test(emailVal)){
                    $('#emailAddress').addClass('error-input');
                    $('#emailAddress').next('span').addClass('error-input-message');
                    // $('#emailAddress').next('span').text(t('signup_message_password_format'));
                    // $('#emailAddress').next('.error-input-message').text(t('signup_message_incorrect_email'));
                    this.setState({
                        emailAddress : "",
                        emailValidation : t('signup_message_incorrect_email'),
                        emailValidationCode : 1
                    })
                }else{
    
                    if(emailVal!==""){  
                        this.checkForEmailDuplication(emailVal);                    
                    }else{
                        this.setState({
                            emailAddress : "",
                            emailValidation : "",
                            emailValidationCode : 0
                        })
                    }
                }
            }
        }
        
        if(provider==="emailAddress"){
            if(e.target.name==="password"){
                let regExp = /^[a-zA-Z0-9\u0660-\u0669]+$/i;
                $('#password').removeClass('error-input');
                $('#password').removeClass('success-input');
                $('#password').next('span').removeClass('error-input-message');
                $('#password').next('span').removeClass('success-input-message');
    
                if(e.target.value.length===0){
                    return
                }
    
                if(!regExp.test(e.target.value)){
    
                    $('#password').addClass('error-input');
                    $('#password').next('span').addClass('error-input-message');
                    // $('#password').next('span').text(t('signup_message_password_format'));
                    this.setState({
                        passValidation : t('signup_message_password_format'),
                        passValidationCode : 3
                    })
                }else{
    
                    if(e.target.value.length<6){
                        $('#password').addClass('error-input');
                        $('#password').next('span').addClass('error-input-message');
                        // $('#password').next('span').text(t('signup_message_password_too_short'));
                        this.setState({
                            passValidation : t('signup_message_password_too_short'),
                            passValidationCode : 2
                        })
                    }else{
                        $('#password').addClass('success-input')
                        $('#password').next('span').addClass('success-input-message');
                        // $('#password').next('span').text(t('signup_message_password_valid'));
                        this.setState({
                            passValidation : t('signup_message_password_valid'),
                            passValidationCode : 1
                        })
                    }  
                }
    
                this.setState({
                    password : e.target.value
                })
            }
        }
        

        if(e.target.name==="gender"){
            if(e.target.value===""){
                $('#gender').addClass('error-input');
                this.setState({
                    gender : ""
                })
            }else{
                $('#gender').removeClass('error-input');
                this.setState({
                    gender : e.target.value
                })
            }
        }

        if(e.target.name==="country"){
            if(e.target.value===""){
                $('#country').addClass('error-input');
                this.setState({
                    country : ""
                })
            }else{
                $('#country').removeClass('error-input');
                this.setState({
                    country : e.target.value
                })
            }
        }

        if(e.target.name==="specialty"){
            if(e.target.value===""){
                $('#specialty').addClass('error-input');
                this.setState({
                    specialty : ""
                })
            }else{
                $('#specialty').removeClass('error-input');
                this.setState({
                    specialty : e.target.value
                })
            }
        }

        if(e.target.name==="yearsOfExperience"){
            if(e.target.value===""){
                $('#yearsOfExperience').addClass('error-input');
                this.setState({
                    yearsOfExperience : ""
                })
            }else{
                $('#yearsOfExperience').removeClass('error-input');
                this.setState({
                    yearsOfExperience : e.target.value
                })
            }
        }

        // if(e.target.name==="dateOfBirth"){
        //     this.setState({
        //         dateOfBirth : new Date(e.target.value)
        //     })
        // }

        // if(e.target.name==="interests"){

        //     this.setState({
        //         interests : e.target.value
        //     })
        // }

        if(e.target.name==="aboutYourSelf"){

            var text = e.target.value; // The raw text
           
            var length = text.split(/[^\s]+/).length - 1;

            if(text === null || text.trim()==="" || length>50){
                $('#aboutYourSelf').addClass('error-input');
                this.setState({
                    aboutYourSelf : ""
                })
            }else{
                $('#aboutYourSelf').removeClass('error-input');
                this.setState({
                    aboutYourSelf : e.target.value
                })
            }

           
        }

        if(e.target.name==="terms"){
            if(e.target.checked){
                $('#terms').parent('label').next().text('');
            }

            this.setState({
                acceptTerms : e.target.checked
            })
        }

    }

    checkForEmailDuplication = async (email) => {
        const { t, currentLanguage } = this.props;
        let x = await this.props.checkForDuplicateEmail(email);
        if(x.code==="success"){

            $('#emailAddress').removeClass('error-input');
            $('#emailAddress').next('span').removeClass('error-input-message');
            $('#emailAddress').next('span').addClass('success-input-message');
            $('#emailAddress').next('span').text('');
            $('#emailAddress').addClass('success-input');
            // $('#emailAddress').next().next().text(t('signup_message_available_email'));

            this.setState({
                emailAddress : email,
                emailValidation : t('signup_message_available_email'),
                emailValidationCode : 3
            })
        }else{
            $('#emailAddress').addClass('error-input');
            $('#emailAddress').removeClass('success-input');
            $('#emailAddress').next('span').addClass('error-input-message');
            $('#emailAddress').next('span').removeClass('success-input-message');
            $('#emailAddress').next('span').text('');
            $('#emailAddress').addClass('error-input');
            // $('#emailAddress').next('.error-input-message').text(t('signup_message_used_email'));
            this.setState({
                emailAddress : "",
                emailValidation : t('signup_message_used_email'),
                emailValidationCode : 2
            })
        }
    }

    onUserPhotoFileChange = (file) => {
        this.setState({
            imageFile : file
        })
    }

    onCVFileChange = (file) => {
        if(file!==null){
            $('.btn-upload-cv').next('.error-input-message').text('')
        }
        this.setState({
            resumeFile : file
        })
    }
    
    onDateChange = (data) => {
        
        let regExp = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/;

        let birthDate = null;
        if(regExp.test(data)){

            let dParts = data.split("/");
            birthDate = new Date(`${dParts[2]}-${dParts[1]}-${dParts[0]}`)
        }
    
        this.setState({
            dateOfBirth : birthDate !== null ? (birthDate.isValid() ? birthDate : null) : null
        })
    }

    nextStep = (e) => {

        const { t, currentLanguage, userProfile } = this.props;
        const { userType , signUpStep, provider } = this.state;
        if(signUpStep===1){

            const { firstName, lastName, emailAddress, password, resumeFile } = this.state;
            let errorCount = 0;

            $('#firstName').removeClass('error-input');
            $('#firstName').next('.error-input-message').text("");
            $('#lastName').removeClass('error-input');
            $('#lastName').next('.error-input-message').text("");

            
            if(firstName.trim()==="" || $('#firstName').val().trim()===""){
                $('#firstName').addClass('error-input');
                errorCount++;
            }

            if(lastName.trim()==="" || $('#lastName').val().trim()===""){
                $('#lastName').addClass('error-input');
                errorCount++;
            }

            let regExp = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\u0660-\u0669]+/g;
            
            if(regExp.test(firstName)){
                $('#firstName').addClass('error-input');
                // $('#firstName').next('.error-input-message').text(t('signup_message_incorrect_name'));
                this.setState({
                    firstNameValidation : t('signup_message_incorrect_name')
                })
                errorCount++;
            }

            if(regExp.test(lastName)){
                $('#lastName').addClass('error-input');
                // $('#lastName').next('.error-input-message').text(t('signup_message_incorrect_name'));
                this.setState({
                    lastNameValidation : t('signup_message_incorrect_name')
                })
                errorCount++;
            }

            // $('#fullName').removeClass('error-input');
            // $('#fullName').next('.error-input-message').text("");

            // let uname = fullName;
            // uname = uname.trim();
            // if( uname === "" ){
            //     $('#fullName').addClass('error-input');
            //     errorCount++;
            // }
            
            // uname = uname.replace(" ","");

            // if( uname.length<4 ){
            //     $('#fullName').addClass('error-input');
            //     $('#fullName').next('.error-input-message').text("Please enter your full name");
            //     errorCount++;
            // }

            // let regExp = /^[\w]*,\s[\w\s]*$/i;

            // if(!regExp.test(fullName)){
            //     $('#fullName').addClass('error-input');
            //     $('#fullName').next('.error-input-message').text( `Name should be on the following format, Last Name, First Name` );
            //     errorCount++;
            // }

            $('#emailAddress').removeClass('error-input');
           
            if(provider==="emailAddress" || userProfile.emailAddress==="" || (emailAddress.trim()!==userProfile.emailAddress)){
                if( emailAddress.trim() === "" ){
                    $('#emailAddress').addClass('error-input');
                    errorCount++;
                }
            }

            $('#password').removeClass('error-input');
            $('#password').removeClass('success-input');
            $('#password').next('span').removeClass('success-input-message');

            regExp = /^[a-zA-Z0-9\u0660-\u0669]+$/i;
            $('#password').removeClass('error-input');
            $('#password').removeClass('success-input');
            $('#password').next('span').removeClass('error-input-message');
            $('#password').next('span').removeClass('success-input-message');


            if(provider==="emailAddress"){
                if( password.length<6 ){
                    $('#password').addClass('error-input');
                    $('#password').next('span').addClass('error-input-message');
                    // $('#password').next('span').text(t('signup_message_password_too_short'));
                    this.setState({
                        passValidation : t('signup_message_password_too_short'),
                        passValidationCode : 2
                    })
                    errorCount++;
                }else{
    
                    if(!regExp.test(password)){
                        $('#password').addClass('error-input');
                        $('#password').next('span').addClass('error-input-message');
                        // $('#password').next('span').text(t('signup_message_password_format'));
                        this.setState({
                            passValidation : t('signup_message_password_format'),
                            passValidationCode : 3
                        })
                        errorCount++;
                    }else{
                        $('#password').addClass('success-input')
                        $('#password').next('span').addClass('success-input-message');
                        // $('#password').next('span').text(t('signup_message_password_valid'));
                        this.setState({
                            passValidation : t('signup_message_password_valid'),
                            passValidationCode : 1
                        })
                    }
                }  
            }

            if(userType==="translator"){

                $('.btn-upload-cv').next('.error-input-message').text('');
                if(resumeFile===null){
                    // $('.btn-upload-cv').next('.error-input-message').text(t('signup_message_upload_resume'));
                    this.setState({
                        uploadValidation : t('signup_message_upload_resume')
                    })
                    errorCount++;
                }

            }

            if(errorCount===0){
                this.setState({
                    signUpStep : signUpStep + 1,
                    stepTwoDone : true
                })
            }

        }

        if(signUpStep===2){
            const { specialty, yearsOfExperience, aboutYourSelf } = this.state;
            
            let errorCount = 0;

            $('#specialty').removeClass('error-input');
            if(specialty===""){
                $('#specialty').addClass('error-input');
                errorCount++;
            }

            $('#yearsOfExperience').removeClass('error-input');
            if(yearsOfExperience===""){
                $('#yearsOfExperience').addClass('error-input');
                errorCount++;
            }

            $('#aboutYourSelf').removeClass('error-input');
            if(aboutYourSelf.trim()===""){
                $('#aboutYourSelf').addClass('error-input');
                errorCount++;
            }else{

                var text = e.target.value; // The raw text
           
                var length = text.split(/[^\s]+/).length - 1;
                if(length > 50){
                    $('#aboutYourSelf').addClass('error-input');
                    errorCount++;
                }else{
                    $('#aboutYourSelf').removeClass('error-input');
                }
            }

            if(errorCount===0){
                this.setState({
                    signUpStep : signUpStep + 1,
                    stepThreeDone : true
                })
            }
        }
    }

    prevStep = (e) => {
        const { signUpStep, stepThreeDone, stepTwoDone } = this.state;

        if(signUpStep==3){
            this.setState({
                signUpStep : signUpStep - 1,
                stepThreeDone : false
            })
        }

        if(signUpStep==2){
            this.setState({
                signUpStep : signUpStep - 1,
                stepTwoDone : false
            })
        }
        
    }

    signUpUser = () => {
        
        const { t, currentLanguage } = this.props;

        const { 
            firstName,
            lastName, 
            emailAddress, 
            password, 
            country, 
            gender, 
            interests, imageFile, userType, phoneNumber, specialty, status,
            aboutYourSelf,
            yearsOfExperience , resumeFile, acceptTerms, provider} = this.state;

        let errorCount = 0;
        if(provider==="emailAddress"){
            if(!acceptTerms){
                errorCount++;
                this.setState({
                    termsValidation : t('signup_message_accept_terms')
                })
                // $('#terms').parent('label').next().text(t('signup_message_accept_terms'));
            }else{
                $('#terms').parent('label').next().text('');
                this.setState({
                    termsValidation : ""
                })
            }
        }

        // if(dateOfBirth===null){
        //     $('#dateOfBirth').addClass('error-input');
        //     errorCount++;
        // }else{
        //     $('#dateOfBirth').removeClass('error-input');
        // }

        if($('#gender option:selected').val()===""){
            $('#gender').addClass("error-input")
            errorCount++;
        }else{
            $('#gender').removeClass("error-input")
        }

        if($('#country option:selected').val()===""){
            $('#country').addClass("error-input")
            errorCount++;
        }else{
            $('#country').removeClass("error-input")
        }

        // if($('#interests').val()===""){
        //     $('#interests').addClass("error-input")
        //     errorCount++;
        // }else{
        //     $('#interests').removeClass("error-input")
        // }

        let fullName = `${lastName}, ${firstName}`;
    
        if(errorCount===0){
        
            this.setState({
                isSigningUp : true
            }, () => {
                this.props.signUpUser({
                    fullName,
                    emailAddress,
                    password,
                    country,
                    gender,
                    imageFile,
                    resumeFile,
                    userType,
                    phoneNumber,
                    specialty,
                    status,
                    aboutYourSelf,
                    yearsOfExperience,
                    provider
                }).then((result)=>{
                    const { userType, provider } = this.state;
                    if(result.code==="success"){
                        this.setState({
                            isSigningUp : false,
                        }, () => {
                           
                            if(provider==="emailAddress" || userType==="translator"){
                                this.setState({
                                    isSignUpModalActive : true,
                                    signUpModalHeader : userType === "customer" ? t('signup_message_customer_success_header') : t('signup_message_translator_success_header'),
                                    signUpModalSubHeader : userType === "customer" ? t('signup_message_customer_success') : t('signup_message_translator_success'),
                                    signUpModalLink : "/",
                                })
                            }
                        })
                    }
                }).catch(error=>{
                    this.setState({
                        isSigningUp : false
                    })
                    console.log(error)
                });
            })
        }
    
    }

    closeMessageModal = () => {
        $('#messageModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                isSignUpModalActive : false
            }, async () => {
                await this.props.signOutUser();
                this.props.history.push("/");
            })
        }, 1500)
    }

    setTermsAndConditionsModal =() =>{
        if(this.state.termsAndConditionsModalActive===true){
            $('#termsAndConditionsModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
            setTimeout( () => {
                this.setState({
                    termsAndConditionsModalActive : false
                })
            }, 1500)
        }else{
            this.setState({
                termsAndConditionsModalActive : true
            })
        }
      
    }

    setAcceptTerms = () => {
        $('#termsAndConditionsModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideOutDown animated')
        setTimeout( () => {
            this.setState({
                acceptTerms : true,
                termsAndConditionsModalActive : false
            })
        }, 1500)
    }

    signOutUser = async () => {
        setTimeout( () => {
            if($('.modal-backdrop')[0]!==undefined){
                $('.modal-backdrop')[0].remove();
            }
            if($('.spinner-modal')[0]!==undefined){
                $('.spinner-modal')[0].remove();
            }
        }, 800)
        
        await this.props.signOutUser();
    }

    render () {

        const { isMobileView } = this.props;
        const { auth, userProfile } = this.props;
        const { activeTab } = this.state;

        const { t, currentLanguage } = this.props;
        if(auth.uid && userProfile.hasOwnProperty('uid') && (userProfile.loginType==="facebook" || userProfile.loginType==="google") && userProfile.status!=="pending_signup"){
            if(userProfile.type==="customer"){
                return <Redirect to="/tasks/published"/>
            }

            if(userProfile.type==="translator"){
                if(userProfile.status=="available" || userProfile.status==="notAvailable"){
                    return <Redirect to="/tasks/assigned"/>
                }
            }
        }

        if(auth.uid && auth.emailVerified && userProfile.hasOwnProperty('uid') && userProfile.status!=="pending_signup"){
            if(userProfile.type==="customer"){
                return <Redirect to="/tasks/published"/>
            }

            if(userProfile.type==="translator"){
                if(userProfile.status=="available" || userProfile.status==="notAvailable"){
                    return <Redirect to="/tasks/assigned"/>
                }
            }
        }

        const { isEmailDisabled, isPasswordDisabled, termsAndConditionsModalActive, isSignUpModalActive, signUpModalHeader, signUpModalSubHeader, signUpModalLink, signUpStep, isSigningUp, stepTwoDone, stepThreeDone } = this.state
        const { lastName, firstName, acceptTerms, userType, fullName, emailAddress, password, country, gender, interests, imageFile, yearsOfExperience, specialty, aboutYourSelf, resumeFile } = this.state;
        const values = { isEmailDisabled, isPasswordDisabled, lastName, firstName, acceptTerms, fullName, emailAddress, password, country, gender, interests, imageFile, yearsOfExperience, specialty, aboutYourSelf,resumeFile };
        let customerForm = null;
        let nextButton = null;
        let translatorForm = null;
        const { termsAndConditions } = this.props;

        const {
            passValidation,
            firstNameValidation,
            lastNameValidation,
            termsValidation,
            emailValidation,
            uploadValidation
        } = this.state;

        switch(signUpStep){
            case 3:
                    translatorForm = <TranslatorPage3
                        t={t}
                        inputFocus={this.inputFocus}
                        isMobileView={isMobileView}
                        onChange = {this.onChange}
                        onDateChange ={this.onDateChange}
                        onFileChange ={this.onUserPhotoFileChange}
                        setTermsAndConditionsModal ={this.setTermsAndConditionsModal}
                        termsValidation={termsValidation}
                        values={values}
                    />

                    nextButton = <button disabled={isSigningUp} onClick={this.signUpUser} style={{ width: "100%", marginTop: "28px" }} className="custom-btn">{t('signup_label_apply')}</button>
                    
                break;
            case 2:
                if(userType==="customer"){
                    customerForm = <CustomerPage2
                        t={t}
                        inputFocus={this.inputFocus}
                        isMobileView={isMobileView}
                        onChange={this.onChange}
                        onDateChange ={this.onDateChange}
                        onFileChange ={this.onUserPhotoFileChange}
                        setTermsAndConditionsModal ={this.setTermsAndConditionsModal}
                        termsValidation={termsValidation}
                        values={values}
                    />;

                    nextButton = <button disabled={isSigningUp} onClick={this.signUpUser} style={{ width: "100%", marginTop: "28px" }} className="custom-btn">{t('signup_customer_header')}</button>
                }else{
                    translatorForm = <TranslatorPage2
                        t={t}
                        inputFocus={this.inputFocus}
                        currentLanguage={currentLanguage}
                        isMobileView={isMobileView}
                        onChange = {this.onChange}
                        values={values}
                    />
                    nextButton =  <button onClick={this.nextStep} style={{ width: "100%", marginTop: "28px" }} className="custom-btn">{t('signup_label_more_step')}</button>
                }
                break;
            default:
                if(userType==="customer"){
                    customerForm = <CustomerPage1 
                        t={t}
                        inputFocus={this.inputFocus}
                        isMobileView={isMobileView}
                        onChange = {this.onChange}
                        values={values}
                        firstNameValidation={firstNameValidation}
                        lastNameValidation={lastNameValidation}
                        passValidation={passValidation}
                        emailValidation={emailValidation}
                    />;        
                }else{
                    translatorForm = <TranslatorPage1
                        t={t}
                        inputFocus={this.inputFocus}
                        isMobileView={isMobileView}
                        onChange = {this.onChange}
                        onFileChange ={this.onCVFileChange}
                        values={values}
                        firstNameValidation={firstNameValidation}
                        lastNameValidation={lastNameValidation}
                        passValidation={passValidation}
                        emailValidation={emailValidation}
                        uploadValidation={uploadValidation}
                    />
                }
                nextButton =  <button onClick={this.nextStep} style={{ width: "100%", marginTop: "28px" }} className="custom-btn">{t('common_next')}</button>
        }

        const signupHeader = {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "48px",
            lineHeight: "64px",
            color: "#414042",
            marginBottom : "10px"
        }

        const signupSubHeader = {
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#080708"
        }

        return (
            <div className={`main-page main-page-${userType}`}>
                <div className="row">
                    <div className={`col-md-6 ${currentLanguage==="ar" ? "ml-auto" : "mr-auto"}`}>
                        <p className="align-left" style={signupHeader}>{signUpStep!==1 ? <img onClick={this.prevStep} style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage==="ar" ? "unset" : "15px", marginLeft: currentLanguage==="ar" ? "15px" : "unset", }} src={require('../../images/back.png')}/> : ""}{ userType==="customer" ? t('signup_customer_header') : t('signup_translator_header')}</p>
                        <p className="align-left" style={signupSubHeader}>{userType==="customer" && t('signup_customer_sub_header')}{userType==="translator" && t('signup_translator_sub_header')}</p>
                        <ul style={{ paddingRight : "0" }} className="nav nav-tabs nav-fill nav-header" role="tablist">
                            <li className="nav-item" >
                                <a onClick={this.setUserType} ref={input => this.customerTab = input} data-usertype="customer" className={`nav-link ${activeTab==="customer" ? "active" : ""} ${userProfile.hasOwnProperty('status') && userProfile.status==="pending_signup" && userProfile.type==="translator" ? "disabled" : ""}`} id="customer-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected="true">{t('signup_label_customer')}</a>
                            </li>
                            <li className="nav-item">
                                <a onClick={this.setUserType} ref={input => this.translatorTab = input}  data-usertype="translator" className={`nav-link ${activeTab==="translator" ? "active" : ""} ${userProfile.hasOwnProperty('status') && userProfile.status==="pending_signup" && userProfile.type==="customer" ? "disabled" : ""}`} id="translator-tab" data-toggle="tab" href="#translator" role="tab" aria-controls="translator" aria-selected="false">{t('signup_label_translator')}</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div style={{ paddingTop: "30px", paddingBottom: "30px" }} className={`tab-pane fade show ${activeTab==="customer" ? "active" : ""}`} id="customer" role="tabpanel" aria-labelledby="customer-tab">
                                <div className="tab-content">
                                    { customerForm }
                                    { nextButton }
                                    <div className="steps">
                                        <div className="step done"></div>
                                        <div className={stepTwoDone ? "step done" : "step"}></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingTop: "30px",  paddingBottom: "30px" }} className={`tab-pane fade show ${activeTab==="translator" ? "active" : "" }`} id="translator" role="tabpanel" aria-labelledby="translator-tab">
                                { translatorForm }
                                { nextButton }
                                <div className="steps">
                                    <div className="step done"></div>
                                    <div className={stepTwoDone ? "step done" : "step"}></div>
                                    <div className={stepThreeDone ? "step done" : "step"}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    userProfile.hasOwnProperty('status') ?
                    <div className="row" style={{ textAlign : "center" }}>
                        <div className="col-md-6">
                            <span>{t('common_or')}&nbsp;&nbsp;<a style={{ fontWeight : "bold", color : "#22c9c7", cursor : "pointer" }} className="mt-3 mb-3"  onClick={this.signOutUser}>{t('signup_message_already_have_an_account')}</a></span>
                        </div>
                    </div>
                    :
                    <div className="row" style={{ textAlign : "center" }}>
                        <div className="col-md-6">
                            <span>{t('common_or')+" "+t('signup_message_login_with_diff_account')}<a style={{ fontWeight : "bold", color : "#22c9c7", cursor : "pointer" }} className="mt-3 mb-3"  onClick={() => this.props.history.push("/")}>&nbsp;&nbsp;{t('navbar_login')}</a></span>
                        </div>
                    </div>

                }
                {
                    isSigningUp && <SpinnerModal isOpened={isSigningUp} />
                }
                {
                    isSignUpModalActive && <MessageModal t={t} isMobileView={isMobileView} closeMessageModal={this.closeMessageModal} opened={isSignUpModalActive} header={signUpModalHeader} subHeader={signUpModalSubHeader} link={signUpModalLink}/>
                }
                {
                    termsAndConditionsModalActive && <TermsAndConditionsModal t={t} isMobileView={isMobileView} termsAndConditionsList={termsAndConditions} setTermsAndConditionsModal={this.setTermsAndConditionsModal} setAcceptTerms={this.setAcceptTerms} isOpened={termsAndConditionsModalActive}/>
                }
            </div>
        )
        
    }

}

const mapStateToProps = state => ({
    auth : state.firebase.auth,
    userProfile : state.firebase.profile,
    isEmailAvailable : state.auth.isEmailAvailable,
    isSignUpSuccess : state.auth.isSignUpSuccess,
    termsAndConditions : state.termsAndConditions.termsAndConditionsList,
    isMobileView : state.app.isMobileView,
    currentLanguage : state.app.currentLanguage
})


export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, 
        { 
            signUpUser, 
            checkForDuplicateEmail,
            getTermsAndConditions,
            updateCurrentUser,
            signOutUser
        })
)(Signup)
