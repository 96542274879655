import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    SEND_PASSWORD_RESET,
    SEND_PASSWORD_RESET_SUCCESS,
    SEND_PASSWORD_RESET_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    SIGN_UP_SUCCESS,
    SIGN_UP_ERROR,
    VALIDATE_EMAIL_ERROR,
    VALIDATE_EMAIL_SUCCESS
} from '../actions/types'

import $ from 'jquery'

const initState = {
    isLoggingIn : false,
    isLoggingOut : false,
    isEmailAvailable : true,
    isSignUpSuccess : false,
    authError : null,
    lastOpenedAssignedTasksDate : null
}

const authReducer = ( state = initState , action ) => {
    switch(action.type){
        case VALIDATE_EMAIL_ERROR:
            return {
                ...state,
                isEmailAvailable : false
            }
        case VALIDATE_EMAIL_SUCCESS:
            return {
                ...state,
                isEmailAvailable : true
            }
        case SIGN_UP_SUCCESS:
            return {
                ...state,
                isSignUpSuccess : true
            }
        case SIGN_UP_ERROR:
            return {
                ...state,
                isSignUpSuccess : false
            }
        case LOGIN:
            return {
                ...state,
                authError : null,
                isLoggingIn : true
            }
        case LOGIN_ERROR:
           
            return {
                ...state,
                authError : action.payload.error,
                isLoggingIn : false
            }
        case LOGIN_SUCCESS:
            return {
                authError : null,
                isLoggingIn : false
            }
        case LOGOUT:
            return{
                ...state,
                isLoggingOut : true
            }
        case LOGOUT_ERROR:
            return{
                ...state,
                isLoggingOut : false,
            }
        case LOGOUT_SUCCESS:
            return initState;
        default:
            return state;
    }
}

export default authReducer;