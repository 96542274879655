import React, { Component } from 'react'
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import { createConversationMessage, uploadMessageFiles } from '../../../actions/conversationAction'
import SpinnerModal from '../../Spinner/SpinnerModal'


export class Footer extends Component {

    constructor(props){
        super(props);
        this.state = {
            message : "",
            files : [],
            filesUrl : []
        }
    }

    openFileUpload = (e) => {
        e.preventDefault();
        $('#message_attachment').click();
    }

    sendMessage = async () => {
        const { message, files } = this.state;
        this.props.sendMessage({
            message : message,
            files : files
        })

        this.setState({
            message : "",
            files : []
        })
    }

    onKeyPress = (e) => {
        if (e.key !== 'Enter') return;
        this.sendMessage();
    }

    onFileChange = (e) => {
        if(e.target.files!==null && e.target.files!==undefined){
            
            this.setState({
                files : [...e.target.files]
            })
        }
    }

    removeToFileList = (key) => {
        const { files } = this.state;
        let newFiles = files;

        newFiles.splice(key,1);

        this.setState({
            files : [...newFiles]
        })

    }

    onChange = (e) => {
        this.setState({message: e.target.value});
    }

    render () {
        const { message, files } = this.state;
        const { t } = this.props;

        return (
            <div className="chat-footer">
                <div style={{ display : "flex", marginBottom : "20px" }}>
                    <input onClick={this.props.updateUserOpenedDate} value={message} onChange ={this.onChange} onKeyPress={this.onKeyPress} style={{ width: "100%" }} type="text" name="message" id="message" className="custom-input" placeholder={t('task_modal_details_place_text')}/>
                    <input multiple onChange={this.onFileChange} type="file" name="message_attachment" id="message_attachment" style={{ display : "none" }} />
                    <img src={require('../../../images/icon-message-attach.png')} onClick={this.openFileUpload} style={{ width: "20px", height: "20px", marginTop : "5px", marginLeft : "10px", cursor : "pointer" }} />
                </div>
                <div >
                    <ul className="chat-file-list">
                    {
                        files.length !== 0 && files.map( (data,i) => (
                            <li key={i}>
                                <span className="message-file"><img style={{ width: "10px", height : "10px", marginRight : "10px" }} src={require('../../../images/icon-task-file.png')}/>{data.name}<img onClick={ () => this.removeToFileList(i)} style={{ cursor : "pointer", width: "10px", height : "10px", marginRight : "10px", float : "right" }} src={require('../../../images/chat-file-remove.png')}/></span>
                            </li>
                        ))
                    }
                    </ul>
                </div>
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        conversation : state.conversation.conversation,
        userProfile : state.firebase.profile
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { createConversationMessage, uploadMessageFiles })
)(Footer)
