export const getAdditionalServices = () => (dispatch, getState, { getFirestore, getFirebase }) => {

    dispatch({
        type : "GET_ADDITIONAL_SERVICES"
    })

    return new Promise ( (resolve) => {

        let additionalServices = [];        

        const firestore = getFirestore();
        let appState = getState().app;

        if(appState.currentLanguage=="en"){

            firestore.collection('additionalServices').orderBy('serviceID','asc').get().then( (querySnapshots) => {

                querySnapshots.forEach( (doc) => {
                    additionalServices.push( doc.data() )
                })
    
                resolve({
                    additionalServices
                })
    
                dispatch({
                    type : "GET_ADDITIONAL_SERVICES_SUCCESS",
                    payload : {
                        data : additionalServices
                    }
                })
    
            }).catch( error => {
                dispatch({
                    type : "GET_ADDITIONAL_SERVICES_ERROR",
                    payload : {
                        data : []
                    }
                })
            })

        }else{

            firestore.collection('translations').doc('ar').collection('additionalServices').orderBy('serviceID','asc').get().then( (querySnapshots) => {

                querySnapshots.forEach( (doc) => {
                    additionalServices.push( doc.data() )
                })
    
                resolve({
                    additionalServices
                })
    
                dispatch({
                    type : "GET_ADDITIONAL_SERVICES_SUCCESS",
                    payload : {
                        data : additionalServices
                    }
                })
    
            }).catch( error => {
                dispatch({
                    type : "GET_ADDITIONAL_SERVICES_ERROR",
                    payload : {
                        data : []
                    }
                })
            })

        }

        

    })

}