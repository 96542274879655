import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import $ from 'jquery'
import _ from 'lodash/'
import moment from 'moment'

import { Message } from './Message';

const renderDate = (date) => {

    let nmDateDiff = moment().diff(moment(date, "dddd, MMMM Do YYYY"),'days');

    if(nmDateDiff==0){
        return "Today"
    }else if(nmDateDiff==1){
        return "Yesterday"
    }else{
        return date;
    }

}


export class ConversationBody extends Component {

    constructor(){
        super();
        this.state = {
            scrolling : false
        }
        this.handleScroll = _.debounce( this.handleScroll, 500);
    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps){
        if(prevProps.groupedMessages!==this.props.groupedMessages){
            if(!this.state.scrolling){
                $('.conversation-body')[0].scrollTop = $('.conversation-body')[0].scrollHeight;
                this.setState({
                    scrolling : false
                })
            }
        }
    }

    handleScroll = () => {
        const { messageList } = this.refs;
        if(messageList!==undefined){
            if( ReactDOM.findDOMNode(messageList).scrollTop===0 ){
                const { activeConversation } = this.props;
                if(activeConversation!==null){
                    this.props.loadConversationMessages()
                    this.setState({
                        scrolling : true
                    })
                }
            }
        }
    }

    scrollToBottom = () => {
        const { messageList } = this.refs;
        if(messageList!==undefined){
            const scrollHeight = messageList.scrollHeight;
            const height = messageList.clientHeight;
            const maxScrollTop = scrollHeight - height;
            ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }

    render(){

        const { isGettingMoreMessages, isGettingMessages, groupedMessages, activeConversation, auth, lastOpenedDate } = this.props;
        const { isMobileView } = this.props;
        const { t, currentLanguage } = this.props;

        return (
            <div className="conversation-body" ref="messageList" onScroll={this.handleScroll}>
                {
                    isGettingMessages ?
                    <div class="custom-spinner-border spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    :
                    <div style={{ paddingTop : "5px" }}>
                    {
                        isGettingMoreMessages && <div class="custom-get-more-spinner spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    }
                    {
                        Object.keys(groupedMessages).length!==0 && Object.keys(groupedMessages).reverse().map( ( data, i) => (
                            <div>
                                <div className="messages-header">
                                    <div className="message-header-border"></div>
                                    <p>{renderDate(data)}</p>
                                    <div className="message-header-border"></div>
                                </div>
                                <div className="messages-list" >
                                    {
                                        groupedMessages[data].map( (data, i) => (
                                            <Message 
                                                t={t}
                                                currentLanguage={currentLanguage}
                                                isMobileView={isMobileView}
                                                lastOpenedDate={lastOpenedDate}
                                                auth={auth}
                                                activeConversation={activeConversation}
                                                message={data}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                    </div>
                }
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        activeConversation : state.conversation.activeConversation,
        isGettingMessages : state.conversation.isGettingMessages,
        groupedMessages : state.conversation.groupedMessages,
        isGettingMoreMessages : state.conversation.isGettingMoreMessages
    }
}


export default compose(
    withRouter,
    connect( mapStateToProps)
)(ConversationBody)