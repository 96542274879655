import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAdditionalServices } from '../../../actions/additionalServiceAction'
import $ from 'jquery';

import {
    addModal,
    popModal
} from '../../../actions/appAction';

import {
    translate
} from 'react-switch-lang'

export class AdditionalServicesModal extends Component {

    constructor(){
        super();
        this.state = {
            numberOfTranslators : 1,
            diamondAdded : false,
            expressAdded : false,
            certifiedAdded : false,
            modalZ : 1050
        }
    }

    async componentDidMount(){
        
        await this.props.getAdditionalServices();

        const { isOpened, task, isViewing } = this.props;
        if(isOpened){
            if( task!==undefined && task.hasOwnProperty('additionalServices')){
                task.additionalServices.map( data => {
                    this.setToOrder(data);
                })
                
                if(task.additionalServices.length!==0){
                    this.setState({
                        numberOfTranslators : task.numberOfTranslators
                    })
                }
            }

            $('#additionalServicesModal').modal({backdrop:'static', keyboard:false});
            $('#additionalServicesModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#additionalServicesModal').modal('show');
            this.props.addModal('additional-services-modal')
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("as-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="additional-services-modal");
            if(modalInstance!==undefined){
                $('.as-bd').css('z-index',`${modalInstance.z_index-10}`)
                this.setState({
                    modalZ : modalInstance.z_index
                })
            }
        }
    }

    componentWillUnmount(){
        $('#additionalServicesModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }


    addToOrder = (e, amount) => {
        const { t } = this.props;
        let btnName = e.target.name;

        const { diamondAdded, expressAdded, certifiedAdded } = this.state;
        let addedCheck = require('../../../images/icon-check.png')
 
        if(btnName==="diamond"){
            this.setState({
                diamondAdded : true
            }, () => {
                $(`#${btnName}`).remove();
                $(`#${btnName}-div`).html(`<img src="${addedCheck}"/> ${t('task_modal_added')}`)
                this.props.addToOrder(btnName, amount);
            })
        }

        if(btnName==="express"){
            this.setState({
                expressAdded : true
            }, () => {
                $(`#${btnName}`).remove();
                $(`#${btnName}-div`).html(`<img src="${addedCheck}"/> ${t('task_modal_added')}`)
                this.props.addToOrder(btnName, amount);
            })
        }

        if(btnName==="certified"){
            this.setState({
                certifiedAdded : true
            }, () => {
                $(`#${btnName}`).remove();
                $(`#${btnName}-div`).html(`<img src="${addedCheck}"/> ${t('task_modal_added')}`)
                this.props.addToOrder(btnName, amount);
            })
        }

    }

    setToOrder = (e) => {
        
        let btnName = e;
        const { t } = this.props;

        const { diamondAdded, expressAdded, certifiedAdded } = this.state;
        let addedCheck = require('../../../images/icon-check.png')
 
        if(btnName==="diamond"){
            
            this.setState({
                diamondAdded : true
            }, () => {
                $(`#${btnName}`).remove();
                $(`#${btnName}-div`).html(`<img src="${addedCheck}"/> ${t('task_modal_added')}`)
            })
        }

        if(btnName==="express"){
            this.setState({
                expressAdded : true
            }, () => {
                $(`#${btnName}`).remove();
                $(`#${btnName}-div`).html(`<img src="${addedCheck}"/> ${t('task_modal_added')}`)
            })
        }

        if(btnName==="certified"){
            this.setState({
                certifiedAdded : true
            }, () => {
                $(`#${btnName}`).remove();
                $(`#${btnName}-div`).html(`<img src="${addedCheck}"/> ${t('task_modal_added')}`)
            })
        }

    }

    render(){
        
        const { expressAdded, modalZ } = this.state;
        const { t, currentLanguage } = this.props;

        const {  additionalServices, selectedTask, numberOfTranslators } = this.props;
        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        return (
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} additional-services-modal modal fade`} id="additionalServicesModal" tabIndex="-1" role="dialog" aria-labelledby="additionalServicesModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closeAdditionalServicesModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="modal-header">
                                <button type="button" onClick={this.props.closeAdditionalServicesModal} className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontWeight: "300", fontSize: "70px" }} aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        
                        <div className="modal-body">
                            <p className="text-header">{t('task_modal_additional_services_header')}</p>
                            <ul className="services-list">
                                {
                                    isMobileView ?  
                                    additionalServices.map( (data, i) => {
                                        return <li className="">
                                            <div className="service-item" style={{ flexWrap : "wrap"}}>
                                                <div style={{ width: "100%", marginBottom : "15px" }}>
                                                    <img src={require(`../../../images/icon-${data.code}.png`)}/>
                                                </div>
                                                <div style={{ display : "block" }}>
                                                    <p style={{ textAlign : "center" }} className="service-title">{data.title}</p>
                                                    <p style={{ textAlign : "center", marginLeft : "0" }} className="service-price">{ data.code==="express" ? (numberOfTranslators===0 ? parseFloat(data.amount).toFixed(2) : parseFloat(data.amount * numberOfTranslators).toFixed(2)) : parseFloat(data.amount).toFixed(2) }</p>
                                                    <p style={{ textAlign : "center", width : "100%" }} className="service-text">{data.description}</p>
                                                    {
                                                        (data.code==="express" && expressAdded) && 
                                                        <div >
                                                            <span style={{ textAlign : "center", fontSize : "16px", color : "#414042", marginTop: "5px", marginBottom : "15px", display : "block" }}>{t('task_modal_number_of_translators')}</span>
                                                            <button onClick={ () => this.props.subTranslator(data.amount) } className="service-dec-translator">-</button>
                                                                <span className="service-translator-count">{numberOfTranslators}</span>
                                                            <button onClick={ () => this.props.addTranslator(data.amount) } className="service-inc-translator">+</button>
                                                        </div>

                                                    }
                                                </div>
                                                <div style={{ width : "100%", marginTop : "15px" }}>
                                                    <div style={{ textAlign : "center" }} id={data.code+"-div"} className="service-add-to-order-div">
                                                    {
                                                        data.status==="comingSoon" ? 
                                                        <span style={{ fontSize: "14px", color : "#939097", display : "block", width : "100%" }}>{t('task_modal_coming_soon')}</span> 
                                                        : 
                                                        <button id={data.code} name={data.code} onClick={(e) => this.addToOrder(e, data.amount)} className="service-add-to-order">{t('task_modal_add_to_order')}</button>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    })
                                    :
                                    additionalServices.map( (data, i) => {

                                        return <li className="">
                                            <div className="service-item">
                                                <div style={{ width: "120px" }}>
                                                    <img src={require(`../../../images/icon-${data.code}.png`)}/>
                                                </div>
                                                <div style={{ flex : "2 1" }}>
                                                    <p className="service-title">{data.title}</p>
                                                    <p className="service-text">{data.description}</p>
                                                    {
                                                        (data.code==="express" && expressAdded) && 
                                                        <div style={{ display : "flex" }}>
                                                            <span style={{ fontSize : "16px", color : "#414042", textAlign : "left", marginTop: "5px", marginRight: "30px" }}>{t('task_modal_number_of_translators')}:</span>
                                                            <button onClick={ () => this.props.subTranslator(data.amount) } className="service-dec-translator">-</button>
                                                                <span className="service-translator-count">{numberOfTranslators}</span>
                                                            <button onClick={ () => this.props.addTranslator(data.amount) } className="service-inc-translator">+</button>
                                                        </div>

                                                    }
                                                </div>
                                                <div style={{ flex : "1 1" }}>
                                                    <p className="service-price">{ data.code==="express" ?  (numberOfTranslators===0 ? parseFloat(data.amount).toFixed(2) : parseFloat(data.amount * numberOfTranslators).toFixed(2)) : parseFloat(data.amount).toFixed(2) }</p>

                                                    <div id={data.code+"-div"} className="service-add-to-order-div">
                                                    {
                                                        data.status==="comingSoon" ? 
                                                        <span style={{ fontSize: "14px", color : "#939097", display : "block", width : "100%" }}>{t('task_modal_coming_soon')}</span> 
                                                        : 
                                                        <button id={data.code} name={data.code} onClick={(e) => this.addToOrder(e, data.amount)} className="service-add-to-order">{t('task_modal_add_to_order')}</button>
                                                    }
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </li>

                                    })
                                }
                            </ul>
                        </div>
                    
                    </div>
                </div>
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        additionalServices : state.additionalServices.sList,
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    translate,
    connect(mapStateToProps, { getAdditionalServices, addModal, popModal })
)(AdditionalServicesModal)