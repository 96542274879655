import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import Dropzone from "react-dropzone";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import NextStep from './NextStep';
import exactMath from 'exact-math'

import { 
    uploadTaskFiles,
    getFileWordCount,
    getFilesWordCount,
    removeTaskFile,
    computePriceApproximate,
    createTask,
    updateTask,
    saveTaskCreationState,
    resetTaskCreationState,
    getTaskDetails,
    resetUploadingTaskFiles,
    getBasketCount
} from '../../../../actions/taskAction';

import { 
    updateCustomerTaskCreated
} from '../../../../actions/authAction';

import AdditionalServicesModal from '../../Modals/AdditionalServicesModal';
import SpinnerModal from '../../../Spinner/SpinnerModal'
import Message from '../../../Modals/Message';

import {
    translate
} from 'react-switch-lang'

import $ from 'jquery'

const taskCategories = [
    { en : "Accounting & Consulting", ar : "اسشارات ومحاسبة" },
    { en : "Admin Support", ar : "دعم إداري" },
    { en : "Customer Service", ar : "خدمة عملاء"},
    { en : "Data Science and Analytics", ar : "علم البيانات والتحليل"},
    { en : "Design & Creative", ar : "ابتكار وتصميم" },
    { en : "Engineering and Architecture", ar : "هندسة وتصميم"},
    { en : "IT & Engineering", ar : "هندسة وتكنولوجيا المعلومات" },
    { en : "Legal", ar : "علوم القانون" },
    { en : "Sales & Marketing", ar : "مبيعات وتسويق" },
    { en : "Translation", ar : "ترجمة" },
    { en : "Web, Mobile, & Software Development", ar : "مول والبرمجيات" },
    { en : "Writing", ar : "الكتابة"},
    { en : "Medical", ar : "المجال الطبي"}
]


export class EntryForm extends Component {

    constructor(){
        super();
        this.state = {
            taskType : "",
            customerStudy : "",
            taskCategory : "",
            selectedDate : null,
            excludeDates : [ new Date() ],
            minDate : new Date(),
            selectedTime : null,
            wordCount : 0,
            pageCount : 0,
            deadlineError : false,
            title : "",
            detailedInstructions : "",
            courseName : "",
            taskFiles : [],
            taskFilesUrl : [],
            emptyTaskFiles : [],
            stepOne : false,
            stepOneDone : false,
            stepTwo : false,
            stepTwoDone : false,
            stepThree : false,
            stepThreeDone : false,
            stepFour : false,
            stepFourDone : false,
            isAdditionalServicesModalActive : false,
            additionalServices : [],
            numberOfTranslators : 0,
            price : 0,
            forPayment : false,
            payingWithCard : false,
            payingWithPaypal : false,
            isProcessing : false,
            loadingTextHeader : "",
            selectedTask : null,
            fileDropzoneError : "",
            isAuthenticated : false,
            isMessageModalActive : false,
            messageModalHeader : "",
            messageModalSubHeader : "",
            isDeletingFile : false,
            showStepOne : true,
            showStepTwo : false,
            showStepThree : false
        }
    }

    async componentDidMount(){
        const { userProfile, taskCreationState } = this.props;
        
        if(taskCreationState!==null){
            let result = await this.props.getTaskDetails(taskCreationState.taskUid)

            this.props.history.push({
                pathname : '/task/edit',
                state : {
                    task : result
                }
            })
        }else{

            this.props.resetUploadingTaskFiles();
        }
        
        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    async componentDidUpdate(prevProps) {
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }

        if(prevProps.uploadingTaskFiles!==this.props.uploadingTaskFiles && this.props.uploadingTaskFiles.length!==0){

            let unfinishedUploading = this.props.uploadingTaskFiles.filter( data => data.uploading === true)

            if(unfinishedUploading.length!==0){
            }else{

                let newFilesUrl = [];

                this.props.uploadingTaskFiles.map( data => {
                    newFilesUrl.push(data.url)
                })
    
                this.setState({
                    taskFilesUrl : newFilesUrl
                }, async () => {
    
                    const { taskFilesUrl } = this.state;
                    const { t } = this.props;
    
                    this.setState({
                        isProcessing : true,
                        loadingTextHeader : t('common_checking_count')
                    }, async () => {

                        let uploadResult = await this.props.getFileWordCount(taskFilesUrl);
                        let totalPrice;

                        if(uploadResult.data.code===200){
                            const { parsedFiles, totalWordCount, totalPageCount } = uploadResult.data;
                            this.setState({
                                actualWordCount : totalWordCount,
                                wordCount : totalWordCount,
                                actualPageCount : totalPageCount,
                                pageCount : totalPageCount
                            })
                            
                            let emptyFile = parsedFiles.filter( data => data.wordCount === 0);
                            if(emptyFile.length!==0){
                                this.setState({
                                    emptyTaskFiles : [...emptyFile],
                                    isMessageModalActive : true,
                                    messageModalSubHeader : t('common_empty_files')
                                })
                            }else{
                                
                                // const { customerStudy } = this.state;
                                // this.setState({
                                //     emptyTaskFiles : []
                                // })
                                // const { price } = this.state;

                                // let wordCount = totalWordCount
                                // if(price===0 || price===undefined){
                                //     let priceResult = await this.props.computePriceApproximate(wordCount,customerStudy);
                                //     totalPrice = priceResult.data
                                // }else{
                                //     totalPrice = price
                                // }
                            }

                            this.setState({
                                isProcessing : false,
                                loadingTextHeader : "",
                                price : totalPrice
                            })
                        }else{
                            this.setState({
                                isProcessing : false,
                                loadingTextHeader : ""
                            })
                        }
                        
                    })
                })
            }
        }
    }

    componentWillUnmount(){
        this.props.resetUploadingTaskFiles();
    }

    showStep = (step) => {

        const { showStepOne, showStepTwo, showStepThree } = this.state;

        if(step===2){
            this.setState({
                showStepOne : false,
                showStepTwo : true,
                showStepThree : false
            })
        }else if(step===3){
            this.setState({
                showStepOne : false,
                showStepTwo : false,
                showStepThree : true
            })
        }else{
            this.setState({
                showStepOne : true,
                showStepTwo : false,
                showStepThree : false
            })
        }
    }

    selectTaskType = (task) => {
        const { customerStudy } = this.state;
        const { isMobileView } = this.props;

        if(!isMobileView){
            if(customerStudy.trim()!==""){
                this.setState({
                    stepTwo : true,
                    stepOneDone : true
                })
            }
        }

        this.setState({
           taskType : task
        })
    }

    selectCustomerStudy = async (customerStudy) => {

        const { isMobileView } = this.props;

        const { taskType, wordCount } = this.state;

        if(!isMobileView){
            if(taskType.trim()!==""){
                this.setState({
                    stepTwo : true,
                    stepOneDone : true
                })
            }
        }
        
        this.setState({
            customerStudy : customerStudy
        })
    }

    toStepTwo = () => {
        const { customerStudy, taskType } = this.state;

        if(customerStudy.trim()!=="" && taskType.trim()!==""){
            this.setState({
                stepTwo : true,
                stepOneDone : true
            }, () => {
                $('#step_2_next').trigger('click');
            })
        }
    }

    onDateChange = (date) => {
        
        const { isMobileView } = this.props;

        if(date === undefined ){
            this.setState({
                deadlineError : true
            })
        }else{

            const { selectedTime, deadlineError, wordCount, taskFilesUrl } = this.state;
            const { uploadingTaskFiles, t } = this.props;

            let d = new Date();
            let daysDiff = moment(new Date(date)).diff(d,'days') + 1;

            if(uploadingTaskFiles.length!==0){
                if(wordCount<=500){
                    if(daysDiff<=2){
                        this.setState({
                            deadlineError : true,
                            messageModalSubHeader : t('common_minimum_days', { number : "3" }),
                            isMessageModalActive : true
                        })
                    }else{
                        this.setState({
                            deadlineError : false,
                            messageModalSubHeader : null,
                            isMessageModalActive : false
                        })
                    }
                }else if(wordCount>=501 && wordCount<=1000){
                    let exclDates = [];
        
                    if(daysDiff<=6){
                        this.setState({
                            deadlineError : true,
                            messageModalSubHeader : t('common_minimum_days', { number : "7" }),
                            isMessageModalActive : true,
                            // minDate : new Date().setDate(new Date().getDate() + 7)
                        })
                    }else{
                        this.setState({
                            deadlineError : false,
                            messageModalSubHeader : null,
                            isMessageModalActive : false
                        })
                    }
                }else if(wordCount>=1001 && wordCount<=1500){
                    if(daysDiff<=9){
                        this.setState({
                            deadlineError : true,
                            messageModalSubHeader : t('common_minimum_days', { number : "10" }),
                            isMessageModalActive : true,
                            // minDate : new Date().setDate(new Date().getDate() + 10)
                        })
                    }else{
                        this.setState({
                            deadlineError : false,
                            messageModalSubHeader : null,
                            isMessageModalActive : false
                        })
                    }
                }else if(wordCount>=1501){
                    if(daysDiff<10){
                        this.setState({
                            deadlineError : true,
                            messageModalSubHeader : t('common_minimum_days', { number : "10" }),
                            isMessageModalActive : true,
                            // minDate : new Date().setDate(new Date().getDate() + 10)
                        })
                    }else{
                        this.setState({
                            deadlineError : false,
                            messageModalSubHeader : null,
                            isMessageModalActive : false
                        })
                    }
                }
            }
            

            if(selectedTime!==null){
                this.setState({
                    stepThreeDone : true
                })
            }

            this.setState({
                selectedDate : date
            })

        }
        
    } 

    onTimeChange = (time) => {

        const { isMobileView } = this.props;
        const { selectedDate } = this.state;

        if(selectedDate!==null){
            this.setState({
                stepThreeDone : true
            })
        }
        
        this.setState({
            selectedTime : time
        })
    }

    onInputChange = (e) => {

        const { isMobileView } = this.props;

        this.setState({
            [e.target.name] : e.target.value
        }, () => {
            const { detailedInstructions, courseName, taskCategory, wordCount } = this.state;
            
            if(!isMobileView){
                if(wordCount!=="" && detailedInstructions!=="" && courseName !== "" && taskCategory!== ""){
                    this.setState({
                        stepThree : true,
                        stepTwoDone : true
                    })
                }
            }
        })
    }

    docTypeChange = (e) => {

        const { isMobileView } = this.props;

        this.setState({
           taskCategory : e.target.value
        }, () => {
            const { detailedInstructions, courseName, taskCategory, wordCount } = this.state;
           
            if(!isMobileView){
                if(wordCount!=="" && detailedInstructions!=="" && courseName !== "" && taskCategory!== ""){
                    this.setState({
                        stepThree : true,
                        stepTwoDone : true
                    })
                }
            }
        
        })
    }

    toStepThree = () => {

        const { detailedInstructions, courseName, taskCategory, wordCount } = this.state;
        const { uploadingTaskFiles } = this.props;
           
        if(wordCount!=="" && detailedInstructions!=="" && courseName !== "" && taskCategory!== "" && uploadingTaskFiles.length!==0){
            this.setState({
                stepThree : true,
                stepTwoDone : true
            }, () => {
                $('#step_3_next').trigger('click');
            })
        }

    }

    wordCountFocus = (e) => {
        e.target.select();
    }

    wordCountChange = (e) => {

        const re = /^[0-9\u0660-\u0669\b]+$/;
        const { wordCount, actualWordCount } = this.state;

        let newWordCount = e.target.value;

        // if(newWordCount===""){
        //     newWordCount = actualWordCount;
        // }

        if (newWordCount !== '' || re.test(newWordCount)) {
            if(newWordCount>actualWordCount){
                // newWordCount = actualWordCount;
            }else{

                if(!isNaN(parseInt(newWordCount))){
                    this.setState({
                        wordCount : parseInt(newWordCount)
                    })
                }else{
                    this.setState({
                        wordCount : ""
                    })
                }
                // this.setState({wordCount: isNaN(parseInt(newWordCount)) ? actualWordCount : parseInt(newWordCount)})
            }
        }else{
            this.setState({
                wordCount : ""
            })
        }
    }

    pageCountChange = (e) => {
        const re = /^[0-9\b]+$/;
        const { pageCount, actualPageCount } = this.state;

        let newPageCount = e.target.value;

        if(newPageCount===""){
            newPageCount = actualPageCount;
        }
        if (newPageCount === '' || re.test(newPageCount)) {
            if(newPageCount>actualPageCount){
                newPageCount = actualPageCount;
            }else{
                this.setState({pageCount: parseInt(newPageCount)})
            }
           
        }
    }

    pageCountFocus = (e) => {
        e.target.select();
    }

    onFileRemove = async (key) => {
        
        const { uploadingTaskFiles } = this.props;

        this.setState({
            isProcessing : true,
            isDeletingFile : true,
        }, async () => {

            let eF = this.state.emptyTaskFiles;
            let eIndex = eF.findIndex( e => e.url == uploadingTaskFiles[key].url);
            eF.splice(eIndex, 1)

            this.props.removeTaskFile(uploadingTaskFiles[key].url)
            .then( async (result) => {
                this.setState({
                    isProcessing : false,
                    isDeletingFile : false,
                    emptyTaskFiles  : eF
                })
            }).catch( error => {
                console.log(error)
            })
        })

      
    }

    onDrop = (accepted, rejected) => {

        if(accepted.length>0){
            
            let aFiles = [];
            accepted.map( (data,i) => {

                let fIndex = this.props.uploadingTaskFiles.length!==0 ? this.props.uploadingTaskFiles.findIndex( f => f.up_file === data.name ) : -1;
                if(fIndex===-1){
                    aFiles.push(accepted[i]);
                }
            })

            if(aFiles.length!==0){
                this.setState({
                    taskFiles : [...aFiles]
                }, async () => {
        
                    const { taskFiles, selectedDate, customerStudy } = this.state;
                    this.props.uploadTaskFiles(taskFiles);
        
                })
            }
            
        }else{
            // this.setState({
            //     isMessageModalActive : true,
            //     messageModalSubHeader : "Please upload only .doc or .docx files."
            // })
        }
    }

    completeOrder = async () => {

        const { auth, uploadingTaskFiles } = this.props;

        const { deadlineError,
            emptyTaskFiles,
            taskType,
            customerStudy,
            wordCount,
            pageCount,
            courseName,
            detailedInstructions,
            taskFilesUrl,
            price,
            taskCategory,
            actualWordCount
        } = this.state;

        const { stepOneDone, stepTwoDone, stepThreeDone } = this.state;

        let taskData = {
            assignedTranslator : "",
            isAcceptedByTranslator : false,
            isArchived : false,
            taskType : taskType,
            customer : auth.uid,
            customerStudy : customerStudy,
            numberOfWords : wordCount,
            title : courseName,
            pageCount : pageCount,
            courseName : courseName,
            detailedInstructions : detailedInstructions,
            status : "new",
            isUnpaid : true,
            taskFiles : [...taskFilesUrl],
            resultFiles : [],
            ratings : [],
            couponCode : "",
            additionalServices : [],
            numberOfTranslators : 0,
            isDeleted : false,
            transactions : [],
            taskCategory : taskCategory,
            paymentStatus : "unpaid",
            isPaidToTranslator : false,
            pageCount : pageCount
        }

        if(!deadlineError){
            if(uploadingTaskFiles.length>0){
                if(emptyTaskFiles.length===0){
                    if(stepOneDone && stepTwoDone && stepThreeDone){
                        const { customerStudy, wordCount, price } = this.state;
                        let totalPrice = 0;
                        if(wordCount!=="" && wordCount<=actualWordCount){
                            if(price===0 || price==undefined){
                                this.setState({
                                    isProcessing : true
                                }, async () => {
                                    let priceResult = await this.props.computePriceApproximate(wordCount,customerStudy);
                                    totalPrice = priceResult.data.data
                                    this.setState({
                                        isProcessing : false
                                    }, () => {
    
                                        taskData.price = totalPrice;
                                        taskData.remainingAmount = totalPrice;
                
                                        const { selectedDate, selectedTime } = this.state;
                
                                        let dx = selectedDate.getDate();
                                        let m = selectedDate.getMonth();
                                        let y = selectedDate.getFullYear();
                                        let h = selectedTime.getHours();
                                        let mi = selectedTime.getMinutes();
                                
                                        var dateTime = moment(y + ' ' + (m+1) + ' ' + dx + ' '+ h + ' '+mi, 'YYYY-MM-DD HH:mm');
                                        taskData.deadline = new Date(dateTime);
                
                                        this.setState({
                                            isProcessing : true
                                        }, async () => {
                
                                            let result = await this.props.createTask(taskData);
                
                                            if(result.code==="success"){
                                                this.setState({
                                                    isAdditionalServicesModalActive : true,
                                                    isProcessing : false,
                                                    numberOfTranslators : 1,
                                                    task : result.task,
                                                    price : totalPrice
                                                })
                
                                                this.props.updateCustomerTaskCreated()
                                                this.props.getBasketCount();
                                            }
                                        })
    
                                    })
                                })
                            }else{
                                totalPrice = price
    
                                taskData.price = totalPrice;
                                taskData.remainingAmount = totalPrice;
        
                                const { selectedDate, selectedTime } = this.state;
        
                                let dx = selectedDate.getDate();
                                let m = selectedDate.getMonth();
                                let y = selectedDate.getFullYear();
                                let h = selectedTime.getHours();
                                let mi = selectedTime.getMinutes();
                        
                                var dateTime = moment(y + ' ' + (m+1) + ' ' + dx + ' '+ h + ' '+mi, 'YYYY-MM-DD HH:mm');
                                taskData.deadline = new Date(dateTime);
        
                                this.setState({
                                    isProcessing : true
                                }, async () => {
        
                                    let result = await this.props.createTask(taskData);
        
                                    if(result.code==="success"){
                                        this.setState({
                                            isAdditionalServicesModalActive : true,
                                            isProcessing : false,
                                            numberOfTranslators : 1,
                                            task : result.task,
                                            price : totalPrice
                                        })
        
                                        this.props.updateCustomerTaskCreated()
                                        this.props.getBasketCount();
                                    }
                                })
                            }
                        }
                    }
                }else{
                    const { t } = this.props;
                    this.setState({
                        isMessageModalActive : true,
                        messageModalSubHeader : t('common_empty_files')
                    })
                }
            }else{
                this.setState({
                    isMessageModalActive : true,
                    messageModalSubHeader : "Please upload a file."
                })
            }
        }
       
    }

    addTranslator = (amount) => {
        this.setState({
            numberOfTranslators : this.state.numberOfTranslators + 1
        }, () => {
            const { price, numberOfTranslators } = this.state;
            let newPrice = exactMath.add(price,amount);
            this.setState({
                price : newPrice
            })

        })
    }

    subTranslator = (amount) => {
        if(this.state.numberOfTranslators>1){
            this.setState({
                numberOfTranslators : this.state.numberOfTranslators - 1
            }, () => {
                const { price, numberOfTranslators } = this.state;
                let newPrice = exactMath.sub(price,amount);
                this.setState({
                    price : newPrice
                })
            })
        }
    }

    addToOrder = (service, amount) => {

        const { price } = this.state;

        let newPrice = price;
        newPrice = exactMath.add(newPrice, amount);

        this.setState({
            additionalServices : [...this.state.additionalServices, service],
            price : newPrice
        })

    }

    removeToOrder = (service) => {

        const { price } = this.state;

        let newPrice = price;

        if(service==="diamond"){
            newPrice = exactMath.sub(newPrice,60);
        }

        if(service==="express"){
            const { numberOfTranslators } = this.state;
            newPrice = exactMath.sub(newPrice, exactMath.mul(numberOfTranslators,70));
        }

        if(service==="certified"){

        }

        let newServices = this.state.additionalServices.filter( serv => serv !== service)

        this.setState({
            additionalServices : [...newServices],
            price : newPrice
        })

    }

    closeAdditionalServicesModal = () => {
        this.setState({
            isAdditionalServicesModalActive : false
        }, async () => {
            let newData = {
                additionalServices : this.state.additionalServices,
                price : this.state.price,
                translatorEarnings : exactMath.mul(this.state.price,.40),
                remainingAmount : this.state.price,
                numberOfTranslators : this.state.numberOfTranslators,
                uid : this.state.task.uid
            }
            
            this.setState({
                isProcessing : true
            }, async () => {
                
                let result = await this.props.updateTask(newData);
                
                this.setState({
                    isProcessing : false
                }, () => {
                    this.props.saveTaskCreationState({...this.state, taskUid : result.data.uid})
                    let task = result.data;
                    let tasks = [];
                    tasks.push(task);

                    this.props.history.push({
                        pathname : 'payment',
                        state : {
                            tasks
                        }
                    });
                })
            })
        })
    }

    closeMessageModal = () => {
        this.setState({
            isMessageModalActive : false,
            messageModalSubHeader : "",
            messageModalHeader : ""
        })
    }

    render(){

        const { isMobileView } = this.props;
        const {t , currentLanguage} = this.props;

        const {
            stepOne, stepTwo, stepThree, stepFour,
            stepOneDone, stepTwoDone, stepThreeDone, stepFourDone,
            isAdditionalServicesModalActive, forPayment,
            fileDropzoneError, isDeletingFile
        } = this.state;

        const { taskType, 
            customerStudy,
            selectedDate, 
            courseName,
            detailedInstructions,
            minDate, 
            excludeDates,
            selectedTime, 
            taskFiles,
            numberOfTranslators,
            additionalServices,
            price,
            task,
            isAuthenticated } = this.state;

        const { isLoggingOut, auth, userProfile, uploadingTaskFiles, isUploadingFiles } = this.props;
        const { actualWordCount, actualPageCount, wordCount, pageCount, emptyTaskFiles } = this.state;
        const { loadingTextHeader, isProcessing } = this.state;
        const { isMessageModalActive, messageModalHeader, messageModalSubHeader } = this.state;
       
        let uploadDropStyle = {
            padding: "28px",
            textAlign: "center",
            height: "150px",
            border: " 2px dashed #9AD7D6",
            borderRadius : "8px"
        }

        if(uploadingTaskFiles.length>0){
            uploadDropStyle = {
                padding: "14px 10px",
                textAlign: "left",
                height: "50px",
                border: " 2px dashed #9AD7D6",
                borderRadius : "8px"
            }
        }

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="customer"){
                    return "NOT ALLOWED";
                }
            }
        }

        if(isMobileView){

            return (
                <div className="div-holder" id="create-task-accordion">
                    {
                        (isAuthenticated && isMobileView) ?
                        <div style={{ position : "absolute", top : "0" }}>
                            <nav className="navbar navbar-expand-lg fixed-top">
                                <span className="page-mobile-header"><img onClick={ () => this.props.history.goBack() } style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === 'ar' ? "unset" : "15px", marginLeft: currentLanguage === 'ar' ? "15px" : "unset", transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset"  }} src={require('../../../../images/back.png')}/>{t('common_create_task')}</span>
                            </nav>
                        </div>:
                        ""
                    }
                    {
                        isAuthenticated &&
                        <div className="div-holder">
                                {
                                    !isMobileView &&  <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <p className="module-header align-left">{t('common_create_task')}</p>
                                        </div>
                                    </div>
                                }
                                <div>
                                    <div style={{ position : "relative", paddingBottom : "20px", borderBottom : "1px solid #DFDFE0" }}>
                                        <span className="create-task-step-header block align-left">{t('common_step_one')}&nbsp;{t('common_step_one_text')}</span>
                                        <div style={{ position : "absolute", right : currentLanguage==="ar" ? "unset" : "0", left : currentLanguage === "ar" ? "0" : "unset", top : "0" }}>
                                            <a id="step_1_next" href="#step_1" data-toggle="collapse"><img src={ require('../../../../images/icon-collapse.png')} style={{ width : "15px", height : "10px "}} /></a>
                                        </div>
                                    </div>
                                    {/* STEP 1 */}
                                    <div id="step_1" data-parent="#create-task-accordion" className="collapse show" style={{ paddingBottom:"20px", borderBottom : "1px solid #DFDFE0"}}>
                                        <div className="step-1-divs">
                                            <p className="align-left" style={{ fontSize : "16px", fontStyle : "bold", color : "#293846" }}>{t('common_do_for_you')}</p>
                                            <span onClick={()=>this.selectTaskType('essay')} className={` ${taskType==="essay" ? "step-1-selected" : ""} step-1-option `}>{t('common_essay')}</span>
                                            <span onClick={()=>this.selectTaskType('presentation')} className={`  ${taskType==="presentation" ? "step-1-selected" : ""} step-1-option`}>{t('common_presentation')}</span><br/><br/>
                                            <span onClick={()=>this.selectTaskType('dissertation')} className={`  ${taskType==="dissertation" ? "step-1-selected" : ""} step-1-option`}>{t('common_dissertation')}</span>
                                        </div>
                                        <div className="step-1-divs">
                                            <p className="align-left" style={{ fontSize : "16px", fontStyle : "bold", color : "#293846" }}>{t('common_customer_study')}</p>
                                            <span onClick={()=>this.selectCustomerStudy('highSchool')} className={` ${customerStudy==="highSchool" ? "step-1-selected" : ""} step-1-option`}>{t('common_high_school')}</span>
                                            <span onClick={()=>this.selectCustomerStudy('university')} className={` ${customerStudy==="university" ? "step-1-selected" : ""} step-1-option`}>{t('common_university')}</span><br/><br/>
                                            <span onClick={()=>this.selectCustomerStudy('postGrad')} className={` ${customerStudy==="postGrad" ? "step-1-selected" : ""} step-1-option`}>{t('common_post_grad')}</span>
                                            <span onClick={()=>this.selectCustomerStudy('professional')} className={` ${customerStudy==="professional" ? "step-1-selected" : ""} step-1-option`}>{t('common_professional')}</span>
                                        </div>
                                        <NextStep t={t} proceedToNext={this.toStepTwo}/>
                                    </div>
                                    
                                    {/* STEP 2 */}
                                    <div style={{ position : "relative", paddingTop : "20px", paddingBottom : "20px", borderBottom : "1px solid #DFDFE0"  }}>
                                        <span className={` ${stepTwo===true ? "active" : "inactive"} create-task-step-header block align-left`}>{t('common_step_two')}&nbsp; {t('common_step_two_text')}</span>
                                        <div style={{ position : "absolute", right : currentLanguage==="ar" ? "unset" : "0", left : currentLanguage === "ar" ? "0" : "unset", top : "20px" }}>
                                            <a id="step_2_next" href="#step_2" data-toggle="collapse"><img src={ require('../../../../images/icon-collapse.png')} style={{ width : "15px", height : "10px "}} /></a>
                                        </div>
                                    </div>
                                    <div data-parent="#create-task-accordion" id="step_2" className="collapse" style={ stepTwo ? { paddingBottom:"20px" } : { paddingBottom:"20px" }}>
                                        <div>
                                            {
                                                stepTwo && 
                                                <div className="form-group">
                                                    <label className="custom-create-task-label block align-left">{t('common_select_doc_type')}</label>
                                                    <select onChange={this.docTypeChange} placeholder={t('common_select_task_type')} className="custom-select form-control" id="docType" name="docType">
                                                        <option value="">{t('common_select_task_type')}</option>
                                                        {
                                                            taskCategories.map( (data, i) => (
                                                                <option value={data.en} key={i}>{ currentLanguage === "ar" ? data.ar : data.en }</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            {
                                                stepTwo &&
                                                <div className="form-group">
                                                    <label className="custom-create-task-label block align-left">{t('common_course_name')}</label>
                                                    <input defaultValue={courseName} onChange={this.onInputChange} id="courseName" name="courseName" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_course')}/>
                                                </div>
                                            }
                                        </div>
                                        <div style={{ paddingBottom:"20px"}}>
                                            <div>
                                                {
                                                    (stepTwo && uploadingTaskFiles.length>0) && 
                                                    <ul className="task-file-list">
                                                        { uploadingTaskFiles.map( (data,i) => (
                                                            <li key={i}>
                                                                <div style={{ display : "flex" }}>
                                                                    <img className="task-file-item-icon" src={require('../../../../images/icon-task-file.png')}/>{t('common_task_file',{ number : (i + 1)})}
                                                                    <div style={{ marginTop : "5px", marginLeft : "10px", marginRight : "10px", flex : "1" }} className="progress custom-progress">
                                                                        <div className={ (emptyTaskFiles && emptyTaskFiles.findIndex( d => d.url===data.url ) !== -1) ? `custom-progress-bar-danger` : `custom-progress-bar-safe`} style={{ width : data.upload_progress +"%" }} aria-valuenow={data.upload_progress} aria-valuemin={0} aria-valuemax={100}>
                                                                        </div>
                                                                    </div>
                                                                    { data.upload_progress===100 &&<img disabled={isDeletingFile} onClick={ () => this.onFileRemove(i)} className="task-file-item-remove" src={require('../../../../images/icon-remove-task-file.png')}/> }
                                                                </div>
                                                                
                                                            </li>
                                                        ))}
                                                    </ul>
                                                }
                                                {
                                                    stepTwo && 
                                                    <div>
                                                        <Dropzone 
                                                            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*"
                                                            onDrop={this.onDrop}>
                                                            {({getRootProps, getInputProps}) => (
                                                            <div style={uploadDropStyle}
                                                            {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {
                                                                    uploadingTaskFiles.length===0 ? <div><p style={{ fontSize: "16px", color : "#414042" }}>{t('common_drag_files')}</p>
                                                                    <button className="create-task-upload-btn">{t('common_upload_files')}</button></div> : <p className="align-left" style={{ cursor: "pointer", fontSize: "12px", color : "#464646"}}>{t('common_upload_more_files')}</p>
                                                                }
                                                            </div>
                                                            )}
                                                        </Dropzone>
                                                        <span className="error-input-message">{fileDropzoneError}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            uploadingTaskFiles.length!==0 && <div className="row" style={ stepTwo ? { paddingBottom:"20px" } : { paddingBottom:"20px", borderBottom : "1px solid #DFDFE0" }}>
                                                <div className="col">
                                                    {
                                                        stepTwo &&
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_total_files_wc')}</label>
                                                            <input value={actualWordCount} disabled style={{ width: "100%" }} className="custom-input" type="text" placeholder=""/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            uploadingTaskFiles.length!==0 && <div className="row" style={ stepTwo ? { paddingBottom:"20px" } : { paddingBottom:"20px", borderBottom : "1px solid #DFDFE0" }}>
                                                <div className="col">
                                                    {
                                                        stepTwo &&
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_words_to_translate')}</label>
                                                            <input value={wordCount} onFocus={this.wordCountFocus} onChange={this.wordCountChange} id="wordCount" name="wordCount" style={{ width: "100%" }} className="custom-input" type="text" placeholder=""/>
                                                        </div>
                                                    }
                                                </div>
                                                {/* <div className="col">
                                                    {
                                                        stepTwo &&
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_pages_to_translate')}</label>
                                                            <input value={pageCount} onFocus={this.pageCountFocus} onChange={this.pageCountChange} id="pageCount" name="pageCount" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Number of pages"/>
                                                        </div>
                                                    }
                                                </div> */}
                                            </div>
                                        }
                                        {
                                            stepTwo && 
                                            <div style={{ paddingBottom:"20px", borderBottom : "1px solid #DFDFE0"  }}>
                                                <div>
                                                    {
                                                        stepTwo && 
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_detailed_instruction')}</label>
                                                            <textarea defaultValue={detailedInstructions} onChange={this.onInputChange} style={{ resize : "none", width: "100%" }} name="detailedInstructions" id="detailedInstructions" rows={3} placeholder={t('common_enter_detailed_instruction')} className="custom-input"></textarea>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        { stepOneDone && <NextStep t={t} proceedToNext={this.toStepThree}/> }
                                    </div>
                            
                                    
                                    <div style={{ position : "relative", paddingTop : "20px" }}>
                                        <span className={` ${stepThree===true ? "active" : "inactive"} create-task-step-header block align-left`}>{t('common_step_three')}&nbsp; {t('common_step_three_text')}</span>
                                        <div style={{ position : "absolute", right : currentLanguage==="ar" ? "unset" : "0", left : currentLanguage === "ar" ? "0" : "unset", top : "20px" }}>
                                            <a href="#step_3" id="step_3_next" data-toggle="collapse"><img src={ require('../../../../images/icon-collapse.png')} style={{ width : "15px", height : "10px "}} /></a>
                                        </div>
                                    </div>
                                    <div data-parent="#create-task-accordion" id="step_3" className="row collapse" style={ messageModalSubHeader === "" ? { paddingBottom:"20px" } : {} }>
                                        <div className="col-sm-12">
                                            {
                                                stepThree && 
                                                <div className="form-group">
                                                    <label className="custom-create-task-label block align-left">{t('common_date')}</label>
                                                    <DatePicker minDate={minDate} className={`date-picker`} style={{ width: "100%" }} showYearDropdown showMonthDropdown name="deadlineDate" id="deadlineDate" onChange={this.onDateChange} selected={selectedDate} placeholderText="_ _/_ _/_ _ _ _" />
                                                </div>
                                            }
                                        </div>
                                        <div className="col-sm-12">
                                            {
                                                stepThree &&
                                                <div className="form-group">
                                                    <label className="custom-create-task-label block align-left">{t('common_time')}</label>
                                                    <DatePicker className="time-picker" dateFormat="H:mm" style={{ width: "100%" }} timeIntervals={60} showTimeSelect showTimeSelectOnly name="deadlineTime" id="deadlineTime" onChange={this.onTimeChange} selected={selectedTime} placeholderText="_ _:_ _" />
                                                </div>
                                            }
                                            { stepThree && <button onClick={this.completeOrder} className="btn-complete-order">{t('common_complete_order')}</button> }
                                        </div>
                                        
                                    </div>
                                </div>
                                {
                                    isAdditionalServicesModalActive && 
                                    <AdditionalServicesModal 
                                        isOpened={isAdditionalServicesModalActive} 
                                        addTranslator={this.addTranslator}
                                        subTranslator={this.subTranslator}
                                        numberOfTranslators={numberOfTranslators}
                                        addToOrder={this.addToOrder}
                                        removeToOrder={this.removeToOrder}
                                        closeAdditionalServicesModal={this.closeAdditionalServicesModal}
                                        task={task}
                                        currentState={this.state}
                                        isViewing={false}
                                    />
                                }
                                {
                                    isProcessing && 
                                    <SpinnerModal
                                        isOpened={isProcessing}
                                        loadingTextHeader={loadingTextHeader}
                                    />
                                }
                                {
                                    isLoggingOut && 
                                    <SpinnerModal
                                        isOpened={isLoggingOut}
                                    />
                                }
                                {
                                    isMessageModalActive && 
                                    <Message 
                                        t={t}
                                        closeMessageModal={this.closeMessageModal} 
                                        isOpened={isMessageModalActive} 
                                        header={messageModalHeader} 
                                        subHeader={messageModalSubHeader}
                                        addModal={this.props.addModal}
                                        popModal={this.props.popModal}
                                        modalInstances={this.props.modalInstances}
                                    />
                                }
                        </div>
                    }
                </div>
            )

        }else{
            return (
                <div className="div-holder">
                    {
                        (isAuthenticated && isMobileView) ?
                        <div style={{ position : "absolute", top : "0" }}>
                            <nav className="navbar navbar-expand-lg fixed-top">
                                <span className="page-mobile-header"><img onClick={ () => this.props.history.goBack() } style={{ cursor: "pointer", width: "15px", height : "20px", marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../../../images/back.png')}/>{t('common_create_task')}</span>
                            </nav>
                        </div>:
                        ""
                    }
                    {
                        isAuthenticated &&
                        <div className="div-holder">
                                {
                                    !isMobileView &&  <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <p className="module-header align-left">{t('common_create_task')}</p>
                                        </div>
                                    </div>
                                }
    
                                <div className="row">
    
                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
    
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2"><span className="create-task-step-header block align-left">{t('common_step_one')}</span></div>
                                            <div className="col-lg-10 col-md-10"><span className="create-task-step-header block align-left">{t('common_step_one_text')}</span></div>
                                        </div>
                                        {/* STEP 1 */}
                                        <div className="row" style={{ paddingBottom:"20px", borderBottom : "1px solid #DFDFE0"}}>
                                            <div className="col-lg-2 col-md-2"></div>
                                            <div className="col">
                                                <div className="step-1-divs">
                                                    <p className="align-left" style={{ fontSize : "16px", fontStyle : "bold", color : "#293846" }}>{t('common_do_for_you')}</p>
                                                    <span onClick={()=>this.selectTaskType('essay')} className={` ${taskType==="essay" ? "step-1-selected" : ""} step-1-option `}>{t('common_essay')}</span>
                                                    <span onClick={()=>this.selectTaskType('presentation')} className={`  ${taskType==="presentation" ? "step-1-selected" : ""} step-1-option`}>{t('common_presentation')}</span><br/><br/>
                                                    <span onClick={()=>this.selectTaskType('dissertation')} className={`  ${taskType==="dissertation" ? "step-1-selected" : ""} step-1-option`}>{t('common_dissertation')}</span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="step-1-divs">
                                                    <p className="align-left" style={{ fontSize : "16px", fontStyle : "bold", color : "#293846" }}>{t('common_customer_study')}</p>
                                                    <span onClick={()=>this.selectCustomerStudy('highSchool')} className={` ${customerStudy==="highSchool" ? "step-1-selected" : ""} step-1-option`}>{t('common_high_school')}</span>
                                                    <span onClick={()=>this.selectCustomerStudy('university')} className={` ${customerStudy==="university" ? "step-1-selected" : ""} step-1-option`}>{t('common_university')}</span><br/><br/>
                                                    <span onClick={()=>this.selectCustomerStudy('postGrad')} className={` ${customerStudy==="postGrad" ? "step-1-selected" : ""} step-1-option`}>{t('common_post_grad')}</span>
                                                    <span onClick={()=>this.selectCustomerStudy('professional')} className={` ${customerStudy==="professional" ? "step-1-selected" : ""} step-1-option`}>{t('common_professional')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* STEP 2 */}
                                        <div className="row" style={{ paddingTop : "20px", paddingBottom : "20px", borderBottom : "1px solid #DFDFE0"  }}>
                                            <div className="col-lg-2 col-md-2"><span className={` ${stepTwo===true ? "active" : "inactive"} create-task-step-header block align-left`}>{t('common_step_two')}</span></div>
                                            <div className="col-lg-10 col-md-10"><span className={` ${stepTwo===true ? "active" : "inactive"} create-task-step-header block align-left`}>{t('common_step_two_text')}</span></div>
                                        </div>
                                        
                                        <div className="row" style={ stepTwo ? { paddingBottom:"20px" } : {}}>
                                            <div className="col-lg-2 col-md-2"></div>
                                            <div className="col">
                                                {
                                                    stepTwo && 
                                                    <div className="form-group">
                                                        <label className="custom-create-task-label block align-left">{t('common_select_doc_type')}</label>
                                                        <select onChange={this.docTypeChange} placeholder={t('common_select_task_type')} className="custom-select form-control" id="docType" name="docType">
                                                            <option value="">{t('common_select_task_type')}</option>
                                                            {
                                                                taskCategories.map( (data, i) => (
                                                                    <option value={data.en} key={i}>{ currentLanguage === "ar" ? data.ar : data.en }</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col">
                                                {
                                                    stepTwo &&
                                                    <div className="form-group">
                                                        <label className="custom-create-task-label block align-left">{t('common_course_name')}</label>
                                                        <input defaultValue={courseName} onChange={this.onInputChange} id="courseName" name="courseName" style={{ width: "100%" }} className="custom-input" type="text" placeholder={t('common_enter_course')}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                
                                        <div className="row" style={{ paddingBottom:"20px"}}>
                                            <div className="col-lg-2 col-md-2"></div>
                                            <div className="col">
                                                {
                                                    (stepTwo && uploadingTaskFiles.length>0) && 
                                                    <ul className="task-file-list">
                                                        { uploadingTaskFiles.map( (data,i) => (
                                                            <li key={i}>
                                                                <div style={{ display : "flex" }}>
                                                                    <img className="task-file-item-icon" src={require('../../../../images/icon-task-file.png')}/>{t('common_task_file', { number : (i+1)})}
                                                                    <div style={{ marginTop : "5px", marginLeft : "10px", marginRight : "10px", flex : "1" }} className="progress custom-progress">
                                                                        <div className={ (emptyTaskFiles && emptyTaskFiles.findIndex( d => d.url===data.url ) !== -1) ? `custom-progress-bar-danger` : `custom-progress-bar-safe`} style={{ width : data.upload_progress +"%" }} aria-valuenow={data.upload_progress} aria-valuemin={0} aria-valuemax={100}>
                                                                        </div>
                                                                    </div>
                                                                    { data.upload_progress===100 &&<img disabled={isDeletingFile} onClick={ () => this.onFileRemove(i)} className="task-file-item-remove" src={require('../../../../images/icon-remove-task-file.png')}/> }
                                                                </div>
                                                                
                                                            </li>
                                                        ))}
                                                    </ul>
                                                }
                                                {
                                                    stepTwo && 
                                                    <div>
                                                        <Dropzone 
                                                            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*"
                                                            onDrop={this.onDrop}>
                                                            {({getRootProps, getInputProps}) => (
                                                            <div style={uploadDropStyle}
                                                            {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                {
                                                                    uploadingTaskFiles.length===0 ? <div><p style={{ fontSize: "16px", color : "#414042" }}>{t('common_drag_files')}</p>
                                                                    <button className="create-task-upload-btn">{t('common_upload_files')}</button></div> : <p className="align-left" style={{ cursor: "pointer", fontSize: "12px", color : "#464646"}}>{t('common_upload_more_files')}</p>
                                                                }
                                                            </div>
                                                            )}
                                                        </Dropzone>
                                                        <span className="align-left block error-input-message">{fileDropzoneError}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            uploadingTaskFiles.length!==0 && <div className="row" style={ stepTwo ? { paddingBottom:"20px" } : { paddingBottom:"20px", borderBottom : "1px solid #DFDFE0" }}>
                                                <div className="col-lg-2 col-md-2"></div>
                                                <div className="col">
                                                    {
                                                        stepTwo &&
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_total_files_wc')}</label>
                                                            <input value={actualWordCount} disabled style={{ width: "100%" }} className="custom-input" type="text" placeholder=""/>
                                                        </div>
                                                    }
                                                </div>
                                                {/* <div className="col">
                                                    {
                                                        stepTwo &&
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_total_files_pc')}</label>
                                                            <input value={actualPageCount} disabled style={{ width: "100%" }} className="custom-input" type="text" placeholder="Number of pages"/>
                                                        </div>
                                                    }
                                                </div> */}
                                            </div>
                                        }
                                        {
                                            uploadingTaskFiles.length!==0 && <div className="row" style={ stepTwo ? { paddingBottom:"20px" } : { paddingBottom:"20px", borderBottom : "1px solid #DFDFE0" }}>
                                                <div className="col-lg-2 col-md-2"></div>
                                                <div className="col">
                                                    {
                                                        stepTwo &&
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_words_to_translate')}</label>
                                                            <input value={wordCount} onFocus={this.wordCountFocus} onChange={this.wordCountChange} id="wordCount" name="wordCount" style={{ width: "100%" }} className="custom-input" type="text" placeholder=""/>
                                                        </div>
                                                    }
                                                </div>
                                                {/* <div className="col">
                                                    {
                                                        stepTwo &&
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_pages_to_translate')}</label>
                                                            <input value={pageCount} onFocus={this.pageCountFocus} onChange={this.pageCountChange} id="pageCount" name="pageCount" style={{ width: "100%" }} className="custom-input" type="text" placeholder="Number of pages"/>
                                                        </div>
                                                    }
                                                </div> */}
                                            </div>
                                        }
                                        {
                                            stepTwo && 
                                            <div className="row" style={{ paddingBottom:"20px" }}>
                                                <div className="col-lg-2 col-md-2"></div>
                                                <div className="col-lg-10 col-md-10">
                                                    {
                                                        stepTwo && 
                                                        <div className="form-group">
                                                            <label className="custom-create-task-label block align-left">{t('common_detailed_instruction')}</label>
                                                            <textarea defaultValue={detailedInstructions} onChange={this.onInputChange} style={{ resize : "none", width: "100%" }} name="detailedInstructions" id="detailedInstructions" rows={3} placeholder={t('common_enter_detailed_instruction')} className="custom-input"></textarea>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                       
                                        {/* STEP 3 */}
                                        <div className="row" style={{ paddingTop : "20px" }}>
                                            <div className="col-lg-2 col-md-2"><span className={` ${stepThree===true ? "active" : "inactive"} create-task-step-header block align-left`}>{t('common_step_three')}</span></div>
                                            <div className="col-lg-10 col-md-10"><span className={` ${stepThree===true ? "active" : "inactive"} create-task-step-header block align-left`}>{t('common_step_three_text')}</span></div>
                                        </div>
                                        <div className="row" style={ messageModalSubHeader === "" ? { paddingBottom:"20px", borderBottom : "1px solid #DFDFE0"} : {} }>
                                            <div className="col-lg-2 col-md-2"></div>
                                            <div className="col">
                                                {
                                                    stepThree && 
                                                    <div className="form-group">
                                                        <label className="custom-create-task-label block align-left">{t('common_date')}</label>
                                                        <DatePicker minDate={minDate} className={`date-picker`} style={{ width: "100%" }} showYearDropdown showMonthDropdown name="deadlineDate" id="deadlineDate" onChange={this.onDateChange} selected={selectedDate} placeholderText="_ _/_ _/_ _ _ _" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="col">
                                                {
                                                    stepThree &&
                                                    <div className="form-group">
                                                        <label className="custom-create-task-label block align-left">{t('common_time')}</label>
                                                        <DatePicker className="time-picker" dateFormat="H:mm" style={{ width: "100%" }} timeIntervals={60} showTimeSelect showTimeSelectOnly name="deadlineTime" id="deadlineTime" onChange={this.onTimeChange} selected={selectedTime} placeholderText="_ _:_ _" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <div className="row">
                                            <div className="col">&nbsp;</div>
                                        </div>
                                        <div className="row" style={{ marginTop: "-5px" }}>
                                            <div className="col" style={{ padding: "30px" }}>
                                                <div className="create-checklist align-left">
                                                    {t('common_checklist')}
                                                    <ul className="task-checklist">
                                                        <li className="align-left"><span>{ stepOneDone === false ? <img src={require('../../../../images/icon-uncheck.png')} style={{ width: "10px", height: "10px",  marginRight : currentLanguage === "ar" ? "unset" : "11px", marginLeft : currentLanguage==='ar' ? "11px" : "unset" }}/> : <img src={require('../../../../images/icon-check.png')} style={{ width: "10px", height: "10px", marginRight : currentLanguage === "ar" ? "unset" : "11px", marginLeft : currentLanguage==='ar' ? "11px" : "unset"}}/> }{t('common_checklist_one')}</span></li>
                                                        <li className="align-left"><span>{ stepOneDone === false ? <img src={require('../../../../images/icon-uncheck.png')} style={{ width: "10px", height: "10px",  marginRight : currentLanguage === "ar" ? "unset" : "11px", marginLeft : currentLanguage==='ar' ? "11px" : "unset" }}/> : <img src={require('../../../../images/icon-check.png')} style={{ width: "10px", height: "10px", marginRight : currentLanguage === "ar" ? "unset" : "11px", marginLeft : currentLanguage==='ar' ? "11px" : "unset"}}/> }{t('common_checklist_two')}</span></li>
                                                        <li className="align-left"><span>{ stepThreeDone === false ? <img src={require('../../../../images/icon-uncheck.png')} style={{ width: "10px", height: "10px",  marginRight : currentLanguage === "ar" ? "unset" : "11px", marginLeft : currentLanguage==='ar' ? "11px" : "unset" }}/> : <img src={require('../../../../images/icon-check.png')} style={{ width: "10px", height: "10px", marginRight : currentLanguage === "ar" ? "unset" : "11px", marginLeft : currentLanguage==='ar' ? "11px" : "unset"}}/> }{t('common_checklist_three')}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col" style={{ padding: "30px" }}>
                                                <button onClick={this.completeOrder} className="btn-complete-order">{t('common_complete_order')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isAdditionalServicesModalActive && 
                                    <AdditionalServicesModal 
                                        isOpened={isAdditionalServicesModalActive} 
                                        addTranslator={this.addTranslator}
                                        subTranslator={this.subTranslator}
                                        numberOfTranslators={numberOfTranslators}
                                        addToOrder={this.addToOrder}
                                        removeToOrder={this.removeToOrder}
                                        closeAdditionalServicesModal={this.closeAdditionalServicesModal}
                                        task={task}
                                        currentState={this.state}
                                        isViewing={false}
                                    />
                                }
                                {
                                    isProcessing && 
                                    <SpinnerModal
                                        isOpened={isProcessing}
                                        loadingTextHeader={loadingTextHeader}
                                    />
                                }
                                {
                                    isLoggingOut && 
                                    <SpinnerModal
                                        isOpened={isLoggingOut}
                                    />
                                }
                                {
                                    isMessageModalActive && 
                                    <Message 
                                        t={t}
                                        closeMessageModal={this.closeMessageModal} 
                                        isOpened={isMessageModalActive} 
                                        header={messageModalHeader} 
                                        subHeader={messageModalSubHeader}
                                        addModal={this.props.addModal}
                                        popModal={this.props.popModal}
                                        modalInstances={this.props.modalInstances}
                                    />
                                }
                        </div>
                    }
                </div>
            )
        }

        
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        isLoggingOut : state.auth.isLoggingOut,
        uploadingTaskFiles : state.task.uploadingTaskFiles,
        isUploadingFiles : state.task.isUploadingFiles,
        taskCreationState : state.task.taskCreationState,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        uploadTaskFiles, 
        getFileWordCount,
        getFilesWordCount,
        removeTaskFile, 
        computePriceApproximate,
        createTask,
        updateTask,
        saveTaskCreationState,
        getTaskDetails,
        updateCustomerTaskCreated,
        resetUploadingTaskFiles,
        getBasketCount,
        resetTaskCreationState
    })
)(EntryForm)