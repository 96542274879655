import React, { Component } from 'react'
import moment from 'moment';
import $ from 'jquery'

export class ConversationItem extends Component {

    constructor(){
        super();
        this.state = {
            shouldRender : false
        }
    }

    componentDidMount(){
        const { displayDelay } = this.props;
        this.timer = setTimeout(() => {
            this.setState({ shouldRender: true });
        }, displayDelay);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){
        const { shouldRender } = this.state;
        const { conversation, activeConversation, userProfile } = this.props;

        let imageUrl;
        let userName;
        let recipient;

        Object.keys(conversation.memberData).map( i=> {
            if(i!==userProfile.uid){
                recipient = conversation.memberData[i]
            }
        })

        imageUrl = recipient.imageUrl === "" ? require('../../images/default-user.jpg') : recipient.imageUrl
        userName = recipient.fullName.split(",");

        let conversationClass = (activeConversation!==null && activeConversation.uid===conversation.uid ) && "selected"
        let lastReceivedAt = ( conversation!==null && conversation.hasOwnProperty('lastMessageSentAt') ) ? conversation.lastMessageSentAt : null
        //let hasUnseenMessages = conversation.lastMessageSentBy !== "" && conversation.lastMessageSentBy !== userProfile.uid ? true : false
        let hasUnseenMessages = false;

        if(conversation.lastOpenedDates[userProfile.uid]!==undefined){
            hasUnseenMessages = (conversation.lastMessageSentAt.seconds >= conversation.lastOpenedDates[userProfile.uid].seconds && conversation.lastMessageSentBy!== userProfile.uid) ? true : false;
        }

        return (
            <div>
                {
                    shouldRender &&
                    <div onClick={ () => this.props.setActiveConversation(conversation) } className={`animated fadeIn chat-item ${conversationClass} `}>
                        <div className="chat-item-body">
                            <img className="display-photo" src={imageUrl}/>
                            <div className="details">
                                <span className="username">{userName[1]+' '+userName[0]}</span>
                                <span className="task-title">{conversation.taskTitle}</span>
                                <span className="chat-date">{lastReceivedAt !== null ? moment.unix(lastReceivedAt.seconds).fromNow() : ""}{hasUnseenMessages && <img src={require('../../images/icon-archived.png')} style={{ marginLeft : "10px",  width : "10px", height : "10px" }} />}</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )

    }

}

export default ConversationItem