import React, { Component } from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import moment from 'moment'

import { 
    getPrepaidTasks,
    loadMorePrepaidTasks,
    getFullyPaidTasks,
    loadMoreFullyPaidTasks,
    setOutcomeReportsFilter,
    resetOutcomeReportsFilter,
    setOutcomeReportsPaymentFilter,
    resetOutcomeReportsPaymentFilter
} from '../../../../actions/taskAction'

import TaskList from './List'
import SpinnerModal from '../../../Spinner/SpinnerModal';
import DateFilter from './DateFilter'
import PaymentFilterModal from '../../Modals/PaymentFilter'
import MobileList from './MobileList'
import {
    translate
} from 'react-switch-lang'

export class Index extends Component {

    constructor(){
        super();
        this.state= {
            isProcessing : false,
            isAuthenticated : false,
            dateFrom : new Date( moment().startOf('month') ),
            dateTo : new Date( moment().endOf('month')),
            isPaymentFilterModalActive : false,
            paymentFilter : "",
            statusFilter : "inProgress"
        }
    }

    componentDidMount(){
       
        const { auth , userProfile } = this.props;

        if(auth.uid){

            const { dateFrom, dateTo } = this.state;

            if(dateFrom!==null && dateTo!==null){
                this.props.setOutcomeReportsFilter({
                    dateFrom: dateFrom,
                    dateTo : dateTo
                })

                this.loadPrepaidTasks();
                this.loadFullyPaidTasks();
            }
            
        }

        if(userProfile.hasOwnProperty('fullName')){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userProfile!==this.props.userProfile){
            this.setState({
                isAuthenticated : true
            })
        }
    }

    scrollToBottom = () => {
        const scrollHeight = $('html')[0].scrollHeight;
        const height = $('html')[0].clientHeight;
        const maxScrollTop = scrollHeight - height;
        $('html')[0].scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    loadPrepaidTasks = () =>{
        this.props.getPrepaidTasks();
    }

    loadMorePrepaidTasks = () => {
        this.props.loadMorePrepaidTasks();
    }

    loadFullyPaidTasks = () =>{
        this.props.getFullyPaidTasks();
    }

    loadMoreFullyPaidTasks = () => {
        this.props.loadMoreFullyPaidTasks();
    }

    payAllAmount = () => {

        const { prepaidTasks } = this.props;
        
        this.props.history.push({
            pathname : 'task/payment',
            state : {
                tasks : prepaidTasks,
                multiple : true
            }
        });

    }

    onDateFromChange = (date) => {
        
        if(date!==undefined){
            this.setState({
                dateFrom : date
            }, () => {
                const { dateFrom, dateTo } = this.state;

                if(dateFrom!==null && dateTo!==null){
                    this.props.setOutcomeReportsFilter({
                        dateFrom: dateFrom,
                        dateTo : dateTo
                    })

                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }

                if(dateFrom===null && dateTo===null){
                    this.props.resetOutcomeReportsFilter();
                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }
               
            })
        }
    }

    onDateToChange = (date) =>{
        if(date!==undefined){
            this.setState({
                dateTo : date
            }, () => {
                const { dateFrom, dateTo } = this.state;

                if(dateFrom!==null && dateTo!==null){
                    this.props.setOutcomeReportsFilter({
                        dateFrom: dateFrom,
                        dateTo : dateTo
                    })
                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }

                if(dateFrom===null && dateTo===null){
                    this.props.resetOutcomeReportsFilter();
                    this.loadPrepaidTasks();
                    this.loadFullyPaidTasks();
                }
                
            })
        }
    }

    filterPaymentMethod = () => {
        this.setState({
            isPaymentFilterModalActive : true
        })
    }

    selectPaymentFilter = (e) => {
        
        let text = $(e.target).parent('.checkbox-container').text();

        if(text==="Paypal"){
            this.setState({
                paymentFilter : "paypal",
                isPaymentFilterModalActive : false
            }, () => {
                const { paymentFilter } = this.state;

                this.props.setOutcomeReportsPaymentFilter({
                    paymentFilter : paymentFilter
                })
                this.loadPrepaidTasks();
                this.loadFullyPaidTasks();
            })
        }

        if(text==="Credit Card"){
            this.setState({
                paymentFilter : "creditCard",
                isPaymentFilterModalActive : false
            }, () => {
                const { paymentFilter } = this.state;

                this.props.setOutcomeReportsPaymentFilter({
                    paymentFilter : paymentFilter
                })
                this.loadPrepaidTasks();
                this.loadFullyPaidTasks();
            })
        }
    }

    filterByStatus = (filter) => {

        this.setState({
            statusFilter : filter
        })

    }

    render() {

        const { isMobileView } = this.props;

        const { isAuthenticated, isProcessing } = this.state;
        const { isLoggingOut, auth, userProfile } = this.props;
        const { isPaymentFilterModalActive } = this.state;

        const { dateFrom, dateTo } = this.state;
        const { prepaidTasks, isGettingPrepaidTasks, isLoadingMorePrepaidTasks } = this.props;
        const { fullyPaidTasks, isGettingFullyPaidTasks, isLoadingMoreFullyPaidTasks } = this.props;

        const { statusFilter } = this.state;
        const { t, currentLanguage } = this.props;

        if(auth.uid && userProfile.status==="pending_signup"){
            return <Redirect to="/signup" />
        }
        
        if(auth.uid && !userProfile.hasOwnProperty('uid')){
            return ""
        }

        if(!auth.uid && !auth.emailVerified){
            return <Redirect to="/" />
        }else{
            if(isAuthenticated){
                if(userProfile.type!=="customer"){
                    return "NOT ALLOWED";
                }
            }
        }

        return (
            <div className="div-holder">
                {
                    (isAuthenticated && isMobileView) ?
                    <div style={{ position : "absolute", top : "0" }}>
                        <nav className="navbar navbar-expand-lg fixed-top">
                            <span className="page-mobile-header"><img onClick={ () => this.props.history.goBack() } style={{ cursor: "pointer", width: "15px", height : "20px",marginRight: currentLanguage === "ar" ? "unset" : "15px",  marginLeft: currentLanguage === "ar" ? "15px" : "unset",  transform: currentLanguage==="ar" ? "scaleX(-1)" : "unset" }} src={require('../../../../images/back.png')}/>{t('outcome_header')}</span>
                        </nav>
                    </div>:
                    ""
                }
                {
                    isAuthenticated &&
                    <div className="div-holder">
                        {
                            isMobileView ?
                            <div>
                                <div style={{ textAlign : "center", marginBottom : "20px" }}>
                                    <div>
                                        <DateFilter t={t} isMobileView={isMobileView} onDateToChange={this.onDateToChange} onDateFromChange={this.onDateFromChange} dateTo={dateTo} dateFrom={dateFrom} />
                                    </div>
                                </div>
                                <div style={{ flex : "1", marginTop : "20px", marginBottom : "20px" }}>
                                    <ul className="status-filters" style={{ paddingLeft : "0" }}>
                                        <li onClick={ () => this.filterByStatus('inProgress')} style={ { marginRight : "15px", flex : "1" } }><span style={ statusFilter==="inProgress" ? {  background: "#7AB7B6", color : "white", width : "100%", textAlign : "center" } : {   background: "#F5F4F6", color : "#7D7C7F", width : "100%", textAlign : "center" } } className="status-filter">{t('common_need_to_pay')}</span></li>
                                        <li onClick={ () => this.filterByStatus('completed')} style={ { flex : "1" } }><span style={ statusFilter==="completed" ? {  background: "#7AB7B6", color : "white", width : "100%", textAlign : "center" } : {   background: "#F5F4F6", color : "#7D7C7F", width : "100%", textAlign : "center" }  } className="status-filter">{t('outcome_old_projects')}</span></li>
                                    </ul>
                                </div>
                            </div>
                            :
                            <div style={{ display : "flex"}}>
                                <div className="align-left" style={{ flex : "1"}}>
                                    <p className="module-header">{t('navbar_outcome_report')}</p>
                                </div>
                                <div style={{ display : "flex", float : "right" }}>
                                    <DateFilter t={t} onDateToChange={this.onDateToChange} onDateFromChange={this.onDateFromChange} dateTo={dateTo} dateFrom={dateFrom} />
                                </div>
                                {/* <div style={{ display : "flex", float : "right"}}>
                                    <button className="filter-payment-method" onClick={this.filterPaymentMethod}>PAYMENT METHOD</button>
                                </div> */}
                            </div>
                            
                        }
                        
                        { !isMobileView && <span style={{ display: "block", marginBottom : "15px" }}>{t('common_need_to_pay')}</span>}
                        {
                            isGettingPrepaidTasks ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            isMobileView ?
                                prepaidTasks.length!== 0 && statusFilter==="inProgress" ?
                                <MobileList
                                    t={t}
                                    currentLanguage={currentLanguage}
                                    taskSet={"prepaid"}
                                    isLoadingMorePrepaidTasks={isLoadingMorePrepaidTasks}
                                    tasks={prepaidTasks}
                                    payAllAmount={this.payAllAmount}
                                    loadMorePrepaidTasks={this.loadMorePrepaidTasks}
                                />
                                : ""
                            :   
                            prepaidTasks.length!== 0 ?
                            <TaskList
                                t={t}
                                currentLanguage={currentLanguage}
                                taskSet={"prepaid"}
                                isLoadingMorePrepaidTasks={isLoadingMorePrepaidTasks}
                                tasks={prepaidTasks}
                                payAllAmount={this.payAllAmount}
                                loadMorePrepaidTasks={this.loadMorePrepaidTasks}
                            />
                            : ""
                            
                        }
                        { !isMobileView && <span style={{ display: "block", marginTop: "20px", marginBottom : "15px" }}>{t('outcome_old_projects')}</span>}
                        {
                            isGettingFullyPaidTasks ?
                            <div className="d-flex justify-content-center">
                                <div style={{ height: "5rem", width: "5rem" }} className="custom-spinner-border spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            isMobileView ?
                                fullyPaidTasks.length!== 0 && statusFilter==="completed" ?
                                <MobileList
                                    t={t}
                                    currentLanguage={currentLanguage}
                                    taskSet={"fullyPaid"}
                                    isLoadingMoreFullyPaidTasks={isLoadingMoreFullyPaidTasks}
                                    tasks={fullyPaidTasks}
                                    loadMoreFullyPaidTasks={this.loadMoreFullyPaidTasks}
                                />
                                : ""
                            :
                            fullyPaidTasks.length!== 0 ?
                            <TaskList
                                t={t}
                                currentLanguage={currentLanguage}
                                taskSet={"fullyPaid"}
                                isLoadingMoreFullyPaidTasks={isLoadingMoreFullyPaidTasks}
                                tasks={fullyPaidTasks}
                                loadMoreFullyPaidTasks={this.loadMoreFullyPaidTasks}
                            />
                            : ""
                            
                        }
                        {
                            isLoggingOut && 
                            <SpinnerModal
                                isOpened={isLoggingOut}
                            />
                        }
                        {
                            isPaymentFilterModalActive &&
                            <PaymentFilterModal 
                                isOpened={isPaymentFilterModalActive}
                                selectPaymentFilter={this.selectPaymentFilter}
                            />
                        }
                    </div>
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        userProfile : state.firebase.profile,
        prepaidTasks : state.task.prepaidTasks,
        isGettingPrepaidTasks : state.task.isGettingPrepaidTasks,
        isLoadingMorePrepaidTasks : state.task.isLoadingMorePrepaidTasks,
        fullyPaidTasks : state.task.fullyPaidTasks,
        isGettingFullyPaidTasks : state.task.isGettingFullyPaidTasks,
        isLoadingMoreFullyPaidTasks : state.task.isLoadingMoreFullyPaidTasks,
        isLoggingOut : state.auth.isLoggingOut,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, {   
        getPrepaidTasks,
        loadMorePrepaidTasks,
        getFullyPaidTasks,
        loadMoreFullyPaidTasks,
        setOutcomeReportsFilter,
        setOutcomeReportsPaymentFilter,
        resetOutcomeReportsFilter,
        resetOutcomeReportsPaymentFilter
    })
)(Index)