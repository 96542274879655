import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment';
import $ from 'jquery'

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'

import {
    addModal,
    popModal
} from '../../../actions/appAction';

import {
    translate
} from 'react-switch-lang'

export class PauseTaskModal extends Component {

    constructor(){
        super();
        this.state = {
            dateToContinue : null,
            pauseReason : "",
            modalZ : 1050
        }
    }

    componentDidMount(){
        const { isOpened } = this.props;
        
        if(isOpened){
            $('#pauseTaskModal').modal({backdrop:'static', keyboard:false});
            $('#pauseTaskModal .modal-dialog').attr('class','modal-dialog modal-dialog-centered slideInDown animated')
            $('#pauseTaskModal').modal('show');
            this.props.addModal("pause-task-modal")
            $('.modal-backdrop').eq($('.modal-backdrop').length-1).addClass("pt-bd");
        }

    }

    componentDidUpdate(prevProps){
        if(prevProps.modalInstances!==this.props.modalInstances){
            let modalInstance = this.props.modalInstances.find( (data) => data.modal_class==="pause-task-modal");
            $('.pt-bd').css('z-index',`${modalInstance.z_index-10}`)
            this.setState({
                modalZ : modalInstance.z_index
            })
        }
    }

    componentWillUnmount(){
        $('#pauseTaskModal').modal('hide');
        this.props.popModal()
        $('body').removeClass('modal-open');
    }

    onDateChange = (date) => {

        let d = moment(date)
       
        this.setState({
            dateToContinue : date
        })
        
    }

    selectReason = (e) => {
        let text = $(e.target).parent('.checkbox-container').text();
        this.setState({
            pauseReason : text
        })
    }

    pauseTask = () => {

        const { dateToContinue, pauseReason } = this.state;

        if(dateToContinue===null || pauseReason===""){
        }else{
            this.props.pauseTask({ pauseReason : pauseReason, dateToContinue : dateToContinue })
        }

    }

    render(){
        const { dateToContinue, modalZ } = this.state;
        const { isMobileView } = this.props;

        let modalDialogClass = isMobileView ? "mobile-modal" : "" 

        const { t, currentLanguage } = this.props;

        return (
            
            <div style={{ zIndex : `${modalZ}` }} className={`${modalDialogClass} pause-task-modal modal fade modal-opaque`} id="pauseTaskModal" tabIndex="-1" role="dialog" aria-labelledby="pauseTaskModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered animated" role="document">
                    <div className="modal-content">
                        {
                            isMobileView ?
                            <div className="mobile-custom-header modal-header">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col" style={{ textAlign : "left" }}>
                                        <img style={{ display: "block", width: "150px" }} src={require('../../../images/tarjemle-logo.png')}/>
                                    </div>
                                    <div className="col" style={{ textAlign : "right", paddingRight : "0" }}>
                                        <button onClick={this.props.closePauseTaskModal} type="button" className="close" aria-label="Close">
                                            <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="custom-modal-header modal-header">
                                <button onClick={this.props.closePauseTaskModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span className="close-modal-btn" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        }
                        <div className="modal-body">
                            <p className="text-header">{t('task_option_pause')}</p>
                            <p className="align-left" style={{ fontWeight : "bold", marginLeft : "30px", marginTop : "50px" }}>{t('task_modal_pause_reason_header')}</p>
                            
                            <div className="form-group">
                                <ul className="reason-list align-left">
                                    <li>
                                        <label className="checkbox-container">{t('task_modal_pause_no_money')}
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('task_modal_pause_info_updates')}
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                    <li>
                                        <label className="checkbox-container">{t('task_modal_pause_postponed')}
                                            <input name="reason" onClick={this.selectReason} className="reason" type="radio"/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <p className="align-left" style={{ fontWeight : "bold", marginLeft : "30px", marginTop : "50px" }}>{t('task_modal_pause_continue')}</p>

                            <div className="form-group" style ={{ textAlign : "left", paddingLeft : "30px" }}>
                                <label className="custom-create-task-label block align-left">{t('common_date')}</label>
                                <DatePicker style={{ width: "90%"}} className="date-picker" minDate={new Date(new Date().setDate(new Date().getDate() + 1))} showYearDropdown showMonthDropdown name="dateOfBirth" id="dateOfBirth" onChange={this.onDateChange} selected={dateToContinue} placeholderText="_ _/_ _/_ _ _ _" />
                            </div>
                            <div className="text-center">
                                <button onClick={this.pauseTask} style={{ width: isMobileView ? "100%" : "192px" , border :"none", background : "#22C9C7", borderRadius : "8px", padding: "16px 20px", fontSize : "16px", fontWeight : "bold", textTransform : "uppercase", textAlign : "center", color : "#FAFAFA", marginTop : "30px" }}>{t('task_option_pause')}</button>    
                            </div>                            
                        </div>
                    
                    </div>
                </div>
            </div>
            
        )

    }

}

const mapStateToProps = state => {
    return {
        modalInstances : state.app.modalInstances,
        isMobileView : state.app.isMobileView,
        currentLanguage : state.app.currentLanguage
    }
}

export default compose(
    withRouter,
    translate,
    connect(mapStateToProps, { 
        addModal,
        popModal
    })
)(PauseTaskModal)