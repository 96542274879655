import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter, Link } from 'react-router-dom'
import { watchConversationMessages, watchConversation, loadConversationMessages, clearActiveConversation } from '../../../actions/conversationAction'

import MessageItem from './MessageItem'

export class MessageList extends Component{

    constructor(props){
        super(props);
    }

    scrollToBottom = () => {
        const { messageList } = this.refs;
        const scrollHeight = messageList.scrollHeight;
        const height = messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    async componentDidMount(){
        const { activeConversation } = this.props;
        await this.props.loadConversationMessages(activeConversation);
        this.watchConversation && this.watchConversation()
        this.watchConversation = this.props.watchConversation(activeConversation.uid)
        this.watchConversationMessages && this.watchConversationMessages()
        this.watchConversationMessages = this.props.watchConversationMessages(activeConversation.uid)
    }

    componentWillUnmount(){
        this.props.clearActiveConversation();
        this.watchConversation && this.watchConversation()
        this.watchConversationMessages && this.watchConversationMessages()
    }

    componentDidUpdate(){
        this.scrollToBottom();
    }

    render () {
        const { messages, lastOpenedDate, currentLanguage, t } = this.props;

        return (

            <div className="message-list" ref="messageList">
                {
                    messages.map( (data, i) => <MessageItem t={t} currentLanguage={currentLanguage} lastOpenedDate={lastOpenedDate} key={i} message={data} /> )
                }
            </div>

        )

    }

}


const mapStateToProps = state => {
    return {
        auth : state.firebase.auth,
        activeConversation : state.conversation.activeConversation,
        lastOpenedDate : state.conversation.lastOpenedDate,
        messages : state.conversation.messages
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, { watchConversation, watchConversationMessages, loadConversationMessages, clearActiveConversation })
)(MessageList)
